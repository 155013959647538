define("isolocity/pods/employees/index/controller", ["exports", "isolocity/mixins/employee-list-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _employeeListMixin, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_employeeListMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.employees')
  });

  _exports.default = _default;
});