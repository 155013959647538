define("isolocity/mixins/ccr-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/action-item-mixin", "isolocity/mixins/icon-list-mixin"], function (_exports, _eSignatureMixin, _actionItemMixin, _iconListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _iconListMixin.default, _actionItemMixin.default, {
    isEdit: false,
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('ccrs');
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('ccrs', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('ccrs', 'is_esignature_required_on_save', false);
    },

    sources: ['Audit', 'Continuous Improvement', 'Documentation', 'Deviation/CAPA', 'External', 'Other'],
    actions: {
      createAssessmentActionItem: function createAssessmentActionItem(item) {
        this.send('createActionItem', item, 'ccr_assessment');
      },
      createImplementationPlanActionItem: function createImplementationPlanActionItem(item) {
        this.send('createActionItem', item, 'ccr_implementation_plan');
      },
      createActionItem: function createActionItem(item, code) {
        item.set('link', 'ccrs.edit');
        item.set('entity_type', 'ccr');
        item.set('use_queue', true);

        if (this.get('isEdit')) {
          item.set('entity_id', this.get('model.id'));
        }

        item.set('code', code);
        var actionItemsArray = code == 'ccr_implementation_plan' ? 'implementation_action_items' : 'assessment_action_items';
        this.get('model.' + actionItemsArray).pushObject(item);

        if (this.get('isEdit')) {
          item.save();
        }
      },
      addDepartmentToCcr: function addDepartmentToCcr(department) {
        var _this = this;

        var departments = this.get('model.departments');

        if (!departments.includes(department)) {
          this.get('model.departments').pushObject(department);
        }

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            _this.get('model').reload();
          });
        }
      },
      removeDepartmentFromCcr: function removeDepartmentFromCcr(department) {
        var _this2 = this;

        this.get('model.departments').removeObject(department);

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            _this2.get('model').reload();
          });
        }
      }
    }
  });

  _exports.default = _default;
});