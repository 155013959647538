define("isolocity/mixins/creates-action-items-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    sideMenu: Ember.inject.service(),
    isActionSaving: false,
    actionLink: null,
    actionEntityType: null,
    actions: {
      onActionItemCreated: function onActionItemCreated(actionItem) {
        var _this = this;

        if (Ember.isEmpty(this.get('actionEntityType'))) {
          return;
        }

        var session = this.get('authManager.session');
        var currentUserId = session.get('currentUser.id');
        this.set('isActionSaving', true);
        actionItem.set('entity_type', this.get('actionEntityType'));
        actionItem.set('entity_id', this.get('model.id'));
        actionItem.set('entity_type_secondary', this.get('inspectionModelType'));
        actionItem.set('link', this.get('actionLink'));
        actionItem.save().then(function (item) {
          _this.set('isActionSaving', false);

          _this.notifyPropertyChange('model.hasIncompleteActionItems');

          var fm = _this.get('flashMessages'),
              intl = _this.get('intl');

          setTimeout(function () {
            fm.success(intl.t('activity-log.You have successfully added the action item'));
            fm.success(intl.t('activity-log.It may take several moments to update this record Please refresh the page after few seconds'));
          }, 10);

          if (parseInt(item.get('assignee.id')) === parseInt(currentUserId) && item.get('assignee_type') === 'user') {
            _this.get('model.action_items').pushObject(item);

            if (!Ember.isBlank(_this.get('model.all_action_items'))) {
              _this.get('model.all_action_items').pushObject(item);
            }

            _this.notifyPropertyChange('model.action_items');
          }

          _this.set('model.emptyAction', _this.store.createRecord('action-item'));
        }, function (response) {
          _this.set('isActionSaving', false);
        });
      },
      actionItemSidebar: function actionItemSidebar(code, title) {
        var _this2 = this,
            _model$get;

        var assignee = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
        var details = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
        var priority = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'Medium';
        var dueDaysFromNow = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 5;
        var model = this.get('model');
        var dueDate = new moment().add(dueDaysFromNow, 'days');
        var newAction = this.store.createRecord('action-item', {
          code: code,
          title: title,
          details: details,
          assignee: assignee,
          priority: 'Medium',
          due_date: dueDate
        }); // NOTE: Unlock sidemenu. ( This is to prevent malfunctioning swipe gestures in mobile and tab key).
        // It malfunctions because the menu opens randomly and distubs the users.

        this.get('sideMenu').set('canOpen', true);
        model.set('emptyAction', newAction);
        this.set('sidebarCurrentTab', 'action-items');
        var delayMenuOpen = 100; // canOpen and isOpen variables cannot be updated simultaneously, sidemenu service gives error

        setTimeout(function () {
          // Proceed to open menu
          _this2.get("sideMenu").open('action-notes');
        }, delayMenuOpen); // Trigger observer and load Action Items Sidebar Data

        var loadActionItems = (_model$get = model.get('loadActionItems')) !== null && _model$get !== void 0 ? _model$get : 1;
        model.set('loadActionItems', loadActionItems + 1);
      }
    }
  });

  _exports.default = _default;
});