define("isolocity/pods/complaints/-form-submit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YSS05gWq",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,13],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,12],null,[[\"model\",\"module\",\"icons\",\"isShowCustomFields\",\"hasFormNameRevision\"],[[35,4],[35,11],[35,10],false,true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,13],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"model\",\"actionItems\",\"newRecord\",\"userNotes\",\"note\",\"onActionItemCreated\",\"onCompletedListReset\",\"onNoteCreated\",\"isSaving\",\"isNoteSaving\",\"onNotesReadAll\",\"hasUnreadNotes\",\"actionTitleOptions\"],[[35,4],[35,4,[\"action_items\"]],[35,4,[\"emptyAction\"]],[35,4,[\"user_notes\"]],\"\",\"onActionItemCreated\",\"onCompletedListReset\",\"onNoteCreated\",[35,3],[35,2],\"onNotesReadAll\",[35,1],[35,0]]]]],[2,\"\\n\\n    \"],[1,[30,[36,6],null,[[\"model\",\"activities\"],[[35,4],[35,4,[\"activities\"]]]]]],[2,\"\\n\\n\"],[6,[37,9],null,[[\"eSignaturePassword\",\"eSignaturePopup\"],[[35,8],[35,7]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"actionTitleOptions\",\"hasUnreadNotes\",\"isNoteSaving\",\"isActionSaving\",\"model\",\"module-sidebar\",\"sidebar/activity-log\",\"eSignaturePopup\",\"eSignaturePassword\",\"e-signature\",\"fontAwesomeIcons\",\"module\",\"settings-sidebar\",\"if\",\"isEdit\"]}",
    "meta": {
      "moduleName": "isolocity/pods/complaints/-form-submit/template.hbs"
    }
  });

  _exports.default = _default;
});