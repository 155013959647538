define("isolocity/pods/components/module-sidebar/action-items/component", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/action-item-priorities-mixin"], function (_exports, _actionItemMixin, _eSignatureMixin, _actionItemPrioritiesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_actionItemMixin.default, _eSignatureMixin.default, _actionItemPrioritiesMixin.default, {
    intl: Ember.inject.service(),
    items: [],
    errors: [],
    isEdit: true,
    isSaving: false,
    newRecord: null,
    isShortForm: true,
    isCustomTitle: false,
    showCompleted: false,
    actionTitleOptions: [],
    requiredFields: ['title', 'priority', 'assignee.id', 'due_date'],
    errorMessages: {},
    init: function init() {
      this.errorMessages = {
        'title': this.get('intl').t('The title field is required'),
        'priority': this.get('intl').t('The priority field is required'),
        'assignee': {
          'id': this.get('intl').t('The team member field is required')
        },
        'due_date': this.get('intl').t('The due date field is required'),
        'details': this.get('intl').t('The description field is required')
      };

      this._super.apply(this, arguments);
    },
    showDetailFields: Ember.computed('newRecord.title', 'isCustomTitle', 'allUsers.length', function () {
      return Ember.isBlank(this.get('actionTitleOptions')) || !Ember.isBlank(this.get('newRecord.title')) || this.get('isCustomTitle') === true;
    }),
    sortedItems: Ember.computed('items', 'items.length', function () {
      return this.get('items').sortBy('numericPriority', 'asc');
    }),
    onRequiredFieldsChanged: Ember.observer('newRecord.title', 'newRecord.priority', 'newRecord.assignee.id', 'newRecord.due_date', 'newRecord.details', function () {
      this.set('errors', []);
    }),
    hasCompletedItems: Ember.computed('items', 'items.length', function () {
      return this.get('items').filterBy('completed', true).length > 0;
    }),
    hasIncompleteItems: Ember.computed('items', 'items.length', function () {
      return this.get('items').filterBy('completed', false).length > 0;
    }),
    actions: {
      toggleCompletedItems: function toggleCompletedItems() {
        this.set('showCompleted', !this.get('showCompleted'));
      },
      resetCompletedList: function resetCompletedList(item) {
        this.send('eSignature', 'toggleActionItemCompleted', item, this.get('isEsignatureRequiredForItemCompletion')); // Update Status

        this.set('isCustomTitle', false);
        this.notifyPropertyChange('hasCompletedItems');
        this.notifyPropertyChange('hasIncompleteItems');
        this.sendAction('onCompletedListReset');
      },
      toggleActionItemCompleted: function toggleActionItemCompleted(item) {
        var _this = this;

        var intl = this.get('intl');
        item.set('completed', !item.get('completed'));

        if (this.get('isEdit')) {
          item.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (actionItem) {
            _this.send('clearEsignature');
          }, function (error) {
            item.set('completed', !item.get('completed'));

            _this.get('flashMessages').danger(intl.t('An error occurred while trying to add your authorization, please ensure your password is correct'));

            _this.send('clearEsignature');
          });
        }
      },
      chooseCustomTitle: function chooseCustomTitle() {
        this.set('isCustomTitle', true);
      },
      createActionItem: function createActionItem() {
        var _this2 = this;

        this.set('errors', []);
        this.get('requiredFields').forEach(function (fieldName) {
          if (Ember.isBlank(_this2.get('newRecord.' + fieldName)) || !_this2.get('newRecord.' + fieldName)) {
            _this2.get('errors').push(_this2.get('errorMessages.' + fieldName));
          }
        });

        if (Ember.isBlank(this.get('errors'))) {
          if (!this.get('newRecord.details')) {
            this.set('newRecord.details', '');
          }

          this.set('newRecord.assignee_id', this.get('newRecord.assignee.id'));
          this.sendAction('onActionItemCreated', this.get('newRecord'));
        }
      }
    }
  });

  _exports.default = _default;
});