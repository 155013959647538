define("isolocity/pods/employees/edit/controller", ["exports", "isolocity/mixins/file-download-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/user-employee-link-mixin", "moment"], function (_exports, _fileDownloadMixin, _pdfGenerationMixin, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _ajaxRequestMixin, _userEmployeeLinkMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _ajaxRequestMixin.default, _userEmployeeLinkMixin.default, _pdfGenerationMixin.default, _fileDownloadMixin.default, {
    PdfTitle: 'Training Activities',
    isEdit: true,
    isSaving: false,
    hasGroupsChanged: false,
    requiredFields: ['first_name', 'last_name', 'is_active'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    modules: Ember.inject.service('modules'),
    selectedUser: null,
    selectedPrograms: [],
    selectedDocuments: [],
    trainingStartDate: null,
    trainingEndDate: null,
    isTrainingRepeats: false,
    trainingRepeatFrequency: null,
    trainingRepeatDuration: null,
    isCheckingForTraining: false,
    confirmEnrollmentBtn: Ember.computed('trainingStartDate', 'trainingEndDate', function () {
      if (!Ember.isEmpty(this.get('trainingStartDate')) && !Ember.isEmpty(this.get('trainingEndDate'))) {
        return 'Save';
      }

      return false;
    }),
    initializeTrainingPrograms: function initializeTrainingPrograms(programs) {
      programs.map(function (program) {
        program.is_enroll_employee = true;
        return program;
      });
    },
    initializeTrainingDocuments: function initializeTrainingDocuments(documents) {
      documents.map(function (doc) {
        doc.is_enroll_employee = true;
        return doc;
      });
    },

    get isQueueDownloads() {
      var module = this.get('modules').getModuleBySlug('employees');
      return module.get('settings.is_queue_pdf_downloads');
    },

    actions: {
      save: function save() {
        if (this.get('isValid')) {
          if (this.get('hasGroupsChanged') && this.get('model.groups.length') > 0) {
            this.send('checkNewTrainingEnrollments');
          } else {
            this.send('saveEmployee');
          }
        } else {
          this.send('showErrors');
        }
      },
      saveWithoutEnrolling: function saveWithoutEnrolling() {
        this.set('enrollmentConfirmationPromise', null);

        if (this.get('isValid')) {
          this.send('saveEmployee');
        } else {
          this.send('showErrors');
        }
      },
      checkNewTrainingEnrollments: function checkNewTrainingEnrollments() {
        var _this = this;

        var isWizard = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var groupsToCheck = this.get('model.groupIds');
        var payload = {
          group_ids: groupsToCheck
        };

        if (isWizard) {
          payload['is_fresh_check'] = true;
          this.set('isCheckingForTraining', true);
        }

        var authHeaders = this.get('authHeaders');
        $.ajax({
          cache: false,
          headers: authHeaders,
          url: this.getApiUrl('/employees/' + this.get('model.id') + '/enrollments/check'),
          method: 'POST',
          data: payload
        }).then(function (data) {
          _this.set('isCheckingForTraining', false);

          if (data.programs.length === 0 && data.documents.length === 0) {
            if (!isWizard) {
              _this.send('saveEmployee');
            } else {
              _this.send('showNoEnrollmentsModal');
            }
          } else {
            _this.send('showEnrollmentConfirmation', data);
          }
        });
      },
      showNoEnrollmentsModal: function showNoEnrollmentsModal() {
        var _this2 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this2.set('noEnrollmentsPromise', null);
        }, function () {
          _this2.set('noEnrollmentsPromise', null);
        });
        this.set('noEnrollmentsPromise', defer);
      },
      showEnrollmentConfirmation: function showEnrollmentConfirmation(data) {
        var _this3 = this;

        var defer = Ember.RSVP.defer();
        this.initializeTrainingPrograms(data.programs);
        this.initializeTrainingDocuments(data.documents);
        this.set('selectedPrograms', data.programs);
        this.set('selectedDocuments', data.documents);
        this.set('trainingStartDate', new _moment.default());
        defer.promise.then(function () {
          _this3.set('isSaving', true);

          _this3.send('doEnrollment', _this3.get('selectedPrograms'), _this3.get('selectedDocuments'));

          _this3.set('enrollmentConfirmationPromise', null);
        }, function () {
          _this3.set('enrollmentConfirmationPromise', null);
        });
        this.set('enrollmentConfirmationPromise', defer);
      },
      doEnrollment: function doEnrollment(programs, documents) {
        var _this4 = this;

        var programIds = [],
            documentIds = [];
        programs.forEach(function (program) {
          if (program.is_enroll_employee) {
            programIds.push(program.id);
          }
        });
        documents.forEach(function (doc) {
          if (doc.is_enroll_employee) {
            documentIds.push(doc.id);
          }
        });

        if (programIds.length === 0 && documentIds.length === 0) {
          this.send('saveEmployee');
          return;
        }

        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/employees/' + this.get('model.id') + '/enrollments/create'),
          method: 'POST',
          data: {
            training_program_ids: programIds,
            document_ids: documentIds,
            start_date: (0, _moment.default)(this.get('trainingStartDate')).format("YYYY-MM-DD"),
            end_date: (0, _moment.default)(this.get('trainingEndDate')).format("YYYY-MM-DD"),
            repeat_frequency: this.get('trainingRepeatFrequency'),
            repeat_duration: this.get('trainingRepeatDuration')
          }
        }).then(function () {
          _this4.send('saveEmployee');
        }).always(function () {
          _this4.set('selectedPrograms', []);

          _this4.set('selectedDocuments', []);

          _this4.set('trainingStartDate', undefined);

          _this4.set('trainingEndDate', undefined);

          _this4.set('isTrainingRepeats', false);

          _this4.set('trainingRepeatFrequency', null);

          _this4.set('trainingRepeatDuration', null);
        });
      },
      saveEmployee: function saveEmployee() {
        var _this5 = this;

        this.set('isSaving', true);
        this.get('model').save().then(function (employee) {
          _this5.set('hasGroupsChanged', false);

          $('.component__company_logo').toggle();

          _this5.send('saveAttachments', employee);
        }, function (response) {
          _this5.send('showServerErrors', response);
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(employee) {
        var _this6 = this;

        var fm = this.get('flashMessages');
        this.get('history').setRoute('employees');
        this.set('hasSaved', true);
        this.get('model').reload().then(function () {
          _this6.set('isSaving', false);

          fm.success('You have successfully saved the employee.');
          fm.success('It may take several moments to update this record. Please refresh the page after few seconds!');
        });
      },
      resendTrainingPassword: function resendTrainingPassword() {
        var _this7 = this;

        this.set('isResendingPassword', true);
        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/employees/' + this.get('model.id') + '/password'),
          method: 'POST'
        }).then(function () {
          fm.success('The password has been re-sent');
        }).fail(function () {
          fm.danger('An error occurred while trying to send the password');
        }).always(function () {
          _this7.set('isResendingPassword', false);
        });
      },
      addGroupToEmployee: function addGroupToEmployee(group) {
        this.get('model.groups').pushObject(group);
        this.set('hasGroupsChanged', true);
      },
      removeGroupFromEmployee: function removeGroupFromEmployee(group) {
        this.get('model.groups').removeObject(group);
        this.set('hasGroupsChanged', true);
      },
      onFileUploadSuccessfully: function onFileUploadSuccessfully(data) {
        var _this8 = this;

        if (data.hasOwnProperty('file_id')) {
          this.get('model').set('profile_file_id', data.file_id);
          this.get('model').save().then(function (logo) {
            _this8.get('model').set('pic', _this8.store.find('file', data.file_id));

            _this8.get('model').set('current_logo_exists', true);

            _this8.set('hasSaved', undefined);

            $('.component__company_logo').toggle();
            var $el = $('#file-upload');
            $el.wrap('<form>').closest('form').get(0).reset();
            $el.unwrap();

            _this8.get('model').reload();
          });
        }
      },
      onFileUploadFailure: function onFileUploadFailure(message) {
        var fm = this.get('flashMessages');
        fm.danger(message);
        this.set('hasSaved', false);
      },
      linkUserProfile: function linkUserProfile(employee) {
        var _this9 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this9.send('doUserEmployeeLink', _this9.get('selectedUser'), employee);

          _this9.set('linkUserEmployeePopup', null);
        }, function () {
          _this9.set('linkUserEmployeePopup', null);
        });
        this.set('linkUserEmployeePopup', defer);
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      },
      createReportNew: function createReportNew() {
        var _this10 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var employee = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/employees/' + employee.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (_this10.get('isQueueDownloads')) {
            fm.success('Your download has been queued. You will be notified when it is ready.');

            _this10.set('isCreatingReport', false);

            return;
          }

          if (isDownload) {
            _this10.send('downloadPdfFile', data, 'TRAINING_ACTIVITIES_' + _this10.get('model.first_name').trim() + _this10.get('model.last_name').trim());
          } else {
            _this10.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this10.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this10.set('isCreatingReport', false);
        });
      },
      createReportNewWithAttachments: function createReportNewWithAttachments() {
        var _this11 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.send('createReportNew', isDownload);
        var car = this.get('model');
        var files = car.get('files');
        files.forEach(function (file, i) {
          // Delay download between files for browser optimization
          var timeout = 500 * i;
          setTimeout(function () {
            _this11.send('downloadFile', file);
          }, timeout);
        });
      }
    }
  });

  _exports.default = _default;
});