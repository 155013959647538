define("isolocity/pods/inspections/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('inspection', params.inspection_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (Ember.isEmpty(model.get('quantity_accepted')) && !model.get('finalized')) {
        if (!model.get('part_id.is_activity')) {
          model.set('quantity_accepted', model.get('samples').get('length'));
        }
      }

      this.store.findRecord(model.get('entity_type'), model.get('entity_id')).then(function (entity) {
        if (model.get('entity_type') == 'equipment') {
          model.notifyPropertyChange('model.equipment.materials');
        } else {
          model.notifyPropertyChange('model.part_id.materials');
          model.notifyPropertyChange('model.part_id.bi_products');
        }
      }); // Prepare Characteristics Multiple Choices if there are any

      model.get('samples').forEach(function (sample, i) {
        sample.get('results').forEach(function (result, i) {
          if (result.get('radio_group')) {
            var test_result = result.get('test_results') ? JSON.parse(result.get('test_results')) : null;
            var tolerance = JSON.parse(result.get('tolerance'));
            var radioGroupChoicesJson = tolerance.radioGroupChoices;
            result.set('radio_group_choices', []);
            var radioGroupChoices = result.get('radio_group_choices');

            if (!radioGroupChoices) {
              radioGroupChoices = [];
            }

            radioGroupChoicesJson.forEach(function (choiceJson, i) {
              var choice = _this.get('store').createRecord('inspection.sample.result.radio-group-choice', {
                'characteristic_id': choiceJson.characteristic_id,
                'name': choiceJson.name,
                'selected': choiceJson.selected
              });

              radioGroupChoices.pushObject(choice);
            }); //Set saved choice

            if (test_result && test_result.radio_group_selected != null) {
              result.set('radio_group_selected', test_result.radio_group_selected);
            }
          }
        });
      });
      model.set('error_codes', this.store.findAll('error-code'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('emptyAction', this.store.createRecord('action-item'));
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('inspections');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});