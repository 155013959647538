define("isolocity/pods/components/graphs/documents/distribution-by-stage/chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    chartOptions: {
      chart: {
        type: 'column'
      },
      lang: {
        contextButtonTitle: 'Download Chart'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        title: {
          text: 'Stage Number'
        },
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'Number of Documents'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<b>{point.y:.2f}</b> on <span style="color:{point.color}">{point.name}</span>'
      }
    }
  });

  _exports.default = _default;
});