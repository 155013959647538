define("isolocity/pods/equipment-reports/index/controller", ["exports", "isolocity/mixins/pagination-mixin"], function (_exports, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_paginationMixin.default, {
    sortProperties: ['is_overdue:desc', 'has_incomplete_action_items:desc', 'has_unread_notes:desc', 'is_closed:asc'],
    sortedReports: Ember.computed.sort('model.equipmentReports', 'sortProperties'),
    currentDate: Ember.computed(function () {
      return moment();
    })
  });

  _exports.default = _default;
});