define("isolocity/pods/receiving-inspections/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/receiving-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _uploadFilesMixin, _historyHandlerMixin, _receivingMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _receivingMixin.default, _uploadFilesMixin.default, (_Ember$Controller$ext = {
    intl: Ember.inject.service(),
    constants: Ember.inject.service('constants')
  }, _defineProperty(_Ember$Controller$ext, "intl", Ember.inject.service()), _defineProperty(_Ember$Controller$ext, "isLoading", false), _defineProperty(_Ember$Controller$ext, "id_prefix", 'new_'), _defineProperty(_Ember$Controller$ext, "historyOverride", ''), _defineProperty(_Ember$Controller$ext, "isCreate", true), _defineProperty(_Ember$Controller$ext, "actions", {
    create: function create() {
      var _this = this;

      var fm = this.get('flashMessages'),
          intl = this.get('intl'),
          model = this.get('model');

      if (this.get('isValid')) {
        if (!this.hasValidPartLineItems()) {
          var partText = this.get('constants').getModuleText(this.get('intl').t('modules.singular.parts'), 'lower');
          return;
        }

        this.set('isLoading', true);
        model.save().then(function (receiving) {
          _this.send('saveAttachments', receiving);
        }, function (error) {
          _this.set('isLoading', false);

          fm.danger(error.message);
        });
      } else {
        this.send('showErrors');
      }
    },
    insertRow: function insertRow(item) {
      this.send('insertRowLocally', item);
    },
    saveRow: function saveRow(item) {
      item.set('edittable', false);
    },
    afterAttachmentsSaved: function afterAttachmentsSaved(receiving) {
      var _this2 = this;

      var fm = this.get('flashMessages'),
          intl = this.get('intl');
      var model = this.get('model');

      if (model.get('emptyRow') && !model.get('items').includes(model.get('emptyRow'))) {
        this.send('insertRowLocally', model.get('emptyRow'));
      }

      if (model.get('items.length') === 0) {
        this.set('isLoading', false);
        this.get('history').setRoute('receiving-inspections');
        this.transitionToRoute('receiving-inspections.edit', receiving);
        fm.success(intl.t('receiving-inspections.others.Success Create Messages'));
      } else {
        model.get('items').forEach(function (item) {
          item.set('receiving_inspection_id', receiving);

          if (item === model.get('items.lastObject')) {
            item.save().then(function (saved) {
              _this2.set('isLoading', false);

              _this2.get('history').setRoute('receiving-inspections');

              _this2.transitionToRoute('receiving-inspections.edit', receiving);

              fm.success(intl.t('receiving-inspections.others.Success Create Messages'));
            });
          } else {
            item.save();
          }

          if (item.get('is_active_batch') === true) {
            var partId = item.get('part_id.id');

            _this2.store.findRecord('part', partId).then(function (part) {
              part.set('batch_number', item.get('serial_number'));
              part.save();
            });
          }
        });
      }
    }
  }), _Ember$Controller$ext));

  _exports.default = _default;
});