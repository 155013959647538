define("isolocity/pods/planning-reports/index/controller", ["exports", "isolocity/mixins/floating-sidebar-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _floatingSidebarMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_floatingSidebarMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.planning-reports'),
    types: ['Risk Management', 'Opportunity', 'Objective', 'Change Management'],
    graphTypes: ['Risk Management', 'Opportunity', 'Objective', 'Change Management', 'Communication'],
    priorities: ['Low', 'Medium', 'High'],
    sortProperties: ['has_incomplete_action_items:desc', 'has_unread_notes:desc', 'is_closed:asc', 'numericPriority:desc', 'dateOpenedTimestamp:desc', 'id:desc'],
    sortedReports: Ember.computed.sort('model.plannings', 'sortProperties'),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
          this.send('searchModule');
        }
      },
      searchModule: function searchModule() {
        this.get('search').setKeyword(this.get('searchText'));
        this.get('search').setStatus(this.get('searchStatus'));
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.set('searchStatus', '');
        this.get('search').clear();
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      }
    }
  });

  _exports.default = _default;
});