define("isolocity/pods/password/reset/controller", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    actions: {
      submit: function submit() {
        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (!Ember.isBlank($('#email').val())) {
          $.ajax({
            url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/authenticate/password-reset-email',
            type: 'post',
            data: {
              email: $('#email').val()
            },
            headers: {
              ApiKey: _environment.default.APP.apiKey
            },
            dataType: 'json',
            success: function success(data) {// console.info(data);
            }
          });
          fm.success(intl.t('password.others.Email Instructions'));
        } else {
          fm.danger(intl.t('password.others.Email Address'));
        }
      }
    }
  });

  _exports.default = _default;
});