define("isolocity/pods/components/graphs/ncrs/cost-of-quality/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/ncrs/cost-of-quality/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.send('loadChart', this.get('graphDataUrl'));
    },
    graphDataUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/ncrs/cost-of-quality/graph';
    }),
    onReportCriteriaChange: Ember.observer('selectedOverview', 'selectedPart', 'selectedErrorCode', 'selectedCost', function () {
      this.set('chartData', null);
      var data = {};
      var overview = this.get('selectedOverview');
      var part = this.get('selectedPart');
      var errorCode = this.get('selectedErrorCode');
      var cost = this.get('selectedCost');

      if (!Ember.isEmpty(overview)) {
        data.overview = overview;
      }

      if (!Ember.isEmpty(part)) {
        data.part_id = part.get('id');
      }

      if (!Ember.isEmpty(errorCode)) {
        data.error_code_id = errorCode.get('id');
      }

      if (!Ember.isEmpty(cost)) {
        data.cost = cost;
      }

      this.send('loadChart', data);
    }),
    setChartData: function setChartData(data) {
      var dataset = [];

      for (var year in data) {
        for (var month in data[year]) {
          if (!Ember.isEmpty(data[year][month])) {
            dataset.push([Date.UTC(year, month - 1, 1), data[year][month].count]);
          }
        }
      }

      var chartLabel = this.get('intl').t('corrective-actions.labels.Cost of Quality');
      this.set('chartData', [{
        name: chartLabel,
        data: dataset,
        type: 'column'
      }]);
    },
    setStackedChartData: function setStackedChartData(data) {
      var series = [];

      for (var category in data) {
        var dataset = [];

        for (var year in data[category]) {
          for (var month in data[category][year]) {
            if (!Ember.isEmpty(data[category][year][month])) {
              dataset.push([Date.UTC(year, month - 1, 1), data[category][year][month]]);
            }
          }
        }

        series.pushObject({
          name: category,
          data: dataset,
          type: 'column'
        });
      }

      if (!Ember.isEmpty(data)) {
        this.set('chartData', series);
      } else {
        var chartLabel = this.get('intl').t('corrective-actions.labels.Cost of Quality');
        this.set('chartData', [{
          name: chartLabel,
          data: []
        }]);
      }
    },
    actions: {
      loadChart: function loadChart(data) {
        var _this = this;

        var reportData = {
          company_ids: this.get('reportingService').get('companies')
        };

        if (data.overview) {
          reportData.overview = data.overview;
        }

        if (data.part_id) {
          reportData.part_id = data.part_id;
        }

        if (data.error_code_id) {
          reportData.error_code_id = data.error_code_id;
        }

        if (data.cost) {
          reportData.cost = data.cost;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('graphDataUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            if (reportData.overview) {
              _this.setStackedChartData(data);
            } else {
              _this.setChartData(data);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});