define("isolocity/pods/employees/archived/controller", ["exports", "isolocity/mixins/pagination-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/employee-list-mixin"], function (_exports, _paginationMixin, _dateSearchMixin, _employeeListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_employeeListMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.employees-archived')
  });

  _exports.default = _default;
});