define("isolocity/helpers/get-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var value = params[0];

    for (var i = 1; i < params.length; i++) {
      value = value[params[i]];
    }

    return value;
  });

  _exports.default = _default;
});