define("isolocity/mixins/parts-clone-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getModelForClonedPart: function getModelForClonedPart(clonePartId) {
      var _this = this;

      return this.store.findRecord('part', clonePartId, {
        reload: true
      }).then(function (part) {
        part.set('isCloning', false);

        var newPart = _this.store.createRecord('part');

        newPart.setProperties(part.getProperties(Object.keys(part.toJSON())));
        newPart.set('files', []);
        newPart.set('name', '');
        newPart.set('identification', '');
        newPart.set('label_info', part.get('label_info'));
        newPart.set('isCloning', true);
        part.get('materials').forEach(function (material) {
          var newMaterial = _this.store.createRecord('part.material');

          newMaterial.setProperties(material.getProperties(Object.keys(material.toJSON())));
          newPart.get('materials').pushObject(newMaterial);
        });
        return newPart;
      });
    },
    actions: {
      clonePart: function clonePart(part) {
        var routeName = this.get('isActivity') ? 'parts.activity' : 'parts.new';
        part.set('isCloning', true);
        this.transitionToRoute(routeName, {
          queryParams: {
            clone_part_id: part.get('id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});