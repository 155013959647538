define("isolocity/pods/companies/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NBiphj1X",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,2],[[35,1],\"name\"],null]]]],[12],[1,[30,[36,3],[\"companies.labels.Name\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"value\",\"id\",\"placeholder\"],[[35,4,[\"name\"]],[30,[36,2],[[35,1],\"name\"],null],[30,[36,3],[\"companies.other.Enter the teams name\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,2],[[35,1],\"status\"],null]]]],[12],[1,[30,[36,3],[\"companies.labels.Status\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"id\",\"status\"],[[30,[36,2],[[35,1],\"status\"],null],[35,4,[\"is_active\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,2],[[35,1],\"timezone\"],null]]]],[12],[1,[30,[36,3],[\"companies.labels.Timezone\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"value\",\"items\",\"preventNew\"],[[35,4,[\"timezone\"]],[35,7],true]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"order_number\"],[12],[1,[30,[36,3],[\"companies.labels.Order Number Start\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"type\",\"min\",\"value\"],[\"number\",\"0\",[35,4,[\"settings\",\"order_start_number\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"id_prefix\",\"concat\",\"t\",\"model\",\"input\",\"active-inactive-toggle\",\"validTimezones\",\"auto-complete\"]}",
    "meta": {
      "moduleName": "isolocity/pods/companies/-form/template.hbs"
    }
  });

  _exports.default = _default;
});