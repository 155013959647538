define("isolocity/mixins/cost-tracker-route-mixin", ["exports", "moment", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _moment, _environment, _ajaxRequestMixin, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, _authenticationRoutingMixin.default, {
    year: (0, _moment.default)().format('YYYY'),
    month: (0, _moment.default)().format('M'),
    startDate: null,
    endDate: null,
    batchNumber: null,
    modelName: ['expense'],
    model: function model(params) {
      if (!Ember.isEmpty(params.year) && !Ember.isEmpty(params.month)) {
        this.set('year', params.year);
        this.set('month', params.month);
      }

      return {
        expenses: this.store.query('expense', {
          perPage: _environment.default.APP.pagination,
          startingPage: 1,
          totalPagesParam: 'meta.total_pages',
          modelPath: 'controller.model.expenses',
          year: this.get('year'),
          month: this.get('month'),
          start_date: this.get('startDate') ? (0, _moment.default)(this.get('startDate')).format("YYYY-MM-DD") : '',
          end_date: this.get('endDate') ? (0, _moment.default)(this.get('endDate')).format("YYYY-MM-DD") : '',
          batch_number: this.get('batchNumber') ? this.get('batchNumber') : ''
        })
      };
    },
    afterModel: function afterModel(model, transition) {
      var authHeaders = this.get('authHeaders');
      var controller = this.controllerFor("costs");
      controller.set('isByBatchLoading', true);
      controller.set('isExpenseLoading', true);
      controller.set('year', this.get('year'));
      controller.set('month', this.get('month'));
      controller.set('isCustomFilter', false);
      controller.set('timePeriod', (0, _moment.default)(this.get('year') + '-' + this.get('month') + '-01').format('MMMM YYYY'));

      if (this.get('startDate') || this.get('endDate')) {
        var timePeriod = '';
        controller.set('isCustomFilter', true);

        if (this.get('startDate')) {
          timePeriod = (0, _moment.default)(this.get('startDate')).format("MMM Do, YYYY") + ' and later';
        }

        if (this.get('endDate')) {
          timePeriod = (0, _moment.default)(this.get('endDate')).format("MMM Do, YYYY") + ' and earlier';
        }

        if (this.get('startDate') && this.get('endDate')) {
          timePeriod = (0, _moment.default)(this.get('startDate')).format("MMM DD, YYYY") + ' - ' + (0, _moment.default)(this.get('endDate')).format("MMM DD, YYYY");
        }

        controller.set('timePeriod', timePeriod);
      }

      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/expenses/overview'),
        method: 'GET',
        data: {
          year: this.get('year'),
          month: this.get('month'),
          start_date: this.get('startDate') ? (0, _moment.default)(this.get('startDate')).format("YYYY-MM-DD") : '',
          end_date: this.get('endDate') ? (0, _moment.default)(this.get('endDate')).format("YYYY-MM-DD") : '',
          batch_number: this.get('batchNumber') ? this.get('batchNumber') : ''
        }
      }).then(function (data) {
        controller.set('expensesOverview', {
          is_error: false,
          total_cost: data.total_cost,
          num_expenses: data.num_expenses,
          percent_change: data.percent_change,
          percent_type: data.percent_type,
          fixed_percentage: data.fixed_percentage,
          variable_percentage: data.variable_percentage
        });
      }).fail(function () {
        controller.set('expensesOverview', {
          is_error: true,
          total_cost: 0,
          num_expenses: 0,
          percent_change: 0,
          percent_type: null,
          fixed_percentage: 0,
          variable_percentage: 0
        });
      });
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/expenses/batches'),
        method: 'GET',
        data: {
          year: this.get('year'),
          month: this.get('month'),
          start_date: this.get('startDate') ? (0, _moment.default)(this.get('startDate')).format("YYYY-MM-DD") : '',
          end_date: this.get('endDate') ? (0, _moment.default)(this.get('endDate')).format("YYYY-MM-DD") : '',
          batch_number: this.get('batchNumber') ? this.get('batchNumber') : ''
        }
      }).then(function (data) {
        controller.set('filteredBatches', data);
        controller.set('isByBatchLoading', false);
      });
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/expenses'),
        method: 'GET',
        data: {
          year: this.get('year'),
          month: this.get('month'),
          start_date: this.get('startDate') ? (0, _moment.default)(this.get('startDate')).format("YYYY-MM-DD") : '',
          end_date: this.get('endDate') ? (0, _moment.default)(this.get('endDate')).format("YYYY-MM-DD") : '',
          batch_number: this.get('batchNumber') ? this.get('batchNumber') : ''
        }
      }).then(function (data) {
        controller.set('filteredExpenses', data);
        controller.set('isExpenseLoading', false);
      });
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.refresh();
      },
      goNextMonth: function goNextMonth() {
        var newYear = parseInt(this.get('month')) === 12 ? parseInt(this.get('year')) + 1 : this.get('year');
        var newMonth = parseInt(this.get('month')) === 12 ? 1 : parseInt(this.get('month')) + 1;
        this.transitionTo('costs.timeline', newYear, newMonth);
      },
      goPrevMonth: function goPrevMonth() {
        var newYear = parseInt(this.get('month')) === 1 ? parseInt(this.get('year')) - 1 : this.get('year');
        var newMonth = parseInt(this.get('month')) === 1 ? 12 : parseInt(this.get('month')) - 1;
        this.transitionTo('costs.timeline', newYear, newMonth);
      },
      doFilterExpenses: function doFilterExpenses(startDate, endDate, batchNumber) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);
        this.set('batchNumber', batchNumber);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});