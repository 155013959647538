define("isolocity/pods/components/e-signature/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_defineProperty({
    intl: Ember.inject.service(),
    eSignatureReasons: [],
    onReasonChanged: Ember.observer('eSignatureReason', function () {
      if (this.get('eSignatureReason') === 'Other') {
        this.sendAction('customReason');
        this.set('eSignatureReason', '');
      }
    })
  }, "eSignatureReasons", Ember.computed('intl.locale', function () {
    return [this.get('intl').t('e-signatures.New Data'), this.get('intl').t('e-signatures.Data change'), this.get('intl').t('e-signatures.Incorrect data'), this.get('intl').t('e-signatures.Data verification'), this.get('intl').t('e-signatures.Other')];
  })));

  _exports.default = _default;
});