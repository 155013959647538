define("isolocity/pods/components/employee-groups-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    selectedGroup: null,
    parentEntityName: 'list',
    actions: {
      removeGroupFromDocument: function removeGroupFromDocument(group) {
        var _this = this;

        var fm = _this.get('flashMessages'),
            intl = this.get('intl');

        this.get('model.groups').removeObject(group);

        if (this.get('isEdit') === true) {
          this.get('model').save().then(function () {
            fm.success(intl.t('The employee group has been removed from the document'));
          });
        } else {
          fm.success(intl.t('The employee group has been removed from the document'));
        }
      }
    }
  });

  _exports.default = _default;
});