define("isolocity/pods/components/module-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentTab: 'action-items',
    store: Ember.inject.service(),
    isSaving: false,
    actionItems: [],
    userNotes: [],
    allUsers: [],
    allEmployees: [],
    actionTitleOptions: [],
    sideMenu: Ember.inject.service(),
    newRecord: null,
    note: '',
    hasUnreadNotes: false,
    loadActionItems: null,
    actionItemsTabClass: Ember.computed('currentTab', function () {
      return this.get('currentTab') === 'action-items' ? 'active' : '';
    }),
    canOpenSideMenu: Ember.computed('sideMenu.canOpen', function () {
      return this.get('sideMenu.canOpen');
    }),
    notesTabClass: Ember.computed('currentTab', function () {
      return this.get('currentTab') === 'notes' ? 'active' : '';
    }),
    onLoadActionItemsChanged: Ember.observer('model.loadActionItems', function () {
      var _this$get,
          _this = this;

      this.set('allEmployees', this.get('store').findAll('employee'));
      this.set('allUsers', this.get('store').findAll('user'));
      var modelLogNamename = (_this$get = this.get('modelLogName')) !== null && _this$get !== void 0 ? _this$get : this.get('model').constructor.modelName;
      this.get('store').query('action-item', {
        per_page: 50,
        page: 1,
        keyword: this.get('keyword'),
        mine: true,
        entity: modelLogNamename,
        entity_id: this.get('model.id')
      }).then(function (items) {
        if (items.get('length') > 0) {
          _this.set('actionItems', items);
        }

        _this.set('model.isReloading', false);
      });
    }),
    actions: {
      onActionItemCreated: function onActionItemCreated() {
        this.sendAction('onActionItemCreated', this.get('newRecord'));
      },
      onNoteCreated: function onNoteCreated() {
        this.sendAction('onNoteCreated', this.get('note'));
      },
      onCompletedListReset: function onCompletedListReset() {
        this.sendAction('onCompletedListReset');
      },
      onNotesReadAll: function onNotesReadAll() {
        this.sendAction('onNotesReadAll');
      },
      switchTab: function switchTab(tabName) {
        this.set('currentTab', tabName);
      }
    }
  });

  _exports.default = _default;
});