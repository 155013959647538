define("isolocity/pods/audit-type/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4AU6zNpU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n  \"],[1,[34,0]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[1,[30,[36,1],[\"audit-type.Name\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"value\",\"id\",\"placeholder\"],[[35,2,[\"name\"]],\"name\",[30,[36,1],[\"audit-type.Audit Name\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[1,[30,[36,1],[\"audit-type.Status\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"status\",\"onActiveChange\"],[[35,2,[\"is_active\"]],\"updateStatus\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[1,[30,[36,1],[\"audit-type.Reports\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"frequency\"],[12],[1,[30,[36,1],[\"audit-type.Frequency\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"value\",\"id\",\"placeholder\"],[[35,2,[\"frequency\"]],\"frequency\",\"\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"duration\"],[12],[1,[30,[36,1],[\"audit-type.Duration\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"placeholder\",\"items\",\"selectedValue\"],[\"Select a duration\",[35,5],[35,2,[\"duration\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"t\",\"model\",\"input\",\"active-inactive-toggle\",\"durationValues\",\"drop-down\"]}",
    "meta": {
      "moduleName": "isolocity/pods/audit-type/-form/template.hbs"
    }
  });

  _exports.default = _default;
});