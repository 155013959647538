define("isolocity/pods/error-code/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    intl: Ember.inject.service(),
    isCreating: false,
    requiredFields: ['code', 'name'],
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.set('isCreating', true); // Update loading status

        if (!this.get('isValid')) {
          this.set('isCreating', false); // Update loading status

          this.send('showErrors');
          return;
        }

        this.get('model').save().then(function (error_code) {
          fm.success(intl.t('You have successfully created an error code'));
          history.back();

          _this.set('isCreating', false);
        }, function (error) {
          fm.danger(error.message);

          _this.set('isCreating', false); // Update loading status

        });
      }
    }
  });

  _exports.default = _default;
});