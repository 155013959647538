define("isolocity/pods/components/confirm-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    design: null,
    tagName: 'div',
    isConfirmEnabled: true,
    classNameBindings: [':confirm-modal__wrapper', ':modal-wrapper', 'hasPromise:modal-wrapper--show'],
    logoutPopup: false,
    click: function click(e) {
      if ($(e.target).is('.confirm-modal__wrapper')) {
        this.send('reject');
      }
    },
    confirm: '',
    cancel: '',
    init: function init() {
      this.confirm = this.get('intl').t('Confirm'), this.cancel = this.get('intl').t('Cancel'), this._super.apply(this, arguments);
    },
    hasPromise: Ember.computed('promise', function () {
      return !Ember.isEmpty(this.get('promise'));
    }),

    get className() {
      return this.get('design') && this.get('design') == 'sidebar' ? ' modal-window-sidebar' : ' modal-window';
    },

    keyPress: function keyPress(ev) {
      var keys = {
        tab: 9,
        enter: 13,
        escape: 27,
        up: 38,
        down: 40
      };

      if (ev.keyCode === keys['enter']) {
        this.get('promise').resolve();
        return false;
      }
    },
    actions: {
      accept: function accept() {
        this.get('promise').resolve();
      },
      reject: function reject() {
        this.get('promise').reject();
      },
      delete: function _delete() {
        this.get('promise').resolve({
          isDelete: true
        });
      }
    }
  });

  _exports.default = _default;
});