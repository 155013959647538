define("isolocity/pods/inventory/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'inventory.index',
    search: Ember.inject.service('search.inventory'),
    modelName: ['inventory', 'inventory.history'],
    model: function model(params) {
      this.startLoading();
      return {
        inventory: this.store.query('inventory', {
          per_page: _environment.default.APP.pagination,
          page: this.get('currentPage'),
          keyword: this.get('search').getKeyword(),
          type: this.get('search').getType(),
          status: this.get('search').getStatus(),
          supplier_id: this.get('search').getSupplierId(),
          sort_by: this.get('search').getSortBy(),
          expiry_soon: this.get('search').getExpirySoon(),
          is_active: true
        })
      };
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      model.inventory.then(function (inventory) {
        _this.doneLoading();

        _this.setPageMetadata(inventory);
      });
      model.suppliers = this.store.findAll('supplier');
      model.inventory_dropdown = this.store.query('inventory', {
        list_type: 'for_autocompletion'
      });
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.set('currentPage', 1);
        this.refresh();
      },
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('inventory');
        }
      }
    }
  });

  _exports.default = _default;
});