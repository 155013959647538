define("isolocity/pods/inspection/sample/result/radio-group-choice/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    characteristic_id: _emberData.default.belongsTo('inspection.sample.result', {
      inverse: 'radio_group_choices'
    }),
    name: _emberData.default.attr('string'),
    selected: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});