define("isolocity/pods/components/inspections-table/result/radio-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    readOnly: false,
    result: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('isTemplate')) {
        this.setupCheckboxSelection();
      }
    },
    selectedIndices: Ember.computed('result.radio_group_selected', function () {
      var selected = this.get('result.radio_group_selected');

      if (selected === undefined || selected === null) {
        // Handle cases where selected is undefined or null
        return [];
      }

      if (typeof selected === 'number') {
        return [selected.toString()]; // Convert number to string
      }

      if (!Array.isArray(selected)) {
        // Handle cases where selected is neither number nor array
        // Maybe set it to an empty array or some default value
        return [];
      } // Already an array, no need to convert


      return selected;
    }),
    setupCheckboxSelection: function setupCheckboxSelection() {
      var selectedIndices = this.get('selectedIndices').slice();
      var checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(function (checkbox, index) {
        checkbox.checked = selectedIndices.includes(index.toString());
      });
    },
    checkboxSelectionChanged: Ember.observer('result.radio_group_selected.[]', function () {
      // Recalculate isCheckboxChecked when radio_group_selected changes
      this.notifyPropertyChange('isCheckboxChecked');
    }),
    isCheckboxChecked: Ember.computed('index', 'result.radio_group_selected.[]', function () {
      var index = this.get('index');

      if (typeof index === 'undefined') {
        return false; // Handle the case where index is undefined
      }

      var selectedIndices = this.get('selectedIndices') || [];
      return selectedIndices.includes(index.toString());
    }),
    isEnforceMultipleSelction: Ember.computed('result.enforce_multiple_selection', function () {
      var enforceMultipleSelection = this.get('result.enforce_multiple_selection');
      var radioGroupSelected = this.get('result.radio_group_selected'); // If enforce_multiple_selection is true and radio_group_selected is empty or an array

      if (enforceMultipleSelection === true) {
        return Array.isArray(radioGroupSelected) || Ember.isEmpty(radioGroupSelected);
      } // If enforce_multiple_selection is false and radio_group_selected is empty or a number


      if (enforceMultipleSelection === false) {
        return false;
      } // If enforce_multiple_selection is undefined


      if (enforceMultipleSelection === undefined) {
        // Return false if radio_group_selected is empty
        if (Ember.isEmpty(radioGroupSelected)) {
          return false;
        } // Return true if radio_group_selected is an array


        if (Array.isArray(radioGroupSelected)) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      addItem: function addItem(result) {
        var choice = this.store.createRecord('drawing.characteristic.radio-group-choice', {
          'characteristic_id': result.get('id'),
          'name': ''
        });
        result.get('radio_group_choices').pushObject(choice);
      },
      // old function for single seclection
      toggleChoice: function toggleChoice(value, result) {
        var radio_group_choices = result.get('radio_group_choices');
        var choice = radio_group_choices.objectAt(value);

        if (this.get('isTemplate')) {
          radio_group_choices.forEach(function (the_choice, i) {
            the_choice.set('selected', i == value ? true : false);
          }); // Unselect if same choice clicked

          if (result.get('radio_group_selected') == value) {
            value = null;
            choice.set('selected', false);
          }
        }

        result.set('radio_group_selected', value);
      },
      toggle: function toggle(value) {
        if (this.get('readOnly') || this.get('isTemplate')) {
          return;
        }

        this.sendAction('toggle', value);
      },
      handleKeyPress: function handleKeyPress(event) {
        this.sendAction('handleKeyPress', event);
      },
      toggleCheckboxChoice: function toggleCheckboxChoice(index, result) {
        var radio_group_choices = result.get('radio_group_choices');

        if (index >= 0 && index < radio_group_choices.length) {
          var selectedChoice = radio_group_choices.objectAt(index); // Ensure the selectedChoice object exists before toggling its 'selected' property

          if (selectedChoice) {
            // Toggle the 'selected' property of the selectedChoice
            selectedChoice.toggleProperty('selected');

            if (!selectedChoice.get('selected')) {
              selectedChoice.set('selected', false);
            }
          }
        }
      },
      toggleInspectionCheckboxMultipleSelection: function toggleInspectionCheckboxMultipleSelection(index, result) {
        var selectedIndices = this.get('selectedIndices').slice();
        var currentIndex = selectedIndices.indexOf(index.toString());

        if (currentIndex === -1) {
          // If the index is not already selected, add it to the array
          selectedIndices.push(index.toString());
        } else {
          // If the index is already selected, remove it from the array
          selectedIndices.splice(currentIndex, 1);
        } // Update the radio_group_selected array


        result.set('radio_group_selected', selectedIndices);
        result.notifyPropertyChange('radio_group_selected');
        this.setupCheckboxSelection();
      }
    }
  });

  _exports.default = _default;
});