define("isolocity/pods/companies/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rYGyc2if",
    "block": "{\"symbols\":[\"filteredModel\",\"company\"],\"statements\":[[6,[37,16],null,[[\"filterFields\",\"newPath\",\"isModelLoading\",\"model\"],[[30,[36,15],[\"name\"],null],\"companies.new\",[35,14],[35,13,[\"companies\"]]]],[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"listType\",\"currentPage\",\"totalPages\",\"totalRecords\",\"previousPage\",\"nextPage\",\"filterSuffix\"],[[30,[36,9],[\"Team\"],null],[35,8],[35,7],[35,6],[30,[36,5],[[32,0],\"previousPage\"],null],[30,[36,5],[[32,0],\"nextPage\"],null],[30,[36,4],[[35,3],[35,2]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"        \"],[10,\"table\"],[14,0,\"data-table\"],[12],[2,\"\\n            \"],[10,\"thead\"],[12],[2,\"\\n                \"],[10,\"tr\"],[12],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,9],[\"companies.labels.Status\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,9],[\"companies.headers.Team Name\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[30,[36,11],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"tr\",\"companies.edit\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[10,\"td\"],[12],[1,[30,[36,0],null,[[\"status\"],[[32,2,[\"is_active\"]]]]]],[13],[2,\"\\n                        \"],[10,\"td\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"current-status\",\"link-to\",\"searchDescriptor\",\"searchTypeDescriptor\",\"concat\",\"action\",\"totalRecords\",\"totalPages\",\"currentPage\",\"t\",\"pagination\",\"-track-array\",\"each\",\"model\",\"isModelLoading\",\"create-array\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/companies/index/template.hbs"
    }
  });

  _exports.default = _default;
});