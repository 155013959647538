define("isolocity/pods/customers/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l+jcy9N+",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,9],null,[[\"modelName\",\"disableHeaderButton\",\"hideFilterFields\",\"isModelLoading\",\"model\"],[\"customer\",[35,8],[35,7],[35,6],[35,5,[\"customers\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,0],\"list\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[19,\"customers/list\",[1]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[35,0],\"reports\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[19,\"customers/reports\",[1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"div\"],[14,0,\"col floating-action-buttons\"],[12],[2,\"\\n            \"],[11,\"a\"],[24,\"title\",\"List\"],[24,6,\"#\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[[35,0],\"list\"],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"setViewMode\",\"list\"],null],[12],[2,\"\\n                \"],[10,\"i\"],[14,0,\"fa fa-table fa-2x\"],[12],[13],[2,\"\\n                \"],[10,\"p\"],[14,0,\"text-center\"],[12],[1,[30,[36,4],[\"sidebar.List\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[11,\"a\"],[24,\"title\",\"Reports\"],[24,6,\"#\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[[35,0],\"reports\"],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"setViewMode\",\"reports\"],null],[12],[2,\"\\n                \"],[10,\"i\"],[14,0,\"fa fa-file-excel-o fa-2x\"],[12],[13],[2,\"\\n                \"],[10,\"p\"],[14,0,\"text-center\"],[12],[1,[30,[36,4],[\"sidebar.Reports\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":true,\"upvars\":[\"viewMode\",\"equals\",\"if\",\"action\",\"t\",\"model\",\"isModelLoading\",\"hideFilterFields\",\"disableHeaderButton\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/customers/index/template.hbs"
    }
  });

  _exports.default = _default;
});