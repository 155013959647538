define("isolocity/pods/document-type/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XivgloTP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n  \"],[11,\"form\"],[4,[38,0],[[32,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"goBack\"],[[30,[36,0],[[32,0],\"goBack\"],null]]]]],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[30,[36,2],[\"document-type.headers.Edit Document Type\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n\\n      \"],[19,\"document-type/form\",[]],[2,\"\\n\\n    \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\\n      \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[15,2,[30,[36,2],[\"document-type.other.Save Document Type\"],null]],[14,4,\"submit\"],[12],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"action\",\"back-link\",\"t\"]}",
    "meta": {
      "moduleName": "isolocity/pods/document-type/edit/template.hbs"
    }
  });

  _exports.default = _default;
});