define("isolocity/mixins/risk-report-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin"], function (_exports, _eSignatureMixin, _iconListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _iconListMixin.default, {
    allowNew: true,
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('risk-report');
    },

    riskReportTypes: [{
      key: 'pfmea',
      name: 'Potential Failure Mode & Effects Analysis (PFMEA)'
    }, {
      key: 'haccp',
      name: 'Hazard Analysis & Critical Control Points (HACCP)'
    }],
    sortedPfmeaItems: Ember.computed('model.pfmea_items', function () {
      return this.get('model.pfmea_items').sortBy('order', 'asc');
    }),
    sortedHaccpItems: Ember.computed('model.haccp_items', function () {
      return this.get('model.haccp_items').sortBy('order', 'asc');
    }),

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('risk-report', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('risk-report', 'is_esignature_required_on_save', false);
    },

    actions: {
      addDepartmentToRiskReport: function addDepartmentToRiskReport(department) {
        var _this = this;

        var departments = this.get('model.departments');

        if (!departments.includes(department)) {
          this.get('model.departments').pushObject(department);
        }

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            _this.get('model').reload();
          });
        }
      },
      removeDepartmentFromRiskReport: function removeDepartmentFromRiskReport(department) {
        var _this2 = this;

        this.get('model.departments').removeObject(department);

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            _this2.get('model').reload();
          });
        }
      },
      selectedReportType: function selectedReportType(type) {
        this.set('model.report_type', type.key);
      },
      showPfmeaLegend: function showPfmeaLegend() {
        var _this3 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this3.set('pfmeaLegendPopup', null);
        }, function () {
          _this3.set('pfmeaLegendPopup', null);
        });
        this.set('pfmeaLegendPopup', defer);
      },
      insertPfmeaRow: function insertPfmeaRow(item) {
        this.send('insertRow', item, 'pfmea');
      },
      savePfmeaRow: function savePfmeaRow(item) {
        this.send('saveRow', item, 'pfmea');
      },
      deletePfmeaRow: function deletePfmeaRow(item) {
        this.send('deleteRow', item, 'pfmea');
      },
      insertHaccpRow: function insertHaccpRow(item) {
        this.send('insertRow', item, 'haccp');
      },
      saveHaccpRow: function saveHaccpRow(item) {
        this.send('saveRow', item, 'haccp');
      },
      deleteHaccpRow: function deleteHaccpRow(item) {
        this.send('deleteRow', item, 'haccp');
      }
    }
  });

  _exports.default = _default;
});