define("isolocity/pods/components/receiving-parts-table/component", ["exports", "isolocity/mixins/parts-filter-mixin", "isolocity/mixins/table-editor-mixin", "isolocity/mixins/parts-table-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _partsFilterMixin, _tableEditorMixin, _partsTableMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorMixin.default, _partsFilterMixin.default, _partsTableMixin.default, _userOrganizationMixin.default, {
    sortedItems: Ember.computed.sort('model.items', 'sortDefinition'),
    sortDefinition: ['order:asc'],
    modules: Ember.inject.service('modules'),
    isSecondaryLotNumberColumnVisible: Ember.computed('modules', function () {
      var receivingInspectionsModule = this.get('modules').getModuleBySlug('receiving-inspections');
      return receivingInspectionsModule.settings.sections.secondary_lot_number;
    }),
    actions: {
      handlePartSelect: function handlePartSelect(item) {
        var _this = this;

        if (Ember.isBlank(item) || item.get('isDeleted')) {
          return;
        }

        var partId = item.get('part_id');

        if (!Ember.isEmpty(item.get('part_id.id')) && Ember.isEmpty(item.get('expiry_date')) && !Ember.isEmpty(partId.get('shelf_life_duration')) && !Ember.isEmpty(partId.get('shelf_life_frequency'))) {
          this.get('store').findRecord('part', item.get('part_id.id'));
          var frequency = partId.get('shelf_life_frequency');
          var duration = partId.get('shelf_life_duration');
          var expiry_date = moment().add(frequency, duration.toLowerCase());
          Ember.set(item, 'expiry_date', expiry_date);
        }

        if (Ember.isBlank(item.get('serial_number'))) {
          item.set('serial_number', this.get('model.serial_number'));
        }

        if (!Ember.isBlank(item.get('part_id.unit_of_measure'))) {
          item.set('unit_of_measure', item.get('part_id.unit_of_measure'));
        }

        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/parts/' + item.get('part_id.id') + '/historical-batch-numbers'),
          method: 'GET'
        }).then(function (data) {
          if (data.indexOf(_this.get('model.serial_number')) == -1) {
            // Adding serial_number at the begining of the array
            data = [_this.get('model.serial_number')].concat(data);
          }

          item.set('historical_batch_numbers', data);
        });
        this.send('refreshDrawing', item);
      },
      onSupplierPartsChange: function onSupplierPartsChange() {
        this.sendAction('notifyPartsChange');
      },
      inspectItem: function inspectItem(item) {
        this.sendAction('inspectItem', item);
      },
      inspectAllItems: function inspectAllItems() {
        this.sendAction('inspectAllItems');
      }
    }
  });

  _exports.default = _default;
});