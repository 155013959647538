define("isolocity/helpers/translate-module-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    // constants: Ember.inject.service('constants'),
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var slug = params[0],
          name = params[1],
          originalName = params[2];
      var intl = this.get('intl');
      return name == originalName ? intl.t("modules.original.".concat(slug)) : name;
    }
  });

  _exports.default = _default;
});