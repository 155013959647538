define("isolocity/pods/components/display-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+G0Lofgn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[34,4]],[13],[2,\"\\n\\n    \"],[1,[34,5]],[2,\"\\n\\n\"],[6,[37,6],[\"user\"],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"user-search\"],[12],[2,\"\\n        \\t\"],[11,\"form\"],[4,[38,0],[[32,0],\"searchAll\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n         \\t\\t\"],[10,\"span\"],[14,0,\"search-all\"],[12],[2,\"\\n           \\t\\t\\t\"],[1,[30,[36,3],null,[[\"type\",\"value\",\"placeholder\",\"keyUp\"],[\"text\",[35,2],[30,[36,1],[\"Search all modules\"],null],[30,[36,0],[[32,0],\"searchAll\"],null]]]]],[2,\"\\n\\t            \"],[13],[2,\"\\n         \\t\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"searchKeyword\",\"input\",\"title\",\"user-options\",\"min-role\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/display-header/template.hbs"
    }
  });

  _exports.default = _default;
});