define("isolocity/mixins/equipment-users-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isEdit: false,
    actions: {
      addUserToEquipment: function addUserToEquipment(user) {
        var owners = this.get('model.owners');
        var users = this.get('model.users');

        if (owners.includes(user)) {
          this.get('model.owners').removeObject(user);
        }

        if (!users.includes(user)) {
          this.get('model.users').pushObject(user);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      addOwnerToEquipment: function addOwnerToEquipment(user) {
        var owners = this.get('model.owners');
        var users = this.get('model.users');

        if (users.includes(user)) {
          this.get('model.users').removeObject(user);
        }

        if (!owners.includes(user)) {
          this.get('model.owners').pushObject(user);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeUserFromEquipment: function removeUserFromEquipment(user) {
        this.get('model.users').removeObject(user);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeOwnerFromEquipment: function removeOwnerFromEquipment(user) {
        this.get('model.owners').removeObject(user);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      }
    }
  });

  _exports.default = _default;
});