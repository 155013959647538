define("isolocity/pods/orders/new/controller", ["exports", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/orders-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _historyHandlerMixin, _ordersMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _ordersMixin.default, {
    constants: Ember.inject.service('constants'),
    id_prefix: 'new_',
    historyOverride: '',
    isLoading: false,
    intl: Ember.inject.service(),
    isNew: true,
    insertRowLocally: function insertRowLocally(item) {
      if (Ember.isEmpty(item.get('order_id')) || Ember.isBlank(item.get('quantity'))) return; //push new item to list

      var items = this.get('model.items');
      items.pushObject(item);
      item.setProperties({
        'order': items.get('length'),
        'edittable': false,
        'alwaysEdittable': false,
        'isSavedLocally': true
      });
      this.set('model.emptyRow', this.store.createRecord('order.item', {
        serial_number: this.get('model.serial_number'),
        part_id: null,
        alwaysEdittable: true
      }));
    },
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages'),
            model = this.get('model'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          this.set('showSaveErrors', false);

          if (!this.hasValidPartLineItems()) {
            var partText = this.get('constants').getModuleText(this.get('intl').t('modules.singular.parts'), 'lower');
            fm.danger(intl.t('orders.others.Selected Valid') + ' ' + partText + ' ' + intl.t('orders.others.Quantity Line'));
            this.set('showSaveErrors', true);
            return;
          }

          if (!Ember.isBlank(model.get('emptyRow.part_id')) && !Ember.isBlank(model.get('emptyRow.quantity')) && Ember.isBlank(model.get('emptyRow.isSavedLocally'))) {
            this.insertRowLocally(model.get('emptyRow'));
          }

          this.set('isLoading', true);
          model.save().then(function (order) {
            if (model.get('items.length') === 0) {
              _this.set('isLoading', false);

              _this.get('history').setRoute('orders');

              _this.transitionToRoute('orders.edit', order);

              fm.success(intl.t('orders.others.Order Success'));
            } else {
              model.get('items').forEach(function (item) {
                item.set('order_id', order);

                if (item === model.get('items.lastObject')) {
                  item.save().then(function (saved) {
                    _this.set('isLoading', false);

                    _this.get('history').setRoute('orders');

                    _this.transitionToRoute('orders.edit', order);

                    fm.success(intl.t('orders.others.Order Success'));
                  });
                } else item.save();
              });
            }
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      insertRow: function insertRow(item) {
        //check if valid
        this.insertRowLocally(item);
      },
      saveRow: function saveRow(item) {
        item.set('edittable', false);
      }
    }
  });

  _exports.default = _default;
});