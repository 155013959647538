define("isolocity/pods/components/employee-groups-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fc84twM4",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"data-table table-with-inputs\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[1,[30,[36,2],[\"Name\"],null]],[13],[2,\"\\n        \"],[10,\"th\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"groups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[6,[37,0],null,[[\"tagName\",\"route\",\"model\"],[\"td\",\"groups.edit\",[32,1]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n          \"],[10,\"td\"],[14,0,\"remove\"],[12],[2,\"\\n              \"],[11,\"button\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"removeGroupFromDocument\",[32,1]],null],[12],[2,\"x\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\",\"action\",\"t\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/employee-groups-table/template.hbs"
    }
  });

  _exports.default = _default;
});