define("isolocity/pods/corrective-actions/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/has-departments-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/corrective-action-mixin", "isolocity/mixins/module-required-fields-mixin", "isolocity/mixins/car-preventative-action-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _pdfGenerationMixin, _uploadFilesMixin, _hasDepartmentsMixin, _userOrganizationMixin, _ownersAndSubscribersMixin, _correctiveActionMixin, _moduleRequiredFieldsMixin, _carPreventativeActionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _pdfGenerationMixin.default, _hasDepartmentsMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _ownersAndSubscribersMixin.default, _correctiveActionMixin.default, _moduleRequiredFieldsMixin.default, _carPreventativeActionMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    id_prefix: 'new_',
    isNew: true,
    isLoading: false,
    isReadOnly: false,
    modelSettings: null,
    moduleSlug: 'corrective-actions',
    ingoreRequiredFields: Ember.computed(function () {
      if (this.get('permissions').hasRoleAtLeast('manager')) {
        return ['approval_completed_description', 'verification_completed_description', 'approval_comlpeted_description'];
      }

      return ['root_cause_description', 'verification_completed_description', 'approval_comlpeted_description', 'preventative_action_description', 'approval_completed_description', 'process_effect'];
    }),
    defaultRequiredFields: ['po_number'],
    defaultRequiredFieldsTranslation: {
      'po_number': 'Job #',
      'date_opened': 'Date Opened',
      'description': 'Description',
      'immediate_action': 'Immediate Action',
      "verification_completed_description": "Verification Description",
      'root_cause_description': 'Root Cause Description',
      'approval_completed_description': 'Conclusion',
      'regulatory_authorization_description': 'Supplier\'s Verification Actions',
      'process_effect': 'Effect on Other Processes',
      'preventative_action_description': 'Preventative Description'
    },
    relatedRequiredFields: ['preventative_action_description', 'approval_completed_description'],
    hasSource: Ember.computed('model.car_source_id', function () {
      var source = this.get('model.car_source_id.name');
      return source === 'Customer' || source === 'Supplier';
    }),
    hasFilesUploader: Ember.computed('model.is_closed', 'model.isSaving', function () {
      var _isClosed = this.get('model.is_closed'),
          _isSaving = this.get('model.isSaving');

      return !_isClosed || _isSaving;
    }),
    onModelSettingsChange: Ember.observer('model.custom_form', function () {
      var model = this.get('model');
      var newSettings = !Ember.isBlank(model.get('custom_form')) ? JSON.parse(model.get('custom_form')) : JSON.parse('{ "settings": { "fields":{}, "sections":{} } }'); // Initiate settings key if it is missing in custom_form array

      if (!Ember.isBlank(newSettings) && Ember.isBlank(newSettings['settings'])) {
        newSettings['settings'] = JSON.parse('{ "fields":{}, "sections":{} }');
      } // Get settings from default module settings and update if applicable


      var oldSettings = this.get('module.settings');

      for (var key in oldSettings) {
        // In modules.settings property for this report, fields are under extra level called 'visible' or 'required'
        if (key == 'fields') {
          if (oldSettings['fields'].hasOwnProperty('visible')) {
            for (var childKey in oldSettings['fields']['visible']) {
              if (Ember.isBlank(newSettings['settings']['fields'][childKey])) {
                // Only update if setting does not exist on current report
                newSettings['settings']['fields'][childKey] = oldSettings['fields']['visible'][childKey];
              }
            }
          } else {
            for (var childKey in oldSettings['fields']['required']) {
              if (Ember.isBlank(newSettings['settings']['fields'][childKey])) {
                // Only update if setting does not exist on current report
                newSettings['settings']['fields'][childKey] = oldSettings['fields']['required'][childKey];
              }
            }
          }
        }

        if (key == 'sections') {
          for (var childKey in oldSettings['sections']) {
            if (Ember.isBlank(newSettings['settings']['sections'][childKey])) {
              // Only update if setting does not exist on current report
              newSettings['settings']['sections'][childKey] = oldSettings['sections'][childKey];
            }
          }
        } // For all other cases, fields are under current level


        if (!['fields', 'sections', 'required', 'helpers', 'configuration'].includes(key)) {
          if (Ember.isBlank(newSettings['settings']['fields'][key])) {
            // Only update if setting does not exist on current report
            newSettings['settings']['fields'][key] = oldSettings[key];
          }
        }
      }

      this.set('modelSettings', newSettings);
    }),
    onSourceChange: Ember.observer('model.car_source_id', function () {
      var model = this.get('model');

      if (model.get('car_source_id.name') !== 'Customer') {
        model.set('source_customer_id', null);
      }

      if (model.get('car_source_id.name') !== 'Supplier') {
        model.set('source_supplier_id', null);
        model.set('send_supplier_email', false);
      }

      if (model.get('car_source_id.name') === 'Supplier') {
        model.set('send_supplier_email', true);
      }
    }),
    onTypeChange: Ember.observer('model.car_type_id.id', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model) || model.get('isDeleted') || model.get('isDeleting')) {
        return;
      }

      if (model.get('car_type_id.code') !== 'INS') {
        model.set('type_inspection_id', null);
      }

      if (model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_id', null);
      }

      if (Ember.isEmpty(model.get('car_type_id.code')) && model.get('car_type_id.code') !== 'INS' && model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_rev_number', null);
        model.set('disposition', null);
        model.set('disposition_authorized_by', null);
      }
    }),
    controlActionOptions: [{
      code: 'car_ctrl_sop_updates',
      name: 'SOP Updates'
    }, {
      code: 'car_ctrl_pfmea_updates',
      name: 'PFMEA Updates'
    }, {
      code: 'car_ctrl_plan_updates',
      name: 'Control Plan Updates'
    }, {
      code: 'car_ctrl_similar_process_review',
      name: 'Similar Process Review'
    }, {
      code: 'car_ctrl_training_records_updates',
      name: 'Training Records Updates'
    }],
    controlActionItem: Ember.computed('model.all_action_items', 'model.allUsers', function () {
      return this.store.createRecord('action-item', {
        users: this.get('model.allUsers')
      });
    }),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners'))) {
            fm.danger(this.get('intl').t('At least one owner is required'));
            return;
          }

          var model = this.get('model');
          this.set('isLoading', true);
          model.save().then(function (car) {
            car.get('implementation_action_items').forEach(function (item) {
              item.set('entity_id', car.get('id'));
              item.save();
            });
            car.get('control_system_action_items').forEach(function (item) {
              item.set('entity_id', car.get('id'));
              item.save();
            });

            if (!Ember.isEmpty(car.get('ncr_id.id'))) {
              _this.store.findRecord('ncr', car.get('ncr_id.id')).then(function (ncr) {
                ncr.set('closed_at', new moment());
                ncr.save();
              });
            }

            if (model.get('preventative_actions.length') > 0) {
              _this.send('savePreventativeAndAttachments', car);
            } else {
              _this.send('saveAttachments', car);
            }
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.errors);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(car) {
        var _this2 = this;

        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('corrective-actions.other.A new corrective action report has been created successfully'));
        this.get('history').setRoute('corrective-actions');
        car.reload().then(function (theCar) {
          _this2.set('isLoading', false);

          _this2.transitionToRoute('corrective-actions.edit', theCar);
        });
      },
      handleSelection: function handleSelection() {
        var _this3 = this;

        var closeDate = this.get('model.date_closed');
        this.set('promise', null);

        if (!Ember.isBlank(closeDate) && closeDate.isValid()) {
          var defer = Ember.RSVP.defer();
          defer.promise.then(function () {
            _this3.set('promise', null);
          }, function () {
            _this3.set('model.date_closed', null);

            _this3.set('promise', null);
          });
          this.set('promise', defer);
        }
      },
      selectControlSystemUpdate: function selectControlSystemUpdate(item) {
        this.set('controlActionItem.code', item.code);
        this.set('controlActionItem.title', item.name);
      },
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.set('isFiveWhyAnalysis', true);
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.set('isFishboneAnalysis', true);
      },
      createControlSystemAction: function createControlSystemAction() {
        var action = this.get('controlActionItem'),
            fm = this.get('flashMessages');

        if (Ember.isEmpty(action.get('assignee.id')) || Ember.isEmpty(action.get('code'))) {
          fm.danger('Please select a control system update and responsible user');
          return;
        }

        action.set('entity_type', 'car');
        action.set('entity_id', this.get('model.id'));
        action.set('assignee_id', action.get('assignee.id'));
        action.set('priority', 'Medium');
        action.set('due_date', new moment().add(5, 'days'));
        action.set('link', 'corrective-actions.edit');
        this.set('isControlSystemSaving', true);
        this.get('model.control_system_action_items').pushObject(action);
        fm.success('A new control system update has been added to this report');
        this.set('isControlSystemSaving', false);
        this.set('selectedControlSystemUpdate', null);
        this.set('controlActionItem', this.store.createRecord('action-item', {
          users: this.store.peekAll('user')
        }));
      }
    }
  });

  _exports.default = _default;
});