define("isolocity/pods/components/option-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: [':approval-toggle'],
    approvedText: Ember.computed(function () {
      return this.get('options.lastObject');
    }),
    approveAction: Ember.computed(function () {
      return this.get('options.lastObject');
    }),
    unapprovedText: Ember.computed(function () {
      return this.get('options.firstObject');
    }),
    unapproveAction: Ember.computed(function () {
      return this.get('options.firstObject');
    }),
    disabled: false,
    actions: {
      toggle: function toggle() {
        this.toggleProperty('status');
        this.sendAction('onStatusChange');
      }
    }
  });

  _exports.default = _default;
});