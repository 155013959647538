define("isolocity/mixins/mentionable-mixin", ["exports", "ember-jquery-legacy"], function (_exports, _emberJqueryLegacy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isPresent = Ember.isPresent,
      computed = Ember.computed;
  var Mentionable = Ember.Object.extend({
    token: '@',
    re: null,
    values: null
  });

  var _default = Ember.Mixin.create({
    elementClassNames: null,
    actions: {
      didFocusIn: function didFocusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      },
      didFocusOut: function didFocusOut() {
        this.sendAction('didFocusOut'); // eslint-disable-line ember/closure-actions
      },
      didKeyPress: function didKeyPress(value, event) {
        this.sendAction('didKeyPress', value, event); // eslint-disable-line ember/closure-actions
      },
      didInsertNewline: function didInsertNewline() {
        this.sendAction('didInsertNewline'); // eslint-disable-line ember/closure-actions
      },
      didPressEnter: function didPressEnter() {
        this.sendAction('didPressEnter'); // eslint-disable-line ember/closure-actions
      },
      didPressEscape: function didPressEscape() {
        this.sendAction('didPressEscape'); // eslint-disable-line ember/closure-actions
      },
      didKeyDown: function didKeyDown() {
        this.sendAction('didKeyDown'); // eslint-disable-line ember/closure-actions
      },
      didKeyUp: function didKeyUp(event, value) {
        var jEvent = (0, _emberJqueryLegacy.normalizeEvent)(event);
        this.sendAction('didKeyUp', event); // eslint-disable-line ember/closure-actions

        this.selectionStart = event.target.selectionStart;
        this.focusPicker(jEvent);

        if (jEvent.keyCode !== 38 && jEvent.keyCode !== 40 && jEvent.keyCode !== 13) {
          Ember.run.debounce(this, this.parseMentionables, this.get('debounceTime'));
        }
      },
      didInput: function didInput(event) {
        this.sendAction('didInput', event); // eslint-disable-line ember/closure-actions
      },
      didSelectValue: function didSelectValue(selectedValue) {
        this.updateValue(selectedValue);
        this.sendAction('didSelectValue', selectedValue); // eslint-disable-line ember/closure-actions
      },
      focusInput: function focusInput() {
        this.$(this.get('inputSelector')).focus();
      },
      updateKeypress: function updateKeypress(event) {
        var $input = this.$(this.get('inputSelector'));
        var value = $input.val();
        $input.focus().val("".concat(value).concat(event.key));
      }
    },
    config: null,
    value: null,
    mentionables: null,
    inputSelector: 'input',
    inputClassNames: 'mentionable-input',
    debounceTime: 100,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var mentionables = Ember.A();
      Ember.makeArray(this.get('config')).map(function (configItem) {
        Ember.assert('Values must be an array!', Array.isArray(configItem.values)); // make a copy of values to keep from mutating on sort

        var values = Ember.A(configItem.values.slice());
        var mentionable = Mentionable.create(configItem);
        mentionable.set('re', new RegExp("(^|\\W+)".concat(mentionable.get('token'), "\\w*$"), 'gi'));
        var searchProperty = mentionable.get('searchProperty');
        values = isPresent(searchProperty) ? values.sortBy(searchProperty) : values.sort();
        mentionable.set('values', values);
        mentionables.addObject(mentionable);
      });
      this.set('mentionables', mentionables);
    },
    parseMentionables: function parseMentionables() {
      var _this = this;

      this.set('matchingValues', null);
      this.set('matchingValuesPending', true);
      var promises = Ember.A();
      this.get('mentionables').map(function (mentionable) {
        promises.addObject(_this.parseMentionable(mentionable));
      });
      Ember.RSVP.all(promises).finally(function () {
        _this.set('matchingValuesPending', false);
      });
    },
    parseMentionable: function parseMentionable(mentionable) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var text = "".concat(_this2.get('noteBody'));
        var tokenIndex = text.lastIndexOf(mentionable.get('token'), _this2.selectionStart);

        if (tokenIndex > text.lastIndexOf(' ', _this2.selectionStart - 1) && tokenIndex > text.lastIndexOf('/n', _this2.selectionStart - 1)) {
          //If fromMentionButton is true, match includes ',' and '/n'.
          var match = text.substring(tokenIndex - (_this2.fromMentionButton ? 2 : 0), _this2.selectionStart);
          _this2.fromMentionButton = false;

          _this2.set('match', match);

          _this2.set('token', mentionable.get('token'));

          var matchText = match.split(mentionable.get('token'))[1];

          _this2.searchValues(matchText, mentionable).then(function (matchingValues) {
            _this2.set('matchingValues', matchingValues);

            _this2.set('searchProperty', mentionable.get('searchProperty'));
          }).finally(function () {
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    searchValues: function searchValues(text, mentionable) {
      return new Ember.RSVP.Promise(function (resolve
      /* , reject */
      ) {
        var values = mentionable.get('values');
        var searchProperty = mentionable.get('searchProperty');
        var matchingValues = Ember.A();
        var matchingStartsWith = Ember.A();
        var matchingIncludes = Ember.A();

        if (text.length === 0) {
          matchingValues = values;
        } else {
          values.map(function (value) {
            var searchValue = value;

            if (isPresent(searchProperty)) {
              searchValue = Ember.Object.create(value).get(searchProperty);
            }

            if (searchValue.toLowerCase().startsWith(text.toLowerCase())) {
              matchingStartsWith.addObject(value);
            } else if (searchValue.toLowerCase().includes(text.toLowerCase())) {
              matchingIncludes.addObject(value);
            }
          });
          matchingValues.addObjects(matchingStartsWith);
          matchingValues.addObjects(matchingIncludes);
        }

        resolve(matchingValues);
      });
    },
    updateValue: function updateValue(selectedValue) {
      var searchProperty = this.get('searchProperty');

      if (isPresent(searchProperty)) {
        selectedValue = Ember.Object.create(selectedValue).get(searchProperty);
      }

      var match = this.get('match');
      var valueLeft = this.get('noteBody').substring(0, this.selectionStart - match.length);
      var valueRight = this.get('noteBody').substring(this.selectionStart);
      var token = this.get('token');
      this.set('noteBody', "".concat(valueLeft).concat(token).concat(selectedValue, " ").concat(valueRight));
      this.set('matchingValues', null);
      this.$(this.get('inputSelector')).focus();
    },

    /*
      properties for matchingValues
    */
    selectionStart: null,
    fromMentionButton: false,
    matchingValues: null,
    matchingValuesPending: null,
    showPicker: computed('matchingValues', 'matchingValuesPending', function () {
      return this.get('matchingValues') !== null || this.get('matchingValuesPending') === true;
    }),
    pickerClass: 'mentionable-picker',
    noMatchingValuesMessage: 'no matching items found.',
    matchingValuesPendingMessage: 'loading...',
    pickerItemPartial: null,
    focusPicker: function focusPicker(event) {
      if ((event.keyCode === 38 || event.keyCode === 40) && isPresent(this.get('matchingValues'))) {
        this.$(".".concat(this.get('pickerClass'))).focus();
      }
    }
  });

  _exports.default = _default;
});