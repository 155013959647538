define("isolocity/pods/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gZLBRGia",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[14,\"role\",\"primary\"],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"Loading\"],null]],[2,\"...\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"content-block\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"content loading\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"loading-message\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"loading-image\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"chart-message__loading\"],[12],[10,\"div\"],[14,0,\"loader loader-margins\"],[12],[13],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"Hang Tight\"],null]],[13],[2,\"\\n                \"],[10,\"p\"],[12],[1,[30,[36,0],[\"We're fetching your data\"],null]],[2,\". \"],[1,[30,[36,0],[\"If this takes longer than a minute, please refresh and try again\"],null]],[2,\".\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n        \\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "isolocity/pods/loading/template.hbs"
    }
  });

  _exports.default = _default;
});