define("isolocity/services/search/customers", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    isActive: null,
    getIsActive: function getIsActive() {
      return this.get('isActive');
    },
    setIsActive: function setIsActive(isActive) {
      this.set('isActive', isActive);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
      this.setIsActive(null);
    }
  });

  _exports.default = _default;
});