define("isolocity/pods/risk-reports/new/controller", ["exports", "isolocity/mixins/risk-report-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/risk-report-editing-mixin", "isolocity/mixins/owners-and-subscribers-mixin"], function (_exports, _riskReportMixin, _eSignatureMixin, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _userOrganizationMixin, _riskReportEditingMixin, _ownersAndSubscribersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _riskReportMixin.default, _eSignatureMixin.default, _riskReportEditingMixin.default, _ownersAndSubscribersMixin.default, {
    intl: Ember.inject.service(),
    id_prefix: 'new_',
    requiredFields: ['report_number', 'report_type'],
    isNew: true,
    isLoading: false,
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          var model = this.get('model');
          var type = model.get('report_type');
          this.set('isLoading', true);
          model.save().then(function (risk) {
            if (model.get(type + '_items.length') > 0) {
              _this.send('saveItemsAndAttachments', risk);
            } else {
              _this.send('saveAttachments', risk);
            }
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      saveItemsAndAttachments: function saveItemsAndAttachments(risk) {
        var _this2 = this;

        var model = this.get('model');
        var type = model.get('report_type');
        model.get(type + '_items').forEach(function (item) {
          item.set('risk_report_id', risk);

          if (item === model.get(type + '_items.lastObject')) {
            item.save().then(function () {
              _this2.send('saveAttachments', risk);
            });
          } else {
            item.save();
          }
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(risk) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        fm.success(intl.t('risk-reports.others.New Risk Report'));
        this.get('history').setRoute('risk-reports');
        this.transitionToRoute('risk-reports.edit', risk);
      },
      insertRow: function insertRow(item, type) {
        var items = this.get('model.' + type + '_items');
        item.setProperties({
          risk_report_id: this.get('model'),
          order: this.get('model.' + type + '_items.length'),
          alwaysEdittable: false,
          edittable: false
        });
        items.pushObject(item);
        var typeUpper = type.charAt(0).toUpperCase() + type.slice(1);
        this.set('model.empty' + typeUpper + 'Row', this.store.createRecord('risk-report.' + type + '-item', {
          alwaysEdittable: true
        }));
      },
      saveRow: function saveRow(item, type) {
        item.set('edittable', false);
        this.notifyPropertyChange('model.' + type + '_items');
      },
      deleteRow: function deleteRow(item, type) {
        var typeUpper = type.charAt(0).toUpperCase() + type.slice(1);

        if (Ember.isNone(item.get('order'))) {
          this.set('model.empty' + typeUpper + 'Row', this.store.createRecord('risk-report.' + type + '-item', {
            alwaysEdittable: true
          }));
          return;
        }

        var items = this.get('model.' + type + '_items');
        items.removeObject(item);
        this.notifyPropertyChange('model.' + type + '_items');
      }
    }
  });

  _exports.default = _default;
});