define("isolocity/pods/components/graphs/documents/distribution-by-stage/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/documents/distribution-by-stage/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.send('loadChart', this.get('graphDataUrl'));
    },
    graphDataUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/documents/distribution-by-stage/graph';
    }),
    onReportCriteriaChange: Ember.observer('selectedOverview', 'selectedPart', 'selectedErrorCode', 'selectedCarType', function () {
      this.set('chartData', null);
      var data = {};
      var overview = this.get('selectedOverview');

      if (!Ember.isEmpty(overview)) {
        data.overview = overview;
      }

      this.send('loadChart', data);
    }),
    setChartData: function setChartData(data) {
      var series = [];
      var dataset = [];

      for (var stage in data) {
        dataset.pushObject({
          name: stage,
          y: data[stage],
          drilldown: stage
        });
      }

      var chartLabel = this.get('intl').t('documents.labels.Number of Documents');
      this.set('chartData', [{
        name: chartLabel,
        colorByPoint: true,
        data: dataset,
        type: 'column'
      }]);
    },
    setStackedChartData: function setStackedChartData(data) {
      var series = [];

      for (var stage in data) {
        dataset.push(["STAGE", stage]);
      }

      for (var category in data) {
        var _dataset = [];

        for (var _stage in data) {
          _dataset.push(["STAGE", _stage]);
        }

        for (var year in data[category]) {
          for (var month in data[category][year]) {
            if (!Ember.isEmpty(data[category][year][month])) {
              _dataset.push([Date.UTC(year, month - 1, 1), data[category][year][month]]);
            }
          }
        }

        series.pushObject({
          name: category,
          data: _dataset,
          type: 'column'
        });
      }

      if (!Ember.isEmpty(data)) {
        this.set('chartData', series);
      } else {
        var chartLabel = this.get('intl').t('corrective-actions.labels.Cost of Quality');
        this.set('chartData', [{
          name: chartLabel,
          data: []
        }]);
      }
    },
    actions: {
      loadChart: function loadChart(data) {
        var _this = this;

        var reportData = {
          company_ids: this.get('reportingService').get('companies')
        };

        if (data.overview) {
          reportData.overview = data.overview;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('graphDataUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            if (reportData.overview) {
              _this.setStackedChartData(data);
            } else {
              _this.setChartData(data);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});