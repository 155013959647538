define("isolocity/pods/components/contact-form/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    types: ['', 'Billing', 'Bug Report', 'Support'],
    requiredFields: ['selectedType', 'description'],
    isSending: false,
    isSent: false,
    onTypeChange: Ember.observer('selectedType', function () {
      var type = this.get('selectedType');
      this.$('#question_type').val(type);

      if (type == 'Bug Report') {
        console.log(type);
        this.$('#bugReportsInformation').show();
        this.$('#startBugButton').show();
        this.$('#startBugText').show();
        this.$('#stopBugButton').show();
        this.$('#stopBugText').show();
      } else {
        this.$('#bugReportsInformation').hide();
        this.$('#startBugButton').hide();
        this.$('#startBugText').hide();
        this.$('#stopBugButton').hide();
        this.$('#stopBugText').hide();
      }
    }),
    valuesChanged: Ember.observer('selectedType', 'description', function () {
      this.get('flashMessages').clearMessages();
    }),
    getCurrentDateTime: function getCurrentDateTime() {
      var date = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
      });
      return date;
    },
    actions: {
      startBug: function startBug() {
        console.log("startBug");
        this.$('#startBugButton').addClass("btn-disabled");
        this.$('#startBugButton').attr("disabled", true);
        this.$('#stopBugButton').removeClass("btn-disabled");
        this.$('#stopBugButton').removeAttr("disabled");
        this.$('#startBugText').text("Report start time: " + this.getCurrentDateTime());
        window.open(window.location.origin, '_blank');
      },
      stopBug: function stopBug() {
        console.log("stopBug");
        this.$('#stopBugButton').addClass("btn-disabled");
        this.$('#stopBugButton').attr("disabled", true);
        this.$('#stopBugText').text("Report end time: " + this.getCurrentDateTime());
      },
      sendEmail: function sendEmail() {
        console.log(this.get('selectedType'));
        var fm = this.get('flashMessages');
        var missing_fields = [];

        var _this = this;

        if (this.requiredFields.length > 0) {
          this.requiredFields.forEach(function (item) {
            if (!_this.get(item)) {
              missing_fields.push(item);
            }
          });
        }

        if (missing_fields.length > 0) {
          fm.danger('These required fields are missing:', {
            sticky: true
          });
          fm.danger(missing_fields.join(', '), {
            sticky: true
          });
        } else {
          this.set('isSending', true);

          var _this2 = this;

          var desc = this.get('description') + '\n' + this.$('#startBugText').text() + '\n' + this.$('#stopBugText').text();
          $.ajax({
            headers: this.get('authHeaders'),
            url: this.getApiUrl('/support/contact'),
            data: {
              question_type: _this2.get('selectedType'),
              description: desc
            },
            method: 'POST',
            success: function success(data) {
              _this2.set('isSending', false);

              _this2.set('isSent', true);

              _this2.set('selectedType', '');

              _this2.set('description', '');
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});