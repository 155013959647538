define("isolocity/pods/drawings/archived/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    authManager: Ember.inject.service('session'),
    routeName: 'drawings.archived',
    search: Ember.inject.service('search.drawings-archived'),
    model: function model() {
      this.startLoading();
      return {
        drawings: this.store.query('drawing', {
          is_archived: true,
          per_page: _environment.default.APP.pagination,
          page: this.get('currentPage'),
          keyword: this.get('search').getKeyword()
        })
      };
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      model.drawings.then(function (drawings) {
        _this.doneLoading();

        _this.setPageMetadata(drawings);
      });
    },
    actions: {
      doSearchDrawings: function doSearchDrawings() {
        this.set('currentPage', 1);
        this.refresh();
      },
      rollback: function rollback(list) {
        list.forEach(function (item) {
          if (!Ember.isEmpty(item.get('id'))) {
            item.rollbackAttributes();
          }
        });
      }
    }
  });

  _exports.default = _default;
});