define("isolocity/pods/components/esignature-verified/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lgJ9qv4Y",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"i\"],[14,0,\"fa fa-lock esign-lock\"],[14,\"aria-hidden\",\"true\"],[14,\"title\",\"eSignature Verified\"],[14,\"alt\",\"eSignature Verified\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"event\",\"side\"],[\"hover\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h5\"],[14,0,\"tooltip-header\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-lock green m-r-10\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"eSignature Verified\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"This change was digitally e-signed by\"],null]],[2,\" \"],[1,[35,1,[\"causer_name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left m-t-25\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"IP Address\"],null]],[2,\": \"],[13],[2,\" \"],[1,[35,1,[\"ip_address\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"Browser\"],null]],[2,\": \"],[13],[2,\" \"],[1,[35,1,[\"browser_name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"Operating System\"],null]],[2,\": \"],[13],[2,\" \"],[1,[35,1,[\"platform_name\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"activity\",\"ember-tooltip\",\"isShowTooltip\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/esignature-verified/template.hbs"
    }
  });

  _exports.default = _default;
});