define("isolocity/pods/complaints/new/controller", ["exports", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/complaint-mixin"], function (_exports, _historyHandlerMixin, _uploadFilesMixin, _userOrganizationMixin, _ownersAndSubscribersMixin, _complaintMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _defaultRequiredField;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_historyHandlerMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _ownersAndSubscribersMixin.default, _complaintMixin.default, {
    id_prefix: 'new_',
    isNew: true,
    isLoading: false,
    isFormLocked: false,
    intl: Ember.inject.service(),
    ignoredFields: ['trend', 'complaint_source_id', 'part_id', 'error_code_id', 'root_cause', 'is_capa_needed', 'investigation', 'is_product_replaced', 'additional_comments', 'is_recall_required', 'corrective_actions', 'immediate_action', 'is_product_returned', 'is_investigation_needed', 'is_reply_sent', 'is_sample_examined'],
    defaultRequiredFieldsTranslation: (_defaultRequiredField = {
      'additional_comments': 'Comments / Notes',
      'comments': 'Comments From Complainant',
      'is_adult_use': 'Adult-use, purchased at store',
      'is_product_returned': 'Product Returned',
      'is_capa_needed': 'CAPA Needed?',
      'is_reply_sent': 'Reply Sent to Complainant?',
      'is_product_replaced': 'Part Replaced?',
      'is_recall_required': 'Recall Required?'
    }, _defineProperty(_defaultRequiredField, "is_product_replaced", 'Part Replaced?'), _defineProperty(_defaultRequiredField, 'corrective_actions', 'Action / Corrections Made'), _defineProperty(_defaultRequiredField, 'is_sample_examined', 'Retention Sample Examined?'), _defineProperty(_defaultRequiredField, 'investigation', 'Investigation'), _defineProperty(_defaultRequiredField, 'is_investigation_needed', 'Investigation Needed?'), _defaultRequiredField),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');
        this.send('hasRequiredFields');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners'))) {
            fm.danger(this.get('intl').t('At least one owner is required'));
            return;
          }

          var model = this.get('model');
          this.set('isLoading', true);
          model.save().then(function (complaint) {
            complaint.get('implementation_action_items').forEach(function (item) {
              item.set('entity_id', complaint.get('id'));
              item.save();
            });

            _this.send('saveAttachments', complaint);
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.errors);
          });
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(complaint) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('A new complaint report has been created successfully'));
        this.get('history').setRoute('complaints');
        this.transitionToRoute('complaints.edit', complaint);
      }
    }
  });

  _exports.default = _default;
});