define("isolocity/pods/profile/index/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/timezones-mixin", "isolocity/mixins/localization-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _timezonesMixin, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_uploadFilesMixin.default, _requiredFieldsMixin.default, _timezonesMixin.default, _localizationMixin.default, {
    intl: Ember.inject.service(),
    requiredFields: ['first_name', 'last_name', 'email', 'phone'],
    isLoading: false,
    hasSaved: false,
    session: Ember.inject.service('session'),
    valuesChanged: Ember.observer('model.first_name', 'model.last_name', 'model.email', 'model.phone', function () {
      this.get('flashMessages').clearMessages();
    }),
    actions: {
      save: function save() {
        var _this = this;

        if (this.get('isValid')) {
          if ($('img.preview-image').length > 0) {
            this.set('isLogoLoading', true);
            this.set('model.profile_file_id', null);
          }

          this.set('isLoading', true);
          var model = this.get('model'); // Sanitize settings in case they are empty or is in array format.

          this.send('sanitizeSettings');
          this.get('model').save().then(function (user) {
            _this.send('saveAttachments', user);
          }, function (error) {
            _this.set('isLogoLoading', false);

            _this.set('isLoading', false);

            _this.send('showServerErrors', error);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(user) {
        this.set('isLoading', false);
        this.set('hasSaved', true);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('profile.others.Save Profile'));
      },
      onFileUploadSuccessfully: function onFileUploadSuccessfully(data) {
        var _this2 = this;

        if (data.hasOwnProperty('file_id')) {
          this.get('model').set('profile_file_id', data.file_id);
          this.get('model').save().then(function (logo) {
            _this2.get('model').set('pic', _this2.store.find('file', data.file_id));

            _this2.set('isLogoLoading', false);

            _this2.set('hasSaved', false);

            var $el = $('#file-upload');
            $el.wrap('<form>').closest('form').get(0).reset();
            $el.unwrap();

            _this2.get('model').reload();
          });
        }
      },
      onFileUploadFailure: function onFileUploadFailure(message) {
        var fm = this.get('flashMessages');
        fm.danger(message);
        this.set('hasSaved', false);
      },
      selectedLanguage: function selectedLanguage(language) {
        this.send('saveLanguagePreference', language);
      },
      sanitizeSettings: function sanitizeSettings() {
        var model = this.get('model');
        var settings = model.get("settings"); // If settings is an array, convert it to object format

        if (Array.isArray(settings)) {
          settings = Object.assign({}, settings);
          model.set("settings", settings);
        }
      }
    }
  });

  _exports.default = _default;
});