define("isolocity/services/side-menu", ["exports", "ember-side-menu/services/side-menu"], function (_exports, _sideMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sideMenu.default.extend({
    progress: 0,
    canOpen: false,
    isOpen: Ember.computed.equal('progress', 100),
    isClosed: Ember.computed.equal('progress', 0),
    isSlightlyOpen: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'menus', []);
    },
    initMenu: function initMenu(menuName) {
      var menu;

      if (menuName) {
        menu = {
          progress: 0,
          isOpen: Ember.computed.equal('progress', 100),
          isClosed: Ember.computed.equal('progress', 0),
          isSlightlyOpen: false
        };
        Ember.set(this, menuName, menu);
        Ember.get(this, 'menus').addObject(menuName);
      }

      return menu;
    },
    close: function close() {
      var _this = this;

      Ember.set(this, 'progress', 0);
      Ember.get(this, 'menus').forEach(function (_menu) {
        var menu = Ember.get(_this, _menu);
        Ember.set(menu, 'progress', 0);
        Ember.set(menu, 'isSlightlyOpen', false);
      });
    },
    open: function open(menuName, tab) {
      Ember.set(this, 'progress', 100);
      var menu = menuName ? Ember.get(this, menuName) : this;
      Ember.set(menu, "progress", 100);
      Ember.set(menu, "isSlightlyOpen", false);
    },
    toggle: function toggle(menuName, tab) {
      this.open(menuName);
      /* TODO: Need to understand why all this is needed.
      // Code was added Apr 16, 2020
      let menu = menuName ? get(this, menuName) : this
      let currentTab = get(menu, "tab")
      if (tab)
        set(menu, "tab", tab)
      let isOpen = get(menu, "isOpen")
      if (isOpen && (!tab || currentTab === tab)) {
          this.close(menuName)
      } else {
        this.open(menuName)
      }
      */
    }
  });

  _exports.default = _default;
});