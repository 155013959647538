define("isolocity/pods/department/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sgKXJw75",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n  \"],[11,\"form\"],[4,[38,5],[[32,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n      \"],[1,[30,[36,6],null,[[\"goBack\"],[[30,[36,5],[[32,0],\"goBack\"],null]]]]],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"departments.headers.Edit Department\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n\\n      \"],[19,\"department/form\",[]],[2,\"\\n\\n    \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\\n      \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[15,2,[30,[36,0],[\"departments.headers.Save Department\"],null]],[14,4,\"submit\"],[12],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,8],null,[[\"promise\",\"confirm\",\"cancel\",\"title\",\"isConfirmEnabled\"],[[35,7],[30,[36,0],[\"Add\"],null],[30,[36,0],[\"Cancel\"],null],[30,[36,0],[\"departments.headers.Add Authorized Signer\"],null],[35,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n          \"],[10,\"label\"],[12],[1,[30,[36,0],[\"departments.headers.User\"],null]],[13],[2,\"\\n          \"],[1,[30,[36,4],null,[[\"value\",\"preventNew\",\"items\",\"excludeItemIds\",\"entity\",\"allowBlank\",\"placeholder\"],[[35,3],true,[35,2],[35,1],\"user\",false,\"\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"t\",\"signerIds\",\"allUsersExceptSigners\",\"selectedUser\",\"auto-complete\",\"action\",\"back-link\",\"addAuthorizedSignerPromise\",\"confirm-popup\"]}",
    "meta": {
      "moduleName": "isolocity/pods/department/edit/template.hbs"
    }
  });

  _exports.default = _default;
});