define("isolocity/pods/components/graphs/inventory/on-time-deliveries/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0SvadCsm",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"mode\",\"content\",\"chartOptions\"],[[35,2],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"chartOptions\",\"chartData\",\"chartMode\",\"high-charts\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/graphs/inventory/on-time-deliveries/template.hbs"
    }
  });

  _exports.default = _default;
});