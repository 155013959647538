define("isolocity/pods/enrollment-public/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/rendered-form-mixin", "isolocity/mixins/e-signature-mixin"], function (_exports, _environment, _renderedFormMixin, _eSignatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_renderedFormMixin.default, _eSignatureMixin.default, {
    isEsignatureRequiredForTraining: false,
    queryParams: ['is_esig'],
    enrollmentHash: '',
    publicEnrollmentUpdateApiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/training-schedule-employee',
    modules: Ember.inject.service('modules'),
    // List of file id which user has opened
    fileRead: [],
    // Flag will indicate if user has read all the file
    userReadAllFile: false,
    hasFiles: Ember.computed('model.training_schedule_files.length', 'stickiedFiles.length', function () {
      return this.get('model.training_schedule_files.length') > 0 || this.get('stickiedFiles.length') > 0;
    }),
    stickiedFiles: Ember.computed('model.training_schedule_id.training_program_id', 'model.training_schedule_id.document_id', function () {
      var files = [];

      if (!Ember.isBlank(this.get('model.training_program_files'))) {
        files = files.concat(this.get('model.training_program_files'));
      }

      if (!Ember.isBlank(this.get('model.document_files'))) {
        var docFiles = this.get('model.document_files');
        files = files.concat(docFiles);
      }

      return files;
    }),
    hasReadAllDocuments: function hasReadAllDocuments() {
      var validate = true,
          enrollmentPubliController = this; // Check if all file has opend by user
      // By checking file id in fileRead array
      // If not then restrict user to submit the form

      if (!Ember.isBlank(this.get('model.training_schedule_files'))) {
        this.get('model.training_schedule_files').map(function (file) {
          if (enrollmentPubliController.get('fileRead').indexOf(file.id) == -1) {
            validate = false;
          }
        });
      }

      if (!Ember.isBlank(this.get('model.training_program_files'))) {
        this.get('model.training_program_files').map(function (file) {
          if (enrollmentPubliController.get('fileRead').indexOf(file.id) == -1) {
            validate = false;
          }
        });
      }

      if (!Ember.isBlank(this.get('model.document_files'))) {
        this.get('model.document_files').map(function (file) {
          if (enrollmentPubliController.get('fileRead').indexOf(file.id) == -1) {
            validate = false;
          }
        });
      }

      return validate;
    },
    actions: {
      pushFileIdToFileRead: function pushFileIdToFileRead(id) {
        if (this.get('fileRead').indexOf(id) == -1) {
          this.get('fileRead').push(id);
        }
      },
      validateEnrollment: function validateEnrollment() {
        if (this.hasReadAllDocuments()) {
          this.send('saveEnrollment');
        } else {
          this.set('model.is_completed', false);
          this.resetFormData();
          this.set('showEnrollmentFormPromise', null);
          var fm = this.get('flashMessages');
          fm.danger('Please read all the attached resource materials before submitting the training.');
        }
      },
      saveEnrollment: function saveEnrollment() {
        var _this2 = this;

        var _this = this,
            fm = this.get('flashMessages'),
            model = this.get('model'),
            resultObject = this.getResultObject();

        $.ajax({
          url: this.get('publicEnrollmentUpdateApiUrl'),
          data: {
            enrollment_hash: _this.get('enrollmentHash'),
            is_completed: model.get('is_completed'),
            result: JSON.stringify(resultObject),
            password: this.get('eSignaturePassword')
          },
          method: 'POST',
          success: function success(data) {
            fm.success("Your changes have been saved successfully");
          }
        }).fail(function () {
          fm.danger('An error occurred while trying to save the form. Please ensure your password is correct.');
        }).always(function () {
          _this2.set('eSignaturePassword', '');
        });
      }
    }
  });

  _exports.default = _default;
});