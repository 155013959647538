define("isolocity/pods/batch-record/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    part_id: _emberData.default.belongsTo('part', {
      async: true
    }),
    batch_number: _emberData.default.attr('string'),
    disposition: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    error_code_id: _emberData.default.belongsTo('error-code'),
    cost: _emberData.default.attr('number'),
    entity: _emberData.default.attr(),
    esignature_reason: _emberData.default.attr('string'),
    part: _emberData.default.belongsTo('part')
  });

  _exports.default = _default;
});