define("isolocity/pods/components/graphs/deviations/source-breakdown/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/deviations/source-breakdown/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      var data = {};
      data.overview = this.get('selectedOverview');
      this.send('loadChart', data);
    },
    reportUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/deviations/source-breakdown/graph';
    }),
    onDeviationDropdownChange: Ember.observer('selectedOverview', 'selectedType', function () {
      this.set('chartData', null);
      var data = {};
      data.overview = this.get('selectedOverview');
      data.type = this.get('selectedType.id');
      this.send('loadChart', data);
    }),
    setChartData: function setChartData(data) {
      var dataset = [];

      for (var year in data) {
        for (var month in data[year]) {
          if (!Ember.isEmpty(data[year][month])) {
            dataset.push([Date.UTC(year, month - 1, 1), data[year][month].count]);
          }
        }
      }

      this.set('chartData', [{
        name: 'Deviations',
        data: dataset
      }]);
    },
    actions: {
      loadChart: function loadChart(data) {
        var _this = this;

        var reportData = {
          company_ids: this.get('reportingService').get('companies')
        };

        if (data.overview) {
          reportData.overview = data.overview;
        }

        if (data.type) {
          reportData.type = data.type;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('reportUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            _this.setChartData(data);
          }
        });
      }
    }
  });

  _exports.default = _default;
});