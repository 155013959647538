define("isolocity/pods/components/graphs/inventory/production-report/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/inventory/production-report/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      var intl = this.get('intl');
      this.set('chartData', null);

      var _this = this;

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/inventory/production/graph',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies'),
          part_id: this.get('selectedPart.id')
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;
          var dataset = [];

          for (var year in data) {
            for (var month in data[year]) {
              if (data[year][month] !== null && data[year][month] !== undefined) {
                var monthlyCount = data[year][month].count === null ? 0 : parseFloat(data[year][month].count);
                dataset.push([Date.UTC(year, month - 1, 1), monthlyCount]);
              }
            }
          }

          _this.set('chartData', [{
            type: 'column',
            name: intl.t('Quantity'),
            data: dataset
          }]);
        }
      });

      this._super.apply(this, arguments);
    },
    onSelectedPartChange: Ember.observer('selectedPart', function () {
      this.init();
    })
  });

  _exports.default = _default;
});