define("isolocity/pods/audit-reports/new/controller", ["exports", "isolocity/mixins/audit-reports-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _auditReportsMixin, _uploadFilesMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _auditReportsMixin.default, _uploadFilesMixin.default, {
    intl: Ember.inject.service(),
    isLoading: false,
    id_prefix: 'new_',
    historyOverride: '',
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        var _resultGroup = $('*[data="rendered-item"]');

        var _resultObject = {};

        _resultGroup.each(function () {
          var _resultItemId = $(this).attr('name');

          _resultItemId = _resultItemId.replace('[]', '');

          var _resultItemVal = $(this).val();

          if (_resultItemId.indexOf('checkbox-group') >= 0) {
            if (typeof _resultObject[_resultItemId] === 'undefined') {
              _resultObject[_resultItemId] = [];
            }

            if ($(this).is(":checked")) {
              _resultObject[_resultItemId].push(_resultItemVal);
            }
          } else if (_resultItemId.indexOf('radio-group') >= 0) {
            if (typeof _resultObject[_resultItemId] === 'undefined') {
              _resultObject[_resultItemId] = [];
            }

            if ($(this).is(":checked")) {
              _resultObject[_resultItemId].push(_resultItemVal);
            }
          } else {
            _resultObject[_resultItemId] = _resultItemVal;
          }
        });

        this.get('model').setProperties({
          'audit_type_id': this.get('model.report_template_id.audit_type_id'),
          'result': JSON.stringify(_resultObject),
          'audit_id': this.get('model.audit_id'),
          'report_template_id': this.get('model.report_template_id')
        });

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function (auditReport) {
            _this.send('saveAttachments', auditReport);
          }, function (response) {
            _this.set('isLoading', false);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(report) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        fm.success(intl.t('audit-report.Report Created'));
        this.get('history').setRoute('audit-reports');
        this.transitionToRoute('audit-reports.edit', report.get('id'));
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});