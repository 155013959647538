define("isolocity/pods/components/settings-sidebar/receiving-inspections/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GxlcLGDn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[1,[30,[36,0],[\"Enable Title and Location\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,1,[\"settings\",\"sections\",\"title_and_location\"]],false,\"No\",\"Yes\",[30,[36,3],[[30,[36,2],[[35,1,[\"settings\",\"sections\",\"title_and_location\"]]],null]],null],false,\"pull-left\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n     \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[1,[30,[36,0],[\"Enable Secondary Lot Number\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n     \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,1,[\"settings\",\"sections\",\"secondary_lot_number\"]],false,\"No\",\"Yes\",[30,[36,3],[[30,[36,2],[[35,1,[\"settings\",\"sections\",\"secondary_lot_number\"]]],null]],null],false,\"pull-left\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"module\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/settings-sidebar/receiving-inspections/template.hbs"
    }
  });

  _exports.default = _default;
});