define("isolocity/pods/car-type/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    intl: Ember.inject.service(),
    requiredFields: ['name', 'code'],
    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('isValid')) {
          this.send('showErrors');
          return;
        }

        this.get('model').save().then(function () {
          _this.get('flashMessages').success(_this.get('intl').t('car-type.You have successfully saved the corrective action type.'));

          history.back();
        }, function (response) {
          var model = _this.get('model');

          var oldCode = model.changedAttributes()['code'] != null ? model.changedAttributes()['code'][0] : null;
          var oldName = model.changedAttributes()['name'] != null ? model.changedAttributes()['name'][0] : null;
          model.set('name', oldName !== null && oldName !== void 0 ? oldName : model.get('name'));
          model.set('code', oldCode !== null && oldCode !== void 0 ? oldCode : model.get('code'));

          _this.send('showServerErrors', response);
        });
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});