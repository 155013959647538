define("isolocity/pods/components/model-index/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NqhKGa72",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[15,0,[31,[[30,[36,4],[[35,7],\"instant-search\"],null],\" \",[30,[36,4],[[35,6],\"instant-search--has-dropdown\"],null],\" \",[30,[36,4],[[35,5],\"instant-search--has-checkbox\"],null]]]],[12],[2,\"\\n                \"],[18,1,null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n\"],[6,[37,9],[[35,10]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[6,[37,3],null,[[\"class\",\"route\"],[\"btn btn-go pull-right\",[35,2]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"Create\"],null]],[2,\" \"],[1,[34,1]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"singular\",\"newPath\",\"link-to\",\"if\",\"hasCheckbox\",\"hasDropdown\",\"filterFields\",\"hideFilterFields\",\"unless\",\"disableHeaderButton\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/model-index/header/template.hbs"
    }
  });

  _exports.default = _default;
});