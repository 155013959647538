define("isolocity/pods/training-type/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fAH9IcNA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n  \"],[1,[34,0]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[1,[30,[36,1],[\"training-types.labels.Name\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"value\",\"id\",\"placeholder\"],[[35,2,[\"name\"]],\"name\",[30,[36,1],[\"training-types.placeholders.Training Type Name\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[1,[30,[36,1],[\"training-types.labels.Status\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"status\",\"onActiveChange\"],[[35,2,[\"is_active\"]],\"updateStatus\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"t\",\"model\",\"input\",\"active-inactive-toggle\"]}",
    "meta": {
      "moduleName": "isolocity/pods/training-type/-form/template.hbs"
    }
  });

  _exports.default = _default;
});