define("isolocity/pods/components/sidebar/part-inventory/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sideMenu: Ember.inject.service(),
    searchText: '',
    inventory: [],
    selectedInventoryId: null,

    get filteredInventory() {
      var _this = this;

      return this.get('inventory').filter(function (item) {
        if (Ember.isBlank(item.get('batch_number'))) {
          return false;
        }

        return item.get('batch_number').toLowerCase().indexOf(_this.get('searchText').toLowerCase()) !== -1;
      });
    },

    actions: {
      toggleSelectedInventory: function toggleSelectedInventory(id) {
        if (this.get('selectedInventoryId') == id) {
          this.set('selectedInventoryId', null);
          return;
        }

        this.set('selectedInventoryId', id);
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
      }
    }
  });

  _exports.default = _default;
});