define("isolocity/pods/training-schedule-employee/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nkIHKmiQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n  \"],[1,[34,2]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[2,\"Is Completed\"],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"status\",\"active\",\"inactive\"],[[35,0,[\"is_completed\"]],\"Yes\",\"No\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,0,[\"training_schedule\",\"training_program\",\"form\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\t  \"],[10,\"hr\"],[12],[13],[2,\"\\n\\t  \"],[1,[30,[36,1],null,[[\"formContent\",\"formResult\"],[[35,0,[\"training_schedule\",\"training_program\",\"form\"]],[35,0,[\"result\"]]]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"form-renderer\",\"flash-messages\",\"active-inactive-toggle\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/training-schedule-employee/-form/template.hbs"
    }
  });

  _exports.default = _default;
});