define("isolocity/mixins/training-schedule-pdf-mixin", ["exports", "ember-data", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/test-result-pdf-mixin"], function (_exports, _emberData, _pdfGenerationMixin, _testResultPdfMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_pdfGenerationMixin.default, _testResultPdfMixin.default, {
    getTrainingSchedulePdfContent: function getTrainingSchedulePdfContent(schedule) {
      var _this = this;

      var reportContent = [];
      reportContent.push(this.getSectionHeader('Basic Information'));
      reportContent.push(this.getSectionTable(['25%', '25%', '25%', '25%'], ['Training Program #', 'Employee Group', 'Status', 'Linked Document'], [Ember.isEmpty(schedule.get('training_program_id.title')) ? '' : schedule.get('training_program_id.title'), Ember.isEmpty(schedule.get('group_id.name')) ? '' : schedule.get('group_id.name'), {
        text: schedule.get('is_active') ? 'Active' : 'Inactive',
        style: schedule.get('is_active') ? 'open' : 'closed'
      }, Ember.isEmpty(schedule.get('document_id.name')) ? '' : schedule.get('document_id.name')]));
      reportContent.push(this.getSectionHeader('Scheduling & Timelines'));
      reportContent.push(this.getSectionTable(['25%', '25%', '25%', '25%'], ['Start Date', 'Completion Date', 'Repeat Frequency', 'Repeat Duration'], [Ember.isEmpty(schedule.get('start_date')) ? '' : moment(schedule.get('start_date')).format("MMMM D, YYYY"), Ember.isEmpty(schedule.get('completion_date')) ? '' : moment(schedule.get('completion_date')).format("MMMM D, YYYY"), Ember.isEmpty(schedule.get('repeat_frequency')) ? '' : schedule.get('repeat_frequency'), Ember.isEmpty(schedule.get('repeat_duration')) ? '' : schedule.get('repeat_duration')]));
      reportContent.push(this.getSectionHeader('Employees'));
      var employeesArray = [];
      schedule.get('employees').forEach(function (employee) {
        employeesArray.push([employee.is_active ? 'Active' : 'Inactive', employee.first_name, employee.last_name, employee.email_address]);
      });
      reportContent.push(this.getSectionTableMultipleRows(['25%', '25%', '25%', '25%'], ['Status', 'First Name', 'Last Name', 'Email Address'], employeesArray));
      schedule.get('results').forEach(function (result) {
        var title = result.get('taker_name') + "'s test result for " + result.get('subject.display_name');
        reportContent.push(_this.getPageBreak());
        reportContent.push(_this.getSectionHeader(title));
        reportContent.push(_this.getTestResultReportContent(result, false));
      });
      return reportContent;
    }
  });

  _exports.default = _default;
});