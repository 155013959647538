define("isolocity/mixins/freshchat-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    freshchatWidgetLoaded: false,
    // Track whether Freshchat is loaded
    // Inject the auth service to get user details
    session: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    store: Ember.inject.service(),
    currentOrganization: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Check if the session is authenticated at initialization


      if (this.get('session.isAuthenticated')) {
        this.loadUserOrganizationData();
      }
    },
    loadUserOrganizationData: function loadUserOrganizationData() {
      var _this = this;

      var organization_id = this.get('authManager.session.currentUser.organization_id');

      if (!Ember.isEmpty(organization_id)) {
        // Check if organization data is already loaded in the store
        var organization = this.get('store').peekRecord('organization', organization_id);

        if (Ember.isEmpty(organization)) {
          // Organization not in store, fetch it
          this.get('store').findRecord('organization', organization_id).then(function (organizationRecord) {
            // Set the current organization once the record is fetched
            _this.set('currentOrganization', organizationRecord); // Now that the organization is set, check freshchat settings and freshchat script load


            _this.loadFreshchatScript();
          }).catch(function (error) {
            console.error("Error fetching organization:", error);
          });
        } else {
          // Organization is already in the store, use it
          this.set('currentOrganization', organization);
          this.loadFreshchatScript();
        }
      }
    },
    loadFreshchatScript: function loadFreshchatScript() {
      var _this2 = this;

      var freshchatActive = this.get('currentOrganization.settings.freshchat_bot_active'); // Check if the script is already added

      if (!document.getElementById('freshchat-script') && freshchatActive) {
        var script = document.createElement('script');
        script.id = 'freshchat-script'; // To prevent multiple injections

        script.src = '//fw-cdn.com/11752667/4373758.js';
        script.setAttribute('chat', 'true');
        script.async = true;

        script.onload = function () {
          _this2.initializeFreshchat(); // Initialize Freshchat after script loads

        };

        script.onerror = function () {
          console.error('Failed to load Freshchat script');
        };

        document.head.appendChild(script);
      }
    },
    initializeFreshchat: function initializeFreshchat() {
      var _this3 = this;

      var checkWidgetLoad = function checkWidgetLoad() {
        if (window.fcWidget) {
          _this3.set('freshchatWidgetLoaded', true);

          _this3.setUserDetails(); // Set user details after widget is loaded


          window.fcWidget.show();
        } else {
          setTimeout(checkWidgetLoad, 1000); // Retry every second until ready
        }
      };

      checkWidgetLoad(); // Start checking for widget load
    },
    setUserDetails: function setUserDetails() {
      var currentUser = this.get('session.session.currentUser');

      if (currentUser) {
        // Attempt to set user details using Freshchat API
        try {
          if (window.fcWidget) {
            window.fcWidget.user.setFirstName(currentUser.name);
            window.fcWidget.user.setEmail(currentUser.email);
            window.fcWidget.setTags("2.0");
          }
        } catch (error) {
          console.error('Error setting Freshchat user details:', error);
        }
      }
    },
    clearFreshchat: function clearFreshchat() {
      // Destroy the Freshchat widget
      if (window.fcWidget) {
        window.fcWidget.hide();
        window.fcWidget.user.clear();
        window.fcWidget.user.setProperties({});
        window.fcWidget.destroy(); // Completely remove the widget
      }
    }
  });

  _exports.default = _default;
});