define("isolocity/pods/inspections/-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNbx3h9d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row inspection-notes-batch\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col inspection-notes\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"notes\"],[12],[2,\"\\n      \"],[11,\"a\"],[4,[38,4],[[32,0],\"toggleNotesSectionVisible\"],null],[12],[1,[30,[36,5],[\"Notes\"],null]],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"fa fa-angle-down\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,8],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"fa fa-angle-up\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,8],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],null,[[\"value\",\"id\",\"rows\",\"placeholder\"],[[35,2,[\"notes\"]],[30,[36,1],[[35,0],\"notes\"],null],\"10\",\"Enter notes\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"id_prefix\",\"concat\",\"model\",\"textarea\",\"action\",\"t\",\"isNotesSectionVisible\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/inspections/-notes/template.hbs"
    }
  });

  _exports.default = _default;
});