define("isolocity/services/search/inventory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    type: '',
    keyword: '',
    supplierId: '',
    status: '',
    sortBy: '',
    expirySoon: '',
    getType: function getType() {
      return this.get('type');
    },
    setType: function setType(type) {
      this.set('type', type);
    },
    getKeyword: function getKeyword() {
      return this.get('keyword');
    },
    setKeyword: function setKeyword(keyword) {
      this.set('keyword', keyword);
    },
    getStatus: function getStatus() {
      return this.get('status');
    },
    setStatus: function setStatus(status) {
      this.set('status', status);
    },
    getSupplierId: function getSupplierId() {
      return this.get('supplierId');
    },
    setSupplierId: function setSupplierId(supplierId) {
      this.set('supplierId', supplierId);
    },
    getSortBy: function getSortBy() {
      return this.get('sortBy');
    },
    setSortBy: function setSortBy(sortBy) {
      this.set('sortBy', sortBy);
    },
    getExpirySoon: function getExpirySoon() {
      return this.get('expirySoon');
    },
    setExpirySoon: function setExpirySoon(expirySoon) {
      this.set('expirySoon', expirySoon);
    },
    clear: function clear() {
      this.setType('');
      this.setKeyword('');
      this.setStatus('');
      this.setSupplierId('');
      this.setSortBy('');
      this.setExpirySoon('');
    }
  });

  _exports.default = _default;
});