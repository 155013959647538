define("isolocity/router", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('page-not-found', {
      path: '*'
    });
    this.route('page-not-found', {
      path: '404'
    });
    this.route('forbidden', {
      path: '403'
    });
    this.route('login');
    this.route('okta', function () {
      this.route('login');
      this.route('callback');
    });
    this.route('azure', function () {
      this.route('callback');
    });
    this.route('password', function () {
      this.route('reset');
      this.route('change');
    });
    /** Page Routes **/

    this.route('support');
    this.route('faq');
    this.route('settings');
    this.route('team-settings');
    this.route('notifications', function () {});
    this.route('downloads', function () {});
    this.route('profile', function () {});
    this.route('reporting');
    this.route('search', function () {});
    this.route('supplier-upload', {
      path: '/supplier-upload/:upload_hash'
    });
    this.route('car-upload', {
      path: '/car-upload/:upload_hash'
    });
    this.route('test-take', {
      path: '/test-take/:test_hash'
    });
    this.route('document-public', {
      path: '/document-public/:document_hash'
    });
    this.route('enrollment-public', {
      path: '/enrollment-public/:enrollment_hash'
    });
    this.route('action-item-complete', {
      path: '/action-item/complete/:action_hash'
    });
    this.route('expired');
    this.route('training-schedule-employee');
    /** Resource Routes **/

    this.route('users', function () {
      this.route('archived');
      this.route('new');
      this.route('edit', {
        path: ':user_id'
      });
    });
    this.route('companies', function () {
      this.route('new');
      this.route('edit', {
        path: ':company_id'
      });
    });
    this.route('company-groups', function () {
      this.route('new');
      this.route('edit', {
        path: ':company_group_id'
      });
    });
    this.route('customers', function () {
      this.route('new');
      this.route('edit', {
        path: ':customer_id'
      });
    });
    this.route('action-items', function () {
      this.route('completed');
      this.route('new');
      this.route('edit', {
        path: ':action_item_id'
      });
    });
    this.route('suppliers', function () {
      this.route('new');
      this.route('edit', {
        path: ':supplier_id'
      });
    });
    this.route('employees', function () {
      this.route('archived');
      this.route('edit', {
        path: ':employee_id'
      });
    });
    this.route('groups', function () {
      this.route('new');
      this.route('edit', {
        path: ':group_id'
      });
    });
    this.route('training-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':training_type_id'
      });
    });
    this.route('training-program', function () {
      this.route('new');
      this.route('edit', {
        path: ':training_program_id'
      });
    });
    this.route('training-schedules', function () {
      this.route('new');
      this.route('document', {
        path: 'document'
      });
      this.route('edit', {
        path: ':training_schedule_id'
      });
    });
    this.route('training-schedule-employee', function () {
      this.route('edit', {
        path: ':training_schedule_employee_id'
      });
    });
    this.route('testing', function () {
      this.route('new');
      this.route('edit', {
        path: ':test_id'
      });
      this.route('result', {
        path: ':test_id/result'
      });
      this.route('result', {
        path: ':test_id/result/:test_result_id'
      });
    });
    this.route('drawings', function () {
      this.route('new');
      this.route('archived');
      this.route('edit', {
        path: ':drawing_id'
      });
      this.route('characteristics');
    });
    this.route('parts', function () {
      this.route('new');
      this.route('activity');
      this.route('activities');
      this.route('archived');
      this.route('edit', {
        path: ':part_id'
      });
    });
    this.route('part-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':part_type_id'
      });
    });
    this.route('inventory', function () {
      this.route('new');
      this.route('edit', {
        path: ':inventory_id'
      });
    });
    this.route('location', function () {
      this.route('new');
      this.route('edit', {
        path: ':location_id'
      });
    });
    this.route('equipment', function () {
      this.route('new');
      this.route('edit', {
        path: ':equipment_id'
      });
      this.route('archived');
    });
    this.route('costs', function () {
      this.route('timeline', {
        path: '/:year/:month'
      });
    });
    this.route('audit', function () {
      this.route('new');
      this.route('edit', {
        path: ':audit_id'
      });
    });
    this.route('report-templates', function () {
      this.route('new');
      this.route('edit', {
        path: ':report_template_id'
      });
    });
    this.route('audit-report-templates', function () {
      this.route('new');
      this.route('edit', {
        path: ':audit-report_template_id'
      });
    });
    this.route('equipment-reports', function () {
      this.route('new');
      this.route('edit', {
        path: ':equipment_report_id'
      });
    });
    this.route('audit-reports', function () {
      this.route('new');
      this.route('edit', {
        path: ':audit_report_id'
      });
    });
    this.route('orders', function () {
      this.route('new');
      this.route('edit', {
        path: ':order_id'
      });
      this.route('items');
    });
    this.route('receiving-inspections', function () {
      this.route('new');
      this.route('edit', {
        path: ':receiving_inspection_id'
      });
      this.route('items');
    });
    this.route('shipments', function () {
      this.route('new');
      this.route('edit', {
        path: ':shipment_id'
      });
      this.route('items');
    });
    this.route('calibration', function () {
      this.route('new');
      this.route('edit', {
        path: ':calibration_id'
      });
    });
    this.route('inspections', function () {
      this.route('equipment');
      this.route('receiving');
      this.route('activity');
      this.route('activities');
      this.route('new');
      this.route('new-equipment', {
        path: 'new-equipment/:equipment_id/drawing/:drawing_id'
      });
      this.route('edit', {
        path: ':inspection_id'
      });
      this.route('samples', function () {
        this.route('results');
      });
    });
    this.route('corrective-actions', function () {
      this.route('new');
      this.route('edit', {
        path: ':car_id'
      });
    });
    this.route('car-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':car_type_id'
      });
    });
    this.route('car-source', function () {
      this.route('new');
      this.route('edit', {
        path: ':car_source_id'
      });
    });
    this.route('ncrs', function () {
      this.route('new');
      this.route('edit', {
        path: ':ncr_id'
      });
    });
    this.route('ccrs', function () {
      this.route('new');
      this.route('edit', {
        path: ':ccr_id'
      });
    });
    this.route('ccr-source', function () {
      this.route('new');
      this.route('edit', {
        path: ':ccr_source_id'
      });
    });
    this.route('ccr-classification', function () {
      this.route('new');
      this.route('edit', {
        path: ':ccr_classification_id'
      });
    });
    this.route('ccr-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':ccr_type_id'
      });
    });
    this.route('deviations', function () {
      this.route('new');
      this.route('edit', {
        path: ':deviation_id'
      });
    });
    this.route('deviation-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':deviation_type_id'
      });
    });
    this.route('complaints', function () {
      this.route('new');
      this.route('edit', {
        path: ':complaint_id'
      });
    });
    this.route('complaint-category', function () {
      this.route('new');
      this.route('edit', {
        path: ':complaint_category_id'
      });
    });
    this.route('complaint-source', function () {
      this.route('new');
      this.route('edit', {
        path: ':complaint_source_id'
      });
    });
    this.route('complaint-impact', function () {
      this.route('new');
      this.route('edit', {
        path: ':complaint_impact_id'
      });
    });
    this.route('oosforms', function () {
      this.route('new');
      this.route('edit', {
        path: ':oosform_id'
      });
    });
    this.route('planning-reports', function () {
      this.route('new');
      this.route('edit', {
        path: ':planning_report_id'
      });
    });
    this.route('risk-reports', function () {
      this.route('haccp');
      this.route('new');
      this.route('edit', {
        path: ':risk_report_id'
      });
    });
    this.route('equipment-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':equipment_type_id'
      });
    });
    this.route('audit-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':audit_type_id'
      });
    });
    this.route('documents', function () {
      this.route('new');
      this.route('edit', {
        path: ':document_id'
      });
      this.route('revision', {
        path: ':document_id/revisions/:document_revision_id'
      });
      this.route('archived');
      this.route('revisions', {
        path: ':document_id/revisions'
      });
    });
    this.route('document-type', function () {
      this.route('new');
      this.route('edit', {
        path: ':document_type_id'
      });
    });
    this.route('department', function () {
      this.route('new');
      this.route('edit', {
        path: ':department_id'
      });
    });
    this.route('approval-matrices', function () {
      this.route('new');
      this.route('edit', {
        path: ':approval_matrix_id'
      });
    });
    this.route('error-code', function () {
      this.route('new');
      this.route('edit', {
        path: ':error_code_id'
      });
    });
    this.route('activities', function () {});
  });
  var _default = Router;
  _exports.default = _default;
});