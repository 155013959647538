define("isolocity/pods/oosforms/index/controller", ["exports", "isolocity/mixins/oosform-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/floating-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _oosformMixin, _dateSearchMixin, _floatingSidebarMixin, _downloadableReportsMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_oosformMixin.default, _dateSearchMixin.default, _floatingSidebarMixin.default, _downloadableReportsMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.oosforms'),
    sortedModel: Ember.computed.sort('model.oosforms', 'sortProperties'),
    sortProperties: ['hasIncompleteActionItems:desc', 'has_unread_notes:desc', 'created_at:desc'],
    paretoType: 'Source',
    searchOwner: null,
    searchBatchNumber: null,
    allReportData: Ember.computed('allStartDate', 'allEndDate', 'allSource', 'allImpact', 'customErrorCode', function () {
      return {
        source: this.get('allSource') ? this.get('allSource') : null,
        impact: this.get('allImpact') ? this.get('allImpact') : null,
        start_date: this.get('allStartDate') ? moment(this.get('allStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('allEndDate') ? moment(this.get('allEndDate')).format("YYYY-MM-DD") : null,
        error_code_id: this.get('customErrorCode') ? this.get('customErrorCode.id') : null
      };
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setStatus(this.get('searchStatus'));
        this.get('search').setOwner(this.get('searchOwner.id'));
        this.get('search').setBatchNumber(this.get('searchBatchNumber'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('searchStatus', '');
        this.set('searchOwner', null);
        this.set('searchBatchNumber', null);
      }
    }
  });

  _exports.default = _default;
});