define("isolocity/pods/parts/-expanded/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1aNJvayV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"tr\"],[14,0,\"tr row-expanded\"],[12],[2,\"\\n    \"],[10,\"td\"],[14,\"colspan\",\"6\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n                \"],[19,\"parts/summary\",[]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-half text-right\"],[12],[2,\"\\n\"],[6,[37,3],[[35,0,[\"isCloning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[10,\"a\"],[14,0,\"btn btn-small btn-default btn-disabled m-r-15\"],[12],[1,[30,[36,2],[\"parts.others.Cloning\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[11,\"a\"],[24,0,\"btn btn-small btn-default m-r-15\"],[4,[38,1],[[32,0],\"clonePart\",[35,0]],null],[12],[2,\"\\n                        \"],[1,[30,[36,2],[\"parts.others.Clone\"],null]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[11,\"a\"],[24,0,\"btn btn-small btn-danger\"],[4,[38,1],[[32,0],\"togglePartStatus\",[35,0]],null],[12],[2,\"\\n                    \"],[1,[30,[36,2],[\"parts.others.Archive\"],null]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"part\",\"action\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/parts/-expanded/template.hbs"
    }
  });

  _exports.default = _default;
});