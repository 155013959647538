define("isolocity/pods/components/inspections-table/results-table/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    intl: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    characteristicHeader: Ember.computed('sample.inspection_id.drawing_id.id', function () {
      var intl = this.get('intl');
      return !Ember.isBlank(this.get('sample.inspection_id.drawing_id.custom_labels')) ? this.get('sample.inspection_id.drawing_id.custom_labels.characteristic') : intl.t('Characteristic');
    }),
    operationsHeader: Ember.computed('sample.inspection_id.drawing_id.id', function () {
      var intl = this.get('intl');
      return !Ember.isBlank(this.get('sample.inspection_id.drawing_id.custom_labels')) ? this.get('sample.inspection_id.drawing_id.custom_labels.operations') : intl.t('Operations');
    }),
    isOrgAdmin: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    currentUserId: Ember.computed(function () {
      return this.get('currentUser.id');
    }),
    actions: {
      approveInspectionResult: function approveInspectionResult(result) {
        this.sendAction('approveInspectionResult', result);
      },
      reopenInspectionResult: function reopenInspectionResult(result) {
        var isSecondaryUser = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.sendAction('reopenInspectionResult', result, isSecondaryUser);
      }
    }
  });

  _exports.default = _default;
});