define("isolocity/pods/complaint-category/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    modelName: 'complaint-category',
    model: function model() {
      this.startLoading();
      return this.store.query('complaint-category', {
        per_page: _environment.default.APP.pagination,
        page: this.get('currentPage'),
        reload: true
      });
    }
  });

  _exports.default = _default;
});