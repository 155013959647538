define("isolocity/mixins/parts-form-mixin", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_userOrganizationMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    cannotCreateDepartments: Ember.computed(function () {
      return !this.get('permissions').hasRoleAtLeast('manager');
    }),
    actions: {
      showFinishedLabelSample: function showFinishedLabelSample() {
        var _this = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this.set('finishedLabelPopup', null);
        }, function () {
          _this.set('finishedLabelPopup', null);
        });
        this.set('finishedLabelPopup', defer);
      },
      addTypeToPart: function addTypeToPart(type) {
        var types = this.get('model.types');

        if (!types.includes(type)) {
          this.get('model.types').pushObject(type);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeTypeFromPart: function removeTypeFromPart(type) {
        this.get('model.types').removeObject(type);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      }
    }
  });

  _exports.default = _default;
});