define("isolocity/pods/customers/index/controller", ["exports", "isolocity/mixins/reporting-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _reportingSidebarMixin, _downloadableReportsMixin, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportingSidebarMixin.default, _downloadableReportsMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.customers'),
    permissions: Ember.inject.service('user-permissions'),
    isActive: {
      id: true,
      name: 'Active'
    },
    statusOptions: [{
      id: true,
      name: 'Active'
    }, {
      id: false,
      name: 'Inactive'
    }],
    canCreateCustomers: Ember.computed(function () {
      console.log('thasRoleAtLeast', this.get('permissions').hasRoleAtLeast('user'));
      return this.get('permissions').hasRoleAtLeast('user');
    }),
    newPath: Ember.computed(function () {
      if (this.get('canCreateCustomers') === true) {
        return "customers.new";
      }

      return false;
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setIsActive(this.get('isActive.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('isActive', {
          id: true,
          name: 'Active'
        });
      }
    }
  });

  _exports.default = _default;
});