define("isolocity/mixins/failure-rate-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    displayMode: 'Defect Percentage',
    displayModes: ['Defect Percentage', 'Defect Quantity'],
    carOverviewOptions: ['By Supplier', 'By Customer', 'By Error Code'],
    failureOverviewOptions: ['By Part', 'By Error Code'],
    includeChildren: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('errorCodes', this.get('store').query('error-code', {
        company_ids: ''
      }));
      this.set('errorCodeParts', this.get('store').query('part', {
        company_ids: ''
      }));
    },
    actions: {
      handleSelectedPart: function handleSelectedPart() {
        var _this = this;

        var part = this.get('selectedPart');
        this.set('selectedCharacteristic', null);

        if (!Ember.isEmpty(part)) {
          var promise = this.attrs.onPartChange(part);
          promise.then(function (characteristics) {
            _this.set('partCharacteristics', characteristics);
          }, function (response) {
            _this.set('partCharacteristics', []);
          });
        }
      },
      handleSelectedErrorPart: function handleSelectedErrorPart() {
        if (this.get('selectedFailureOverview') === 'By Part') {
          this.set('selectedFailureOverview', null);
        }

        var part = this.get('selectedErrorPart');

        if (!Ember.isBlank(part) && part.get('children.length') === 0) {
          this.set('includeChildren', false);
        }
      },
      handleSelectedErrorCode: function handleSelectedErrorCode() {
        this.set('selectedCarOverview', null);

        if (this.get('selectedFailureOverview') === 'By Error Code') {
          this.set('selectedFailureOverview', null);
        }

        if (this.get('selectedCarOverview') === 'By Error Code') {
          this.set('selectedCarOverview', null);
        }

        this.set('errorCodeParts', this.get('store').query('part', {
          company_ids: ''
        }));
      },
      handleSelectedSupplier: function handleSelectedSupplier() {
        if (this.get('selectedCarOverview') === 'By Supplier') {
          this.set('selectedCarOverview', null);
        }

        this.set('selectedCustomer', null);
      },
      handleSelectedCustomer: function handleSelectedCustomer() {
        if (this.get('selectedCarOverview') === 'By Customer') {
          this.set('selectedCarOverview', null);
        }

        this.set('selectedSupplier', null);
      },
      handleSelectedCarOverview: function handleSelectedCarOverview() {
        if (this.get('selectedCarOverview') === 'By Supplier') {
          this.set('selectedSupplier', null);
        }

        if (this.get('selectedCarOverview') === 'By Customer') {
          this.set('selectedCustomer', null);
        }

        if (this.get('selectedCarOverview') === 'By Error Code') {
          this.set('selectedCarErrorCode', null);
        }
      },
      handleSelectedFailureOverview: function handleSelectedFailureOverview() {
        if (this.get('selectedFailureOverview') === 'By Part') {
          this.set('selectedErrorPart', null);
        }

        if (this.get('selectedFailureOverview') === 'By Error Code') {
          this.set('selectedErrorCode', null);
        }
      }
    }
  });

  _exports.default = _default;
});