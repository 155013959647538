define("isolocity/mixins/training-activity-create-mixin", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _ajaxRequestMixin, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_uploadFilesMixin.default, _requiredFieldsMixin.default, _historyHandlerMixin.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    isNew: true,
    isLoading: false,
    id_prefix: 'new_',
    selectedGroups: [],
    requiredFields: ['training_program_id.id', 'start_date'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    historyOverride: '',
    stickiedFiles: Ember.computed('model.training_program_id', function () {
      return this.get('model.training_program_id.files');
    }),
    onSelectedEmployeeChange: Ember.observer('selectedEmployee', function () {
      if (this.get('selectedEmployee') && this.get('selectedEmployee') !== false) {
        var employee = this.get('selectedEmployee');
        this.send('addEmployeeToSchedule', employee);
      }
    }),
    confirmTrainingRecipientsBtn: Ember.computed(function () {
      return 'Save';
    }),
    initializeDocumentGroups: function initializeDocumentGroups(groups) {
      groups.map(function (group) {
        // Initially check all groups
        group.is_enroll_group = true;
        group.employees.map(function (employee) {
          // Initially check all employees
          employee.is_enroll_employee = true;
          return employee;
        });
        return group;
      });
    },
    actions: {
      create: function create() {
        var _this = this;

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function (schedule) {
            _this.send('saveAttachments', schedule);
          }, function (response) {
            _this.set('isLoading', false);

            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(schedule) {
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.set('isLoading', false);
        this.get('history').setRoute('training-schedules');
        this.transitionToRoute('training-schedules.edit', schedule.get('id'));
        setTimeout(function () {
          fm.success(intl.t('mixins.traning-activity-create.Traning Success'));
          fm.success(intl.t('mixins.traning-activity-create.Refresh Page'));
        }, 2500);
      },
      addEmployeeToSchedule: function addEmployeeToSchedule(employee) {
        var employees = this.get('model.employees');

        if (!employees.includes(employee)) {
          this.get('model.employees').pushObject(employee);
        }

        this.set('selectedEmployee', false);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        fm.success(intl.t('mixins.traning-activity-create.Employee added'));
      },
      updateStatus: function updateStatus() {},
      removeItem: function removeItem() {},
      checkAssignedEmployees: function checkAssignedEmployees() {
        var _this2 = this;

        var data = null;
        var payload = {};
        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/document/' + this.get('model.document_id.id') + '/employees/check'),
          method: 'POST',
          data: payload
        }).then(function (data) {
          if (data.groups.length === 0) {
            _this2.send('create');
          } else {
            _this2.send('showAssignedEmployeesModal', data);
          }
        });
      },
      showAssignedEmployeesModal: function showAssignedEmployeesModal(data) {
        var _this3 = this;

        var defer = Ember.RSVP.defer();
        this.initializeDocumentGroups(data.groups);
        this.set('selectedGroups', data.groups);
        defer.promise.then(function () {
          _this3.set('isSaving', true);

          _this3.send('doEmployeesEnrollment', _this3.get('selectedGroups'));

          _this3.set('employeesConfirmationPromise', null);
        }, function () {
          _this3.set('employeesConfirmationPromise', null);
        });
        this.set('employeesConfirmationPromise', defer);
      },
      doEmployeesEnrollment: function doEmployeesEnrollment(groups) {
        var groupIds = [],
            employeeIds = [];
        this.get('model').set('manual_select_employees', true); // Flag to track if employees where selected manually

        groups.forEach(function (group) {
          if (group.is_enroll_employee) {
            groupIds.push(group.id);
          }

          group.employees.forEach(function (employee) {
            if (employee.is_enroll_employee) {
              employeeIds.push(employee.id);
            }
          });
        });

        if (groupIds.length === 0 && employeeIds.length === 0) {
          this.send('create');
          return;
        }

        this.get('model').set('training_employees_ids', employeeIds);
        this.send('create');
      },
      clickEmployeeGroupCheck: function clickEmployeeGroupCheck(selected_group) {
        var isChecked = !selected_group.is_enroll_group;
        var groups = this.get('selectedGroups');
        var updatedGroupEmployees = [];
        var updatedGroup = {
          'id': selected_group.id,
          'name': selected_group.name,
          'is_enroll_group': isChecked,
          'employees': {}
        }; // Invert check status for employees in selected group

        selected_group.employees.map(function (employee) {
          var newEmployee = {
            'id': employee.id,
            'first_name': employee.first_name,
            'last_name': employee.last_name,
            'is_enroll_employee': isChecked
          };
          updatedGroupEmployees.pushObject(newEmployee);
        });
        updatedGroup.employees = updatedGroupEmployees; // Replace updated group in groups array

        var updatedGroups = groups.map(function (group) {
          return selected_group.id == group.id ? updatedGroup : group;
        });
        this.set('selectedGroups', updatedGroups);
      }
    }
  });

  _exports.default = _default;
});