define("isolocity/utils/datetime-formats", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatDateTime;

  function formatDateTime(timestamp) {
    var timezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    if (timezone === null || timezone === undefined) {
      timezone = _momentTimezone.default.tz.guess();
    }

    if (!format) {
      format = 'MMM D, YYYY h:mm A (z)';
    } //there is no need to check whether timestamp is valid. if the timestamp is invalid. we simply return '' at the end
    // And if timestamp is "undefined", it would automatically use current time


    var formatedStr = (0, _momentTimezone.default)(timestamp).tz(timezone).format(format);

    if (formatedStr === 'Invalid date') {
      return '';
    }

    return formatedStr;
  }
});