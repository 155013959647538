define("isolocity/pods/deviations/new/route", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_userOrganizationMixin.default, {
    persistence: Ember.inject.service(),
    model: function model() {
      var existingModel = this.get('persistence').find('deviations.new');

      if (!Ember.isBlank(existingModel)) {
        if (!Ember.isEmpty(existingModel.model)) {
          return existingModel.model;
        }
      }

      return this.store.createRecord('deviation', {
        proposed_date: new moment(),
        fishbone_5_why: {},
        custom_fields: {
          "field_1": false,
          "field_2": false,
          "field_3": false,
          "field_4": false,
          "field_5": false,
          "field_6": false
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));

      if (!Ember.isBlank(user)) {
        model.get('owners').pushObject(user);
      }

      model.set('allDepartments', this.store.findAll('department'));
      model.set('errorCodes', this.store.findAll('error-code'));
      model.set('parts', this.store.findAll('part'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('allTypes', this.store.findAll('deviation-type'));
    },
    actions: {
      pushToParentModel: function pushToParentModel(deviation) {
        var model = this.modelFor('deviations.index');

        if (model) {
          model.deviations.pushObject(deviation);
        }
      }
    }
  });

  _exports.default = _default;
});