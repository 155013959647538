define("isolocity/pods/components/frequency-entry-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    durationValues: ['days', 'weeks', 'months'],
    actions: {
      addFrequency: function addFrequency() {
        var frequency = this.store.createRecord('frequency-entry', {
          entity_id: this.get('model.id'),
          entity_type: this.get('entityType'),
          is_authored_by_current_user: true,
          isDirty: true
        });
        this.get('model.frequency_entries').pushObject(frequency);
      },
      saveFrequency: function saveFrequency(entry) {
        var isNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (!Ember.isEmpty(entry.next_review_date) && !Ember.isEmpty(entry.review_repeat_duration) && !Ember.isEmpty(entry.review_repeat_frequency)) {
          if (!Ember.isEmpty(entry.drawing_id.content)) {
            if (entry.drawing_id.content.is_approved) {
              if (!entry.isDirty || isNew) {
                entry.save().then(function () {
                  entry.set('isDirty', false);
                  fm.success(intl.t('Frequency entry successfully saved'));
                });
              }
            } else {
              fm.danger(intl.t('The selected template is not approved'));
            }
          } else {
            fm.danger(intl.t('Please select an approved template'));
          }
        } else {
          fm.danger(intl.t('Frequency, duration and next due date are mandatory'));
        }
      },
      reviewTemplateChanged: function reviewTemplateChanged(entry) {
        if (!Ember.isEmpty(entry.next_review_date) && !Ember.isEmpty(entry.review_repeat_frequency) && !Ember.isEmpty(entry.review_repeat_duration)) {
          this.send('saveFrequency', entry);
        }
      },
      removeFrequency: function removeFrequency(entry) {
        entry.set('isDeleting', true);
        entry.destroyRecord();
      },
      reviewFrequencyChanged: function reviewFrequencyChanged(entry) {
        this.send('reCalculateNextReviewDate', entry);
      },
      reviewDurationChanged: function reviewDurationChanged(entry) {
        this.send('reCalculateNextReviewDate', entry);
      },
      lastReviewDateChanged: function lastReviewDateChanged(entry) {
        this.send('reCalculateNextReviewDate', entry);
      },
      toogleExcludeWeekends: function toogleExcludeWeekends(entry) {
        entry.exclude_weekends = !entry.exclude_weekends;
        this.send('reCalculateNextReviewDate', entry);
      },
      reCalculateNextReviewDate: function reCalculateNextReviewDate(entry) {
        var last_review_date = entry.last_review_date,
            frequency = entry.review_repeat_frequency,
            duration = entry.review_repeat_duration;

        if (!Ember.isEmpty(last_review_date) && !Ember.isEmpty(frequency) && !Ember.isEmpty(duration)) {
          var next_review_date = moment(last_review_date).add(frequency, duration.toLowerCase()); // Exclude weekends

          if (entry.exclude_weekends) {
            if (next_review_date.get('day') == 6) {
              next_review_date = moment(next_review_date).add(2, 'days');
            }

            if (next_review_date.get('day') == 0) {
              next_review_date = moment(next_review_date).add(1, 'days');
            }
          }

          entry.next_review_date = next_review_date;
          this.send('saveFrequency', entry);
        }
      }
    }
  });

  _exports.default = _default;
});