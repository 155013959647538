define("isolocity/pods/customers/index/route", ["exports", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    search: Ember.inject.service('search.customers'),
    modelName: 'customer',
    model: function model() {
      this.startLoading();
      var filters = this.getQueryFilters();
      filters['is_active'] = this.get('search').getIsActive();
      return {
        customers: this.store.query('customer', filters)
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.customers.then(function (customers) {
        _this.doneLoading();

        _this.setPageMetadata(customers);
      });
    }
  });

  _exports.default = _default;
});