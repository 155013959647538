define("isolocity/pods/training-schedules/document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n/i5ekl/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n  \"],[11,\"form\"],[4,[38,1],[[32,0],\"create\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"goBack\"],[[30,[36,1],[[32,0],\"goBack\"],null]]]]],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Create Training Activity\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n\\n      \"],[19,\"training-schedules/form\",[]],[2,\"\\n\\n    \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"input\"],[14,0,\"btn btn-go btn-disabled pull-right\"],[14,2,\"Creating...\"],[14,4,\"button\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"btn btn-go pull-right\"],[4,[38,1],[[32,0],\"checkAssignedEmployees\",[35,0]],null],[12],[2,\"\\n                Create Training Activity\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"model\",\"action\",\"back-link\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/training-schedules/document/template.hbs"
    }
  });

  _exports.default = _default;
});