define("isolocity/pods/components/capa-escalated-to-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // For Deviation
    deviations: null,
    deviationSelected: Ember.computed('deviations', function () {
      return this.get('deviations.firstObject.deviation_number');
    }),
    deviationCreatedIdSelected: Ember.computed('deviationCreated', function () {
      return this.get('deviations.firstObject.id');
    }),
    onDeviationSelected: Ember.observer('deviationSelected', function () {
      var _this = this;

      var selectedDeviationId = null;
      this.get('deviations').forEach(function (item) {
        if (item.get('deviation_number') == _this.get('deviationSelected')) {
          _this.set('deviationCreatedIdSelected', item.get('id'));
        }
      });
    }),
    deviationList: Ember.computed('deviations', function () {
      var deviationCreatedList = [];
      this.get('deviations').forEach(function (item) {
        deviationCreatedList.push(item.get('deviation_number').toString());
      });
      return deviationCreatedList;
    }),
    // For NCR
    ncrs: null,
    ncrSelected: Ember.computed('ncrs', function () {
      return this.get('ncrs.firstObject.ncr_number');
    }),
    ncrCreatedIdSelected: Ember.computed('ncrCreated', function () {
      return this.get('ncrs.firstObject.id');
    }),
    onNcrSelected: Ember.observer('ncrSelected', function () {
      var _this2 = this;

      var selectedNcrId = null;
      this.get('ncrs').forEach(function (item) {
        if (item.get('ncr_number') == _this2.get('ncrSelected')) {
          _this2.set('ncrCreatedIdSelected', item.get('id'));
        }
      });
    }),
    ncrList: Ember.computed('ncrs', function () {
      var ncrCreatedList = [];
      this.get('ncrs').forEach(function (item) {
        ncrCreatedList.push(item.get('ncr_number').toString());
      });
      return ncrCreatedList;
    }),
    // For CCR
    ccrs: null,
    ccrSelected: Ember.computed('ccrs', function () {
      return this.get('ccrs.firstObject.cc_number');
    }),
    ccrCreatedIdSelected: Ember.computed('ccrCreated', function () {
      return this.get('ccrs.firstObject.id');
    }),
    onCcrSelected: Ember.observer('ccrSelected', function () {
      var _this3 = this;

      var selectedCcrId = null;
      this.get('ccrs').forEach(function (item) {
        if (item.get('cc_number') == _this3.get('ccrSelected')) {
          _this3.set('ccrCreatedIdSelected', item.get('id'));
        }
      });
    }),
    ccrList: Ember.computed('ccrs', function () {
      var ccrCreatedList = [];
      this.get('ccrs').forEach(function (item) {
        ccrCreatedList.push(item.get('cc_number').toString());
      });
      return ccrCreatedList;
    }),
    // For CAR
    cars: null,
    carSelected: Ember.computed('cars', function () {
      return this.get('cars.firstObject.car_number');
    }),
    carCreatedIdSelected: Ember.computed('carCreated', function () {
      return this.get('cars.firstObject.id');
    }),
    oncarSelected: Ember.observer('carSelected', function () {
      var _this4 = this;

      var selectedcarId = null;
      this.get('cars').forEach(function (item) {
        if (item.get('car_number') == _this4.get('carSelected')) {
          _this4.set('carCreatedIdSelected', item.get('id'));
        }
      });
    }),
    carList: Ember.computed('cars', function () {
      var carCreatedList = [];
      this.get('cars').forEach(function (item) {
        carCreatedList.push(item.get('car_number').toString());
      });
      return carCreatedList;
    }),
    actions: {}
  });

  _exports.default = _default;
});