define("isolocity/pods/testing/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IvEjQFxu",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[1,[34,0]],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"model\"],[[35,1]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"section-header\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"testing.headers.Questions\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"sortedItems\",\"model\",\"controllerInstance\",\"allowNew\",\"onRowSaved\",\"onRowCreated\",\"onRowDeleted\"],[[35,6],[35,1],[35,5],[35,4],\"saveQuestion\",\"insertQuestion\",\"deleteQuestion\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"model\",\"test-settings\",\"t\",\"allowNewQuestions\",\"controllerInstance\",\"sortedQuestions\",\"test-questions-list\"]}",
    "meta": {
      "moduleName": "isolocity/pods/testing/-form/template.hbs"
    }
  });

  _exports.default = _default;
});