define("isolocity/pods/training-schedules/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'training-schedules.index',
    search: Ember.inject.service('search.training-schedules'),
    modelName: 'training-schedule',
    model: function model() {
      this.startLoading();
      var filters = this.getQueryFilters();
      filters['per_page'] = _environment.default.APP.pagination;
      filters['page'] = this.get('currentPage');
      filters['keyword'] = this.get('search').getKeyword();
      filters['employee_id'] = this.get('search').getEmployee();
      filters['training_type_id'] = this.get('search').getType();
      filters['is_active'] = this.get('search').getFilterByActive();
      filters['is_status'] = this.get('search').getFilterBy();
      return {
        trainingSchedules: this.store.query('training-schedule', filters)
      };
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      model.trainingSchedules.then(function (schedules) {
        _this.doneLoading();

        _this.setPageMetadata(schedules);
      });
      model.trainingTypes = this.store.findAll('training-type');
      model.allEmployees = this.store.findAll('employee');
      model.allUsers = this.store.findAll('user');
    },
    actions: {
      doSearchTrainingActivities: function doSearchTrainingActivities() {
        this.set('currentPage', 1);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});