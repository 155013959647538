define("isolocity/pods/components/settings-sidebar/ccrs/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    module: null,
    actions: {
      toggleReasonCode: function toggleReasonCode() {
        this.set('module.settings.is_reason_required_on_save', !this.get('module.settings.is_reason_required_on_save'));
        var reasonCodeSetting = this.get('module.settings.is_reason_required_on_save');
        this.set('module.settings.is_esignature_required_on_save', reasonCodeSetting);
      },
      toggleEsignature: function toggleEsignature() {
        this.set('module.settings.is_esignature_required_on_save', !this.get('module.settings.is_esignature_required_on_save'));
        var eSignatureSetting = this.get('module.settings.is_esignature_required_on_save');
        this.set('module.settings.is_reason_required_on_save', eSignatureSetting);
      },
      toggleSection1: function toggleSection1() {
        this.set('module.settings.sections.section_1_change_request', !this.get('module.settings.sections.section_1_change_request'));
        var section_1_change_request = this.get('module.settings.sections.section_1_change_request');
        this.set('module.settings.fields.required.description', section_1_change_request);
        this.set('module.settings.fields.required.rationale', section_1_change_request);
        this.set('module.settings.fields.required.budget_detail', section_1_change_request);
        this.set('module.settings.fields.required.budget_amount', section_1_change_request);
        this.set('module.settings.fields.required.scope_impact', section_1_change_request);
        this.set('module.settings.fields.required.action_plan', section_1_change_request);
      },
      toggleSection3: function toggleSection3() {
        this.set('module.settings.sections.section_3_quality_assessment', !this.get('module.settings.sections.section_3_quality_assessment'));
        var section_3_quality_assessment = this.get('module.settings.sections.section_3_quality_assessment');
        this.set('module.settings.fields.required.regulatory_authorization_description', section_3_quality_assessment);
      }
    }
  });

  _exports.default = _default;
});