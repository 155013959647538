define("isolocity/services/search/equipments", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    isArchived: false,
    isActive: null,
    isOutForService: null,
    equipmentTypeId: null,
    getIsActive: function getIsActive() {
      return this.get('isActive');
    },
    setIsActive: function setIsActive(isActive) {
      this.set('isActive', isActive);
    },
    getIsArchived: function getIsArchived() {
      return false;
    },
    setIsArchived: function setIsArchived(isArchived) {
      this.set('isArchived', false);
    },
    getIsOutForService: function getIsOutForService() {
      return this.get('isOutForService');
    },
    setIsOutForService: function setIsOutForService(isOutForService) {
      this.set('isOutForService', isOutForService);
    },
    getIsOverdue: function getIsOverdue() {
      return this.get('isOverdue');
    },
    setIsOverdue: function setIsOverdue(isOverdue) {
      this.set('isOverdue', isOverdue);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
      this.setIsActive(null);
      this.setIsArchived(false); // Always persist default

      this.setIsOutForService(null);
      this.setIsOverdue(null);
    }
  });

  _exports.default = _default;
});