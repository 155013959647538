define("isolocity/pods/shipments/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'shipments.index',
    search: Ember.inject.service('search.shipments'),
    modelName: 'shipment',
    model: function model() {
      var fromDate = !Ember.isEmpty(this.get('search').getFromDate()) ? this.get('search').getFromDate() : null;
      var toDate = !Ember.isEmpty(this.get('search').getToDate()) ? this.get('search').getToDate() : null;
      this.startLoading();
      return {
        shipments: this.store.query('shipment', {
          per_page: _environment.default.APP.pagination,
          page: this.get('currentPage'),
          keyword: this.get('search').getKeyword(),
          dateRange: this.get('search').getDateRange(),
          fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
          toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : ''
        })
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.shipments.then(function (shipments) {
        _this.doneLoading();

        _this.setPageMetadata(shipments);
      });
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.set('currentPage', 1);
        this.refresh();
      },
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('shipments');
        }
      }
    }
  });

  _exports.default = _default;
});