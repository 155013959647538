define("isolocity/pods/audit/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    intl: Ember.inject.service(),
    id_prefix: 'new_',
    requiredFields: ['name', 'audit_type_id.id'],
    historyOverride: '',
    isLoading: false,
    activeAuditTypes: Ember.computed('model.auditTypes', function () {
      return this.get('model.auditTypes').filterBy('is_active', true);
    }),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function (audit) {
            _this.set('isLoading', false);

            _this.get('history').setRoute('audit');

            _this.transitionToRoute('audit.edit', audit);

            fm.success(intl.t('audit.You have successfully added the audit'));
          }, function (response) {
            _this.set('isLoading', false);
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});