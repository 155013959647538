define("isolocity/pods/car/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    //attributes
    car_number: _emberData.default.attr(),
    identification: _emberData.default.attr('string'),
    initiator_name: _emberData.default.attr('string'),
    initiator_user_id: _emberData.default.belongsTo('user'),
    initiated_at: _emberData.default.attr('utc'),
    date_opened: _emberData.default.attr('utc'),
    date_closed: _emberData.default.attr('utc'),
    is_closed: _emberData.default.attr('boolean'),
    po_number: _emberData.default.attr('string'),
    car_source_id: _emberData.default.belongsTo('car-source'),
    source: _emberData.default.attr('string'),
    type_part_count: _emberData.default.attr('number'),
    type_part_rev_number: _emberData.default.attr('string'),
    batch_number: _emberData.default.attr('string'),
    date_completed: _emberData.default.attr('utc'),
    completed_by: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    immediate_action: _emberData.default.attr('string'),
    root_cause_occurrence_date: _emberData.default.attr('utc'),
    root_cause_description: _emberData.default.attr('string'),
    root_cause_why_1: _emberData.default.attr('string'),
    root_cause_why_2: _emberData.default.attr('string'),
    root_cause_why_3: _emberData.default.attr('string'),
    root_cause_why_4: _emberData.default.attr('string'),
    root_cause_why_5: _emberData.default.attr('string'),
    root_cause_fishbone_measurement: _emberData.default.attr('string'),
    root_cause_fishbone_material: _emberData.default.attr('string'),
    root_cause_fishbone_machine: _emberData.default.attr('string'),
    root_cause_fishbone_mother_nature: _emberData.default.attr('string'),
    root_cause_fishbone_man_power: _emberData.default.attr('string'),
    root_cause_fishbone_method: _emberData.default.attr('string'),
    root_cause_fishbone_final_analysis: _emberData.default.attr('string'),
    is_preventative_action_effective: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    verification_due_date: _emberData.default.attr('utc'),
    verification_completed_date: _emberData.default.attr('utc'),
    verification_completed_by: _emberData.default.attr('string'),
    verification_completed_description: _emberData.default.attr('string'),
    verification_completed_at: _emberData.default.attr('utc'),
    approval_completed_at: _emberData.default.attr('utc'),
    approval_completed_date: _emberData.default.attr('utc'),
    approval_completed_by: _emberData.default.attr('string'),
    approval_completed_description: _emberData.default.attr('string'),
    process_cost: _emberData.default.attr('string'),
    process_effect: _emberData.default.attr('string'),
    supplier_notes: _emberData.default.attr('string'),
    disposition: _emberData.default.attr('string'),
    disposition_authorized_by: _emberData.default.attr('string'),
    disposition_authorized_at: _emberData.default.attr('utc'),
    supplier_comments: _emberData.default.attr(),
    form_name: _emberData.default.attr('string'),
    form_rev_level: _emberData.default.attr('string'),
    custom_form: _emberData.default.attr('string'),
    //relationships (belongsTo)
    car_type_id: _emberData.default.belongsTo('car-type'),
    source_customer_id: _emberData.default.belongsTo('customer'),
    source_supplier_id: _emberData.default.belongsTo('supplier'),
    type_inspection_id: _emberData.default.belongsTo('inspection'),
    type_part_id: _emberData.default.belongsTo('part'),
    error_code_id: _emberData.default.belongsTo('error-code'),
    assignee: _emberData.default.belongsTo('user'),
    verification_completed_user_id: _emberData.default.belongsTo('user'),
    approval_completed_user_id: _emberData.default.belongsTo('user'),
    disposition_authorized_user_id: _emberData.default.belongsTo('user'),
    ncr_id: _emberData.default.belongsTo('ncr'),
    deviation_id: _emberData.default.belongsTo('deviation'),
    ccr_id: _emberData.default.belongsTo('ccr'),
    ccr_created: _emberData.default.hasMany('ccr', {
      inverse: 'causer_car'
    }),
    complaint: _emberData.default.belongsTo('complaint'),
    oosform_id: _emberData.default.belongsTo('oosform'),
    equipment_report: _emberData.default.belongsTo('equipment-report'),
    audit_report: _emberData.default.belongsTo('audit-report'),
    departments: _emberData.default.hasMany('department'),
    // Approval Section
    approval_expiration_date: _emberData.default.attr('utc'),
    owner_user_id: _emberData.default.belongsTo('user'),
    owner_approved_at: _emberData.default.attr('utc'),
    owner_rejected_at: _emberData.default.attr('utc'),
    owner_approved_reason: _emberData.default.attr('string'),
    owner_rejected_reason: _emberData.default.attr('string'),
    inspector_user_id: _emberData.default.belongsTo('user'),
    inspector_approved_at: _emberData.default.attr('utc'),
    inspector_rejected_at: _emberData.default.attr('utc'),
    inspector_approved_reason: _emberData.default.attr('string'),
    inspector_rejected_reason: _emberData.default.attr('string'),
    //relationships (hasMany)
    files: _emberData.default.attr(),
    pdf_logo: _emberData.default.attr(),
    preventative_actions: _emberData.default.hasMany('car.preventative-action'),
    action_items: _emberData.default.hasMany('action-item'),
    all_action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    activities: _emberData.default.hasMany('activity'),
    batch_records: _emberData.default.hasMany('batch-record'),
    // Implementation Plan
    implementation_action_items: _emberData.default.hasMany('action-item'),
    control_system_action_items: _emberData.default.hasMany('action-item'),
    is_action_items_complete: _emberData.default.attr('boolean'),
    //dynamic attributes
    type: _emberData.default.attr('string'),
    error_code_name: _emberData.default.attr('string'),
    car_source_name: _emberData.default.attr('string'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    incomplete_action_items_count: _emberData.default.attr('number'),
    unread_notes_count: _emberData.default.attr('number'),
    abbreviated_report_id: _emberData.default.attr('string'),
    original_source_supplier_id: _emberData.default.attr('string'),
    send_supplier_email: _emberData.default.attr('boolean', {
      default: false
    }),
    initiatedAt: Ember.computed('initiated_at', function () {
      return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
    }),
    dispositionAuthorizedAt: Ember.computed('disposition_authorized_at', function () {
      return Ember.isEmpty(this.get('disposition_authorized_at')) ? '' : moment(this.get('disposition_authorized_at')).format("MMM D, YYYY h:mm A");
    }),
    verificationCompletedAt: Ember.computed('verification_completed_at', function () {
      return Ember.isEmpty(this.get('verification_completed_at')) ? '' : moment(this.get('verification_completed_at')).format("MMM D, YYYY h:mm A");
    }),
    approvalCompletedAt: Ember.computed('approval_completed_at', function () {
      return Ember.isEmpty(this.get('approval_completed_at')) ? '' : moment(this.get('approval_completed_at')).format("MMM D, YYYY h:mm A");
    }),
    isDispositionAuthorized: Ember.computed('disposition_authorized_at', function () {
      return !Ember.isEmpty(this.get('disposition_authorized_at'));
    }),
    sourceAdditional: Ember.computed('source', function () {
      var source = this.get('source');

      if (source === 'Supplier') {
        return this.get('supplier_name');
      } else if (source === 'Customer') {
        return this.get('customer_name');
      }

      return '';
    }),
    errorCodeName: Ember.computed('error_code_name', function () {
      if (this.get('error_code_name') && this.get('error_code_name').indexOf(' - ')) {
        return this.get('error_code_name').split(' - ')[0];
      }

      return '';
    }),
    hasIncompleteActionItems: Ember.computed('action_items', 'action_items.length', function () {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }),
    // Dynamic for approval section
    ownerApprovedAt: Ember.computed('owner_approved_at', function () {
      return Ember.isEmpty(this.get('owner_approved_at')) ? '' : moment(this.get('owner_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    ownerRejectedAt: Ember.computed('owner_rejected_at', function () {
      return Ember.isEmpty(this.get('owner_rejected_at')) ? '' : moment(this.get('owner_rejected_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorApprovedAt: Ember.computed('inspector_approved_at', function () {
      return Ember.isEmpty(this.get('inspector_approved_at')) ? '' : moment(this.get('inspector_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorRejectedAt: Ember.computed('inspector_rejected_at', function () {
      return Ember.isEmpty(this.get('inspector_rejected_at')) ? '' : moment(this.get('inspector_rejected_at')).format("MMM D, YYYY h:mm A");
    }),
    // Load linked report data as needed
    loadLinkedData: function loadLinkedData() {
      if (!Ember.isBlank(this.get('deviation_id.id'))) {
        this.store.findRecord('deviation', this.get('deviation_id.id'));
      }

      if (!Ember.isBlank(this.get('ncr_id.id'))) {
        this.store.findRecord('ncr', this.get('ncr_id.id'));
      }

      if (!Ember.isBlank(this.get('ccr_id.id'))) {
        this.store.findRecord('ccr', this.get('ccr_id.id'));
      }

      if (!Ember.isBlank(this.get('complaint.id'))) {
        this.store.findRecord('complaint', this.get('complaint.id'));
      }

      if (!Ember.isBlank(this.get('oosform_id.id'))) {
        this.store.findRecord('oosform', this.get('oosform_id.id'));
      }

      if (!Ember.isBlank(this.get('type_inspection_id.id'))) {
        this.store.findRecord('inspection', this.get('type_inspection_id.id'));
      }

      if (!Ember.isBlank(this.get('equipment_report.id'))) {
        this.store.findRecord('equipment-report', this.get('equipment_report.id'));
      }

      if (!Ember.isBlank(this.get('audit_report.id'))) {
        this.store.findRecord('audit-report', this.get('audit_report.id'));
      }
    }
  });

  _exports.default = _default;
});