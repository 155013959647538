define("isolocity/pods/components/datetime-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "36PQQzd+",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"date\",\"format\",\"disabled\",\"showClear\",\"placeholder\",\"focusOnShow\",\"minDate\",\"valueDefault\",\"allowInputToggle\",\"class\"],[[35,4],[35,3],[35,2],true,[35,1],false,[35,0],null,true,\"full-width\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"minDate\",\"placeholder\",\"disabled\",\"dateTimeFormat\",\"value\",\"bs-datetimepicker\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/datetime-selector/template.hbs"
    }
  });

  _exports.default = _default;
});