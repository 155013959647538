define("isolocity/pods/inventory/index/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/batch-record-lookup-mixin", "isolocity/mixins/cost-lookup-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/pagination-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/batch-records-list-mixin", "isolocity/mixins/failure-rate-mixin"], function (_exports, _environment, _downloadableReportsMixin, _batchRecordLookupMixin, _costLookupMixin, _userOrganizationMixin, _paginationMixin, _dateSearchMixin, _batchRecordsListMixin, _failureRateMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_downloadableReportsMixin.default, _batchRecordLookupMixin.default, _batchRecordsListMixin.default, _costLookupMixin.default, _userOrganizationMixin.default, _paginationMixin.default, _dateSearchMixin.default, _failureRateMixin.default, {
    intl: Ember.inject.service(),
    config: _environment.default,
    search: Ember.inject.service('search.inventory'),
    sortProperties: ['stockPriority:desc', 'quantity:asc'],
    sortedInventory: Ember.computed.sort('model', 'sortProperties'),
    searchStatusOptions: [],
    hideFilterFields: false,
    viewMode: 'list',
    inventoryId: null,
    matches: [],
    init: function init() {
      this.searchStatusOptions = [this.get("intl").t("In Stock"), this.get('intl').t('Running Low'), this.get('intl').t('Out of Stock')];
      return this._super.apply(this, arguments);
    },
    allReportData: Ember.computed('allStartDate', 'allEndDate', 'allStatus', 'allType', function () {
      return {
        source: this.get('allSource') ? this.get('allSource') : null,
        start_date: this.get('allStartDate') ? moment(this.get('allStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('allEndDate') ? moment(this.get('allEndDate')).format("YYYY-MM-DD") : null
      };
    }),
    actions: {
      setViewMode: function setViewMode(mode) {
        if (mode !== 'list') {
          this.set('hideFilterFields', true);
        } else {
          this.set('hideFilterFields', false);
        }

        this.set('viewMode', mode);
      },
      generateCsv: function generateCsv(apiEndpoint, name) {
        var fm = this.get('flashMessages'),
            intl = this.get('intl'),
            startDateVariable = name + 'StartDate',
            endDateVariable = name + 'EndDate',
            payload = {};

        if (name === 'history') {
          var inventoryId = this.get('inventoryId') ? this.get('inventoryId.id') : null; // Validating inventory id if this is request from Inventory History Download CSV

          if (inventoryId !== undefined && inventoryId === null) {
            fm.danger(intl.t('inventory.others.Please select any inventory.'));
            return;
          }

          payload.inventory_id = inventoryId;
        } else {
          payload.start_date = this.get(startDateVariable) ? moment(this.get(startDateVariable)).format("YYYY-MM-DD") : null;
          payload.end_date = this.get(endDateVariable) ? moment(this.get(endDateVariable)).format("YYYY-MM-DD") : null;
        }

        this.send('generateQueuableReport', apiEndpoint, payload);
        this.set(startDateVariable, '');
        this.set(endDateVariable, '');
      }
    }
  });

  _exports.default = _default;
});