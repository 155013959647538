define("isolocity/mixins/equipment-reports-mixin", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/e-signature-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _eSignatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_requiredFieldsMixin.default, _historyHandlerMixin.default, _eSignatureMixin.default, {
    modules: Ember.inject.service('modules'),
    persistence: Ember.inject.service(),
    intl: Ember.inject.service(),
    requiredFields: ['equipment_id.id', 'report_template_id.id'],

    get isEsignatureRequiredForCompletion() {
      return this.get('modules').getModuleSetting('equipment-reports', 'is_esignature_required_for_completion', false);
    },

    nextDueDate: Ember.computed('model.next_due', 'lastCompletedReport', function () {
      var modelNextDue = this.get('model.next_due'),
          lastDueDate = this.get('lastCompletedReport.next_due');
      if (Ember.isEmpty(modelNextDue) && Ember.isEmpty(lastDueDate)) return 'N/A';
      if (Ember.isEmpty(modelNextDue) || lastDueDate > modelNextDue) return lastDueDate;
      return modelNextDue;
    }),
    lastCompletedReport: Ember.computed('model.equipment_type_id.id', function () {
      var _this2 = this;

      var lastCompleted;
      var model = this.get('model');
      model.get('equipmentReports').forEach(function (report, index) {
        if (report !== model && report.get('equipment_id.id') === _this2.get('model.equipment_id.id')) {
          var reportDate = report.get('completion_date');

          if (!Ember.isEmpty(reportDate) && reportDate.isValid !== undefined) {
            reportDate = reportDate.toDate();

            if (Ember.isEmpty(lastCompleted) || reportDate > lastCompleted.get('completion_date').toDate()) {
              lastCompleted = report;
            }
          }
        }
      });
      return lastCompleted;
    }),
    onCompletedDateChange: Ember.observer('model.equipment_type_id.id', 'model.completion_date', function () {
      var model = this.get('model'),
          equipment_id = model.get('equipment_id');
      if (!Ember.isPresent(model.changedAttributes().completion_date)) return;
      if (Ember.isEmpty(equipment_id.get('id')) || this.get('isSaving')) return;

      if (!(Ember.isBlank(model.get('completion_date')) || Ember.isBlank(model.get('equipment_type_id.frequency')) || Ember.isBlank(model.get('equipment_type_id.duration')))) {
        var nextDue = new Date(model.get('completion_date').toString()),
            frequency = model.get('equipment_type_id.frequency');

        switch (model.get('equipment_type_id.duration')) {
          case 'Months':
            nextDue = nextDue.setMonth(nextDue.getMonth() + frequency);
            break;

          case 'Weeks':
            nextDue = nextDue.setDate(nextDue.getDate() + frequency * 7);
            break;

          case 'Days':
            nextDue = nextDue.setDate(nextDue.getDate() + frequency);
            break;
        }

        model.set('next_due', new Date(nextDue));
        return;
      }

      model.set('next_due', null);
    }),
    actions: {
      selectedType: function selectedType(data) {
        var _this3 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.store.findRecord('report_template', data.id).then(function () {
          var filteredEquipment = [];

          _this3.model.equipment.forEach(function (equipment) {
            if (equipment.get('equipment_type_id').get('id') === data.id) {
              filteredEquipment.push(equipment);
            }
          });

          _this3.set('model.equipment', filteredEquipment);

          _this3.actions.selectedForm(data, _this3);
        }, function (error) {
          fm.danger(intl.t('mixins.equipment-reports.Type not have Template'));
        });
      },
      selectedEquipment: function selectedEquipment(data) {
        var _this4 = this;

        this.store.findRecord('equipment', data.id).then(function (result) {
          _this4.set('model.equipment_type_id', result.get('equipment_type_id'));
        });
      },
      selectedForm: function selectedForm(data) {
        var _this = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this;

        $('#formRender').val('');
        $(".formbuilder-rendering").html('');

        var fm = _this.get('flashMessages'),
            intl = this.get('intl');

        _this.store.findRecord('report_template', data.id).then(function (result) {
          _this.set('model.report_template_id', result);

          $('#formRender').val(result.get('form'));
          $('#formRender').formRender();
        }, function (error) {
          fm.danger(intl.t('mixins.equipment-reports..Type not have Template'));
        });
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      },
      createCAR: function createCAR() {
        var model = this.get('model');
        var route = 'corrective-actions.new';
        this.get('history').setRoute('equipment');
        var car = this.store.createRecord('car', {
          equipment_report: model,
          is_closed: false,
          date_opened: new Date()
        });
        this.get('persistence').add({
          route: route,
          model: car
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      createDeviation: function createDeviation() {
        var route = 'deviations.new';
        this.get('history').setRoute('deviations');
        var deviation = this.store.createRecord('deviation', {
          causer_id: this.get('model.id'),
          causer_type: 'report'
        });
        this.get('persistence').add({
          route: route,
          model: deviation
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      }
    }
  });

  _exports.default = _default;
});