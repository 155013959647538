define("isolocity/pods/training-schedules/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/training-schedule-pdf-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _trainingSchedulePdfMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _trainingSchedulePdfMixin.default, {
    isEdit: true,
    isSaving: false,
    requiredFields: ['training_program_id.id', 'start_date'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    canAddEmployees: true,
    isUseDefaultTrainingProgram: false,
    PdfTitle: Ember.computed('model.id', function () {
      return 'Training Activity # ' + this.get('model.id') + ' - ' + this.get('model.training_program_id.title');
    }),
    stickiedFiles: Ember.computed('model.training_program_id', function () {
      var files = [];

      var _this = this;

      if (!Ember.isBlank(this.get('model.training_program_id.files'))) {
        files = files.concat(this.get('model.training_program_id.files'));
      }

      if (!Ember.isBlank(this.get('model.document_id.files'))) {
        var docFiles = this.get('model.document_id.files').filter(function (file) {
          return file.rev_number === _this.get('model.document_rev_number');
        });
        files = files.concat(docFiles);
      }

      return files;
    }),
    onSelectedEmployeeChange: Ember.observer('selectedEmployee', function () {
      if (this.get('selectedEmployee') && this.get('selectedEmployee') !== false) {
        var employee = this.get('selectedEmployee');
        this.send('addEmployeeToSchedule', employee);
      }
    }),
    actions: {
      save: function save() {
        var _this2 = this;

        if (this.get('isValid')) {
          this.set('isSaving', true);
          this.get('model').save().then(function (trainingSchedule) {
            _this2.send('saveAttachments', trainingSchedule);
          }, function (error) {
            _this2.set('isSaving', false);

            _this2.send('showServerErrors', error);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(schedule) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        this.get('history').setRoute('training-schedules');
        this.transitionToRoute('training-schedules.edit', schedule);
        fm.success("You have successfully saved the training activity.");
        fm.success('It may take several moments to update this record. Please refresh the page after few seconds!');
      },
      addEmployeeToSchedule: function addEmployeeToSchedule(employee) {
        var _this3 = this;

        var employees = this.get('model.employees');

        if (!employees.includes(employee)) {
          this.get('model.employees').pushObject(employee);
        }

        this.get('model').save().then(function () {
          _this3.set('selectedEmployee', false);

          _this3.get('model').reload();
        });
      },
      removeItem: function removeItem(employee) {
        var employees = this.get('model.employees');

        if (employees.includes(employee)) {
          this.get('model.employees').removeObject(employee);
        }
      },
      removeEnrollment: function removeEnrollment(enrollment) {
        var employee = this.store.peekRecord('employee', enrollment.get('employee_id.id'));
        this.send('removeItem', employee);
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      },
      onTestRetaken: function onTestRetaken() {
        this.get('model').reload();
      },
      createReport: function createReport() {
        var schedule = this.get('model');
        this.set('isCreatingReport', true);
        var reportContent = this.getTrainingSchedulePdfContent(schedule);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      }
    }
  });

  _exports.default = _default;
});