define("isolocity/pods/components/graphs/equipment/on-time-percentage/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/equipment/on-time-percentage/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      var intl = this.get('intl');
      this.set('chartData', null);

      var _this = this;

      var startDate = null,
          endDate = null;

      if (!Ember.isEmpty(this.get('startDateOnTime'))) {
        startDate = moment(this.get('startDateOnTime')).format("YYYY-MM-DD");
      }

      if (!Ember.isEmpty(this.get('endDateOnTime'))) {
        endDate = moment(this.get('endDateOnTime')).format("YYYY-MM-DD");
      }

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/equipment/on-time/graph',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies'),
          equipment_id: this.get('selectedOnTimeEquipment.id'),
          start_date: startDate,
          end_date: endDate
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;
          var series = [{
            name: 'Percentage',
            colorByPoint: true,
            data: [{
              name: intl.t('On-time'),
              y: data.on_time,
              selected: true,
              color: '#69c576'
            }, {
              name: intl.t('Overdue'),
              y: data.overdue,
              color: '#ed6161'
            }]
          }];

          if (!Ember.isBlank(series) && (data.on_time > 0 || data.overdue > 0)) {
            _this.set('chartData', series);
          } else {
            _this.set('chartData', [{
              name: intl.t('No Data'),
              data: []
            }]);
          }
        }
      });

      this._super.apply(this, arguments);
    },
    onSelectedOnTimeEquipmentChange: Ember.observer('selectedOnTimeEquipment', function () {
      this.init();
    }),
    onStartDateOnTimeChange: Ember.observer('startDateOnTime', function () {
      this.init();
    }),
    onEndDateOnTimeChange: Ember.observer('endDateOnTime', function () {
      this.init();
    })
  });

  _exports.default = _default;
});