define("isolocity/pods/inventory/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    part_id: _emberData.default.belongsTo('part'),
    quantity: _emberData.default.attr('string'),
    reorder_point: _emberData.default.attr('number'),
    min_level: _emberData.default.attr('number'),
    max_level: _emberData.default.attr('number'),
    is_locked: _emberData.default.attr('boolean'),
    location: _emberData.default.attr('string'),
    batch_number: _emberData.default.attr('string'),
    is_legacy: _emberData.default.attr('boolean'),
    location_id: _emberData.default.belongsTo('location'),
    inventory_location: _emberData.default.hasMany('inventory.inventory-location', {
      async: true
    }),
    batch_records: _emberData.default.hasMany('inventory'),
    legacy_record_id: _emberData.default.attr('number'),
    new_batch_record_id: _emberData.default.attr('number'),
    related_batch_numbers: _emberData.default.attr(),
    // not stored
    notes: _emberData.default.attr('string'),
    // belongsTo
    part: _emberData.default.belongsTo('part'),
    // hasMany
    history: _emberData.default.hasMany('inventory.history'),
    // computed
    value_on_hand: _emberData.default.attr('money'),
    cost: _emberData.default.attr('number'),
    inventory_name: _emberData.default.attr('string'),
    is_finished_product: _emberData.default.attr('boolean'),
    isNewBatchNumber: _emberData.default.attr('boolean', {
      default: false
    }),
    is_expiry_soon: _emberData.default.attr('string'),
    nearest_expiry_date: _emberData.default.attr('isodate'),
    displayName: Ember.computed('part.identification', 'part.name', function () {
      var batch = !Ember.isBlank(this.get('batch_number')) ? this.get('batch_number') : 'Blank batch number';
      return "#".concat(this.get('part.identification'), " - ").concat(this.get('part.name'), " - ").concat(batch);
    }),
    displayExpiryDate: Ember.computed('nearest_expiry_date', function () {
      if (Ember.isEmpty(this.get('nearest_expiry_date'))) {
        return;
      }

      return moment(this.get('nearest_expiry_date')).format("YYYY-MM-DD");
    }),
    stockPriority: Ember.computed('quantity', 'reorder_point', function () {
      var priorities = {
        'In Stock': 0,
        'Running Low': 1,
        'Out of Stock': 2
      };
      var stockDisplay = this.get('stockDisplay');

      if (stockDisplay == this.get('intl').t('In Stock')) {
        stockDisplay = 'In Stock';
      } else if (stockDisplay == this.get('intl').t('Out of Stock')) {
        stockDisplay = 'Out of Stock';
      } else if (stockDisplay == this.get('intl').t('Running Low')) {
        stockDisplay = 'Running Low';
      }

      return priorities[stockDisplay];
    }),
    stockDisplay: Ember.computed('quantity', 'reorder_point', function () {
      if (this.get('quantity') > this.get('reorder_point')) return this.get('intl').t('In Stock');else if (this.get('quantity') <= 0) return this.get('intl').t('Out of Stock');else return this.get('intl').t('Running Low');
    })
  });

  _exports.default = _default;
});