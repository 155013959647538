define("isolocity/mixins/equipment-editing-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isEdit: true,
    actions: {}
  });

  _exports.default = _default;
});