define("isolocity/mixins/inspections-creation-mixin", ["exports", "ember-data", "isolocity/mixins/required-fields-mixin"], function (_exports, _emberData, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_requiredFieldsMixin.default, {
    intl: Ember.inject.service(),
    hasDrawingLoaded: false,
    hasFrequenciesDatesLoaded: 0,
    isValidInspection: Ember.computed('model.part_id', 'model.drawing_id', 'model.drawing_id.name', 'model.equipment', function () {
      var model = this.get('model');
      var fm = this.get('flashMessages');

      if (this.get('isEquipment') && Ember.isEmpty(model.get('equipment.id'))) {
        return false;
      }

      if (this.get('isPart') && Ember.isEmpty(model.get('part_id.id'))) {
        return false;
      }

      if (Ember.isEmpty(model.get('drawing_id.id'))) {
        return false;
      }

      if (model.get('drawing_id.is_approved') !== true) {
        return false;
      }

      return true;
    }),

    get isIncrementSerialNumbers() {
      return this.get('modules').getModuleSetting('inspections', 'is_increment_serial_numbers', false);
    },

    partSearchParams: {
      isFinishedProduct: 1
    },
    equipmentForSerach: {
      is_out_for_service: false
    },
    onPartChange: Ember.observer('model.part_id', function () {
      var _this = this;

      var model = this.get('model');

      if (model.get('isDeleted') || model.get('isDeleting')) {
        return;
      }

      this.get('flashMessages').clearMessages();
      this.set('hasDrawingLoaded', false);

      if (!Ember.isEmpty(model.get('part_id.acceptance_ratio'))) {
        model.set('acceptance_ratio', model.get('part_id.acceptance_ratio'));
      } else {
        model.set('acceptance_ratio', 100);
      }

      if (!Ember.isEmpty(model.get('part_id.id'))) {
        model.get('part_id').then(function (part) {
          if (!Ember.isEmpty(part)) {
            _this.get('store').query('drawing', {
              is_approved: true,
              part_id: part.get('id')
            }).then(function (drawings) {
              model.set('drawing_id', null);

              if (drawings.get('length') > 0) {
                model.set('drawing_id', drawings.get('firstObject'));
              }

              _this.set('hasDrawingLoaded', true);
            });
          }
        });
      }

      if (model.get('hasOrder')) {
        if (!Ember.isEmpty(model.get('part_id.order_item_id'))) {
          model.set('order_item_id', model.get('part_id.order_item_id'));
        }

        if (!Ember.isEmpty(model.get('part_id.order_item_sample_size'))) {
          model.set('sample_size', model.get('part_id.order_item_sample_size'));
        }
      }
    }),
    onEquipmentDrawingChange: Ember.observer('model.drawing_id.id', function () {
      var _this2 = this;

      var model = this.get('model');
      this.set('hasDrawingLoaded', false);

      if (!Ember.isEmpty(model.get('drawing_id.id'))) {
        this.get('store').findRecord('drawing', model.get('drawing_id.id')).then(function (drawing) {
          _this2.set('hasDrawingLoaded', true);
        });
      }
    }),
    orderParts: Ember.computed('model.order_id.id', function () {
      var model = this.get('model');

      if (!Ember.isBlank(model.get('order_id.id'))) {
        return model.get('order_id.parts');
      }

      return [];
    }),
    availableDrawings: Ember.observer('model.equipment.id', function () {
      var model = this.get('model');

      if (!Ember.isBlank(model.get('equipment.id'))) {
        this.get('store').findRecord('equipment', model.get('equipment.id')).then(function (equipment) {
          model.set('availableDrawings', equipment.get('all_approved_drawings'));
          return equipment.get('all_approved_drawings');
        });
      } else {
        return model.get('availableDrawings');
      }
    }),
    availableDrawingsFrequencyDue: Ember.observer('model.availableDrawings', 'model.equipment', 'hasFrequenciesDatesLoaded', function () {
      var model = this.get('model');

      if (!Ember.isBlank(model.get('equipment.id')) && !Ember.isBlank(model.get('availableDrawings'))) {
        var availableDrawings = model.get('availableDrawings');
        var frequenciesDueArray = [];
        return _emberData.default.PromiseObject.create({
          promise: this.store.findRecord('equipment', model.get('equipment.id'), {
            reload: true
          }).then(function (selected_equipment) {
            var frequencyDrawingDatesArray = selected_equipment.get('frequency_drawing_due_dates_array');

            if (!Ember.isEmpty(frequencyDrawingDatesArray)) {
              availableDrawings.forEach(function (drawing, i) {
                for (var key in frequencyDrawingDatesArray) {
                  if (key == drawing.get('id')) {
                    var date = moment(frequencyDrawingDatesArray[key]).format('LL');
                    drawing.set('frequency_due', date);
                  }
                }
              });
            }

            model.set('availableDrawingsFrequencyDue', frequenciesDueArray);
            return frequenciesDueArray;
          })
        });
      } else {
        return model.get('availableDrawingsFrequencyDue');
      }
    }),
    onOrderChange: Ember.observer('model.order_id.id', function () {
      var model = this.get('model');
      this.get('flashMessages').clearMessages();

      if (!Ember.isBlank(model.get('order_id.id'))) {
        this.store.findRecord('order', model.get('order_id.id')).then(function (order) {
          if (!Ember.isEmpty(order.get('serial_number'))) {
            model.set('serial_number', order.get('serial_number'));
          }
        });
      } else {
        model.set('order_item_id', null);
        model.set('part_id', null);
        model.set('drawing_id', null);
        model.set('serial_number', null);
        model.set('sample_size', 1);
        model.set('parts', []);
      }
    }),
    actions: {
      create: function create() {
        var _this3 = this;

        var fm = this.get('flashMessages'),
            model = this.get('model'),
            intl = this.get('intl');

        if (!this.get('isValid')) {
          this.send('showErrors');
          return;
        }

        if (this.get('isValidInspection')) {
          if (Ember.isEmpty(this.get('model.acceptance_ratio')) || this.get('model.acceptance_ratio') < 0 || this.get('model.acceptance_ratio') > 100) {
            fm.danger(intl.t('mixins.inspection-creation.Valid Acceptance Ratio'));
            return;
          }

          this.set('isLoading', true);
          var entity_id = this.get('isEquipment') ? model.get('equipment.id') : model.get('part_id.id');
          var drawing_id = this.get('isEquipment') ? model.get('drawing_id') : null;
          model.set('entity_id', entity_id);
          model.save().then(function (inspection) {
            if (drawing_id != null && inspection.get('drawing_id') != drawing_id) inspection.set('drawing_id', drawing_id);

            _this3.get('history').setRoute('inspections');

            _this3.transitionToRoute('inspections.edit', inspection);

            _this3.set('isLoading', false);

            var frequency = inspection.get('part_id.shelf_life_frequency');
            var duration = inspection.get('part_id.shelf_life_duration');
            var batchRecords = inspection.get('batch_records');

            if (frequency && duration) {
              batchRecords.forEach(function (record) {
                var expiry_date = moment().add(frequency, duration.toLowerCase());
                Ember.set(record, 'expiry_date', expiry_date);
                record.save();
              });
            }

            fm.success(intl.t('mixins.inspection-creation.The report has been created'));
          }, function (error) {
            _this3.set('isLoading', false);

            fm.danger(intl.t('mixins.inspection-creation.Report Error'));
          });
        } else {
          var drawingPlural = this.get('constants').get('text.drawings') ? this.get('constants').get('text.drawings').toLowerCase() : 'drawings';
          fm.danger("".concat(intl.t('mixins.inspection-creation.Report Not Created')) + drawingPlural + '.', {
            sticky: true
          });
        }
      }
    }
  });

  _exports.default = _default;
});