define("isolocity/pods/deviations/edit/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/file-download-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/deviation-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/activity-log-mixin"], function (_exports, _environment, _fileDownloadMixin, _historyHandlerMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _uploadFilesMixin, _pdfGenerationMixin, _ownersAndSubscribersMixin, _deviationMixin, _ajaxRequestMixin, _eSignatureMixin, _activityLogMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileDownloadMixin.default, _activityLogMixin.default, _historyHandlerMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _uploadFilesMixin.default, _pdfGenerationMixin.default, _ownersAndSubscribersMixin.default, _deviationMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, {
    modules: Ember.inject.service('modules'),
    sideMenu: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    persistence: Ember.inject.service(),
    intl: Ember.inject.service(),
    id_prefix: 'edit_',
    PdfTitle: Ember.computed('model.deviation_number', function () {
      return 'Deviation Report - DEV# ' + this.get('model.deviation_number');
    }),
    isEdit: true,
    isSaving: false,
    isCreatingReport: false,
    isAddingOwnerApproval: false,
    isAddingInspectorApproval: false,
    isAddingThirdApproval: false,
    isRejectingByOwner: false,
    isRejectingByInspector: false,
    isRejectingByThird: false,
    canReopenDeviation: false,
    isActionItemsCompleted: false,
    noteEntityType: 'deviation',
    actionLink: 'deviations.edit',
    actionEntityType: 'deviation',
    defaultRequiredFields: ['deviation_number'],
    defaultRequiredFieldsTranslation: {
      'risk_description': 'Description of Risk Assessment',
      'control_measures_description': 'Control Measures',
      'investigation_summary': 'Investigation Summary by Quality Assurance',
      'qa_impact_assessment': 'Quality Assurance Impact Assessment',
      'conclusion_recommendation': 'Conclusion and Recommendation'
    },
    actionTitleOptions: ['Complete Form Part A', 'Complete Form Part B', 'Complete Form Part C', 'Escalate to Corrective Action'],
    isFormApprovedBeforeDepartment: Ember.computed('model.esignatures.@each.signed_at', 'model.owner_approved_at', 'model.inspector_approved_at', function () {
      return this.get('model.esignatures') && this.get('model.esignatures').find(function (param) {
        return param.signed_at != null;
      }) || this.get('model.owner_approved_at') || this.get('model.inspector_approved_at');
    }),
    isFormApproved: Ember.computed('isOwnedByCurrentUser', 'model.owner_approved_at', 'model.inspector_approved_at', 'model.third_approved_at', function () {
      if (this.get('model.owner_approved_at') || this.get('model.inspector_approved_at') || this.get('model.third_approved_at')) {
        return true;
      }

      return false;
    }),
    isOwnedByCurrentUser: Ember.computed('model.id', 'model.owners', function () {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));
      return this.get('model.owners').includes(user);
    }),
    isFormLocked: Ember.computed('isOwnedByCurrentUser', 'model.is_closed', function () {
      if (this.get('model.is_closed') || !this.get('permissions').hasRoleAtLeast('production')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    canUpgradeDeviationsToCars: Ember.computed('model.car.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('production')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser');
    }),
    canUpgradeDeviationsToCcrs: Ember.computed('model.ccr.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('production')) {
        return false;
      }

      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.ccr.id'));
    }),
    canUpgradeDeviationsToNCR: Ember.computed('model.ncr.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('production')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser');
    }),
    canOpenAndCloseDeviations: Ember.computed(function () {
      if (this.get('isOwnedByCurrentUser')) {
        return true;
      }

      if (this.get('permissions').hasRoleAtLeast('manager') && this.get('authManager.session.currentUser.can_reopen_reports')) {
        return true;
      }

      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    isFiveWhyAnalysis: Ember.computed('model.id', 'model.fishbone_5_why.root_cause_why_1', 'model.fishbone_5_why.root_cause_why_2', 'model.fishbone_5_why.root_cause_why_3', 'model.fishbone_5_why.root_cause_why_4', 'model.fishbone_5_why.root_cause_fishbone_5_why', function () {
      return !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_1')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_2')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_3')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_4')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_5_why'));
    }),
    isFishboneAnalysis: Ember.computed('model.id', 'model.fishbone_5_why.root_cause_fishbone_measurement', 'model.fishbone_5_why.root_cause_fishbone_material', 'model.fishbone_5_why.root_cause_fishbone_machine', 'model.fishbone_5_why.root_cause_fishbone_mother_nature', 'model.fishbone_5_why.root_cause_fishbone_man_power', 'model.fishbone_5_why.root_cause_fishbone_method', function () {
      return !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_measurement')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_material')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_machine')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_mother_nature')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_man_power')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_method'));
    }),
    canDeleteDeviation: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('deviations', 'is_reason_required_on_save', false);
    },

    isMissingRequiredFields: function isMissingRequiredFields() {
      this.send('hasRequiredFields'); // Query required fields

      if (this.get('isValid')) {
        return false; // No missing required fields
      }

      return true;
    },
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (Ember.isBlank(this.get('model.owners'))) {
          fm.danger(intl.t('At least one owner is required'));
          return;
        }

        var model = this.get('model');
        this.set('isSaving', true);
        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (deviation) {
          _this.send('saveAttachments', deviation, false, _this.get('eSignaturePassword'), _this.get('eSignatureReason'));

          _this.send('saveBatchRecords', _this.get('eSignaturePassword'), _this.get('eSignatureReason'));
        }, function (error) {
          _this.set('isSaving', false);

          fm.danger(error.errors, {
            sticky: false
          });
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(deviation) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('The changes to this report have been saved successfully'));
      },
      createReport: function createReport() {
        var deviation = this.get('model');
        var report = {};
        this.set('isCreatingReport', true);
        var reportContent = this.getDeviationReportContent(deviation);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createReportNew: function createReportNew() {
        var _this2 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var deviation = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/deviation/' + deviation.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            var id = _this2.get('model.id');

            var model = 'deviation';

            var deviationNumber = _this2.get('model.deviation_number');

            var description = "Deviation Report #".concat(deviationNumber, " has been downloaded");

            _this2.send('newActivityLog', id, model, description);

            _this2.send('downloadPdfFile', data, 'Report_' + _this2.get('model.deviation_number'));
          } else {
            _this2.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this2.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this2.set('isCreatingReport', false);
        });
      },
      createReportNewWithAttachments: function createReportNewWithAttachments() {
        var _this3 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.send('createReportNew', isDownload);
        var car = this.get('model');
        var files = car.get('files');
        files.forEach(function (file, i) {
          // Delay download between files for browser optimization
          var timeout = 500 * i;
          setTimeout(function () {
            _this3.send('downloadFile', file);
          }, timeout);
        });
      },
      createActionFillFormC: function createActionFillFormC() {
        var model = this.get('model');
        model.set('emptyAction', this.store.createRecord('action-item', {
          title: 'Complete Form Part C',
          priority: 'Medium',
          due_date: new moment().add(5, 'days')
        }));
        this.get("sideMenu").open('action-notes');
      },
      upgradeToCar: function upgradeToCar() {
        var model = this.get('model');
        var route = 'corrective-actions.new';
        this.get('history').setRoute('deviations');
        var car = this.store.createRecord('car', {
          deviation_id: model,
          is_closed: false,
          date_opened: new Date(),
          initiator_name: model.get('initiator_user_id.name'),
          description: model.get('description')
        });

        if (!Ember.isBlank(model.get('part_id'))) {
          this.store.query('deviation-type', {
            keyword: 'Part'
          }).then(function (types) {
            var type = types.get('firstObject');
            car.set('deviation_type_id', type);
          });
          car.set('type_part_id', model.get('part_id'));
          car.set('type_part_count', model.get('quantity'));
        }

        this.get('persistence').add({
          route: route,
          model: car
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      upgradeToChangeControl: function upgradeToChangeControl() {
        var model = this.get('model');
        var route = 'ccrs.new';
        this.get('history').setRoute('deviations');
        var ccr = this.store.createRecord('ccr', {
          causer_id: model.get('id'),
          causer_type: 'deviation',
          source: 'Deviation',
          isSourceReadOnly: true
        });
        this.get('persistence').add({
          route: route,
          model: ccr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      upgradeToNCR: function upgradeToNCR() {
        var model = this.get('model');
        var route = 'ncrs.new';
        this.get('history').setRoute('deviations');
        var ncr = this.store.createRecord('ncr', {
          causer_id: model.get('id'),
          causer_type: 'deviation'
        });
        this.get('persistence').add({
          route: route,
          model: ncr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      approve: function approve(type) {
        var _this4 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this4.send('doApproval', type);

          _this4.set('approvalPromise', null);
        }, function () {
          _this4.set('approvalPromise', null);
        });
        this.set('approvalPromise', defer);
      },
      reopen: function reopen() {
        var _this5 = this;

        var model = this.get('model');
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var authHeaders = this.get('authHeaders');
        this.set('isReopening', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/deviation/' + model.get('id') + '/reopen'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          model.reload().then(function () {
            _this5.send('clearEsignature');

            fm.success(intl.t('The report has been re-opened'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to re-open the report'));
        }).always(function () {
          _this5.set('isReopening', false);
        });
      },
      doApproval: function doApproval(type) {
        var _this6 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var approvedReason = ''; // Display error message and close popup

        if (this.isMissingRequiredFields()) {
          return;
        }

        if (type === 'owner') {
          this.set('isAddingOwnerApproval', true);
          approvedReason = this.get('model.owner_approved_reason');
        } else if (type === 'inspector') {
          this.set('isAddingInspectorApproval', true);
          approvedReason = this.get('model.inspector_approved_reason');
        } else if (type === 'third') {
          this.set('isAddingThirdApproval', true);
          approvedReason = this.get('model.third_approved_reason');
        }

        var model = this.get('model');
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/deviation/' + this.get('model.id') + '/approve',
          data: {
            user_type: type,
            approved_reason: approvedReason,
            password: this.get('eSignaturePassword'),
            esignature_reason: approvedReason,
            is_action_items_complete: model.get('is_action_items_complete')
          },
          method: 'POST'
        }).then(function () {
          model.set('is_action_items_complete', model.get('is_action_items_complete'));
          model.saveWithEsignature(_this6.get('eSignaturePassword'), _this6.get('eSignatureReason')).then(function () {
            _this6.get('model').reload().then(function () {
              fm.success(intl.t('Your approval has been added to this report'));
            });
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger(intl.t('Please rectify:'));
            fm.danger(intl.t('- Your password is correct and all required departmental e-signatures are complete'));

            if (_this6.get('isDifferentApproversRequired')) {
              fm.danger(intl.t('- Owner and inspector approvers are different'));
            }
          }
        }).always(function () {
          _this6.set('isAddingOwnerApproval', false);

          _this6.set('isAddingInspectorApproval', false);

          _this6.set('isAddingThirdApproval', false);

          _this6.set('eSignaturePassword', '');
        });
      },
      removeApproval: function removeApproval(type) {
        var _this7 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var approvedReason = ''; // Display error message and close popup

        if (this.isMissingRequiredFields()) {
          return;
        }

        if (type === 'owner') {
          this.set('isAddingOwnerApproval', true);
          approvedReason = this.get('model.owner_approved_reason');
        } else if (type === 'inspector') {
          this.set('isAddingInspectorApproval', true);
          approvedReason = this.get('model.inspector_approved_reason');
        } else if (type === 'third') {
          this.set('isAddingThirdApproval', true);
          approvedReason = this.get('model.third_approved_reason');
        }

        var model = this.get('model');
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/deviation/' + this.get('model.id') + '/approve',
          data: {
            user_type: type,
            approved_reason: approvedReason,
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason'),
            is_action_items_complete: model.get('is_action_items_complete')
          },
          method: 'DELETE'
        }).then(function () {
          model.saveWithEsignature(_this7.get('eSignaturePassword'), _this7.get('eSignatureReason')).then(function () {
            _this7.get('model').reload().then(function () {
              fm.success(intl.t('Your approval has been removed for this report'));
            });
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger('You are not authorized to perform this action');
          }
        }).always(function () {
          _this7.set('isAddingOwnerApproval', false);

          _this7.set('isAddingInspectorApproval', false);

          _this7.set('isAddingThirdApproval', false);

          _this7.set('eSignaturePassword', '');
        });
      },
      reject: function reject(type) {
        var _this8 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var authToken = this.get('authManager.session.content.authenticated.access_token');
        var rejectedReason = ''; // Display missing-fields error message and close popup

        if (this.isMissingRequiredFields()) {
          return;
        }

        if (type === 'owner') {
          this.set('isRejectingByOwner', true);
          rejectedReason = this.get('model.owner_rejected_reason');
        } else if (type === 'inspector') {
          this.set('isRejectingByInspector', true);
          rejectedReason = this.get('model.inspector_rejected_reason');
        } else if (type === 'third') {
          this.set('isRejectingByThird', true);
          rejectedReason = this.get('model.third_rejected_reason');
        }

        if (Ember.isBlank(rejectedReason) || !rejectedReason) {
          this.set('isRejectingByOwner', false);
          this.set('isRejectingByInspector', false);
          this.set('isRejectingByThird', false);
          fm.danger(intl.t('A rejection reason is required in order to proceed'));
          return;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/deviation/' + this.get('model.id') + '/reject',
          data: {
            user_type: type,
            rejected_reason: rejectedReason,
            password: this.get('eSignaturePassword'),
            esignature_reason: rejectedReason
          },
          method: 'POST'
        }).then(function () {
          _this8.get('model').reload().then(function () {
            fm.success(intl.t('You have successfully rejected this report'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to reject the report, please ensure your password is correct'));
        }).always(function () {
          _this8.set('isRejectingByOwner', false);

          _this8.set('isRejectingByInspector', false);

          _this8.set('isRejectingByThird', false);

          _this8.set('eSignaturePassword', '');
        });
      },
      remove: function remove() {
        var _this9 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this9.get('model').destroyRecord().then(function () {
            _this9.transitionToRoute('deviations');
          });

          _this9.set('deletePromise', null);
        }, function () {
          _this9.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      }
    }
  });

  _exports.default = _default;
});