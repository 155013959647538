define("isolocity/pods/suppliers/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('supplier', params.supplier_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('suppliers');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});