define("isolocity/mixins/action-item-search-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      doSearchActionItems: function doSearchActionItems(filterKeyword, filterAssignedUser, filterAssignedEmployee) {
        this.set('keyword', filterKeyword);

        if (!Ember.isBlank(filterAssignedUser)) {
          this.set('assigneeId', filterAssignedUser.get('id'));
          this.set('assigneeType', 'user');
        }

        if (!Ember.isBlank(filterAssignedEmployee)) {
          this.set('assigneeId', filterAssignedEmployee.get('id'));
          this.set('assigneeType', 'employee');
        }

        this.refresh();
      },
      doClearActionItemSearch: function doClearActionItemSearch() {
        this.set('keyword', null);
        this.set('assigneeId', null);
        this.set('assigneeType', null);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});