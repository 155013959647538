define("isolocity/pods/error-code/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YH/TTlY+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"code\"],[12],[1,[30,[36,1],[\"error-code.labels.Error Code\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"value\",\"id\",\"placeholder\"],[[35,2,[\"code\"]],\"code\",[30,[36,1],[\"error-code.labels.Error Code\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[1,[30,[36,1],[\"error-code.labels.Label\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"value\",\"id\",\"placeholder\"],[[35,2,[\"name\"]],\"name\",[30,[36,1],[\"error-code.labels.Error Name\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[1,[30,[36,1],[\"error-code.labels.Status\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"status\"],[[35,2,[\"is_active\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-two-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"description\"],[12],[1,[30,[36,1],[\"error-code.labels.Description\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"value\",\"id\",\"cols\",\"rows\"],[[35,2,[\"description\"]],\"description\",\"50\",\"5\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"t\",\"model\",\"input\",\"active-inactive-toggle\",\"textarea\"]}",
    "meta": {
      "moduleName": "isolocity/pods/error-code/-form/template.hbs"
    }
  });

  _exports.default = _default;
});