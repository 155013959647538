define("isolocity/pods/action-items/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, {
    id_prefix: 'new_',
    requiredFields: ['title', 'due_date', 'assignee.id'],
    historyOverride: '',
    isLoading: false,
    intl: Ember.inject.service(),
    hasFilesUploader: Ember.computed('model.completed', 'model.isSaving', function () {
      var _isCompleted = this.get('model.completed'),
          _isSaving = this.get('model.isSaving');

      return !_isCompleted || _isSaving;
    }),
    actions: {
      create: function create() {
        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.set('model.assignee_id', this.get('model.assignee.id'));
          this.send('saveAttachments', this.get('model'), false, '', '', true);
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(actionItem, arr) {
        var _this = this;

        actionItem.fileIds = arr;
        actionItem.save().then(function (actionItem) {
          _this.set('isLoading', false);

          var fm = _this.get('flashMessages'),
              intl = _this.get('intl');

          _this.get('history').setRoute('action-items');

          setTimeout(function () {
            fm.success(intl.t('activity-log.You have successfully added the action item'));
            fm.success(intl.t('activity-log.It may take several moments to update this record Please refresh the page after few seconds'));
          }, 10);
          setTimeout(function () {
            _this.transitionToRoute('action-items');
          }, 2500);
        }, function (response) {
          _this.set('isLoading', false);

          if (response.errors === undefined) {
            fm.danger(response.message, {
              sticky: true
            });
          } else {
            _this.send('showServerErrors', response);
          }
        });
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});