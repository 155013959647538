define("isolocity/pods/planning-reports/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/planning-reports-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _planningReportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _planningReportsMixin.default, {
    intl: Ember.inject.service(),
    id_prefix: 'new_',
    isLoading: false,
    hasFilesUploader: Ember.computed('model.is_closed', 'model.isSaving', function () {
      var _isClosed = this.get('model.is_closed'),
          _isSaving = this.get('model.isSaving');

      return !_isClosed || _isSaving;
    }),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          var model = this.get('model');
          var actionItems = model.get('result_action_items');
          model.save().then(function (planning_report) {
            if (actionItems.get('length') === 0) {
              _this.send('saveAttachments', planning_report);
            } else {
              actionItems.forEach(function (action) {
                action.set('entity_id', planning_report.get('id'));

                if (action === model.get('result_action_items.lastObject')) {
                  action.save().then(function () {
                    _this.send('saveAttachments', planning_report);
                  });
                } else {
                  action.save();
                }
              });
            }
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(planning) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.get('history').setRoute('planning-reports');
        this.transitionToRoute('planning-reports.edit', planning);
        fm.success(intl.t('planning-reports.others.Report Created'));
      },
      createResultActionItem: function createResultActionItem(item) {
        item.set('link', 'planning-reports.edit');
        item.set('entity_type', 'planning');
        item.set('code', 'planning_result');
        this.get('model.result_action_items').pushObject(item);
      },
      toggleActionItemCompleted: function toggleActionItemCompleted(item) {
        item.set('completed', !item.get('completed'));
      }
    }
  });

  _exports.default = _default;
});