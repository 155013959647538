define("isolocity/pods/department/index/controller", ["exports", "isolocity/mixins/pagination-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/downloadable-reports-mixin"], function (_exports, _paginationMixin, _ajaxRequestMixin, _pdfGenerationMixin, _downloadableReportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_paginationMixin.default, _downloadableReportsMixin.default);

  _exports.default = _default;
});