define("isolocity/mixins/paginated-route-mixin", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentPage: 1,
    setPageMetadata: function setPageMetadata(model) {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('currentPage', this.get('currentPage'));

      if (!Ember.isBlank(model.meta)) {
        controller.set('totalPages', model.meta.last_page);
        controller.set('totalRecords', model.meta.total);
      }
    },
    startLoading: function startLoading() {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('isModelLoading', true);
    },
    doneLoading: function doneLoading() {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('isModelLoading', false);
    },
    getQueryFilters: function getQueryFilters() {
      var fromDate = !Ember.isEmpty(this.get('search').getFromDate()) ? this.get('search').getFromDate() : null;
      var toDate = !Ember.isEmpty(this.get('search').getToDate()) ? this.get('search').getToDate() : null;
      return {
        reload: true,
        per_page: _environment.default.APP.pagination,
        page: this.get('currentPage'),
        keyword: this.get('search').getKeyword(),
        dateRange: this.get('search').getDateRange(),
        fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
        toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : ''
      };
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.set('currentPage', 1);
        this.refresh();
      },
      loadPreviousPage: function loadPreviousPage() {
        this.set('currentPage', this.get('currentPage') - 1);
        this.refresh();
      },
      loadNextPage: function loadNextPage() {
        this.set('currentPage', this.get('currentPage') + 1);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});