define("isolocity/pods/company-groups/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    intl: Ember.inject.service(),
    requiredFields: ['name', 'is_active'],
    isEdit: true,
    remainingCompanies: Ember.computed('model.allCompanies', function () {
      var _this = this;

      var allCompanies = this.get('model.allCompanies');
      var remainingCompanies = [];
      allCompanies.forEach(function (company) {
        if (!_this.get('model').get('companies').includes(company)) {
          remainingCompanies.push(company);
        }
      });
      return remainingCompanies;
    }),
    onSelectedCompanyChange: Ember.observer('selectedCompany', function () {
      if (this.get('selectedCompany') && this.get('selectedCompany') !== false) {
        var company = this.get('selectedCompany');
        this.send('addCompanyToGroup', company);
        this.notifyPropertyChange('remainingCompanies');
      }
    }),
    actions: {
      save: function save() {
        var _this2 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          this.get('model').save().then(function (user) {
            fm.success(intl.t('company-groups.other.You have successfully saved the team group.'));

            _this2.transitionToRoute('company-groups');
          }, function (response) {
            _this2.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      removeItem: function removeItem() {
        this.notifyPropertyChange('remainingCompanies');
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      },
      addCompanyToGroup: function addCompanyToGroup(company) {
        var _this3 = this;

        var companies = this.get('model.companies');

        if (!companies.includes(company)) {
          this.get('model.companies').pushObject(company);
        }

        this.get('model').save().then(function () {
          _this3.set('selectedCompany', false);

          var fm = _this3.get('flashMessages'),
              intl = _this3.get('intl');

          fm.success(intl.t('company-groups.other.The team has been added to the group'));
        });
      }
    }
  });

  _exports.default = _default;
});