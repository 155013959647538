define("isolocity/pods/components/t-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "23MrlEeK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"t-header section-header \",[34,7]]]],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,10],[[35,9],[35,8]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,4],null,[[\"value\",\"placeholder\",\"autocomplete\"],[[35,3],[30,[36,2],[\"Enter header name\"],null],false]]]],[2,\"\\n                \"],[11,\"a\"],[24,0,\"t-label--save\"],[4,[38,1],[[32,0],\"saveLabel\"],null],[12],[2,\"\\n                    \"],[10,\"i\"],[14,0,\"fa fa-check green\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[34,0]],[2,\"\\n                \"],[11,\"a\"],[24,0,\"t-label--edit\"],[4,[38,1],[[32,0],\"editLabel\"],null],[12],[2,\"\\n                    \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"translatedText\",\"action\",\"t\",\"newLabelText\",\"input\",\"isEditing\",\"if\",\"className\",\"allowEdit\",\"isAdmin\",\"and\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/t-header/template.hbs"
    }
  });

  _exports.default = _default;
});