define("isolocity/pods/faq/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mi1mnqKy",
    "block": "{\"symbols\":[\"faq\"],\"statements\":[[10,\"div\"],[14,0,\"content\"],[14,\"role\",\"primary\"],[12],[2,\"\\n\\n  \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"index.faq.Frequently Asked Questions\"],null]],[13],[2,\"\\n\\n    \"],[1,[34,6]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\\n  \"],[10,\"div\"],[14,0,\"content-block no-footer\"],[12],[2,\"\\n\"],[6,[37,7],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"single-faq\"],[12],[2,\"\\n          \"],[10,\"p\"],[12],[10,\"strong\"],[12],[1,[32,1,[\"question\"]]],[13],[13],[2,\"\\n          \"],[10,\"p\"],[12],[1,[30,[36,2],[[32,1,[\"answer\"]]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"index.faq.It looks as though no questions have been added.\"],null]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"index.faq.Curious about something?\"],null]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[6,[37,1],null,[[\"route\"],[\"support\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"index.faq.Contact Support\"],null]]],\"parameters\":[]}]]],[2,\".\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"breaklines\",\"model\",\"-track-array\",\"each\",\"user-options\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/faq/template.hbs"
    }
  });

  _exports.default = _default;
});