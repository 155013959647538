define("isolocity/pods/components/settings-sidebar/audit-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6+8oghUq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[1,[30,[36,0],[\"Require e-signature for completing reports\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,1,[\"settings\",\"is_esignature_required_for_completion\"]],false,\"No\",\"Yes\",[30,[36,3],[[30,[36,2],[[35,1,[\"settings\",\"is_esignature_required_for_completion\"]]],null]],null],false,\"pull-left\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"module\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/settings-sidebar/audit-reports/template.hbs"
    }
  });

  _exports.default = _default;
});