define("isolocity/pods/components/inspections-table/batch/component", ["exports", "isolocity/config/environment", "isolocity/mixins/user-organization-mixin"], function (_exports, _environment, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    config: _environment.default,
    store: Ember.inject.service(),
    part: null,
    sample: null,
    readOnly: false,
    tagName: 'div',
    classNameBindings: [':batch'],
    isBatchSectionVisible: false,
    isBatchVerificationChecks: false,

    get materialParts() {
      var materialParts = [];
      this.get('part.materials').forEach(function (material) {
        materialParts.push(material.get('material_part_id'));
      });
      return materialParts;
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      addBatchRecord: function addBatchRecord() {
        var entity_type = this.get('sample.inspection_id.entity_type');
        var batchRecord = this.get('store').createRecord('inspection.sample.batch-record', {
          inspection_sample_id: this.get('sample'),
          entity_id: this.get('sample.inspection_id.entity_id'),
          entity_type: entity_type,
          part_id: this.get('part'),
          batch_number: '',
          quantity: 0
        }); // Calculate and set expiry date if applicable

        var partId = batchRecord.get('part_id');
        var shelfLifeDuration = partId.get('shelf_life_duration');
        var shelfLifeFrequency = partId.get('shelf_life_frequency');

        if (entity_type == 'part' && Ember.isEmpty(batchRecord.get('expiry_date')) && !Ember.isEmpty(shelfLifeDuration) && !Ember.isEmpty(shelfLifeFrequency)) {
          var expiry_date = moment().add(shelfLifeFrequency, shelfLifeDuration.toLowerCase());
          batchRecord.set('expiry_date', expiry_date);
        } // Create record


        batchRecord.save();
      },
      removeBatchRecord: function removeBatchRecord(record) {
        record.set('isDeleting', true);
        record.destroyRecord();
      },
      toggleBatchSectionVisible: function toggleBatchSectionVisible() {
        this.set('isBatchSectionVisible', !this.get('isBatchSectionVisible'));
      },
      handleSelectedBatchRecordPart: function handleSelectedBatchRecordPart(record) {
        if (Ember.isEmpty(record) || record.get('isDeleted') || record.get('isDeleting')) {
          return;
        }

        var partId = record.get('part_id');
        var expiry_date = null;

        if (record.get('entity_type') == 'part' && !Ember.isEmpty(partId.get('shelf_life_duration')) && !Ember.isEmpty(partId.get('shelf_life_frequency'))) {
          var frequency = partId.get('shelf_life_frequency'),
              duration = partId.get('shelf_life_duration');
          expiry_date = moment().add(frequency, duration.toLowerCase());
        }

        Ember.set(record, 'expiry_date', expiry_date);
        var uom = null;
        this.get('part.materials').forEach(function (material) {
          if (material.get('material_part_id.id') === record.get('part_id.id')) {
            record.set('entity_id', material.get('material_part_id.id'));
            uom = material.get('unit_of_measure');
          }
        });

        if (Ember.isEmpty(uom)) {
          uom = 'units';
        }

        record.set('unit_of_measure', uom);
      },
      toggleNewBatchNumber: function toggleNewBatchNumber(record) {
        var isNewBatchNumber = record.get('isNewBatchNumber');
        record.set('isNewBatchNumber', !isNewBatchNumber);
      }
    }
  });

  _exports.default = _default;
});