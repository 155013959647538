define("isolocity/pods/components/document-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hz6Kt5YK",
    "block": "{\"symbols\":[\"link\"],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\"],[6,[37,0],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h3\"],[12],[1,[30,[36,1],[\"External Links\"],null]],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"form-row document-links no-margins\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"col-three-quarter\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"link-wrap\"],[12],[1,[32,1,[\"url\"]]],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[10,\"a\"],[14,0,\"link-visit\"],[15,6,[31,[[32,1,[\"url\"]]]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n                            \"],[10,\"i\"],[14,0,\"fa fa-external-link\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n                        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"links\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/document-links/template.hbs"
    }
  });

  _exports.default = _default;
});