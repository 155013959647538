define("isolocity/pods/equipment/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/equipment-users-mixin", "isolocity/mixins/upload-files-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _equipmentUsersMixin, _uploadFilesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _equipmentUsersMixin.default, {
    id_prefix: 'new_',
    requiredFields: ['name', 'equipment_type_id.id'],
    historyOverride: '',
    isLoading: false,
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('equipment-manager');
    },

    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function (equipment) {
            _this.send('saveAttachments', equipment);
          }, function (response) {
            _this.set('isLoading', false);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(equipment) {
        var _this2 = this;

        var fm = this.get('flashMessages');
        this.set('isLoading', false);
        this.get('model').save().then(function () {
          fm.success("Your changes were successfully saved.");

          _this2.get('history').setRoute('equipment');

          _this2.transitionToRoute('equipment.edit', equipment);
        });
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});