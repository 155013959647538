define("isolocity/pods/department/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/departments-mixin", "isolocity/mixins/ajax-request-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _departmentsMixin, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _departmentsMixin.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    isEdit: true,
    requiredFields: ['name'],
    selectedUser: null,

    get allUsersExceptSigners() {
      var _this = this;

      return this.get('model.allUsers').filter(function (user) {
        var isSigner = _this.get('signers').filter(function (signer) {
          return signer.id == user.get('id');
        }).length > 0;
        return !isSigner;
      });
    },

    get signers() {
      var signers = [];
      this.get("model.signers").forEach(function (signer) {
        var item = signer.get('signer');
        item.signer_id = signer.get('id');
        signers.push(item);
      });
      return signers;
    },

    get signerIds() {
      var ids = [];
      this.get("signers").forEach(function (signer) {
        ids.push(signer.id);
      });
      return ids;
    },

    actions: {
      save: function save() {
        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.hasBlankResponsibilities()) {
          fm.danger(intl.t('departments.other.Please fill in the empty responsibility fields'));
          return;
        }

        if (this.get('isValid')) {
          this.get('model').save().then(function () {
            fm.success(intl.t('departments.other.You have successfully saved the department'));
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {},
      showAddSignaturePopup: function showAddSignaturePopup() {
        var _this2 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function (data) {
          _this2.set('addAuthorizedSignerPromise', null);

          $.ajax({
            headers: _this2.get('authHeaders'),
            url: _this2.getApiUrl("/departments/".concat(_this2.get('model.id'), "/signers")),
            data: {
              signer_id: _this2.get('selectedUser.id'),
              signer_type: 'user'
            },
            method: 'POST'
          }).then(function (data) {
            _this2.get('model').reload();

            _this2.send('clearAuthorizedSigner');
          });
        }, function () {
          _this2.set('addAuthorizedSignerPromise', null);

          _this2.send('clearAuthorizedSigner');
        });
        this.set('addAuthorizedSignerPromise', defer);
      },
      clearAuthorizedSigner: function clearAuthorizedSigner() {
        this.set('selectedDepartment', null);
        this.set('selectedUser', null);
      },
      removeAuthorizedSigner: function removeAuthorizedSigner(item) {
        var _this3 = this;

        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl("/departments/".concat(this.get('model.id'), "/signers/").concat(item.signer_id)),
          method: 'DELETE'
        }).then(function (data) {
          _this3.get('model').reload();
        });
      }
    }
  });

  _exports.default = _default;
});