define("isolocity/mixins/downloadable-reports-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    isDownloadingReport: false,
    authManager: Ember.inject.service('session'),
    reportDateRange: Ember.computed('startDate', 'endDate', function () {
      return {
        start_date: this.get('startDate') ? moment(this.get('startDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('endDate') ? moment(this.get('endDate')).format("YYYY-MM-DD") : null
      };
    }),
    actions: {
      generateDownloadableReport: function generateDownloadableReport(apiEndpoint) {
        var _this = this;

        var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'report';
        var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var isClearDateRange = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

        if (isClearDateRange) {
          this.set('startDate', null);
          this.set('endDate', null);
        }

        this.set('isDownloadingReport', true);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl(apiEndpoint),
          data: payload,
          method: 'GET'
        }).then(function (data) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var blob = new Blob(["\uFEFF" + data], {
            type: 'text/csv; charset=utf-8'
          }),
              // added the BOM charecter to avoid utf-8 encoding issue with excel
          url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
        }).fail(function (error) {
          var error_string = intl.t('mixins.downloadable-reports.Error on Download');

          if (error && error.responseJSON && error.responseJSON.errors) {
            error_string = error.responseJSON.errors;
          }

          fm.danger(error_string);
        }).always(function () {
          _this.set('isDownloadingReport', false);
        });
      },
      generateQueuableReport: function generateQueuableReport(apiEndpoint) {
        var _this2 = this;

        var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        this.set('isDownloadingReport', true);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl(apiEndpoint),
          data: payload,
          method: 'GET'
        }).then(function () {
          fm.success(intl.t('mixins.downloadable-reports.Report Inprocess'));
        }).fail(function () {
          fm.danger(intl.t('mixins.downloadable-reports.Error on Process'));
        }).always(function () {
          _this2.set('isDownloadingReport', false);
        });
      }
    }
  });

  _exports.default = _default;
});