define("isolocity/pods/components/sidebar-xtoggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    model: null,
    class: 'pull-left',
    settingPath: null,
    globalSetting: null,
    settingData: Ember.computed('model', 'model.custom_form', function () {
      return this.get('model') && this.get('model.custom_form') ? this.get('model.custom_form') : null;
    }),
    categoryKey: Ember.computed('settingPath', function () {
      if (this.get('settingPath')) {
        var strings = this.get('settingPath').split(".");
        var key = 'fields';

        for (var index = 0; index < strings.length; ++index) {
          var item = strings[index];
          key = (item == 'fields' || item == 'sections') && index != strings.length - 1 ? item : key;
        }

        return key;
      }

      return 'fields';
    }),
    settingKey: Ember.computed('settingPath', function () {
      if (this.get('settingPath')) {
        var strings = this.get('settingPath').split(".");
        var key = null;

        if (strings[strings.length - 1] != 'fields' && strings[strings.length - 1] != 'sections') {
          key = strings[strings.length - 1];
        }

        return key;
      }

      return null;
    }),
    isVisible: Ember.computed('settingData', 'globalSetting', function () {
      var isVisible = this.get('globalSetting');

      if (this.get('settingData')) {
        var settingDataObject = JSON.parse(this.get('settingData'));
        var categoryKey = this.get('categoryKey');
        var key = this.get('settingKey');

        if (settingDataObject.hasOwnProperty('settings') && settingDataObject['settings'].hasOwnProperty(categoryKey) && key) {
          isVisible = settingDataObject['settings'][categoryKey].hasOwnProperty(key) ? settingDataObject['settings'][categoryKey][key] : isVisible;
        }
      }

      return isVisible ? 1 : 0;
    }),
    actions: {
      toggleVisibleSetting: function toggleVisibleSetting() {
        this.send('saveSettingForSingleModelItem');
      },
      saveSettingForSingleModelItem: function saveSettingForSingleModelItem() {
        var categoryKey = this.get('categoryKey');
        var model = this.get('model');
        var settingDataObject = JSON.parse(this.get('settingData')); // Initiate data if is empty. E.g: When creating report

        if (Ember.isBlank(settingDataObject)) {
          settingDataObject = JSON.parse('{ "settings": { "fields":{}, "sections":{} } }');
        }

        if (!settingDataObject.hasOwnProperty('settings')) {
          settingDataObject['settings'] = '{}';
        }

        var newSetting = settingDataObject['settings'].hasOwnProperty(categoryKey) && settingDataObject['settings'][categoryKey][this.get('settingKey')] ? false : true;

        if (!settingDataObject['settings'].hasOwnProperty(categoryKey)) {
          if (!Array.isArray(settingDataObject['settings'])) {
            settingDataObject['settings'] = JSON.parse('{ "' + categoryKey + '": { "' + this.get('settingKey') + '":"' + newSetting + '" }}');
            settingDataObject['settings'][categoryKey][this.get('settingKey')] = newSetting;
          } else {
            settingDataObject['settings'][categoryKey] = JSON.parse('{ "' + this.get('settingKey') + '":"' + newSetting + '" }');
            settingDataObject['settings'][categoryKey][this.get('settingKey')] = newSetting;
          }
        } else {
          settingDataObject['settings'][categoryKey][this.get('settingKey')] = newSetting;
        }

        this.set('isVisible', newSetting ? 1 : 0);
        this.set('globalSetting', newSetting);
        model.set('custom_form', JSON.stringify(settingDataObject));
      }
    }
  });

  _exports.default = _default;
});