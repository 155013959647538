define("isolocity/mixins/required-fields-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    requiredFields: [],
    requiredList: [],
    init: function init() {
      for (var i = 0, len = this.requiredFields.length; i < len; i++) {
        Ember.addObserver(this, 'model.' + this.requiredFields[i], this, function () {
          this.notifyPropertyChange('requiredFields');
        });
      }

      this._super.apply(this, arguments);
    },
    willDestroy: function willDestroy() {
      //CLEANUP - necessary? - not sure if called
      for (var i = 0, len = this.requiredFields.length; i < len; i++) {
        Ember.removeObserver(this, 'model.' + this.requiredFields[i], function () {
          this.notifyPropertyChange('requiredFields');
        });
      }

      this._super.apply(this, arguments);
    },
    hasRequiredFields: Ember.computed('requiredFields', function () {
      var model = this.get('model');
      var requiredFields = this.get('requiredFields');

      if (!Ember.isEmpty(this.get('relatedRequiredFields'))) {
        var related = this.get('relatedRequiredFields');
        requiredFields = requiredFields.filter(function (item) {
          return related.indexOf(item) === -1;
        });
      }

      var messages = [];

      for (var i = 0, len = requiredFields.length; i < len; i++) {
        var field = model.get(requiredFields[i]);

        if (Ember.isBlank(field) || moment.isMoment(field) && !field.isValid()) {
          var pcaseField = requiredFields[i].replace('_id', '');
          pcaseField = pcaseField.replace(/_/g, ' ').replace('.', ' ').replace(/\w+/g, function (a) {
            return a.charAt(0).toUpperCase() + a.slice(1).toLowerCase();
          }); // Use default translation if available (This is often given if the field key name is different from the fiel translation name)

          var defaultTranslation = !Ember.isBlank(this.get('defaultRequiredFieldsTranslation')) ? this.get('defaultRequiredFieldsTranslation')[requiredFields[i]] : null;

          if (defaultTranslation) {
            pcaseField = this.get('intl').t(this.get('moduleSlug') + '.labels.' + defaultTranslation);
          } // Otherwise use default auto generated name from the key
          else {
              pcaseField = pcaseField.replace(' Id', '');

              if (this.get('moduleSlug')) {
                pcaseField = this.get('intl').t(this.get('moduleSlug') + '.required.' + pcaseField);
              }
            }

          messages.push(pcaseField);
        }
      }

      if (messages.length > 0) return messages;
      return true;
    }),
    hasRequiredLists: Ember.computed('requiredList', function () {
      var model = this.get('model');

      for (var i = 0, len = this.requiredList.length; i < len; i++) {
        if (Ember.isEmpty(model.get(this.requiredList[i])) || model.get(this.requiredList[i]).get('length') === 0) return false;
      }

      return true;
    }),
    isValid: Ember.computed('hasRequiredFields', function () {
      return this.get('hasRequiredFields') === true;
    }),
    onRequiredFieldChange: Ember.observer('hasRequiredFields', function () {
      this.get('flashMessages').clearMessages();
    }),
    showErrorsList: function showErrorsList(errors) {
      var sticky = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var fm = this.get('flashMessages');

      for (var i = 0, len = errors.length; i < len; i++) {
        if (!Ember.isEmpty(errors[i].message)) {
          fm.danger(errors[i].message, {
            sticky: sticky
          });
        } else {
          fm.danger(errors[i], {
            sticky: sticky
          });
        }
      }
    },
    actions: {
      clearFlashMessages: function clearFlashMessages() {
        this.get('flashMessages').clearMessages();
      },
      showErrors: function showErrors() {
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var required = this.get('hasRequiredFields');

        if (required !== true) {
          fm.danger("".concat(intl.t('mixins.required-fields.Required Fields')), {
            sticky: true
          });

          for (var i = 0, len = required.length; i < len; i++) {
            fm.danger(required[i], {
              sticky: true
            });
          }
        }
      },
      showServerErrors: function showServerErrors(response) {
        var sticky = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var fm = this.get('flashMessages');

        if (response.errors === undefined && !Ember.isEmpty(response.message)) {
          fm.danger(response.message, {
            sticky: sticky
          });
        } else if (response.responseJSON && !Ember.isEmpty(response.responseJSON.message)) {
          fm.danger(response.responseJSON.message, {
            sticky: sticky
          });
        } else {
          return this.showErrorsList(response.errors, sticky);
        }
      }
    }
  });

  _exports.default = _default;
});