define("isolocity/pods/audit-report/serializer", ["exports", "ember-data", "isolocity/mixins/custom-serializer-mixin"], function (_exports, _emberData, _customSerializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _customSerializerMixin.default, {
    attrs: {
      action_items: {
        serialize: false,
        deserialize: 'records'
      },
      user_notes: {
        serialize: false,
        deserialize: 'records'
      },
      deviation: {
        serialize: false,
        deserialize: 'records'
      },
      car: {
        serialize: false,
        deserialize: 'records'
      },
      document_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      document: {
        serialize: false,
        deserialize: 'records'
      },
      files: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});