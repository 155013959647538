define("isolocity/pods/supplier/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    primary_name: _emberData.default.attr('string'),
    primary_phone: _emberData.default.attr('string'),
    primary_email: _emberData.default.attr('string'),
    primary_position: _emberData.default.attr('string'),
    secondary_name: _emberData.default.attr('string'),
    secondary_phone: _emberData.default.attr('string'),
    secondary_email: _emberData.default.attr('string'),
    secondary_position: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    is_certificate_required: _emberData.default.attr('boolean'),
    is_certificate_received: _emberData.default.attr('boolean'),
    quality_department: _emberData.default.attr('string'),
    quality_policy: _emberData.default.attr('string'),
    quality_procedures: _emberData.default.attr('string'),
    is_critical: _emberData.default.attr('boolean'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    is_owned_by_current_user: _emberData.default.attr('boolean'),
    //relationships (hasMany)
    files: _emberData.default.attr(),
    certificates: _emberData.default.hasMany('supplier.certificate'),
    all_action_items: _emberData.default.hasMany('action-item'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    //dynamic attributes
    total_cars_against_supplier: _emberData.default.attr('string'),
    on_time_delivered_percentage: _emberData.default.attr('string'),
    overall_defect_rate: _emberData.default.attr('string')
  });

  _exports.default = _default;
});