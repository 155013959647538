define("isolocity/pods/training-schedule-employee/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    training_schedule_id: _emberData.default.belongsTo('training-schedule', {
      inverse: 'enrollments'
    }),
    employee_id: _emberData.default.belongsTo('employee', {
      inverse: 'enrollments'
    }),
    is_completed: _emberData.default.attr('boolean', {
      default: false
    }),
    is_checklist: _emberData.default.attr('boolean'),
    completion_date: _emberData.default.attr('utc'),
    result: _emberData.default.attr('string'),
    // Computed by server
    training_schedule_title: _emberData.default.attr('string'),
    training_schedule_type: _emberData.default.attr('string'),
    training_schedule_due_date: _emberData.default.attr('string'),
    training_schedule_next_training_date: _emberData.default.attr('string'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email_address: _emberData.default.attr('string'),
    document_links: _emberData.default.attr(),
    document_files: _emberData.default.attr(),
    training_program_files: _emberData.default.attr(),
    training_schedule_files: _emberData.default.attr(),
    training_schedule: _emberData.default.attr(),
    // Generated in Front End
    completed_by_manager: _emberData.default.attr('boolean'),
    group_name: Ember.computed('training_schedule_id.group_id.name', function () {
      return this.get('training_schedule_id.group_id.name');
    })
  });

  _exports.default = _default;
});