define("isolocity/mixins/test-question-edit-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    testModel: 'model',
    actions: {
      insertQuestion: function insertQuestion(question) {
        var _this2 = this;

        var model = this.get(this.get('testModel'));
        question.set('number', model.get('questions.length') + 1);
        question.set('test_id', this.get(this.get('testModel')));
        question.save().then(function (saved) {
          saved.set('alwaysEdittable', false);

          _this2.set(_this2.get('testModel') + '.emptyQuestionRow', _this2.store.createRecord('test.question', {
            is_active: true,
            alwaysEdittable: true
          }));
        });
      },
      saveQuestion: function saveQuestion(question) {
        var _this3 = this;

        question.save().then(function (saved) {
          saved.setProperties({
            'edittable': false
          });

          _this3.notifyPropertyChange(_this3.get('testModel') + '.questions');
        }, function (error) {
          question.setProperties({
            'edittable': false
          });

          _this3.notifyPropertyChange(_this3.get('testModel') + '.questions');
        });
      },
      deleteQuestion: function deleteQuestion(question) {
        if (Ember.isNone(question.get('number'))) {
          this.set(this.get('testModel') + '.emptyQuestionRow', this.store.createRecord('test.question', {
            is_active: true,
            alwaysEdittable: true
          }));
          return;
        }

        var _this = this;

        question.destroyRecord().then(function () {
          _this.set('sortedQuestions', _this.get('model.questions'));

          _this.notifyPropertyChange(_this.get('testModel') + '.questions');
        });
      }
    }
  });

  _exports.default = _default;
});