define("isolocity/pods/documents/revision/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    intl: Ember.inject.service(),
    isBackgroundReload: false,
    permissions: Ember.inject.service('user-permissions'),
    model: function model(params) {
      return this.store.query('document', {
        document_id: params.document_id,
        document_revision_id: params.document_revision_id
      }).then(function (documents) {
        return documents.get('firstObject');
      });
    },
    actions: {
      error: function error(_error, transition) {
        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (_error) {
          this.transitionTo('documents').then(function () {
            fm.danger(intl.t('documents.other.You do not currently have permission to access this document'));
          });
        }
      },
      reloadData: function reloadData() {
        this.set('isBackgroundReload', true);
        this.refresh();
      },
      disableBackgroundReload: function disableBackgroundReload() {
        this.set('isBackgroundReload', false);
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('allGroups', this.store.findAll('group'));
      model.set('departments', this.store.findAll('department'));

      if (this.get('permissions').hasRoleAtLeast('user')) {
        model.set('ccrs', this.store.findAll('ccr'));
        model.set('allTrainingPrograms', this.store.query('training-program', {
          filter_by: 'Active'
        }));
      }

      ;
      model.set('documentTypes', this.store.findAll('document-type'));
      model.set('emptyAction', this.store.createRecord('action-item'));
      model.set('allApprovalMatrices', this.store.findAll('approval-matrix'));

      if (!Ember.isBlank(model.get('document_type_id.id')) && Ember.isBlank(model.get('document_type_id.name'))) {
        this.store.findRecord('document-type', model.get('document_type_id.id'));
      }

      if (!Ember.isBlank(model.get('department_id.id')) && Ember.isBlank(model.get('department_id.name'))) {
        this.store.findRecord('department', model.get('department_id.id'));
      }
    }
  });

  _exports.default = _default;
});