define("isolocity/pods/components/car-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SrWdM9ee",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,10],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \\t\"],[10,\"a\"],[14,0,\"link\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"alert alert-car text-center\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[34,3]],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"space--left-1\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"Initiate a\"],null]],[2,\" \"],[11,\"a\"],[24,0,\"link\"],[4,[38,1],[[32,0],\"onClick\"],null],[12],[1,[30,[36,0],[\"Corrective Action Report\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"Initiating\"],null]],[2,\"...\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"or a\"],null]],[2,\" \"],[11,\"a\"],[24,0,\"link\"],[4,[38,1],[[32,0],\"createNcr\"],null],[12],[1,[30,[36,2],[[30,[36,0],[\"modules.singular.ncrs\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"or a\"],null]],[2,\" \"],[11,\"a\"],[24,0,\"link\"],[4,[38,1],[[32,0],\"createDeviation\"],null],[12],[1,[30,[36,2],[[30,[36,0],[\"modules.singular.deviations\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"or an\"],null]],[2,\" \"],[11,\"a\"],[24,0,\"link\"],[4,[38,1],[[32,0],\"createOosform\"],null],[12],[1,[30,[36,0],[\"OOS Repor\"],null]],[2,\"t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"module-text\",\"message\",\"isCarCreating\",\"unless\",\"allowCreateNcr\",\"if\",\"allowCreateDeviation\",\"allowCreateOosform\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/car-message/template.hbs"
    }
  });

  _exports.default = _default;
});