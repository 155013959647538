define("isolocity/pods/components/inspections-table/result/acceptable-unacceptable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zjf9pV/G",
    "block": "{\"symbols\":[],\"statements\":[[10,\"fieldset\"],[15,0,[30,[36,2],[\"checkboxes\",[30,[36,1],[[35,0],\" checkboxes--readonly\"],null]],null]],[14,\"tabindex\",\"0\"],[12],[2,\"\\n    \"],[11,\"label\"],[24,0,\"active\"],[24,\"for\",\"is_active\"],[4,[38,3],[[32,0],\"toggle\",true],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"tabindex\",\"value\",\"groupValue\"],[0,true,[35,4,[\"acceptable_unacceptable\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,6],[\"Acceptable\"],null]],[13],[2,\"\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"label\"],[14,0,\"sep\"],[12],[2,\"/\"],[13],[2,\"\\n    \"],[11,\"label\"],[24,0,\"inactive\"],[24,\"for\",\"inactive\"],[4,[38,3],[[32,0],\"toggle\",false],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"tabindex\",\"value\",\"groupValue\"],[0,false,[35,4,[\"acceptable_unacceptable\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,6],[\"Unacceptable\"],null]],[13],[2,\"\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"readOnly\",\"iff\",\"concat\",\"action\",\"result\",\"radio-button\",\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/inspections-table/result/acceptable-unacceptable/template.hbs"
    }
  });

  _exports.default = _default;
});