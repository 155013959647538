define("isolocity/services/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    getModuleText: function getModuleText(fallback) {
      var strCase = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'upper';
      return strCase === 'lower' ? fallback.toLowerCase() : fallback;
    }
  });

  _exports.default = _default;
});