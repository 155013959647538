define("isolocity/pods/drawing/characteristic/serializer", ["exports", "ember-data", "isolocity/mixins/custom-serializer-mixin"], function (_exports, _emberData, _customSerializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_customSerializerMixin.default, {
    attrs: {
      drawing_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      drawing_category_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      radio_group_choices: {
        serialize: 'ids',
        deserialize: 'records'
      }
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      if (!Ember.isBlank(resourceHash.dimensions) && resourceHash.dimensions !== 'object') {
        try {
          var dimensions = JSON.parse(resourceHash.dimensions);
          resourceHash.measurement = dimensions.measurement;
          resourceHash.unit = dimensions.unit;
        } catch (e) {//error parsing json
        }
      }

      if (!Ember.isBlank(resourceHash.tolerance) && resourceHash.tolerance !== 'object') {
        try {
          var tolerance = JSON.parse(resourceHash.tolerance);

          if (!Ember.isBlank(tolerance.positive)) {
            resourceHash.positive = tolerance.positive;
          }

          if (!Ember.isBlank(tolerance.negative)) {
            resourceHash.negative = tolerance.negative;
          }

          if (!Ember.isBlank(tolerance.passFail)) {
            resourceHash.pass_or_fail = tolerance.passFail;
          }

          if (!Ember.isBlank(tolerance.acceptableUnacceptable)) {
            resourceHash.acceptable_or_unacceptable = tolerance.acceptableUnacceptable;
          }

          if (!Ember.isBlank(tolerance.yesNo)) {
            resourceHash.yes_or_no = tolerance.yesNo;
          }

          if (!Ember.isBlank(tolerance.isTextInput)) {
            resourceHash.is_text_input = tolerance.isTextInput;
          }

          if (!Ember.isBlank(tolerance.isTextInputOptional)) {
            resourceHash.is_text_input_optional = tolerance.isTextInputOptional;
          }

          if (!Ember.isBlank(tolerance.radioGroup)) {
            resourceHash.radio_group = tolerance.radioGroup;
          }

          if (!Ember.isBlank(tolerance.radioGroupSelected)) {
            resourceHash.radio_group_selected = tolerance.radioGroupSelected;
          }

          if (!Ember.isBlank(tolerance.enforce_multiple_selection)) {
            resourceHash.enforce_multiple_selection = tolerance.enforce_multiple_selection;
          }
        } catch (e) {//error parsing json
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});