define("isolocity/pods/components/graphs/documents/documents-list-by-stage/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    stage1: null,
    stage2: null,
    stage3: null,
    stage4: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.send('loadChart', this.get('graphDataUrl'));
    },
    graphDataUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/documents/list-by-stage/graph';
    }),
    setChartData: function setChartData(data) {
      this.set('stage1', data.stage1);
      this.set('stage2', data.stage2);
      this.set('stage3', data.stage3);
      this.set('stage4', data.stage4);
    },
    actions: {
      loadChart: function loadChart(data) {
        var _this = this;

        var reportData = {
          company_ids: this.get('reportingService').get('companies')
        };
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('graphDataUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            _this.setChartData(data);
          }
        });
      }
    }
  });

  _exports.default = _default;
});