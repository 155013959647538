define("isolocity/pods/inventory/location/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventory_id: _emberData.default.belongsTo('inventory'),
    location_id: _emberData.default.belongsTo('location'),
    user_id: _emberData.default.belongsTo('user'),
    is_current: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('utc')
  });

  _exports.default = _default;
});