define("isolocity/pods/components/settings-sidebar/deviations/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    module: null,
    actions: {
      togglePartAInitiator: function togglePartAInitiator() {
        this.set('module.settings.sections.part_a_initiator', !this.get('module.settings.sections.part_a_initiator'));
        var part_a_initiator = this.get('module.settings.sections.part_a_initiator');
        this.set('module.settings.fields.required.description', part_a_initiator);
        this.set('module.settings.fields.required.risk_description', part_a_initiator);
        this.set('module.settings.fields.required.investigation_summary', part_a_initiator);
      },
      togglePartBAssessment: function togglePartBAssessment() {
        this.set('module.settings.sections.part_b_assessment', !this.get('module.settings.sections.part_b_assessment'));
        var part_b_assessment = this.get('module.settings.sections.part_b_assessment');
        this.set('module.settings.fields.required.control_measures_description', part_b_assessment);
        this.set('module.settings.fields.required.impacted_areas', part_b_assessment);
        this.set('module.settings.fields.required.qa_impact_assessment', part_b_assessment);
        this.set('module.settings.fields.required.conclusion_recommendation', part_b_assessment);
        this.set('module.settings.fields.required.corrective_actions', part_b_assessment);
        this.set('module.settings.fields.required.preventative_actions', part_b_assessment);
      },
      togglePartCApproval: function togglePartCApproval() {
        this.set('module.settings.sections.part_c_approval', !this.get('module.settings.sections.part_c_approval'));
        var part_c_assessment = this.get('module.settings.sections.part_c_approval');
        this.set('module.settings.fields.required.disposition', part_c_assessment);
        this.set('module.settings.fields.required.additional_comments', part_c_assessment);
      },
      toggleReasonCode: function toggleReasonCode() {
        this.set('module.settings.is_reason_required_on_save', !this.get('module.settings.is_reason_required_on_save'));
        var reasonCodeSetting = this.get('module.settings.is_reason_required_on_save');
        this.set('module.settings.is_esignature_required_on_save', reasonCodeSetting);
      },
      toggleEsignature: function toggleEsignature() {
        this.set('module.settings.is_esignature_required_on_save', !this.get('module.settings.is_esignature_required_on_save'));
        var eSignatureSetting = this.get('module.settings.is_esignature_required_on_save');
        this.set('module.settings.is_reason_required_on_save', eSignatureSetting);
      }
    }
  });

  _exports.default = _default;
});