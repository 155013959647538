define("isolocity/pods/components/settings-sidebar/corrective-actions/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    module: null,
    actions: {
      toggleDescriptionSection: function toggleDescriptionSection() {
        this.set('module.settings.sections.description', !this.get('module.settings.sections.description'));
        var isEnabled = this.get('module.settings.sections.description');
        this.set('module.settings.fields.visible.description', isEnabled);
        this.set('module.settings.fields.visible.immediate_action', isEnabled);
      },
      toggleRootCauseSection: function toggleRootCauseSection() {
        this.set('module.settings.sections.root_cause_analysis', !this.get('module.settings.sections.root_cause_analysis'));
        var isEnabled = this.get('module.settings.sections.root_cause_analysis');
        this.set('module.settings.fields.visible.root_cause_description', isEnabled);
        this.set('module.settings.fields.visible.approval_completed_description', isEnabled);
      },
      togglePreventativeActionsSection: function togglePreventativeActionsSection() {
        this.set('module.settings.sections.preventative_actions', !this.get('module.settings.sections.preventative_actions'));
        var isEnabled = this.get('module.settings.sections.preventative_actions');
        this.set('module.settings.fields.visible.preventative_action_description', isEnabled);
      },
      toggleVerificationActionSection: function toggleVerificationActionSection() {
        this.set('module.settings.sections.verification_action', !this.get('module.settings.sections.verification_action'));
        var isEnabled = this.get('module.settings.sections.verification_action');
        this.set('module.settings.fields.visible.verification_completed_description', isEnabled);
      },
      toggleProcessesAffectedSection: function toggleProcessesAffectedSection() {
        this.set('module.settings.sections.processes_affected', !this.get('module.settings.sections.processes_affected'));
        var isEnabled = this.get('module.settings.sections.processes_affected');
        this.set('module.settings.fields.visible.process_effect', isEnabled);
      },
      toggleReasonCode: function toggleReasonCode() {
        this.set('module.settings.is_reason_required_on_save', !this.get('module.settings.is_reason_required_on_save'));
        var reasonCodeSetting = this.get('module.settings.is_reason_required_on_save');
        this.set('module.settings.is_esignature_required_on_save', reasonCodeSetting);
      },
      toggleEsignature: function toggleEsignature() {
        this.set('module.settings.is_esignature_required_on_save', !this.get('module.settings.is_esignature_required_on_save'));
        var eSignatureSetting = this.get('module.settings.is_esignature_required_on_save');
        this.set('module.settings.is_reason_required_on_save', eSignatureSetting);
      },
      toggleOwnerAndInspectorApproval: function toggleOwnerAndInspectorApproval() {
        this.set('module.settings.sections.owner_and_quality_inspector_approval', !this.get('module.settings.sections.owner_and_quality_inspector_approval'));
      }
    }
  });

  _exports.default = _default;
});