define("isolocity/pods/orders/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return this.store.createRecord('order', {
        is_closed: false
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('drawings', this.store.findAll('drawing'));
      model.set('customers', this.store.query('customer', {
        is_active: true,
        list_type: 'for_autocompletion'
      }));
      model.set('parts', this.store.findAll('part')); //create new 'order-item' record for new row entry

      if (Ember.isEmpty(model.get('emptyRow'))) {
        model.set('emptyRow', this.store.createRecord('order.item', {
          alwaysEdittable: true
        }));
      }
    }
  });

  _exports.default = _default;
});