define("isolocity/pods/part-type/route", ["exports", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, {
    modelName: 'part-type',
    model: function model() {
      return this.store.findAll('part-type', {
        reload: true
      });
    }
  });

  _exports.default = _default;
});