define("isolocity/pods/components/cost-settings-sidebar/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    fixedCosts: [],
    actions: {
      addFixedCost: function addFixedCost() {
        var fixedCosts = this.get('fixedCosts');
        fixedCosts.pushObject({
          name: '',
          amount: 0,
          new: 1
        });
        this.set('fixedCosts', fixedCosts);
      },
      removeFixedCost: function removeFixedCost(cost) {
        var fixedCosts = this.get('fixedCosts');
        fixedCosts.removeObject(cost);
        this.set('fixedCosts', fixedCosts);
      },
      saveSettings: function saveSettings() {
        this.sendAction('saveSettings', this.get('fixedCosts'));
      }
    }
  });

  _exports.default = _default;
});