define("isolocity/pods/action-items/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pyy1B8ne",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n  \"],[11,\"form\"],[4,[38,1],[[32,0],\"create\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"goBack\"],[[30,[36,1],[[32,0],\"goBack\"],null]]]]],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"action-items.Create Action Item\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n\\n      \"],[19,\"action-items/form\",[]],[2,\"\\n\\n    \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"input\"],[14,0,\"btn btn-go btn-disabled pull-right\"],[15,2,[31,[[30,[36,0],[\"action-items.others.Creating\"],null]]]],[14,\"disabled\",\"true\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[15,2,[31,[[30,[36,0],[\"action-items.Create Action Item\"],null]]]],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"t\",\"action\",\"back-link\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/action-items/new/template.hbs"
    }
  });

  _exports.default = _default;
});