define("isolocity/pods/drawings/-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DxOcAViA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"form\"],[14,\"autocomplete\",\"off\"],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"value\",\"placeholder\",\"class\",\"keyDown\"],[[35,2],[30,[36,1],[\"Search Keywords\"],null],\"full-width\",[30,[36,0],[[32,0],\"handleSearchKeyPress\"],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,0,\"btn btn-gray btn-pill btn-module-search\"],[4,[38,0],[[32,0],\"searchDrawings\"],null],[12],[1,[30,[36,1],[\"drawings.labels.Search\"],null]],[13],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"clearSearch\"],null],[12],[1,[30,[36,1],[\"drawings.labels.clear\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"searchText\",\"input\"]}",
    "meta": {
      "moduleName": "isolocity/pods/drawings/-search/template.hbs"
    }
  });

  _exports.default = _default;
});