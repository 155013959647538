define("isolocity/pods/components/batch-records/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    isReadOnly: false,
    isNeedDisposition: false,
    dispositionValues: [],
    init: function init() {
      this.dispositionValues = ['', this.get('intl').t('Use As Is'), this.get('intl').t('Sorting'), this.get('intl').t('Scrap'), this.get('intl').t('Rework'), this.get('intl').t('Repair'), this.get('intl').t('Other')];

      this._super.apply(this, arguments);
    },
    actions: {
      addBatchRecord: function addBatchRecord() {
        this.sendAction('addBatchRecord');
      },
      removeBatchRecord: function removeBatchRecord(item) {
        this.sendAction('removeBatchRecord', item);
      }
    }
  });

  _exports.default = _default;
});