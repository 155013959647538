define("isolocity/mixins/customer-form-mixin", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/upload-files-mixin"], function (_exports, _actionItemMixin, _eSignatureMixin, _uploadFilesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_uploadFilesMixin.default, _actionItemMixin.default, _eSignatureMixin.default, {
    requiredFields: ['name', 'primary_name', 'primary_phone', 'primary_email', 'primary_position'],
    modules: Ember.inject.service('modules'),
    moduleSlug: 'customers',

    get isAdditionalFields() {
      return this.get('modules').getModuleSetting('customers', 'is_additional_details_fields', false);
    },

    actions: {
      createCustomerActionItem: function createCustomerActionItem(item) {
        item.set('link', 'customers.edit');
        item.set('entity_type', 'customer');

        if (this.get('isEdit')) {
          item.set('entity_id', this.get('model.id'));
        }

        item.set('code', 'customer_action');
        this.get('model.all_action_items').pushObject(item);

        if (this.get('isEdit')) {
          item.save();
        }
      },
      saveCustomer: function saveCustomer(customer, actionItems) {
        var _this = this;

        if (actionItems.get('length') === 0) {
          this.send('saveAttachments', customer);
        } else {
          actionItems.forEach(function (action) {
            action.set('entity_id', customer.get('id'));

            if (action === actionItems.get('lastObject')) {
              action.save().then(function () {
                _this.send('saveAttachments', customer);
              });
            } else {
              action.save();
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});