define("isolocity/pods/bi-product/model", ["exports", "ember-data", "isolocity/mixins/units-of-measure-mixin"], function (_exports, _emberData, _unitsOfMeasureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_unitsOfMeasureMixin.default, {
    part_id: _emberData.default.attr('number'),
    bi_product_part_id: _emberData.default.belongsTo('part', {
      async: true,
      inverse: null
    }),
    quantity: _emberData.default.attr('string'),
    unit_of_measure: _emberData.default.attr('string'),
    unitsOfMeasure: Ember.computed('bi_product_part_id.unit_type', function () {
      return this.getUnitsOfMeasure(this.get('bi_product_part_id.unit_type'));
    })
  });

  _exports.default = _default;
});