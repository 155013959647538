define("isolocity/pods/part-type/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    // attributes
    name: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('utc'),
    updated_at: _emberData.default.attr('utc'),
    parts: _emberData.default.hasMany('part')
  });

  _exports.default = _default;
});