define("isolocity/pods/azure/callback/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params, transition) {
      var parsedSearch = new URLSearchParams(window.location.search);
      this.set('api_response', parsedSearch);
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.set('api_response', this.get('api_response'));
      this.controllerFor(this.get('routeName')).send('azureCallback', this.get('api_response'));
    }
  });

  _exports.default = _default;
});