define("isolocity/pods/file/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    organization_id: _emberData.default.belongsTo('organization'),
    location: _emberData.default.attr('string'),
    original_name: _emberData.default.attr('string'),
    data_url: _emberData.default.attr('string'),
    full_data_url: _emberData.default.attr('string'),
    remote_url: _emberData.default.attr('string'),
    temporary_url: _emberData.default.attr('string'),
    logo_url: _emberData.default.attr('string'),
    mime_data_url: _emberData.default.attr('string'),
    mime: _emberData.default.attr('string'),
    size: _emberData.default.attr('string'),
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    rev_number: _emberData.default.attr('string'),
    is_pdf: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    friendly_size: _emberData.default.attr('string'),
    defect: _emberData.default.attr(),
    is_sticky: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    is_microsoft: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    is_image: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});