define("isolocity/pods/action-item/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    code: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    completed: _emberData.default.attr('boolean'),
    details: _emberData.default.attr(),
    priority: _emberData.default.attr(),
    assignee: _emberData.default.attr(),
    assignee_id: _emberData.default.attr('number'),
    assignee_type: _emberData.default.attr('string'),
    due_date: _emberData.default.attr('utc-datetime'),
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    entity_id_secondary: _emberData.default.attr('number'),
    entity_type_secondary: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('utc-datetime'),
    completed_at: _emberData.default.attr('utc'),
    is_owned_by_current_user: _emberData.default.attr('boolean'),
    use_queue: _emberData.default.attr('boolean'),
    // Relationships
    files: _emberData.default.attr(),
    fileIds: _emberData.default.attr(),
    // Computed properties
    dueAt: Ember.computed('due_date', function () {
      return Ember.isEmpty(this.get('due_date')) ? '' : moment(this.get('due_date')).format("MMM D, YYYY");
    }),
    completedAt: Ember.computed('completed_at', function () {
      return Ember.isEmpty(this.get('completed_at')) ? 'n/a' : moment(this.get('completed_at')).format("MMM D, YYYY");
    }),
    completedAtTime: Ember.computed('completed_at', function () {
      return Ember.isEmpty(this.get('completed_at')) ? 'n/a' : moment(this.get('completed_at')).format("h:mm A");
    }),
    isOverdue: Ember.computed('completed', function () {
      return !this.get('completed') && moment() > this.get('due_date');
    }),
    priorityClass: Ember.computed('priority', function () {
      var map = {
        Urgent: 'danger',
        High: 'danger',
        Medium: 'warning',
        Low: 'success'
      };
      return !Ember.isEmpty(this.get('priority')) ? map[this.get('priority')] : '';
    }),
    numericPriority: Ember.computed('priority', function () {
      var map = {
        Urgent: 1,
        High: 2,
        Medium: 3,
        Low: 4
      };
      return !Ember.isEmpty(this.get('priority')) ? map[this.get('priority')] : 4;
    }),
    togglePriority: function togglePriority() {
      if (this.get('priority') === 'Low') {
        this.set('priority', 'Medium');
      } else if (this.get('priority') === 'Medium') {
        this.set('priority', 'High');
      } else {
        this.set('priority', 'Low');
      }
    }
  });

  _exports.default = _default;
});