define("isolocity/pods/components/audit/key-performance-indicators/corrective-action/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/audit/key-performance-indicators/corrective-action/chart", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/localization-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, _chart.default, _localizationMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.refreshChartData();
    },
    carReportUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/cars';
    }),
    onCarDropdownChange: Ember.observer('selectedOverview', 'selectedSupplier', 'selectedCustomer', 'selectedErrorCode', 'selectedCarType', function () {
      this.set('chartData', null);
      this.refreshChartData();
    }),
    refreshChartData: function refreshChartData() {
      var data = {};
      var overview = this.get('selectedOverview');
      var supplier = this.get('selectedSupplier');
      var customer = this.get('selectedCustomer');
      var errorCode = this.get('selectedErrorCode');
      var carType = this.get('selectedCarType');

      if (!Ember.isEmpty(overview)) {
        data.overview = overview;
      }

      if (!Ember.isEmpty(supplier)) {
        data.supplier_id = supplier.get('id');
      }

      if (!Ember.isEmpty(customer)) {
        data.customer_id = customer.get('id');
      }

      if (!Ember.isEmpty(errorCode)) {
        data.error_code_id = errorCode.get('id');
      }

      if (!Ember.isEmpty(carType)) {
        data.car_type_id = carType.get('id');
      }

      this.send('loadChart', data);
    },
    setChartData: function setChartData(data) {
      var dataset = [];

      for (var year in data) {
        for (var month in data[year]) {
          if (!Ember.isEmpty(data[year][month])) {
            dataset.push([Date.UTC(year, month - 1, 1), data[year][month].count]);
          }
        }
      }

      var label = this.get('intl').t('Open Reports');
      this.set('chartData', [{
        name: label,
        data: dataset
      }]);
    },
    setStackedChartData: function setStackedChartData(data) {
      var series = [];

      for (var category in data) {
        var dataset = [];

        for (var year in data[category]) {
          for (var month in data[category][year]) {
            if (!Ember.isEmpty(data[category][year][month])) {
              dataset.push([Date.UTC(year, month - 1, 1), data[category][year][month].count]);
            }
          }
        }

        series.pushObject({
          name: category,
          data: dataset
        });
      }

      if (!Ember.isEmpty(data)) {
        this.set('chartData', series);
      } else {
        var label = this.get('intl').t('Open Reports');
        this.set('chartData', [{
          name: label,
          data: []
        }]);
      }
    },
    actions: {
      loadChart: function loadChart(data) {
        var _this = this;

        var reportData = {
          company_ids: this.get('reportingService').get('companies')
        };

        if (data.overview) {
          reportData.overview = data.overview;
        }

        if (data.supplier_id) {
          reportData.supplier_id = data.supplier_id;
        }

        if (data.customer_id) {
          reportData.customer_id = data.customer_id;
        }

        if (data.error_code_id) {
          reportData.error_code_id = data.error_code_id;
        }

        if (data.car_type_id) {
          reportData.car_type_id = data.car_type_id;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('carReportUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            if (reportData.overview) {
              _this.setStackedChartData(data);
            } else {
              _this.setChartData(data);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});