define("isolocity/pods/components/shipment-parts-table/row/component", ["exports", "isolocity/mixins/table-editor-row-mixin", "isolocity/mixins/drawing-preview-mixin"], function (_exports, _tableEditorRowMixin, _drawingPreviewMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorRowMixin.default, _drawingPreviewMixin.default, {
    tagName: 'tr',
    previewCharacteristicsCount: 2,
    notEnoughInventory: Ember.computed('model.part_id.inventoryLevel', 'model.quantity', function () {
      return this.get('model.quantity') > this.get('model.part_id.inventoryLevel');
    }),
    hasPart: Ember.computed('model.part_id', function () {
      return !Ember.isEmpty(this.get('model.part_id.id'));
    }),
    isNewBatchNumber: false,
    onPartChanged: Ember.observer('model.part_id', function () {
      if (this.get('hasPart')) {
        Ember.run.once(this, function () {
          this.sendAction('onPartChange', this.get('model'));
        });
      }
    }),
    onRowChange: Ember.observer('isEdittable', 'model.part_id.id', 'model.serial_number', 'model.quantity', function () {
      var _this = this;

      var fields = ['part_id.id', 'quantity'],
          valid = true; // get historical_ batch_numbers from part

      this.sendAction('onPartChange', this.get('model')); // set nearest_expiry_date based on serial_number 

      if (this.get('model.serial_number')) {
        this.get('model.part_id.inventory').forEach(function (inventory) {
          if (inventory.batch_number == _this.get('model.serial_number')) {
            if (!Ember.isEmpty(inventory.nearest_expiry_date)) {
              _this.set('model.displayExpiryDate', moment(inventory.nearest_expiry_date).format("YYYY-MM-DD"));
            } else {
              _this.set('model.displayExpiryDate', null);
            }
          }
        });
      }

      var model = this.get('model');

      for (var i = 0, len = fields.length; i < len; i++) {
        if (Ember.isEmpty(model.get(fields[i]))) {
          valid = false;
        }
      }

      return this.set('isValidRow', valid);
    }),
    actions: {
      togglePreview: function togglePreview() {
        var _this2 = this;

        this.set('model.edittable', false);
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this2.set('promise', null);

          _this2.sendAction('onDrawingRedirect', _this2.get('model.drawing_id'));
        }, function () {
          _this2.set('promise', null);
        });
        this.set('promise', defer);
      },
      refreshDrawing: function refreshDrawing() {
        this.sendAction('updateDrawing', this.get('model'));
      },
      toggleNewBatchNumber: function toggleNewBatchNumber() {
        this.set('isNewBatchNumber', !this.get('isNewBatchNumber'));
      }
    }
  });

  _exports.default = _default;
});