define("isolocity/pods/training-program/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.training-programs'),
    filterBy: 'Active',
    filterOptions: ['Active', 'Inactive'],
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchModule');
        }
      },
      searchModule: function searchModule() {
        this.get('search').setKeyword(this.get('searchText'));
        this.get('search').setType(this.get('selectedTrainingType.id'));
        this.get('search').setFormat(this.get('selectedFormat'));
        this.get('search').setDateRange(this.get('dateRange'));
        this.get('search').setFromDate(this.get('fromDate'));
        this.get('search').setToDate(this.get('toDate'));
        this.get('search').setFilterBy(this.get('filterBy'));
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.set('selectedTrainingType', null);
        this.set('selectedFormat', null);
        this.set('dateRange', '');
        this.set('fromDate', '');
        this.set('toDate', '');
        this.set('isCustomDateRange', false);
        this.set('filterBy', 'Active');
        this.get('search').clear();
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      }
    }
  });

  _exports.default = _default;
});