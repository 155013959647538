define("isolocity/pods/complaints/new/route", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_userOrganizationMixin.default, {
    persistence: Ember.inject.service(),
    modelName: 'complaint',
    model: function model() {
      var existingModel = this.get('persistence').find('complaints.new');

      if (!Ember.isBlank(existingModel)) {
        if (!Ember.isEmpty(existingModel.model)) {
          return existingModel.model;
        }
      }

      return this.store.createRecord('complaint', {
        closed_at: null,
        complaint_date: new moment()
      });
    },
    afterModel: function afterModel(model, transition) {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));

      if (!Ember.isBlank(user)) {
        model.get('owners').pushObject(user);
      }

      model.set('suppliers', this.store.findAll('supplier'));
      model.set('customers', this.store.findAll('customer'));
      model.set('parts', this.store.findAll('part'));
      model.set('errorCodes', this.store.findAll('error-code'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('allDepartments', this.store.findAll('department'));
      model.set('allCategories', this.store.findAll('complaint-category'));
      model.set('sources', this.store.findAll('complaint-source'));
      model.set('impacts', this.store.findAll('complaint-impact'));
      model.set('partTypes', this.store.findAll('part-type'));
    }
  });

  _exports.default = _default;
});