define("isolocity/pods/inspection/bi-product/model", ["exports", "ember-data", "isolocity/mixins/ajax-request-mixin"], function (_exports, _emberData, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_ajaxRequestMixin.default, {
    inspection_id: _emberData.default.belongsTo('inspection'),
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    part: _emberData.default.belongsTo('part'),
    inventory_id: _emberData.default.belongsTo('inventory'),
    batch_number: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    unit_of_measure: _emberData.default.attr('string'),
    cost: _emberData.default.attr('money'),
    expiry_date: _emberData.default.attr('isodate'),
    is_verification_check: _emberData.default.attr('boolean'),
    // dynamically generated
    material_quantity: _emberData.default.attr('number'),
    quantity_alternate: _emberData.default.attr('string'),
    historical_batch_numbers: _emberData.default.attr(),
    isDirty: false,
    isNewBatchNumber: _emberData.default.attr('boolean', {
      default: false
    }),
    displayDate: Ember.computed('expiry_date', function () {
      if (Ember.isEmpty(this.get('expiry_date'))) {
        return;
      }

      return moment(this.get('expiry_date')).format("YYYY-MM-DD");
    }),
    hasHistoricalBatchNumbers: Ember.computed('historical_batch_numbers.length', function () {
      return this.get('historical_batch_numbers.length') >= 1;
    }),
    isActiveBatch: Ember.computed('entity_id.batch_number', 'batch_number', function () {
      return this.get('batch_number') && this.get('entity_id.batch_number') === this.get('batch_number');
    }),
    setIsDirty: Ember.observer('entity_id', 'quantity', function () {
      this.set('isDirty', true);
    }),
    isValid: Ember.computed('entity_id', 'quantity', function () {
      return !Ember.isBlank(this.get('entity_id')) && !Ember.isBlank(this.get('quantity'));
    }),
    loadHistoricalBatchNumbers: function loadHistoricalBatchNumbers() {
      var _this = this;

      var authHeaders = this.get('authHeaders');
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/parts/' + this.get('entity_id') + '/historical-batch-numbers'),
        method: 'GET'
      }).then(function (data) {
        _this.set('historical_batch_numbers', data);
      });
    }
  });

  _exports.default = _default;
});