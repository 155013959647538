define("isolocity/pods/activitylog/-diff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eoYG++4K",
    "block": "{\"symbols\":[\"change\"],\"statements\":[[10,\"div\"],[14,0,\"activity-popup\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"promise\",\"confirm\",\"title\"],[[35,5],[30,[36,1],[\"activity-log.Done\"],null],[30,[36,1],[\"activity-log.Change Summary\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"table\"],[14,0,\"activity-table data-table data-table--not-hoverable no-sort-icons\"],[12],[2,\"\\n            \"],[10,\"thead\"],[12],[2,\"\\n                \"],[10,\"tr\"],[12],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"activity-log.Field\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"activity-log.Old Value\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"activity-log.New Value\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"tr\"],[12],[2,\"\\n                        \"],[10,\"td\"],[12],[1,[32,1,[\"field\"]]],[13],[2,\"\\n                        \"],[10,\"td\"],[14,0,\"property-old\"],[12],[1,[30,[36,0],[[32,1,[\"old\"]]],null]],[13],[2,\"\\n                        \"],[10,\"td\"],[14,0,\"property-new\"],[12],[1,[30,[36,0],[[32,1,[\"new\"]]],null]],[13],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"truncate-text\",\"t\",\"changedFields\",\"-track-array\",\"each\",\"viewChangesPromise\",\"confirm-popup\"]}",
    "meta": {
      "moduleName": "isolocity/pods/activitylog/-diff/template.hbs"
    }
  });

  _exports.default = _default;
});