define("isolocity/mixins/label-editing-mixin", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/localization-mixin"], function (_exports, _ajaxRequestMixin, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, _localizationMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    className: '',
    labelFor: '',
    translationKey: '',
    newLabelText: '',
    additionalText: '',
    labelModel: null,
    useModelForm: false,
    cachedTranslation: null,
    allowEdit: true,
    isEditing: false,
    isAdmin: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    formData: Ember.computed('labelModel', 'labelModel.custom_form', function () {
      return this.get('labelModel') && this.get('labelModel.custom_form') ? this.get('labelModel.custom_form') : null;
    }),
    categoryKey: Ember.computed('translationKey', function () {
      if (this.get('translationKey')) {
        var strings = this.get('translationKey').split(".");
        var key = 'labels';

        for (var index = 0; index < strings.length; ++index) {
          var item = strings[index];
          key = (item == 'labels' || item == 'headers') && index != strings.length - 1 ? item : key;
        }

        return key;
      }

      return 'labels';
    }),
    labelKey: Ember.computed('translationKey', function () {
      if (this.get('translationKey')) {
        var strings = this.get('translationKey').split(".");
        return strings[strings.length - 1];
      }

      return null;
    }),
    translatedText: Ember.computed('translationKey', 'cachedTranslation', function () {
      if (this.get('formData')) {
        var formDataObject = JSON.parse(this.get('formData'));
        var categoryKey = this.get('categoryKey');
        var theTranslatedText = this.get('intl').t(this.get('translationKey'));

        if (formDataObject.hasOwnProperty(categoryKey)) {
          theTranslatedText = formDataObject[categoryKey].hasOwnProperty(this.get('labelKey')) ? formDataObject[categoryKey][this.get('labelKey')] : this.get('intl').t(this.get('translationKey'));
        }

        return theTranslatedText;
      }

      if (this.get('cachedTranslation')) {
        return this.get('cachedTranslation');
      }

      return this.get('intl').t(this.get('translationKey'));
    }),
    actions: {
      editLabel: function editLabel() {
        this.set('newLabelText', this.get('translatedText'));
        this.set('isEditing', true);
      },
      saveLabel: function saveLabel() {
        var _this = this;

        var session = this.get('session');
        var organizationId = session.get('session.currentUser.organization_id');
        var locale = this.get('intl').get('primaryLocale');
        var authHeaders = this.get('authHeaders');
        var companyId = this.get('authManager.session.currentUser.current_company_id');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/company/translations/' + companyId + '/' + locale),
          method: 'PUT',
          data: {
            translation_key: this.get('translationKey'),
            label_text: this.get('newLabelText')
          }
        }).then(function (data) {
          _this.set('cachedTranslation', _this.get('newLabelText'));

          _this.getTranslations();
        }).always(function () {
          _this.set('isEditing', false);

          _this.send('saveLabelForSingleModelItem');
        });
      },
      saveLabelForSingleModelItem: function saveLabelForSingleModelItem() {
        if (this.get('useModelForm')) {
          var categoryKey = this.get('categoryKey');

          if (Ember.isBlank(this.get('formData'))) {
            var labelsJson = categoryKey == 'labels' ? '{ "' + this.get('labelKey') + '":"' + this.get('newLabelText') + '" }' : '{}';
            var headersJson = categoryKey == 'headers' ? '{ "' + this.get('labelKey') + '":"' + this.get('newLabelText') + '" }' : '{}';
            var formData = '{ "labels" :' + labelsJson + ', "headers" :' + headersJson + '}';
            this.set('formData', formData);
          }

          var model = this.get('labelModel');
          var formDataObject = JSON.parse(this.get('formData'));

          if (!formDataObject.hasOwnProperty(categoryKey)) {
            formDataObject[categoryKey][this.get('labelKey')] = this.get('newLabelText');
          } else {
            formDataObject[categoryKey][this.get('labelKey')] = this.get('newLabelText');
          }

          model.set('custom_form', JSON.stringify(formDataObject));
          model.save();
        }
      }
    }
  });

  _exports.default = _default;
});