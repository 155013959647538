define("isolocity/mixins/route-transition-mixin", ["exports", "isolocity/mixins/localization-mixin"], function (_exports, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_localizationMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    companyService: Ember.inject.service('company-switcher'),
    actions: {
      didTransition: function didTransition() {
        this.get('flashMessages').clearMessages();

        if (this.get('session.isAuthenticated')) {
          var companyService = this.get('companyService');
          companyService.init();
        }

        var locale = this.get('intl').get('primaryLocale');

        if (!locale || !this.get('intl').translationsFor(locale)) {
          this.getTranslations();
        }
      }
    }
  });

  _exports.default = _default;
});