define("isolocity/mixins/parts-table-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    click: function click(ev) {
      var _this = this;

      var items = this.get('model.items');

      if (!Ember.isEmpty(items)) {
        items.forEach(function (item) {
          if (item.get('edittable')) {
            _this.send('saveRow', item);
          }
        });
      }
    },
    actions: {
      handlePartSelect: function handlePartSelect(item) {
        var _this2 = this;

        if (Ember.isBlank(item) || item.get('isDeleted')) {
          return;
        }

        if (!Ember.isEmpty(item.get('part_id.id'))) {
          this.get('store').findRecord('part', item.get('part_id.id'));
        }

        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/parts/' + item.get('part_id.id') + '/historical-batch-numbers'),
          method: 'GET'
        }).then(function (data) {
          if (data.indexOf(_this2.get('model.serial_number')) == -1) {
            // Adding serial_number at the begining of the array
            data = [_this2.get('model.serial_number')].concat(data);
          }

          item.set('historical_batch_numbers', data);
        });
        this.send('refreshDrawing', item);
      },
      refreshDrawing: function refreshDrawing(item) {
        this.sendAction('updateDrawing', item);
      },
      redirectToDrawing: function redirectToDrawing(drawing) {
        this.sendAction('redirectToDrawing', drawing);
      },
      resetEdittable: function resetEdittable(model) {
        var items = this.get('model.items');

        if (items !== undefined) {
          items.forEach(function (item) {
            if (item === model) {
              item.set('edittable', true);
            } else {
              item.set('edittable', false);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});