define("isolocity/pods/location/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    requiredFields: ['name'],
    intl: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        var intl = this.get('intl');

        if (!this.get('isValid')) {
          this.send('showErrors');
          return;
        }

        this.get('model').save().then(function () {
          _this.get('flashMessages').success(intl.t('location.others.Success Message'));

          history.back();
        }, function (response) {
          _this.send('showServerErrors', response);
        });
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});