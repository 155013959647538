define("isolocity/mixins/risk-report-editing-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin"], function (_exports, _eSignatureMixin, _iconListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _iconListMixin.default, {
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    reviewPeriodChanged: Ember.observer('model.review_repeat_frequency', 'model.review_repeat_duration', function () {
      if ('review_repeat_frequency' in this.get('model').changedAttributes() || 'review_repeat_duration' in this.get('model').changedAttributes()) {
        this.send('reCalculateNextReviewDate');
      }
    }),
    lastReviewDateChanged: Ember.observer('model.last_review_date', function () {
      if ('last_review_date' in this.get('model').changedAttributes()) {
        this.send('reCalculateNextReviewDate');
      }
    }),
    actions: {
      reCalculateNextReviewDate: function reCalculateNextReviewDate() {
        var last_review_date = this.get('model.last_review_date'),
            frequency = this.get('model.review_repeat_frequency'),
            duration = this.get('model.review_repeat_duration');

        if (!Ember.isEmpty(last_review_date) && !Ember.isEmpty(frequency) && !Ember.isEmpty(duration)) {
          var next_review_date = moment(last_review_date).add(frequency, duration.toLowerCase());
          this.set('model.next_review_date', next_review_date);
        }
      }
    }
  });

  _exports.default = _default;
});