define("isolocity/mixins/receiving-mixin", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/deletes-line-items-mixin", "isolocity/mixins/part-line-items-validation-mixin"], function (_exports, _requiredFieldsMixin, _deletesLineItemsMixin, _partLineItemsValidationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_requiredFieldsMixin.default, _deletesLineItemsMixin.default, _partLineItemsValidationMixin.default, {
    modules: Ember.inject.service('modules'),
    requiredFields: ['supplier_id.id', 'requested_shipment_arrival_date'],
    isOpen: true,
    isClosed: false,

    get module() {
      return this.get('modules').getModuleBySlug('receiving-inspections');
    },

    actions: {
      setPresetSerialNumber: function setPresetSerialNumber() {
        var emptyModel = this.get('model.emptyRow');

        if (Ember.isEmpty(emptyModel.get('serial_number'))) {
          emptyModel.set('serial_number', this.get('model.serial_number'));
        }
      },
      updateDrawing: function updateDrawing(item) {
        var _this = this;

        item.get('part_id').then(function (part) {
          if (!Ember.isEmpty(part)) {
            _this.get('store').query('drawing', {
              is_approved: true,
              part_id: part.get('id')
            }).then(function (drawings) {
              item.set('drawing_id', null);

              if (drawings.get('length') > 0) {
                item.set('drawing_id', drawings.get('firstObject'));
              }
            });
          }
        });
      },
      redirectToDrawing: function redirectToDrawing(drawing) {
        this.transitionToRoute('drawings.edit', drawing);
      },
      insertRowLocally: function insertRowLocally(item) {
        if (Ember.isEmpty(item.get('receiving_inspection_id')) || !item.get('isValid')) return;
        var items = this.get('model.items');
        items.pushObject(item);
        item.setProperties({
          'order': items.get('length'),
          'edittable': false,
          'alwaysEdittable': false
        });
        this.send('createEmptyRow');
      },
      insertRow: function insertRow(item) {
        var _this2 = this;

        if (Ember.isEmpty(item.get('receiving_inspection_id')) || !item.get('isValid')) return;
        var items = this.get('model.items');
        items.pushObject(item);
        item.set('order', items.get('length'));
        item.save().then(function (inserted) {
          inserted.set('alwaysEdittable', false);

          _this2.send('createEmptyRow');
        }, function (error) {
          items.removeObject(item);
          item.set('order', null);
        });
      },
      createEmptyRow: function createEmptyRow() {
        this.set('model.emptyRow', this.store.createRecord('receiving-inspection.item', {
          serial_number: this.get('model.serial_number'),
          part_id: null,
          alwaysEdittable: true,
          is_active_batch: false,
          quantity_rejected: 0
        }));
      },
      saveRow: function saveRow(item) {
        item.save().then(function (item) {
          item.set('edittable', false);
        });
      },
      handleSelection: function handleSelection() {
        var _this3 = this;

        this.set('promise', null);
        var shipDate = this.get('model.actual_ship_date');

        if (!Ember.isEmpty(shipDate) && shipDate.isValid()) {
          var defer = Ember.RSVP.defer();
          defer.promise.then(function (value) {
            _this3.set('promise', null);
          }, function () {
            _this3.set('model.actual_ship_date', null);

            _this3.set('promise', null);
          });
          this.set('promise', defer);
        } else {
          this.set('model.is_closed', false);
        }
      },
      deleteRow: function deleteRow(item) {
        //reset row if on 'new row', otherwise delete
        if (Ember.isNone(item.get('order'))) {
          this.set('model.emptyRow', this.store.createRecord('receiving-inspection.item', {
            serial_number: this.get('model.serial_number'),
            part_id: null,
            alwaysEdittable: true,
            is_active_batch: false,
            quantity_rejected: 0
          }));
        } else {
          this.send('deleteLineItem', item);
        }
      }
    }
  });

  _exports.default = _default;
});