define("isolocity/pods/suppliers/index/route", ["exports", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    search: Ember.inject.service('search.suppliers'),
    modelName: ['supplier', 'supplier.certificate'],
    model: function model() {
      this.startLoading();
      var filters = this.getQueryFilters();
      filters['is_active'] = this.get('search').getIsActive();
      return {
        suppliers: this.store.query('supplier', filters)
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.suppliers.then(function (suppliers) {
        _this.doneLoading();

        _this.setPageMetadata(suppliers);
      });
    }
  });

  _exports.default = _default;
});