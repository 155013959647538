define("isolocity/pods/components/graphs/documents/outstanding-trainings-number/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/documents/outstanding-trainings-number/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this.set('chartData', null);

      var _this = this;

      var startDate = null,
          endDate = null;

      if (!Ember.isEmpty(this.get('startDateOutstandingTrainings'))) {
        startDate = moment(this.get('startDateOutstandingTrainings')).format("YYYY-MM-DD");
      }

      if (!Ember.isEmpty(this.get('endDateOutstandingTrainings'))) {
        endDate = moment(this.get('endDateOutstandingTrainings')).format("YYYY-MM-DD");
      }

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/documents/outstanding-trainings/graph',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies'),
          start_date: startDate,
          end_date: endDate
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;

          var completedLabel = _this.get('intl').t('Completed');

          var outstandingLabel = _this.get('intl').t('Outstanding');

          var series = [{
            name: 'Percentage',
            colorByPoint: true,
            data: [{
              name: completedLabel,
              y: data.completed,
              selected: true,
              color: '#69c576'
            }, {
              name: outstandingLabel,
              y: data.outstanding,
              color: '#ed6161'
            }]
          }];

          if (!Ember.isBlank(series) && (data.completed > 0 || data.outstanding > 0)) {
            _this.set('chartData', series);
          } else {
            _this.set('chartData', [{
              name: _this.get('intl').t('No Data'),
              data: []
            }]);
          }
        }
      });

      this._super.apply(this, arguments);
    },
    onStartOutstandingTrainingsDateChange: Ember.observer('startDateOutstandingTrainings', function () {
      this.init();
    }),
    onEndOutstandingTrainingsDateChange: Ember.observer('endDateOutstandingTrainings', function () {
      this.init();
    })
  });

  _exports.default = _default;
});