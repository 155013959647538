define("isolocity/mixins/units-of-measure-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hasChangedUnitOfMeasure: Ember.computed('model.unit_of_measure', function () {
      var model = this.get('model');
      return Ember.isPresent(model.changedAttributes().unit_of_measure);
    }),
    hasSelectedUnitOfMeasure: Ember.computed('model.unit_type', 'model.unit_of_measure', function () {
      if (!Ember.isEmpty(this.get('model.unit_type')) && !Ember.isEmpty(this.get('model.unit_of_measure'))) {
        return true;
      }

      return false;
    }),
    unitTypes: ['', 'Volume', 'Mass', 'Length'],
    units: {
      Volume: ['gal', 'cup', 'tsp', 'l', 'ml'],
      Mass: ['mg', 'g', 'kg', 'lb', 't', 'oz', 'st'],
      Length: ['m', 'ft', 'in', 'yd', 'cm', 'mm']
    },
    unitsOfMeasure: Ember.computed('model.unit_type', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model.get('unit_type'))) {
        return ['units'];
      }

      return this.units[model.get('unit_type')];
    }),
    getUnitsOfMeasure: function getUnitsOfMeasure(unitType) {
      if (Ember.isEmpty(unitType)) {
        return ['units'];
      }

      unitType = unitType.charAt(0).toUpperCase() + unitType.slice(1);
      return this.units[unitType];
    },
    actions: {
      undoSetUnitOfMeasure: function undoSetUnitOfMeasure() {
        this.set('model.unit_type', '');
        this.set('model.unit_of_measure', '');
        this.notifyPropertyChange('hasChangedUnitOfMeasure');
        this.notifyPropertyChange('hasSelectedUnitOfMeasure');
      }
    }
  });

  _exports.default = _default;
});