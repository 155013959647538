define("isolocity/mixins/batch-records-list-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    searchText: '',
    searchSupplier: null,
    searchType: '',
    selectedStatus: '',
    selectedSort: '',
    selectedExpiry: '',
    activeRowId: null,
    searchTypes: ['Finished Product', 'Raw Material'],
    searchStatus: ['In Stock', 'Running Low', 'Out of Stock'],
    sortOptions: ['Alphabetical', 'Quantity', 'Value on Hand'],
    expiryOptions: ['1 week', '1 month', '3 months', '6 months'],
    isFilteredResults: Ember.computed(function () {
      return this.get('searchType') || this.get('searchText') || this.get('searchSupplier') || this.get('selectedSort');
    }),
    searchDescriptor: Ember.computed('searchType', 'searchText', 'searchSupplier', 'selectedSort', 'selectedStatus', function () {
      var descriptor = '';

      if (this.get('searchType')) {
        descriptor += this.get('searchType').toLowerCase() + 's';
      } else {
        descriptor += 'results';
      }

      if (this.get('searchText')) {
        descriptor += ' matching "' + this.bold(this.get('searchText')) + '"';
      }

      if (this.get('searchSupplier')) {
        descriptor += ' from ' + this.bold(this.get('searchSupplier.name'));
      }

      if (this.get('searchSupplier')) {
        descriptor += ' are ' + this.bold(this.get('selectedStatus'));
      }

      if (this.get('selectedSort')) {
        descriptor += ' sort by ' + this.bold(this.get('selectedSort'));
      }

      if (this.get('selectedSort')) {
        descriptor += ' will expire in ' + this.bold(this.get('selectedExpiry'));
      }

      return descriptor.htmlSafe();
    }),
    actions: {
      handleInventorySearchKeyPress: function handleInventorySearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchBatchRecords');
        }
      },
      searchBatchRecords: function searchBatchRecords() {
        var supplierId = !Ember.isBlank(this.get('searchSupplier.id')) ? this.get('searchSupplier.id') : null;
        this.get('search').setKeyword(this.get('searchText'));
        this.get('search').setType(this.get('searchType'));
        this.get('search').setStatus(this.get('selectedStatus'));
        this.get('search').setSupplierId(supplierId);
        this.get('search').setSortBy(this.get('selectedSort'));
        this.get('search').setExpirySoon(this.get('selectedExpiry'));
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      clearInventorySearch: function clearInventorySearch() {
        this.set('searchText', '');
        this.set('searchType', '');
        this.set('selectedStatus', '');
        this.set('searchSupplier', null);
        this.set('selectedSort', '');
        this.set('selectedExpiry', '');
        this.get('search').clear();
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      toggleActiveRowId: function toggleActiveRowId(id) {
        if (this.get('activeRowId') === id) {
          this.set('activeRowId', null);
        } else {
          this.set('activeRowId', id);
        }
      }
    }
  });

  _exports.default = _default;
});