define("isolocity/pods/components/settings-sidebar/suppliers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XpBiw8ZK",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[1,[30,[36,0],[\"Only authorized users can edit suppliers details\"],null]],[2,\" \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,1,[\"settings\",\"is_authorized_users\"]],false,\"No\",\"Yes\",[30,[36,3],[[30,[36,2],[[35,1,[\"settings\",\"is_authorized_users\"]]],null]],null],false,\"pull-left\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"module\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/settings-sidebar/suppliers/template.hbs"
    }
  });

  _exports.default = _default;
});