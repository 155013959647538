define("isolocity/pods/company/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    organization_id: _emberData.default.belongsTo('organization'),
    is_active: _emberData.default.attr('boolean'),
    timezone: _emberData.default.attr('string'),
    settings: _emberData.default.attr()
  });

  _exports.default = _default;
});