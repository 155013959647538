define("isolocity/mixins/history-handler-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    history: Ember.inject.service(),
    init: function init() {
      if (this.get('historyOverride') !== undefined) {
        this.get('history').setRoute(this.get('historyOverride'));
      }

      this._super.apply(this, arguments);
    },
    actions: {
      goBack: function goBack() {
        var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

        var _that = this;

        setTimeout(function () {
          if (!Ember.isBlank(_that.get('store'))) {
            $('#notifications-count.notification-badge').html(_that.get('store').peekAll('notification').get('length'));
            $('#downloads-count.notification-badge').html(_that.get('store').peekAll('download').get('length'));
          }
        }, 150);
        var historyService = this.get('history'),
            windowhref = window.location.href;

        if (url !== "") {
          window.location.href = window.location.origin + url;
        } else if (historyService.get('hasHistory') && windowhref.indexOf(historyService.getRoute().split('.').join('/')) !== -1) {
          this.transitionToRoute(historyService.getRoute());
          historyService.setRoute('');
        } else if (document.referrer === '') {
          window.location.href = windowhref.substr(0, windowhref.lastIndexOf('/'));
        } else {
          history.back();
        }
      }
    }
  });

  _exports.default = _default;
});