define("isolocity/pods/equipment/archived/route", ["exports", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    search: Ember.inject.service('search.equipments-archived'),
    isArchived: true,
    model: function model() {
      this.startLoading();
      var filters = this.getQueryFilters();
      filters['is_active'] = this.get('search').getIsActive();
      filters['is_out_for_service'] = this.get('search').getIsOutForService();
      filters['equipment_type_id'] = this.get('search').getEquipmentTypeId();
      filters['is_archived'] = true;
      return {
        equipment: this.store.query('equipment', filters)
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.equipment.then(function (equipment) {
        _this.doneLoading();

        _this.setPageMetadata(equipment);
      });
      model.equipmentTypes = this.store.findAll('equipment-type');
      model.equipments = this.store.findAll('equipment');
    }
  });

  _exports.default = _default;
});