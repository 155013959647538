define("isolocity/pods/components/settings-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      getOwner = Ember.getOwner;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    isSettingsSaving: false,
    hasFormNameRevision: false,
    model: null,
    module: null,
    icons: [],
    selectedIcon: null,
    sideMenu: Ember.inject.service(),
    requiredFields: ['name', 'singular', 'plural'],
    isShowCustomFields: true,
    errorMessages: {
      'name': 'The name field is required',
      'singular': 'The singular field is required',
      'plural': 'The plural field is required'
    },
    canOpenSideMenu: Ember.computed('sideMenu.canOpen', function () {
      return this.get('sideMenu.canOpen');
    }),
    onSelectedIconChanged: Ember.observer('selectedIcon', function () {
      this.set('module.icon', this.get('selectedIcon'));
    }),
    sidebarChildName: Ember.computed('module.id', function () {
      var path = 'settings-sidebar/' + this.get('module.slug');

      if (!this.checkComponentExists(path)) {
        return false;
      }

      return path;
    }),
    checkComponentExists: function checkComponentExists(componentName) {
      var owner = getOwner(this);
      var lookup = owner.lookup('component-lookup:main');

      if (!lookup.componentFor) {
        return !!lookup.lookupFactory(componentName);
      }

      return !!(lookup.componentFor(componentName, owner) || lookup.layoutFor(componentName, owner));
    },
    actions: {
      saveSettings: function saveSettings() {
        var module = this.get('module'),
            fm = this.get('flashMessages'),
            _this = this,
            hasErrors = false;

        this.get('requiredFields').forEach(function (fieldName) {
          if (Ember.isBlank(_this.get('module.' + fieldName)) || !_this.get('module.' + fieldName)) {
            fm.danger(_this.get('errorMessages.' + fieldName));
            hasErrors = true;
          }
        });

        if (hasErrors) {
          return;
        }

        this.set('isSettingsSaving', true); // If has settings for individual model, save it. Unless is new report

        var modelReference = _this.get('model');

        if (modelReference) {
          if (modelReference.get('isNew') == false) {
            modelReference.save();
          }

          modelReference.set('requiredFiledsChanged', module.get('settings.fields'));
        }

        module.save().then(function () {
          _this.set('isSettingsSaving', false);

          fm.success(_this.get('intl').t('Module settings have been saved'));
        }, function (error) {
          _this.set('isSettingsSaving', false);

          var errors = error.errors;

          if (!Ember.isBlank(errors[0].message)) {
            fm.danger(errors[0].message);
          } else {
            fm.danger('An error occurred while saving the module settings');
          }
        });
      }
    }
  });

  _exports.default = _default;
});