define("isolocity/mixins/pfmea-item-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allSeverities: [{
      id: 1,
      name: '1. None'
    }, {
      id: 2,
      name: '2. Very Minor'
    }, {
      id: 3,
      name: '3. Minor'
    }, {
      id: 4,
      name: '4. Very Low'
    }, {
      id: 5,
      name: '5. Low'
    }, {
      id: 6,
      name: '6. Moderate'
    }, {
      id: 7,
      name: '7. High'
    }, {
      id: 8,
      name: '8. Very High'
    }, {
      id: 9,
      name: '9. Hazardous With Warning'
    }, {
      id: 10,
      name: '10. Hazardous Without Warning'
    }],
    allOccurrences: [{
      id: 1,
      name: '1. Remote: Failure Unlikely (<= 0.01 per thousand)'
    }, {
      id: 2,
      name: '2. Low: Relatively Few (0.1 per thousand)'
    }, {
      id: 3,
      name: '3. Low: Relatively Few (0.5 per thousand)'
    }, {
      id: 4,
      name: '4. Moderate: Occasional Failures (1 per thousand)'
    }, {
      id: 5,
      name: '5. Moderate: Occasional Failures (2 per thousand)'
    }, {
      id: 6,
      name: '6. Moderate: Occasional Failures (5 per thousand)'
    }, {
      id: 7,
      name: '7. High: Frequent Failures (10 per thousand)'
    }, {
      id: 8,
      name: '8. High: Frequent Failures (20 per thousand)'
    }, {
      id: 9,
      name: '9. Very High: Persistent Failures (50 per thousand)'
    }, {
      id: 10,
      name: '10. Very High: Persistent Failures (>= 100 per thousand)'
    }],
    allDetections: [{
      id: 1,
      name: '1. Very High'
    }, {
      id: 3,
      name: '2. High'
    }, {
      id: 4,
      name: '3. Moderately High'
    }, {
      id: 5,
      name: '4. Moderate'
    }, {
      id: 6,
      name: '5. Low'
    }, {
      id: 7,
      name: '6. Very Low'
    }, {
      id: 8,
      name: '7. Remote'
    }, {
      id: 9,
      name: '8. Very Remote'
    }, {
      id: 10,
      name: '9. Almost Impossible'
    }]
  });

  _exports.default = _default;
});