define("isolocity/pods/training-schedules/index/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _downloadableReportsMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _downloadableReportsMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.training-schedules'),
    authManager: Ember.inject.service('session'),
    selectedTrainingType: null,
    selectedEmployee: null,
    defaultFilterOption: 'Incomplete',
    filterByActive: null,
    filterActiveOptions: ['Active', 'Inactive'],
    filterBy: Ember.computed('defaultFilterOption', function () {
      return this.get('defaultFilterOption');
    }),
    filterOptions: ['Incomplete', 'Complete', 'Overdue', 'All'],
    trainingTypeData: Ember.computed('selectedTrainingType', function () {
      var trainingTypeId = this.get('selectedTrainingType.id');
      return {
        training_type_id: trainingTypeId
      };
    }),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchTrainingActivities');
        }
      },
      searchTrainingActivities: function searchTrainingActivities() {
        // If user deselects the filter by option
        if (Ember.isEmpty(this.get('filterBy'))) {
          this.set('filterBy', this.get('defaultFilterOption'));
        }

        this.get('search').setKeyword(this.get('searchText'));
        this.get('search').setType(this.get('selectedTrainingType.id'));
        this.get('search').setEmployee(this.get('selectedEmployee.id'));
        this.get('search').setFilterByActive(this.get('filterByActive'));
        this.get('search').setFilterBy(this.get('filterBy'));
        this.send('doSearchTrainingActivities');
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.set('selectedTrainingType', '');
        this.set('selectedEmployee', '');
        this.set('filterByActive', null);
        this.set('filterBy', this.get('defaultFilterOption'));
        this.get('search').clear();
        this.send('doSearchTrainingActivities');
      }
    }
  });

  _exports.default = _default;
});