define("isolocity/pods/index/route", ["exports", "isolocity/mixins/localization-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/config/environment"], function (_exports, _localizationMixin, _authenticationRoutingMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _localizationMixin.default, {
    isVisibleAllUsers: true,
    permissions: Ember.inject.service('user-permissions'),
    session: Ember.inject.service('session'),
    intl: Ember.inject.service(),
    modelName: ['action-item', 'training-schedule-employee', 'test.result'],
    model: function model() {
      return Ember.RSVP.hash({
        modules: this.store.findAll('module')
      });
    },
    afterModel: function afterModel(model, transition) {
      model.myIncompleteActionItems = this.store.query('action-item', {
        mine: true,
        completed: false
      });
      model.myEnrollments = this.store.query('training-schedule-employee', {
        mine: true
      });
      model.myTests = this.store.query('test.result', {
        mine: true
      });

      if (this.get('permissions').hasRoleAtLeast('user')) {
        model.parts = this.store.findAll('part');
        model.errorCodes = this.store.findAll('error-code');
        model.faqs = this.store.findAll('faq');
        model.companies = this.store.findAll('company');
        model.companyGroups = this.store.findAll('company-group');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model); // Move the logic of `didInsertElement` here


      var errorClass = this.get('session.data.authenticated.error_class');

      if (errorClass === 'UserNoAppVersionAccessException' && localStorage.getItem('has_read_redirect-to-new-app-warning-message') === 'no') {
        this.showRedirectToNewAppMessage(controller);
      }
    },
    showRedirectToNewAppMessage: function showRedirectToNewAppMessage(controller) {
      localStorage.setItem('has_read_redirect-to-new-app-warning-message', 'yes');
      var token = this.get('session.data.authenticated.loginToken');

      if (!token) {
        console.error('There is no valid token for 3.0 authentication');
        return;
      }

      var redirectUrl = "".concat(_environment.default.APP.newAppUrl, "/login/token?t=").concat(token);
      var messageContent = this.get('intl').t('redirect-to-new-app-warning-message');
      var message = Ember.String.htmlSafe("<a href=\"".concat(redirectUrl, "\" style=\"text-decoration: underline;\">").concat(messageContent, "</a>"));
      controller.set('redirectMessage', message); // Hide the message after 60 seconds

      Ember.run.later(this, function () {
        return controller.set('redirectMessage', null);
      }, 60000);
    }
  });

  _exports.default = _default;
});