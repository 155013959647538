define("isolocity/pods/components/document-approval-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nYTSi9+N",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"text--green\"],[12],[1,[30,[36,0],[\"Approved\"],null]],[2,\" - \"],[13],[2,\"\\n\\t\"],[11,\"a\"],[24,6,\"#\"],[24,0,\"link\"],[4,[38,1],[[32,0],\"unapprove\"],null],[12],[1,[30,[36,0],[\"unapprove\"],null]],[13],[2,\"\\n\\t\"],[10,\"p\"],[14,0,\"add-my-approval\"],[12],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"approve\"],null],[12],[1,[30,[36,0],[\"Add my approval\"],null]],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"span\"],[12],[1,[30,[36,0],[\"Unapproved\"],null]],[2,\" - \"],[13],[2,\"\\n\\t\"],[11,\"a\"],[24,6,\"#\"],[24,0,\"\"],[4,[38,1],[[32,0],\"approve\"],null],[12],[1,[30,[36,0],[\"approve\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"status\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/document-approval-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});