define("isolocity/pods/complaint/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _hasIncompleteActionI, _DS$Model$extend, _mutatorMap;

  function _defineEnumerableProperties(obj, descs) { for (var key in descs) { var desc = descs[key]; desc.configurable = desc.enumerable = true; if ("value" in desc) desc.writable = true; Object.defineProperty(obj, key, desc); } if (Object.getOwnPropertySymbols) { var objectSymbols = Object.getOwnPropertySymbols(descs); for (var i = 0; i < objectSymbols.length; i++) { var sym = objectSymbols[i]; var desc = descs[sym]; desc.configurable = desc.enumerable = true; if ("value" in desc) desc.writable = true; Object.defineProperty(obj, sym, desc); } } return obj; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, (_DS$Model$extend = {
    // Fillable fields & relationships
    initiator_user_id: _emberData.default.belongsTo('user'),
    initiated_at: _emberData.default.attr('isodate'),
    complaint_number: _emberData.default.attr('string'),
    company_report_id: _emberData.default.attr('number'),
    complaint_date: _emberData.default.attr('isodate'),
    part_id: _emberData.default.belongsTo('part'),
    part_type_id: _emberData.default.belongsTo('part-type'),
    error_code_id: _emberData.default.belongsTo('error-code'),
    complaint_category_id: _emberData.default.belongsTo('complaint-category'),
    complaint_source_id: _emberData.default.belongsTo('complaint-source'),
    complaint_impact_id: _emberData.default.belongsTo('complaint-impact'),
    source_customer_id: _emberData.default.belongsTo('customer'),
    source_supplier_id: _emberData.default.belongsTo('supplier'),
    received_by: _emberData.default.attr('string'),
    received_via: _emberData.default.attr('string'),
    strain_name: _emberData.default.attr('string'),
    batch_number: _emberData.default.attr('string'),
    is_adult_use: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    store_info: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    complainant_information: _emberData.default.attr('string'),
    complainant_full_name: _emberData.default.attr('string', {
      default: 'deprecated'
    }),
    complaint_impact_name: _emberData.default.attr('string'),
    complainant_address: _emberData.default.attr('string'),
    complainant_phone: _emberData.default.attr('string'),
    complainant_email: _emberData.default.attr('string'),
    complainant_fax: _emberData.default.attr('string'),
    qa_received_date: _emberData.default.attr('isodate'),
    complaint_category: _emberData.default.attr('string'),
    is_investigation_needed: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_product_returned: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_sample_examined: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    immediate_action: _emberData.default.attr('string'),
    department_id: _emberData.default.belongsTo('department'),
    investigation: _emberData.default.attr('string'),
    root_cause: _emberData.default.attr('string'),
    impact: _emberData.default.attr('string'),
    trend: _emberData.default.attr('string'),
    corrective_actions: _emberData.default.attr('string'),
    investigator_user_id: _emberData.default.belongsTo('user'),
    investigated_at: _emberData.default.attr('isodate'),
    is_capa_needed: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_reply_sent: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_product_replaced: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_recall_required: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    additional_comments: _emberData.default.attr('string'),
    manager_user_id: _emberData.default.belongsTo('user'),
    manager_approved_at: _emberData.default.attr('isodate'),
    closer_user_id: _emberData.default.belongsTo('user'),
    closed_at: _emberData.default.attr('isodate'),
    form_name: _emberData.default.attr('string'),
    form_rev_level: _emberData.default.attr('string')
  }, _defineProperty(_DS$Model$extend, "impact", _emberData.default.belongsTo('complaint-impact')), _defineProperty(_DS$Model$extend, "source", _emberData.default.belongsTo('complaint-source')), _defineProperty(_DS$Model$extend, "source_customer", _emberData.default.belongsTo('customer')), _defineProperty(_DS$Model$extend, "source_supplier", _emberData.default.belongsTo('supplier')), _defineProperty(_DS$Model$extend, "car", _emberData.default.hasMany('car')), _defineProperty(_DS$Model$extend, "ncr", _emberData.default.hasMany('ncr')), _defineProperty(_DS$Model$extend, "implementation_action_items", _emberData.default.hasMany('action-item')), _defineProperty(_DS$Model$extend, "abbreviated_report_id", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "complaint_source_name", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "files", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "action_items", _emberData.default.hasMany('action-item')), _defineProperty(_DS$Model$extend, "user_notes", _emberData.default.hasMany('note')), _defineProperty(_DS$Model$extend, "has_incomplete_action_items", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "has_unread_notes", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "owners", _emberData.default.hasMany('user')), _defineProperty(_DS$Model$extend, "users", _emberData.default.hasMany('user')), _defineProperty(_DS$Model$extend, "activities", _emberData.default.hasMany('activity')), _defineProperty(_DS$Model$extend, "initiatedAt", Ember.computed('initiated_at', function () {
    return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
  })), _defineProperty(_DS$Model$extend, "investigatedAt", Ember.computed('investigated_at', function () {
    return Ember.isEmpty(this.get('investigated_at')) ? '' : moment(this.get('investigated_at')).format("MMM D, YYYY h:mm A");
  })), _defineProperty(_DS$Model$extend, "approvedAt", Ember.computed('manager_approved_at', function () {
    return Ember.isEmpty(this.get('manager_approved_at')) ? '' : moment(this.get('manager_approved_at')).format("MMM D, YYYY h:mm A");
  })), _defineProperty(_DS$Model$extend, "closedAt", Ember.computed('closed_at', function () {
    return Ember.isEmpty(this.get('closed_at')) ? '' : moment(this.get('closed_at')).format("MMM D, YYYY h:mm A");
  })), _hasIncompleteActionI = "hasIncompleteActionItems", _mutatorMap = {}, _mutatorMap[_hasIncompleteActionI] = _mutatorMap[_hasIncompleteActionI] || {}, _mutatorMap[_hasIncompleteActionI].get = function () {
    return this.get('action_items').filterBy('completed', false).length > 0;
  }, _defineProperty(_DS$Model$extend, "requiredFiledsChanged", Ember.computed(function () {
    return 'default';
  })), _defineEnumerableProperties(_DS$Model$extend, _mutatorMap), _DS$Model$extend));

  _exports.default = _default;
});