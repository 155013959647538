define("isolocity/pods/audit/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    modelName: 'audit',
    model: function model() {
      this.startLoading();
      return {
        audit: this.store.query('audit', {
          per_page: _environment.default.APP.pagination,
          page: this.get('currentPage'),
          reload: true
        })
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.audit.then(function (audits) {
        _this.doneLoading();

        _this.setPageMetadata(audits);
      });
      model.auditTypes = this.store.findAll('audit-type');
    }
  });

  _exports.default = _default;
});