define("isolocity/mixins/part-line-items-validation-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hasValidPartLineItems: function hasValidPartLineItems() {
      var isValid = true;
      this.get('model.items').forEach(function (item) {
        isValid = isValid && item.isValid;
      });
      return isValid;
    },
    isValidShippedDateForLineItems: function isValidShippedDateForLineItems() {
      var isValid = true; // If is_ship_all_items set to false, make sure all itmes have shippdate before closing Shipment

      if (!this.get('model.is_ship_all_items')) {
        this.get('model.items').forEach(function (item) {
          isValid = isValid && !Ember.isEmpty(item.shipped_date);
        });
      }

      return isValid;
    }
  });

  _exports.default = _default;
});