define("isolocity/pods/components/settings-sidebar/orders/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    module: null,
    modules: Ember.inject.service('modules'),
    actions: {
      toggleReasonCode: function toggleReasonCode() {
        this.set('module.settings.is_reason_required_on_save', !this.get('module.settings.is_reason_required_on_save'));
        var reasonCodeSetting = this.get('module.settings.is_reason_required_on_save');
        this.set('module.settings.is_esignature_required_on_save', reasonCodeSetting);
        this.send('sincronizeWithOrdersModule');
      },
      toggleEsignature: function toggleEsignature() {
        this.set('module.settings.is_esignature_required_on_save', !this.get('module.settings.is_esignature_required_on_save'));
        var eSignatureSetting = this.get('module.settings.is_esignature_required_on_save');
        this.set('module.settings.is_reason_required_on_save', eSignatureSetting);
        this.send('sincronizeWithOrdersModule');
      },
      sincronizeWithOrdersModule: function sincronizeWithOrdersModule() {
        // Synchronize with the shipments module settings.
        var reasonCodeSetting = this.get('module.settings.is_reason_required_on_save');
        var eSignatureSetting = this.get('module.settings.is_esignature_required_on_save');
        var shipmentsModule = this.get('modules').getModuleBySlug('shipments');
        shipmentsModule.set('settings.is_reason_required_on_save', reasonCodeSetting);
        shipmentsModule.set('settings.is_esignature_required_on_save', eSignatureSetting);
        shipmentsModule.save();
      }
    }
  });

  _exports.default = _default;
});