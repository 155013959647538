define("isolocity/mixins/deviation-mixin", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/batch-records-mixin", "isolocity/mixins/has-departments-mixin", "isolocity/mixins/icon-list-mixin", "isolocity/mixins/required-fields-validation-mixin"], function (_exports, _actionItemMixin, _eSignatureMixin, _batchRecordsMixin, _hasDepartmentsMixin, _iconListMixin, _requiredFieldsValidationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _batchRecordsMixin.default, _hasDepartmentsMixin.default, _iconListMixin.default, _actionItemMixin.default, _requiredFieldsValidationMixin.default, {
    batchEntityType: 'deviation',
    modules: Ember.inject.service('modules'),
    defaultRequiredFields: ['deviation_number'],

    get module() {
      return this.get('modules').getModuleBySlug('deviations');
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('deviations', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredForRejections() {
      return this.get('modules').getModuleSetting('deviations', 'is_esignature_required_rejections', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('deviations', 'is_esignature_required_on_save', false);
    },

    get isDifferentApproversRequired() {
      return this.get('modules').getModuleSetting('deviations', 'is_require_different_approvers', false);
    },

    modelSettings: null,
    moduleSlug: 'deviations',
    onModelSettingsChange: Ember.observer('model.custom_form', function () {
      var model = this.get('model');
      var newSettings = !Ember.isBlank(model.get('custom_form')) ? JSON.parse(model.get('custom_form')) : JSON.parse('{ "settings": { "fields":{}, "sections":{} } }'); // Initiate settings key if it is missing in custom_form array

      if (!Ember.isBlank(newSettings) && Ember.isBlank(newSettings['settings'])) {
        newSettings['settings'] = JSON.parse('{ "fields":{}, "sections":{} }');
      } // Get settings from default module settings and update if applicable


      var oldSettings = this.get('module.settings');

      for (var key in oldSettings) {
        // In modules.settings property for this report, fields are under extra level called 'visible' or 'required'
        if (key == 'fields') {
          if (oldSettings['fields'].hasOwnProperty('visible')) {
            for (var childKey in oldSettings['fields']['visible']) {
              if (Ember.isBlank(newSettings['settings']['fields'][childKey])) {
                // Only update if setting does not exist on current report
                newSettings['settings']['fields'][childKey] = oldSettings['fields']['visible'][childKey];
              }
            }
          } else {
            for (var childKey in oldSettings['fields']['required']) {
              if (Ember.isBlank(newSettings['settings']['fields'][childKey])) {
                // Only update if setting does not exist on current report
                newSettings['settings']['fields'][childKey] = oldSettings['fields']['required'][childKey];
              }
            }
          }
        }

        if (key == 'sections') {
          for (var childKey in oldSettings['sections']) {
            if (Ember.isBlank(newSettings['settings']['sections'][childKey])) {
              // Only update if setting does not exist on current report
              newSettings['settings']['sections'][childKey] = oldSettings['sections'][childKey];
            }
          }
        } // For all other cases, fields are under current level


        if (!['fields', 'sections', 'required', 'helpers', 'configuration'].includes(key)) {
          if (Ember.isBlank(newSettings['settings']['fields'][key])) {
            // Only update if setting does not exist on current report
            newSettings['settings']['fields'][key] = oldSettings[key];
          }
        }
      }

      this.set('modelSettings', newSettings);
    }),
    actions: {
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.set('isFiveWhyAnalysis', true);
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.set('isFishboneAnalysis', true);
      },
      createActionItem: function createActionItem(item) {
        item.set('link', 'deviations.edit');
        item.set('entity_type', 'deviation');
        item.set('use_queue', true);

        if (this.get('isEdit')) {
          item.set('entity_id', this.get('model.id'));
        }

        item.set('code', 'deviation_implementation_plan');
        this.get('model.implementation_action_items').pushObject(item);

        if (this.get('isEdit')) {
          item.save();
        }
      }
    }
  });

  _exports.default = _default;
});