define("isolocity/pods/components/skeleton/data-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OiHkmbyg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row text-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ssc-wrapper\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ssc-tr\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ssc-tr\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ssc-tr\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "isolocity/pods/components/skeleton/data-table/template.hbs"
    }
  });

  _exports.default = _default;
});