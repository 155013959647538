define("isolocity/mixins/employee-list-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    actions: {
      archive: function archive(employee) {
        var fm = this.get('flashMessages'),
            intl = this.get('intl'),
            authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/employees/' + employee.get('id') + '/archive'),
          method: 'POST'
        }).then(function (data) {
          employee.set('is_active', false);
        }).fail(function (data) {
          employee.set('is_active', true);
          fm.danger(intl.t('mixins.employee-list.Archive Employee Error'));
        });
      },
      unarchive: function unarchive(employee) {
        var fm = this.get('flashMessages'),
            intl = this.get('intl'),
            authHeaders = this.get('authHeaders');
        employee.set('is_active', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/employees/' + employee.get('id') + '/archive'),
          method: 'DELETE'
        }).fail(function (data) {
          employee.set('is_active', false);
          fm.danger(intl.t('mixins.employee-list.Unarchive Employee Error'));
        });
      }
    }
  });

  _exports.default = _default;
});