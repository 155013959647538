define("isolocity/pods/frequency-entry/serializer", ["exports", "ember-data", "isolocity/mixins/custom-serializer-mixin"], function (_exports, _emberData, _customSerializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _customSerializerMixin.default, {
    attrs: {}
  });

  _exports.default = _default;
});