define("isolocity/pods/components/sidebar-xtoggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "udZH+dwU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,0],false,\"No\",\"Yes\",[30,[36,2],[[30,[36,1],[[32,0],\"toggleVisibleSetting\"],null]],null],false,[34,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isVisible\",\"action\",\"fn\",\"class\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/sidebar-xtoggle/template.hbs"
    }
  });

  _exports.default = _default;
});