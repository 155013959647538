define("isolocity/pods/suppliers/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _authenticationRoutingMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _userOrganizationMixin.default, {
    model: function model() {
      return this.store.createRecord('supplier', {
        type: 'supplier',
        is_active: true
      });
    },
    afterModel: function afterModel(model, transition) {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));

      if (!Ember.isBlank(user)) {
        model.get('owners').pushObject(user);
      }

      model.set('allUsers', this.store.findAll('user'));
    }
  });

  _exports.default = _default;
});