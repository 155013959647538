define("isolocity/pods/components/companies-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Zbv+zFl",
    "block": "{\"symbols\":[\"company\"],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"Companies\"],[13],[2,\"\\n  \"],[10,\"table\"],[14,0,\"data-table table-with-inputs\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[1,[30,[36,3],[\"Status\"],null]],[13],[2,\"\\n        \"],[10,\"th\"],[12],[1,[30,[36,3],[\"Name\"],null]],[13],[2,\"\\n        \"],[10,\"th\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4,[\"companies\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"td\",\"companies.edit\",[32,1]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],null,[[\"status\"],[[32,1,[\"is_active\"]]]]]]],\"parameters\":[]}]]],[2,\"\\n          \"],[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"td\",\"companies.edit\",[32,1]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n          \"],[10,\"td\"],[14,0,\"remove\"],[12],[2,\"\\n              \"],[11,\"button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"removeCompanyFromGroup\",[32,1]],null],[12],[2,\"x\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"current-status\",\"link-to\",\"action\",\"t\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/companies-table/template.hbs"
    }
  });

  _exports.default = _default;
});