define("isolocity/pods/oosforms/edit/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/oosform-mixin", "isolocity/mixins/file-download-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/activity-log-mixin"], function (_exports, _environment, _oosformMixin, _fileDownloadMixin, _requiredFieldsMixin, _historyHandlerMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _uploadFilesMixin, _pdfGenerationMixin, _ownersAndSubscribersMixin, _ajaxRequestMixin, _eSignatureMixin, _activityLogMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileDownloadMixin.default, _activityLogMixin.default, _requiredFieldsMixin.default, _historyHandlerMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _uploadFilesMixin.default, _pdfGenerationMixin.default, _oosformMixin.default, _ownersAndSubscribersMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, {
    sideMenu: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    persistence: Ember.inject.service(),
    intl: Ember.inject.service(),
    id_prefix: 'edit_',
    requiredFields: ['oosform_number'],
    PdfTitle: Ember.computed('model.oosform_number', function () {
      return 'OOS Report - OOS# ' + this.get('model.oosform_number');
    }),
    isEdit: true,
    isSaving: false,
    isReopening: false,
    isCreatingReport: false,
    noteEntityType: 'oosform',
    actionLink: 'oosforms.edit',
    actionEntityType: 'oosform',
    actionTitleOptions: [],
    isFormLocked: Ember.computed('model.id', 'isOwnedByCurrentUser', 'model.isClosed', function () {
      if (this.get('model.isClosed') || !this.get('permissions').hasRoleAtLeast('production')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isImplementationPlanLocked: Ember.computed('isOwnedByCurrentUser', 'model.is_closed', function () {
      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isResultSectionLocked: Ember.computed('model.id', 'model.processOwnerAuthorizedAt', 'isOwnedByCurrentUser', function () {
      if (this.get('model.processOwnerAuthorizedAt')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isSampleTestSectionLocked: Ember.computed('model.id', 'model.permitterApprovedAt', 'isOwnedByCurrentUser', function () {
      if (this.get('model.permitterApprovedAt')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isCorrectiveActionSectionLocked: Ember.computed('model.id', 'model.inspectorAuthorizedAt', 'isOwnedByCurrentUser', function () {
      if (this.get('model.inspectorAuthorizedAt')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isLaboratoryInvestigationSectionLocked: Ember.computed('model.id', 'model.budgetApprovedAt', 'isOwnedByCurrentUser', function () {
      if (this.get('model.budgetApprovedAt')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isBudgetFormLocked: Ember.computed('model.id', 'model.budget_user_id.id', 'isFormLocked', function () {
      return this.get('isFormLocked') || !Ember.isBlank(this.get('model.budget_user_id.id'));
    }),
    isRecommenderFormLocked: Ember.computed('model.id', 'model.recommender_user_id.id', 'isFormLocked', function () {
      return this.get('isFormLocked') || !Ember.isBlank(this.get('model.recommender_user_id.id'));
    }),
    isPermitterFormLocked: Ember.computed('model.id', 'model.permitter_user_id.id', 'isFormLocked', function () {
      return this.get('isFormLocked') || !Ember.isBlank(this.get('model.permitter_user_id.id'));
    }),
    canEditOosforms: Ember.computed('model.id', 'isOwnedByCurrentUser', 'model.closed_at', function () {
      if (this.get('permissions').hasRoleAtLeast('manager') && this.get('authManager.session.currentUser.can_reopen_reports')) {
        return true;
      }

      if (!Ember.isBlank(this.get('model.closed_at'))) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser');
    }),
    canOpenAndCloseOosforms: Ember.computed('model.id', 'isOwnedByCurrentUser', function () {
      if (this.get('permissions').hasRoleAtLeast('manager') && this.get('authManager.session.currentUser.can_reopen_reports')) {
        return true;
      }

      return this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser');
    }),
    canUpgradeOosformsToCars: Ember.computed('model.id', 'model.car.id', 'isOwnedByCurrentUser', function () {
      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.car.id')) && this.get('permissions').hasRoleAtLeast('production');
    }),
    canUpgradeOosformsToCcrs: Ember.computed('model.ccr.id', 'isOwnedByCurrentUser', function () {
      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.ccr.id')) && this.get('permissions').hasRoleAtLeast('production');
    }),
    canUpgradeOosformsToDeviations: Ember.computed('model.deviation.id', 'isOwnedByCurrentUser', function () {
      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.deviation.id')) && this.get('permissions').hasRoleAtLeast('production');
    }),
    canUpgradeOosformsToNCR: Ember.computed('model.ncr.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('production')) {
        return false;
      }

      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.ncr.id'));
    }),
    canDeleteOosforms: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('oosforms', 'is_reason_required_on_save', false);
    },

    actions: {
      save: function save(type) {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          var model = this.get('model');
          this.set('isSaving', true);

          if (type === 'close') {
            this.send('close');
            return;
          }

          model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (oosform) {
            _this.send('saveAttachments', oosform, false, _this.get('eSignaturePassword'), _this.get('eSignatureReason'));

            _this.send('clearEsignature');
          }, function (error) {
            _this.set('isSaving', false);

            _this.send('clearEsignature');

            fm.danger(error.errors);
          });
        } else {
          this.send('showErrors');
        }
      },
      close: function close() {
        var _this2 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages'),
            payload = oosform.serialize();
        payload['password'] = this.get('eSignaturePassword');
        payload['esignature_reason'] = this.get('eSignatureReason');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/close'),
          contentType: "application/json",
          data: JSON.stringify(payload),
          method: 'POST'
        }).then(function (data) {
          oosform.set('closed_at', new moment());

          _this2.send('saveAttachments', oosform, false, _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));
        }).fail(function (data) {
          fm.danger(_this2.get('intl').t('Please ensure your password is correct and that all departmental e-signatures have been provided'));
        }).always(function () {
          _this2.set('isSaving', false);

          _this2.set('eSignaturePassword', '');
        });
      },
      reopen: function reopen() {
        var _this3 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isReopening', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/reopen'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          oosform.set('closed_at', null);
          oosform.set('closed_user_id', null);

          _this3.get('model').reload();

          fm.success(_this3.get('intl').t('The report has been re-opened'));
        }).fail(function () {
          fm.danger(_this3.get('intl').t('An error occurred while trying to re-open the report'));
        }).always(function () {
          _this3.set('isReopening', false);
        });
      },
      authorizeBudget: function authorizeBudget() {
        var _this4 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this4.get('model').save();

          _this4.send('doBudgetAuthorization');

          _this4.set('authorizationPromise', null);
        }, function () {
          _this4.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      authorizeRecommender: function authorizeRecommender() {
        var _this5 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this5.get('model').save();

          _this5.send('doRecommenderAuthorization');

          _this5.set('authorizationPromise', null);
        }, function () {
          _this5.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      authorizePermitter: function authorizePermitter() {
        var _this6 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this6.get('model').save();

          _this6.send('doPermitterAuthorization');

          _this6.set('authorizationPromise', null);
        }, function () {
          _this6.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      authorize: function authorize(userType) {
        var _this7 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this7.send('doAuthorization', userType, 'approve');

          _this7.set('authorizationPromise', null);
        }, function () {
          _this7.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      reject: function reject(userType) {
        var _this8 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this8.send('doAuthorization', userType, 'reject');

          _this8.set('authorizationPromise', null);
        }, function () {
          _this8.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      doAuthorization: function doAuthorization(userType, authType) {
        var _this9 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (userType === 'owner') {
          this.set('isAddingProcessOwnerAuthorization', true);
        } else if (userType === 'inspector') {
          this.set('isAddingInspectorAuthorization', true);
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/oosform/' + this.get('model.id') + '/authorize',
          data: {
            user_type: userType,
            authorization_type: authType,
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function () {
          _this9.get('model').reload().then(function () {
            fm.success(intl.t('Your authorization has been added to this report'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to add your authorization, please ensure your password is correct and all required departmental e-signatures are complete'));
        }).always(function () {
          _this9.set('isAddingProcessOwnerAuthorization', false);

          _this9.set('isAddingInspectorAuthorization', false);

          _this9.send('clearEsignature');
        });
      },
      doBudgetAuthorization: function doBudgetAuthorization() {
        var _this10 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/budget/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function () {
          _this10.get('model').reload().then(function () {
            fm.success(intl.t('Your authorization has been added for this section'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to add your authorization, please ensure your password is correct'));
        }).always(function () {
          _this10.set('isAddingBudgetAuthorization', false);

          _this10.send('clearEsignature');
        });
      },
      doRecommenderAuthorization: function doRecommenderAuthorization() {
        var _this11 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/recommender/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function () {
          _this11.get('model').reload().then(function () {
            fm.success(intl.t('Your authorization has been added for this section'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to add your authorization, please ensure your password is correct'));
        }).always(function () {
          _this11.set('isAddingRecommenderAuthorization', false);

          _this11.set('eSignaturePassword', '');
        });
      },
      doPermitterAuthorization: function doPermitterAuthorization() {
        var _this12 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/permitter/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function () {
          _this12.get('model').reload().then(function () {
            fm.success(intl.t('Your authorization has been added for this section'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to add your authorization, please ensure your password is correct'));
        }).always(function () {
          _this12.set('isAddingPermitterAuthorization', false);

          _this12.set('eSignaturePassword', '');
        });
      },
      undoAuthorization: function undoAuthorization(userType) {
        var _this13 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (userType === 'owner') {
          this.set('isAddingProcessOwnerAuthorization', true);
        } else if (userType === 'inspector') {
          this.set('isAddingInspectorAuthorization', true);
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/oosform/' + this.get('model.id') + '/authorize',
          data: {
            user_type: userType,
            authorization_type: 'approve',
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function () {
          _this13.get('model').reload().then(function () {
            fm.success(intl.t('oosforms.other.Authorization Report'));
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger('You are not authorized to perform this action');
          }
        }).always(function () {
          _this13.set('isAddingProcessOwnerAuthorization', false);

          _this13.set('isAddingInspectorAuthorization', false);

          _this13.send('clearEsignature');
        });
      },
      undoBudgetAuthorization: function undoBudgetAuthorization() {
        var _this14 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/budget/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function () {
          _this14.get('model').reload().then(function () {
            fm.success(intl.t('ooforms.other.Authorization Removed'));
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger(intl.t('oosforms.other.Authorized Action'));
          }
        }).always(function () {
          _this14.set('isAddingBudgetAuthorization', false);

          _this14.send('clearEsignature');
        });
      },
      undoRecommenderAuthorization: function undoRecommenderAuthorization() {
        var _this15 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/recommender/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function () {
          _this15.get('model').reload().then(function () {
            fm.success(intl.t('Your authorization has been removed for this section'));
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger(intl.t('oosforms.other.Authorized Action'));
          }
        }).always(function () {
          _this15.set('isAddingRecommenderAuthorization', false);

          _this15.set('eSignaturePassword', '');
        });
      },
      undoPermitterAuthorization: function undoPermitterAuthorization() {
        var _this16 = this;

        var authHeaders = this.get('authHeaders'),
            oosform = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/permitter/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function () {
          _this16.get('model').reload().then(function () {
            fm.success(intl.t('Your authorization has been removed for this section'));
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger(intl.t('oosforms.other.Authorized Action'));
          }
        }).always(function () {
          _this16.set('isAddingPermitterAuthorization', false);

          _this16.set('eSignaturePassword', '');
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(oosform) {
        this.set('isSaving', false);
        oosform.reload();
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('The changes to this report have been saved successfully'));
      },
      createReport: function createReport() {
        var oosform = this.get('model');
        var report = {};
        this.set('isCreatingReport', true);
        var reportContent = this.getOosformReportContent(oosform);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createReportNew: function createReportNew() {
        var _this17 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var oosform = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/oosform/' + oosform.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            var id = _this17.get('model.id');

            var model = 'oosform';

            var oosformNumber = _this17.get('model.oosform_number');

            var description = "Oosform Report #".concat(oosformNumber, " has been downloaded");

            _this17.send('newActivityLog', id, model, description);

            _this17.send('downloadPdfFile', data, 'OOS_' + _this17.get('model.oosform_number'));
          } else {
            _this17.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this17.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this17.set('isCreatingReport', false);
        });
      },
      createReportNewWithAttachments: function createReportNewWithAttachments() {
        var _this18 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.send('createReportNew', isDownload);
        var car = this.get('model');
        var files = car.get('files');
        files.forEach(function (file, i) {
          // Delay download between files for browser optimization
          var timeout = 500 * i;
          setTimeout(function () {
            _this18.send('downloadFile', file);
          }, timeout);
        });
      },
      upgradeToCar: function upgradeToCar() {
        var model = this.get('model');
        var route = 'corrective-actions.new';
        this.get('history').setRoute('oosforms');
        var car = this.store.createRecord('car', {
          oosform_id: model,
          is_closed: false,
          date_opened: new Date(),
          description: model.get('description')
        });
        this.get('persistence').add({
          route: route,
          model: car
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      upgradeToChangeControl: function upgradeToChangeControl() {
        var model = this.get('model');
        var route = 'ccrs.new';
        this.get('history').setRoute('oosforms');
        var ccr = this.store.createRecord('ccr', {
          causer_id: model.get('id'),
          causer_type: 'oosform'
        });
        this.get('persistence').add({
          route: route,
          model: ccr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      upgradeToDeviation: function upgradeToDeviation() {
        var model = this.get('model');
        var route = 'deviations.new';
        this.get('history').setRoute('oosforms');
        var deviation = this.store.createRecord('deviation', {
          causer_id: model.get('id'),
          causer_type: 'oosform'
        });
        this.get('persistence').add({
          route: route,
          model: deviation
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      upgradeToNCR: function upgradeToNCR() {
        var model = this.get('model');
        var route = 'ncrs.new';
        this.get('history').setRoute('oosforms');
        var ncr = this.store.createRecord('ncr', {
          causer_id: model.get('id'),
          causer_type: 'oosform'
        });
        this.get('persistence').add({
          route: route,
          model: ncr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      remove: function remove() {
        var _this19 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this19.get('model').destroyRecord().then(function () {
            _this19.transitionToRoute('oosforms');
          });

          _this19.set('deletePromise', null);
        }, function () {
          _this19.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      }
    }
  });

  _exports.default = _default;
});