define("isolocity/pods/action-items/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/action-item-search-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _actionItemSearchMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _actionItemSearchMixin.default, _paginatedRouteMixin.default, {
    isVisibleAllUsers: false,
    modelName: 'action-item',
    keyword: null,
    assigneeId: null,
    assigneeType: null,
    model: function model() {
      this.startLoading();
      return {
        actionItems: this.store.query('action-item', {
          per_page: _environment.default.APP.pagination,
          page: this.get('currentPage'),
          reload: true,
          completed: false,
          keyword: this.get('keyword'),
          assigneeId: this.get('assigneeId'),
          assigneeType: this.get('assigneeType')
        }),
        allUsers: this.store.findAll('user'),
        allEmployees: this.store.findAll('employee')
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.actionItems.then(function (items) {
        _this.doneLoading();

        _this.setPageMetadata(items);
      });
    }
  });

  _exports.default = _default;
});