define("isolocity/pods/components/action-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5qROL8yW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"status pass text--green\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-check-circle-o\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"Resolved\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"status fail text--red\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-times-circle-o\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"Overdue\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"status inactive text--yellow\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"status__icon status__icon--no-space\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"Open\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"isOverdue\",\"if\",\"isResolved\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/action-status/template.hbs"
    }
  });

  _exports.default = _default;
});