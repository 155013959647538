define("isolocity/pods/equipment-type/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    name: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    frequency: _emberData.default.attr('number'),
    duration: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('isodate'),
    updated_at: _emberData.default.attr('isodate')
  });

  _exports.default = _default;
});