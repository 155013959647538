define("isolocity/pods/components/companies-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    selectedCompany: null,
    parentEntityName: 'list',
    actions: {
      removeCompanyFromGroup: function removeCompanyFromGroup(company) {
        var _this = this;

        var fm = _this.get('flashMessages'),
            intl = this.get('intl');

        this.get('model.companies').removeObject(company);

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            fm.success(intl.t('The team has been removed from the') + _this.get('parentEntityName') + '.');
          });
        } else {
          fm.success(intl.t('The team has been removed from the') + this.get('parentEntityName') + '.');
        }

        this.sendAction('removeItem');
      }
    }
  });

  _exports.default = _default;
});