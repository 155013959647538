define("isolocity/pods/inspections/equipment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vA7yx8lp",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,13],null,[[\"modelName\",\"isModelLoading\",\"model\"],[\"inspection\",[35,12],[35,11]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-three-quarter\"],[12],[2,\"\\n            \"],[19,\"inspections/search\",[1]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-quarter col-create-btn\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"class\",\"route\",\"models\"],[\"btn btn-go pull-right\",\"inspections.new-equipment\",[30,[36,1],[\" \",\" \"],null]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],[\"create_equipment_inspection\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,10],null,[[\"listType\",\"currentPage\",\"totalPages\",\"totalRecords\",\"previousPage\",\"nextPage\",\"filterSuffix\"],[[30,[36,0],[\"inspections_list_name\"],null],[35,9],[35,8],[35,7],[30,[36,6],[[32,0],\"previousPage\"],null],[30,[36,6],[[32,0],\"nextPage\"],null],[30,[36,5],[[35,4],[35,3]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n    \"],[19,\"inspections/list\",[1]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":true,\"upvars\":[\"t\",\"array\",\"link-to\",\"searchDescriptor\",\"searchTypeDescriptor\",\"concat\",\"action\",\"totalRecords\",\"totalPages\",\"currentPage\",\"pagination\",\"sortedModel\",\"isModelLoading\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/inspections/equipment/template.hbs"
    }
  });

  _exports.default = _default;
});