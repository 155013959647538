define("isolocity/pods/components/document-linked-trainings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XFgKLw1J",
    "block": "{\"symbols\":[\"schedule\",\"index\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"app--card none\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"training-schedules.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,5,\"color: #333; padding-right: 1em;\"],[12],[1,[32,1,[\"training_program_id\",\"title\"]]],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"percentage_complete\"]],0.9],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,5,\"color: #85c51f;\"],[12],[1,[30,[36,0],[\"Complete\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[10,\"span\"],[14,5,\"color: #e7ae02;\"],[12],[1,[30,[36,0],[\"Incomplete\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"greater-than\",\"if\",\"link-to\",\"schedules\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/document-linked-trainings/template.hbs"
    }
  });

  _exports.default = _default;
});