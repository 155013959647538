define("isolocity/mixins/inspectlet-app-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    // This mixin is to initialize the Inspectlet External Service
    // - Inspectlet offers sessions recording. This should substitude WalkMe
    // - Web: https://www.inspectlet.com/?gclid=CjwKCAiAqt-dBhBcEiwATw-ggCkrpjjB2vw_wzh8cFi3t63nHFb_GB8xntjFrq2wZ8tqEfjswIqbshoCNncQAvD_BwE
    initInspectletApp: function initInspectletApp() {
      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        // -- Begin Inspectlet Asynchronous Code --
        window.__insp = window.__insp || [];

        __insp.push(['wid', 1102599131]);

        var ldinsp = function ldinsp() {
          if (typeof window.__inspld != "undefined") return;
          window.__inspld = 1;
          var insp = document.createElement('script');
          insp.type = 'text/javascript';
          insp.async = true;
          insp.id = "inspsync";
          insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1102599131&r=' + Math.floor(new Date().getTime() / 3600000);
          var x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(insp, x);
        };

        setTimeout(ldinsp, 0); // -- End Inspectlet Asynchronous Code --
      }
    }
  });

  _exports.default = _default;
});