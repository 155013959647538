define("isolocity/pods/suppliers/index/controller", ["exports", "isolocity/mixins/reporting-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _reportingSidebarMixin, _downloadableReportsMixin, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportingSidebarMixin.default, _downloadableReportsMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    intl: Ember.inject.service(),
    search: Ember.inject.service('search.suppliers'),
    permissions: Ember.inject.service('user-permissions'),
    isActive: {},
    statusOptions: [],
    init: function init() {
      this.isActive = {
        id: true,
        name: this.get('intl').t('Active')
      }, this.statusOptions = [{
        id: false,
        name: this.get('intl').t('Active')
      }, {
        id: true,
        name: this.get('intl').t('Inactive')
      }], this._super.apply(this, arguments);
    },
    canCreateSuppliers: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('user');
    }),
    newPath: Ember.computed(function () {
      if (this.get('canCreateSuppliers') === true) {
        return "suppliers.new";
      }

      return false;
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setIsActive(this.get('isActive.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('isActive', {
          id: true,
          name: 'Active'
        });
      }
    }
  });

  _exports.default = _default;
});