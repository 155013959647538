define("isolocity/pods/receiving-inspection/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inspection_number: _emberData.default.attr('string'),
    po_number: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    serial_number: _emberData.default.attr('string'),
    is_closed: _emberData.default.attr('boolean'),
    is_quarantined: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr(),
    total_order_quantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    requested_shipment_arrival_date: _emberData.default.attr('isodate'),
    actual_arrival_date: _emberData.default.attr('isodate'),
    is_receive_all_items: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    supplier_id: _emberData.default.belongsTo('supplier'),
    items: _emberData.default.hasMany('receiving-inspection.item'),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    is_inspectable: _emberData.default.attr('boolean'),
    existing_secondary_lot_numbers: _emberData.default.attr(),
    files: _emberData.default.attr(),
    assigned_supplier: _emberData.default.attr(),
    supplier: _emberData.default.attr(),
    //computed
    displayName: Ember.computed('id', function () {
      return "Inspection #".concat(this.get('id'));
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});