define("isolocity/pods/inspections/activities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ILv3EtQ9",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,12],null,[[\"modelName\",\"isModelLoading\",\"model\"],[\"inspection\",[35,11],[35,10]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-three-quarter\"],[12],[2,\"\\n            \"],[19,\"inspections/search\",[1]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-quarter col-create-btn\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-go pull-right\",\"inspections.activity\"]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],[\"create_activity_inspection\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,9],null,[[\"listType\",\"currentPage\",\"totalPages\",\"totalRecords\",\"previousPage\",\"nextPage\",\"filterSuffix\"],[[30,[36,0],[\"inspections_list_name\"],null],[35,8],[35,7],[35,6],[30,[36,5],[[32,0],\"previousPage\"],null],[30,[36,5],[[32,0],\"nextPage\"],null],[30,[36,4],[[35,3],[35,2]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n    \"],[19,\"inspections/list\",[1]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":true,\"upvars\":[\"t\",\"link-to\",\"searchDescriptor\",\"searchTypeDescriptor\",\"concat\",\"action\",\"totalRecords\",\"totalPages\",\"currentPage\",\"pagination\",\"sortedModel\",\"isModelLoading\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/inspections/activities/template.hbs"
    }
  });

  _exports.default = _default;
});