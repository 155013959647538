define("isolocity/pods/components/tolerance-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isNotSet::tolerance-selector--collapsed'],
    init: function init() {
      this.set('isEdittable', true);

      this._super.apply(this, arguments);
    },
    isExpanded: Ember.computed('expanded', function () {
      return this.get('expanded') !== false;
    }),
    isPassFail: Ember.computed('model.pass_or_fail', function () {
      return this.get('model.pass_or_fail');
    }),
    isAcceptableUnacceptable: Ember.computed('model.acceptable_or_unacceptable', function () {
      ;
      return this.get('model.acceptable_or_unacceptable');
    }),
    isYesNo: Ember.computed('model.yes_or_no', function () {
      ;
      return this.get('model.yes_or_no');
    }),
    isUnitValue: Ember.computed('model.positive', 'model.negative', 'model.tolerance', function () {
      var units = (this.get('model.positive') || '') + (this.get('model.negative') || '');
      return units.length !== 0;
    }),
    isTextInput: Ember.computed('model.is_text_input', function () {
      return this.get('model.is_text_input');
    }),
    isTextInputOptional: Ember.computed('model.is_text_input_optional', function () {
      return this.get('model.is_text_input_optional');
    }),
    isRadioGroup: Ember.computed('model.radio_group', function () {
      return this.get('model.radio_group');
    }),
    isRadioGroupSelected: Ember.computed('model.radio_group_selected', function () {
      return this.get('model.radio_group_selected');
    }),
    isNotSet: Ember.computed('isPassFail', 'isUnitValue', 'isTextInput', 'isTextInputOptional', 'isAcceptableUnacceptable', 'isYesNo', 'isRadioGroup', function () {
      return !this.get('isUnitValue') && !this.get('isPassFail') && !this.get('isTextInput') && !this.get('isTextInputOptional') && !this.get('isAcceptableUnacceptable') && !this.get('isYesNo') && !this.get('isRadioGroup');
    }),
    actions: {
      selectPassFail: function selectPassFail() {
        this.toggleProperty('model.pass_or_fail');
      },
      selectTextValue: function selectTextValue() {
        this.toggleProperty('model.is_text_input');
      },
      selectTextOptionalValue: function selectTextOptionalValue() {
        this.toggleProperty('model.is_text_input_optional');
      },
      selectAcceptableUnacceptable: function selectAcceptableUnacceptable() {
        this.toggleProperty('model.acceptable_or_unacceptable');
      },
      selectYesNo: function selectYesNo() {
        this.toggleProperty('model.yes_or_no');
      },
      selectRadioGroup: function selectRadioGroup() {
        this.toggleProperty('model.radio_group');
      },
      clearTolerance: function clearTolerance() {
        this.get('model').setProperties({
          positive: '',
          negative: '',
          tolerance: undefined
        });
      }
    }
  });

  _exports.default = _default;
});