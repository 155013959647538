define("isolocity/pods/components/side-menu-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sideMenu: Ember.inject.service(),
    classNameBindings: ['side'],
    classNames: ['side-menu-toggle'],
    side: 'left',
    click: function click() {
      var _this = this;

      var name = Ember.get(this, "name");

      if (!Ember.isEmpty(this.get('model'))) {
        this.set('model.isReloading', true); // Load Action Items and Notes

        if (Ember.computed.equal(name, 'action-notes') && name == 'action-notes') {
          var _this$get;

          var loadActionItems = (_this$get = this.get('model.loadActionItems')) !== null && _this$get !== void 0 ? _this$get : 1;
          this.set('model.loadActionItems', loadActionItems + 1);
        } // Load Activity Logs
        else if (Ember.computed.equal(name, 'activity-log') && name == 'activity-log') {
            var _this$get2;

            var isReloadingActivities = (_this$get2 = this.get('model.isReloadingActivities')) !== null && _this$get2 !== void 0 ? _this$get2 : 1;
            this.set('model.isReloadingActivities', isReloadingActivities + 1);
          } // Load Any other
          else {
              this.get('model').reload().then(function () {
                _this.set('isReloading', false);
              });
            }
      } // NOTE: Unlock sidemenu. ( This is to prevent malfunctioning swipe gestures in mobile and tab key).
      // It malfunctions because the menu opens randomly and distubs the users.


      Ember.get(this, 'sideMenu').set('canOpen', true);
      var dalayMenuOpen = 100; // canOpen and isOpen variables cannot be updated simultaneously, sidemenu service gives error

      setTimeout(function () {
        // Proceed to open menu
        Ember.get(_this, 'sideMenu').toggle(Ember.get(_this, "name"), Ember.get(_this, "defaultTab"));
      }, dalayMenuOpen);
    }
  });

  _exports.default = _default;
});