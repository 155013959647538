define("isolocity/pods/inspections/activities/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'inspections.activities',
    search: Ember.inject.service('search.inspections-activities'),
    modelName: ['inspection', 'inspection.sample', 'inspection.sample.result', 'drawing.characteristic'],
    model: function model(params) {
      this.startLoading();
      var fromDate = !Ember.isEmpty(this.get('search').getFromDate()) ? this.get('search').getFromDate() : null;
      var toDate = !Ember.isEmpty(this.get('search').getToDate()) ? this.get('search').getToDate() : null;
      var sortBy = this.get('search').getSortBy() === 'Recently Updated' ? 'updated_at' : null;
      var filters = this.getQueryFilters();
      filters['per_page'] = _environment.default.APP.pagination;
      filters['page'] = this.get('currentPage');
      filters['keyword'] = this.get('search').getKeyword();
      filters['sort_by'] = sortBy;
      filters['is_activity'] = true;
      filters['type'] = this.get('search').getType();
      filters['status'] = this.get('search').getStatus();
      filters['department'] = this.get('search').getDepartmentId();
      return {
        inspections: this.store.query('inspection', filters),
        departments: this.store.findAll('department')
      };
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      model.inspections.then(function (inspections) {
        _this.doneLoading();

        _this.setPageMetadata(inspections);
      });
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.set('currentPage', 1);
        this.refresh();
      },
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('inspections');
        }
      }
    }
  });

  _exports.default = _default;
});