define("isolocity/pods/company-groups/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EPX71Kz3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n  \"],[1,[34,7]],[2,\"\\n  \\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,1],[[35,0],\"name\"],null]]]],[12],[1,[30,[36,2],[\"company-groups.labels.Team Group Name\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"value\",\"id\",\"placeholder\"],[[35,4,[\"name\"]],[30,[36,1],[[35,0],\"name\"],null],[30,[36,2],[\"company-groups.labels.Team Group Name\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,1],[[35,0],\"status\"],null]]]],[12],[1,[30,[36,2],[\"company-groups.labels.Status\"],null]],[13],[2,\"\\n\\n    \"],[1,[30,[36,9],null,[[\"id\",\"status\",\"onActiveChange\"],[[30,[36,1],[[35,0],\"status\"],null],[35,4,[\"is_active\"]],\"updateStatus\"]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[6,[37,11],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\t    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n\\t      \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,1],[[35,0],\"add_company\"],null]]]],[12],[1,[30,[36,2],[\"company-groups.labels.Add Team\"],null]],[13],[2,\"\\n\\t      \"],[1,[30,[36,6],null,[[\"value\",\"name\",\"model\",\"items\",\"allowBlank\",\"preventNew\"],[[35,5],\"all_companies\",[35,4],[35,3],false,true]]]],[2,\"\\n\\t    \"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"id_prefix\",\"concat\",\"t\",\"remainingCompanies\",\"model\",\"selectedCompany\",\"auto-complete\",\"flash-messages\",\"input\",\"active-inactive-toggle\",\"isEdit\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/company-groups/-form/template.hbs"
    }
  });

  _exports.default = _default;
});