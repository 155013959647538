define("isolocity/pods/documents/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'documents.index',
    search: Ember.inject.service('search.documents'),
    modelName: ['document', 'document.approval'],
    model: function model() {
      this.startLoading();
      return {
        documents: this.store.query('document', {
          per_page: _environment.default.APP.pagination,
          page: this.get('currentPage'),
          keyword: this.get('search').getKeyword(),
          type: this.get('search').getType(),
          department: this.get('search').getDepartment(),
          status: this.get('search').getStatus()
        })
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.documents.then(function (documents) {
        _this.doneLoading();

        _this.setPageMetadata(documents);
      });
      model.documentTypes = this.store.findAll('document-type');
      model.departments = this.store.findAll('department');
    },
    actions: {
      doSearchDocuments: function doSearchDocuments() {
        this.set('currentPage', 1);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});