define("isolocity/pods/drawings/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/characteristic-sorting-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _characteristicSortingMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _ajaxRequestMixin, _eSignatureMixin, _ownersAndSubscribersMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _module, _isEsignatureRequired, _isValid, _hasUnsavedCharacteri, _Ember$Controller$ext, _mutatorMap;

  function _defineEnumerableProperties(obj, descs) { for (var key in descs) { var desc = descs[key]; desc.configurable = desc.enumerable = true; if ("value" in desc) desc.writable = true; Object.defineProperty(obj, key, desc); } if (Object.getOwnPropertySymbols) { var objectSymbols = Object.getOwnPropertySymbols(descs); for (var i = 0; i < objectSymbols.length; i++) { var sym = objectSymbols[i]; var desc = descs[sym]; desc.configurable = desc.enumerable = true; if ("value" in desc) desc.writable = true; Object.defineProperty(obj, sym, desc); } } return obj; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _characteristicSortingMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, _ownersAndSubscribersMixin.default, _userOrganizationMixin.default, (_Ember$Controller$ext = {
    intl: Ember.inject.service(),
    constants: Ember.inject.service('constants'),
    modules: Ember.inject.service('modules')
  }, _defineProperty(_Ember$Controller$ext, "intl", Ember.inject.service()), _defineProperty(_Ember$Controller$ext, "isEdit", true), _defineProperty(_Ember$Controller$ext, "isSaving", false), _defineProperty(_Ember$Controller$ext, "orderChange", false), _defineProperty(_Ember$Controller$ext, "noteEntityType", 'drawing'), _defineProperty(_Ember$Controller$ext, "actionLink", 'drawings.edit'), _defineProperty(_Ember$Controller$ext, "actionEntityType", 'drawing'), _defineProperty(_Ember$Controller$ext, "itemToogleOptions", ['Part or Activity', 'Equipment']), _module = "module", _mutatorMap = {}, _mutatorMap[_module] = _mutatorMap[_module] || {}, _mutatorMap[_module].get = function () {
    return this.get('modules').getModuleBySlug('drawings');
  }, _isEsignatureRequired = "isEsignatureRequiredForApprovals", _mutatorMap[_isEsignatureRequired] = _mutatorMap[_isEsignatureRequired] || {}, _mutatorMap[_isEsignatureRequired].get = function () {
    return this.get('modules').getModuleSetting('drawings', 'is_esignature_required_approvals', false);
  }, _defineProperty(_Ember$Controller$ext, "actionTitleOptions", Ember.computed(function () {
    var drawingText = this.get('constants').getModuleText(this.get('intl').t('modules.singular.drawing'), "lower");
    return [this.get('intl').t('Create') + ' ' + drawingText, this.get('intl').t('Review') + ' ' + drawingText, this.get('intl').t('Revise') + ' ' + drawingText, this.get('intl').t('Approve') + ' ' + drawingText];
  })), _defineProperty(_Ember$Controller$ext, "permissions", Ember.inject.service('user-permissions')), _defineProperty(_Ember$Controller$ext, "authManager", Ember.inject.service('session')), _defineProperty(_Ember$Controller$ext, "requiredFields", ['name', 'identification']), _defineProperty(_Ember$Controller$ext, "hasCharacteristics", Ember.computed('model.characteristics.length', function () {
    return this.get('model.characteristics.length') > 0;
  })), _isValid = "isValid", _mutatorMap[_isValid] = _mutatorMap[_isValid] || {}, _mutatorMap[_isValid].get = function () {
    return this.get('hasRequiredFields') && !this.get('hasUnsavedCharacteristics');
  }, _defineProperty(_Ember$Controller$ext, "canUpdateDrawings", Ember.computed('model.id', 'model.is_latest_revision', function () {
    return this.get('permissions').hasRoleAtLeast('production') && this.get('model.is_latest_revision');
  })), _defineProperty(_Ember$Controller$ext, "canCreateRevision", Ember.computed('isEdit', 'model.id', 'model.is_latest_revision', 'model.is_approved', 'userOrganization.is_mjf_enabled', function () {
    if (this.get('model.isForLabResult')) {
      if (Ember.isBlank(this.get('userOrganization.id')) || this.get('userOrganization.is_mjf_enabled')) {
        return false;
      }
    }

    return this.get('isEdit') && this.get('model.is_latest_revision') && this.get('model.is_approved');
  })), _hasUnsavedCharacteri = "hasUnsavedCharacteristics", _mutatorMap[_hasUnsavedCharacteri] = _mutatorMap[_hasUnsavedCharacteri] || {}, _mutatorMap[_hasUnsavedCharacteri].get = function () {
    var characteristics = this.get('model.characteristics');
    return characteristics.filterBy('edittable', true).length > 0 ? true : false;
  }, _defineProperty(_Ember$Controller$ext, "allowApproval", Ember.computed('canUpdateDrawings', function () {
    return this.get('canUpdateDrawings');
  })), _defineProperty(_Ember$Controller$ext, "allowNew", Ember.computed('model.is_approved', function () {
    return this.get('model.is_approved') !== true;
  })), _defineProperty(_Ember$Controller$ext, "controllerInstance", Ember.computed(function () {
    return this;
  })), _defineProperty(_Ember$Controller$ext, "buttonTextSingular", Ember.computed(function () {
    return this.get('constants').getModuleText(this.get('intl').t('modules.singular.drawings'));
  })), _defineProperty(_Ember$Controller$ext, "onDrawingChanged", Ember.observer('model.id', function () {
    this.set('selectedRevision', null);
  })), _defineProperty(_Ember$Controller$ext, "init", function init() {
    this._super.apply(this, arguments);

    this.set('selectedRevision', null);
  }), _defineProperty(_Ember$Controller$ext, "actions", {
    save: function save() {
      var _this = this;

      var model = this.get('model');

      if (model.get('is_approved')) {
        this.transitionToRoute('drawings');
      } else {
        if (this.get('isValid')) {
          this.set('isSaving', true);
          this.send('reindexCharacteristics');

          if (model.get('characteristics.length') === 0) {
            this.send('saveDrawing');
          } else {
            model.get('characteristics').forEach(function (item, index) {
              item.set('edittable', false);

              if (Object.keys(item.changedAttributes()).length > 0) {
                item.save();
              }

              if (item === model.get('characteristics.lastObject')) {
                item.save().then(function () {
                  _this.send('saveDrawing');
                });
              }
            });
          }
        } else {
          this.send('showErrors');
        }
      }

      return false;
    },
    saveDrawing: function saveDrawing() {
      var _this2 = this;

      var fm = this.get('flashMessages');
      this.get('model').save().then(function (drawing) {
        _this2.send('saveAttachments', drawing);
      }, function (error) {
        _this2.set('isSaving', false);

        fm.danger(error.message);
      });
    },
    afterAttachmentsSaved: function afterAttachmentsSaved(drawing) {
      var _this3 = this;

      drawing.reload().then(function () {
        var drawingText = _this3.get('constants').getModuleText(_this3.get('intl').t('modules.singular.drawings'), 'lower');

        var fm = _this3.get('flashMessages'),
            intl = _this3.get('intl');

        _this3.set('isSaving', false);

        _this3.get('history').setRoute('drawings');

        _this3.notifyPropertyChange('model.is_approved');

        fm.success("".concat(intl.t('drawings.others.The changes to this')) + drawingText + "".concat(intl.t('drawings.others.have been saved successfully')));
      });
    },
    saveRow: function saveRow(characteristic) {
      var _this4 = this;

      characteristic.save().then(function (updated) {
        updated.setProperties({
          'edittable': false
        });

        _this4.notifyPropertyChange('model.characteristics');

        _this4.get('model').reload();
      }, function (error) {
        characteristic.setProperties({
          'edittable': false
        });

        _this4.notifyPropertyChange('model.characteristics');
      });
    },
    insertRow: function insertRow(characteristic) {
      var _this5 = this;

      if (Ember.isBlank(characteristic.get('name')) || Ember.isBlank(characteristic.get('operation'))) {
        return;
      } // Process characteristic into format more easily digestable by store for insertion, create new 'empty' row
      // Find drawing based on parameter and attach via 'drawing_id' relationship


      var model = this.get('model'),
          id = model.get('id');
      var drawing = this.store.peekRecord('drawing', id);

      if (Ember.isNone(drawing)) {
        this.store.findRecord('drawing', id).then(function (drawing) {
          characteristic.set('drawing_id', drawing);
        });
      } else characteristic.set('drawing_id', drawing); // Set order before saving


      characteristic.set('order', model.get('characteristics.length')); // Save characteristic and create new 'empty' model

      characteristic.save().then(function (inserted) {
        inserted.set('alwaysEdittable', false); // Re enable empty row for new characteristic

        var emptyChar = _this5.store.createRecord('drawing.characteristic', {
          alwaysEdittable: true,
          positive: inserted.get('positive'),
          negative: inserted.get('negative'),
          pass_or_fail: inserted.get('pass_or_fail'),
          acceptable_or_unacceptable: inserted.get('acceptable_or_unacceptable'),
          yes_or_no: inserted.get('yes_or_no')
        });

        emptyChar.setTolerance();

        _this5.set('model.emptyRow', emptyChar); // Append characteristic to existing model


        var freshModelCharacteristics = _this5.get('model.characteristics');

        freshModelCharacteristics.pushObject(inserted);
      }, function () {
        // Reset changes if save fails
        characteristic.setProperties({
          'drawing_id': null,
          'order': null
        });
      });
    },
    deleteRow: function deleteRow(item) {
      var _this6 = this;

      //reset row if on 'new row', otherwise delete
      if (Ember.isNone(item.get('id'))) {
        this.set('model.emptyRow', this.store.createRecord('drawing.characteristic', {
          alwaysEdittable: true
        }));
      } else {
        var defer = Ember.RSVP.defer(),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        defer.promise.then(function () {
          item.destroyRecord().then(function () {
            _this6.send('reindexCharacteristics');
          }, function (error) {
            fm.danger(intl.t('drawings.others.An error occurred while trying to delete the characteristic'));
            item.rollbackAttributes();
          });

          _this6.set('deleteCharacteristicPromise', null);
        }, function () {
          _this6.set('deleteCharacteristicPromise', null);
        });
        this.set('deleteCharacteristicPromise', defer);
      }
    },
    eSignature: function eSignature(actionName) {
      var _this7 = this;

      var otherData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var isRequired = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (!isRequired) {
        this.send(actionName, otherData);
        return;
      }

      var defer = Ember.RSVP.defer();
      defer.promise.then(function () {
        _this7.send(actionName, otherData);

        _this7.set('eSignaturePopup', null);
      }, function () {
        _this7.set('model.is_approved', !_this7.get('model.is_approved'));

        _this7.set('eSignaturePopup', null);
      });
      this.set('eSignaturePopup', defer);
    },
    updateApproval: function updateApproval() {
      var _this8 = this;

      var fm = this.get('flashMessages'),
          intl = this.get('intl');
      var method = this.get('model.is_approved') ? 'POST' : 'DELETE';
      var authHeaders = this.get('authHeaders');
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/drawing/' + this.get('model.id') + '/approve'),
        method: method,
        data: {
          password: this.get('eSignaturePassword')
        }
      }).then(function () {
        _this8.get('model').reload().then(function () {
          fm.success(intl.t('drawings.others.Your changes have been saved successfully'));
        });
      }).fail(function (response) {
        _this8.set('model.is_approved', !_this8.get('model.is_approved'));

        if (response.responseJSON && !Ember.isEmpty(response.responseJSON.message)) {
          fm.danger(response.responseJSON.message);
        } else {
          fm.danger(intl.t('drawings.others.Check Password Error'));
        }
      }).always(function () {
        _this8.set('eSignaturePassword', '');
      });
    },
    createNewRevision: function createNewRevision() {
      var _this9 = this;

      var defer = Ember.RSVP.defer();
      var fm = this.get('flashMessages'),
          intl = this.get('intl');
      defer.promise.then(function () {
        _this9.set('isRevisionCreating', true);

        var authHeaders = _this9.get('authHeaders');

        $.ajax({
          headers: authHeaders,
          url: _this9.getApiUrl('/drawing/' + _this9.get('model.id') + '/revisions'),
          method: 'POST'
        }).done(function (response) {
          _this9.transitionToRoute('drawings.edit', response.id);

          fm.success(intl.t('drawings.others.The revision was successfully created'));
        }).fail(function () {
          fm.danger(intl.t('drawings.others.An error occurred while trying to create the revision'));
        }).always(function () {
          _this9.set('isRevisionCreating', false);
        });

        _this9.set('revisionPromise', null);
      }, function () {
        _this9.set('revisionPromise', null);
      });
      this.set('revisionPromise', defer);
    },
    handleSelectedRevision: function handleSelectedRevision(drawing) {
      this.transitionToRoute('drawings.edit', drawing.get('id'));
    }
  }), _defineEnumerableProperties(_Ember$Controller$ext, _mutatorMap), _Ember$Controller$ext));

  _exports.default = _default;
});