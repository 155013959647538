define("isolocity/pods/drawings/index/controller", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _ajaxRequestMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_ajaxRequestMixin.default, _paginationMixin.default, (_Ember$Controller$ext = {
    search: Ember.inject.service('search.drawings'),
    intl: Ember.inject.service(),
    constants: Ember.inject.service('constants'),
    permissions: Ember.inject.service('user-permissions'),
    sortedModel: Ember.computed.sort('model.drawings', 'sortProperties'),
    sortProperties: ['revision_date:desc']
  }, _defineProperty(_Ember$Controller$ext, "intl", Ember.inject.service()), _defineProperty(_Ember$Controller$ext, "buttonTextSingular", Ember.computed(function () {
    return this.get('constants').getModuleText(this.get('intl').t('modules.singular.drawings'));
  })), _defineProperty(_Ember$Controller$ext, "searchText", ''), _defineProperty(_Ember$Controller$ext, "bold", function bold(str) {
    return '<strong>' + str + '</strong>';
  }), _defineProperty(_Ember$Controller$ext, "canCreateDrawings", Ember.computed(function () {
    return this.get('permissions').hasRoleAtLeast('production');
  })), _defineProperty(_Ember$Controller$ext, "isFilteredResults", Ember.computed(function () {
    return this.get('searchText');
  })), _defineProperty(_Ember$Controller$ext, "searchDescriptor", Ember.computed('searchType', 'searchText', 'searchSupplier', 'searchDepartment', 'searchPartType', function () {
    var descriptor = '';

    if (this.get('searchText')) {
      descriptor += ' matching "' + this.bold(this.get('searchText')) + '"';
    }

    return descriptor.htmlSafe();
  })), _defineProperty(_Ember$Controller$ext, "observeSession", function () {
    this.send("doSearchDrawings");
  }.observes("session.isAuthenticated")), _defineProperty(_Ember$Controller$ext, "actions", {
    handleSearchKeyPress: function handleSearchKeyPress(e, event) {
      if (!Ember.isBlank(e) && e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        this.send('searchDrawings');
      }
    },
    searchDrawings: function searchDrawings() {
      this.get('search').setKeyword(this.get('searchText'));
      this.send('doSearchDrawings');
      this.notifyPropertyChange('isFilteredResults');
      this.notifyPropertyChange('searchDescriptor');
    },
    clearSearch: function clearSearch() {
      this.set('searchText', '');
      this.get('search').clear();
      this.send('doSearchDrawings');
      this.notifyPropertyChange('isFilteredResults');
      this.notifyPropertyChange('searchDescriptor');
    },
    archive: function archive(drawing) {
      var _this = this;

      var authHeaders = this.get('authHeaders'),
          intl = this.get('intl');
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/drawing/' + drawing.get('id') + '/archive'),
        method: 'POST'
      }).then(function (data) {
        _this.get('flashMessages').success(intl.t('drawings.others.Successfully archived'));

        setTimeout(function () {
          _this.send("doSearchDrawings");
        }, 1000);
      }).fail(function (data) {});
    }
  }), _Ember$Controller$ext));

  _exports.default = _default;
});