define("isolocity/mixins/enrollment-filter-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    filterText: '',
    filterStatus: '',
    filterStatuses: [],
    modelKey: 'enrollments',
    listName: 'filteredEnrollments',
    totalCount: 0,
    init: function init() {
      this.getFilteredEnrollments();
      this.filterStatus = this.get('intl').t('All Statuses');
      this.filterStatuses = [this.get('intl').t('All Statuses'), this.get('intl').t('In Progress'), this.get('intl').t('Complete')];
      return this._super.apply(this, arguments);
    },
    onFiltersChanged: Ember.observer('filterText', 'filterStatus', function () {
      // Avoid unncecessary reload by checking option got selected and is not empty
      if (this.get('filterStatuses').includes(this.get('filterStatus'))) {
        Ember.run.debounce(this, this.getFilteredEnrollments, 500);
      }
    }),
    hasFilterStatus: Ember.computed('filterStatus', function () {
      return !Ember.isBlank(this.get('filterStatus')) && this.get('filterStatus') != 'All Statuses';
    }),
    matchAllFilters: Ember.computed('filterText', 'hasFilterStatus', function () {
      if (Ember.isBlank(this.get('filterText'))) {
        return false;
      }

      if (Ember.isBlank(this.get('filterStatus')) || this.get('filterStatus') == 'All Statuses') {
        return false;
      }

      return true;
    }),
    getFilteredEnrollments: function getFilteredEnrollments() {
      var _this2 = this;

      var _this = this,
          fm = this.get('flashMessages'),
          intl = this.get('intl');

      this.set('isTableLoading', true);
      this.store.query('training-schedule-employee', {
        keyword: this.get('filterText'),
        status: this.get('filterStatus'),
        module: this.get('model.constructor.modelName'),
        module_id: this.get('model.id'),
        number: this.get('amountToShow'),
        list_type: 'component_table'
      }).then(function (records) {
        _this.set('totalCount', records.meta.total);

        _this.set(_this.get('listName'), records);

        _this2.set('isTableLoading', false);
      }, function (error) {
        fm.danger(intl.t('mixins.enrollment-filter.Fetch Enrollment Records'));

        _this2.set('isTableLoading', false);
      });
    }
  });

  _exports.default = _default;
});