define("isolocity/pods/documents/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/document-links-mixin", "isolocity/mixins/document-users-mixin", "isolocity/mixins/documents-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _documentLinksMixin, _documentUsersMixin, _documentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _documentLinksMixin.default, _documentUsersMixin.default, _documentsMixin.default, (_Ember$Controller$ext = {
    intl: Ember.inject.service(),
    isNew: true,
    modules: Ember.inject.service('modules'),
    authManager: Ember.inject.service('session')
  }, _defineProperty(_Ember$Controller$ext, "intl", Ember.inject.service()), _defineProperty(_Ember$Controller$ext, "requiredFields", ['name', 'document_type_id.id']), _defineProperty(_Ember$Controller$ext, "durationValues", ['', 'Days', 'Weeks', 'Months']), _defineProperty(_Ember$Controller$ext, "historyOverride", ''), _defineProperty(_Ember$Controller$ext, "isLoading", false), _defineProperty(_Ember$Controller$ext, "valuesChanged", Ember.observer('model.name', 'model.type', function () {
    this.get('flashMessages').clearMessages();
  })), _defineProperty(_Ember$Controller$ext, "reviewPeriodChanged", Ember.observer('model.review_repeat_frequency', 'model.review_repeat_duration', 'model.current_revision.last_review_date', function () {
    var frequency = this.get('model.review_repeat_frequency'),
        duration = this.get('model.review_repeat_duration');

    if (!Ember.isEmpty(frequency) && !Ember.isEmpty(duration) && !Ember.isEmpty(last_review_date)) {
      var next_review_date = moment(this.get('model.current_revision.last_review_date')).add(frequency, duration.toLowerCase());
      this.set('model.current_revision.next_review_date', next_review_date);
    }
  })), _defineProperty(_Ember$Controller$ext, "onSelectedUserChange", Ember.observer('selectedUser', function () {
    if (this.get('selectedUser') && this.get('selectedUser') !== false) {
      var user = this.get('selectedUser');
      this.send('addUserToDocument', user);
    }
  })), _defineProperty(_Ember$Controller$ext, "onSelectedGroupChange", Ember.observer('selectedGroup', function () {
    if (this.get('selectedGroup') && this.get('selectedGroup') !== false) {
      var group = this.get('selectedGroup');
      this.send('addGroupToDocument', group);
    }
  })), _defineProperty(_Ember$Controller$ext, "actions", {
    save: function save() {
      var _this = this;

      var is_approval_matrix_required = this.get('modules').getModuleSetting('documents', 'is_approval_matrix_required', false);

      if (is_approval_matrix_required) {
        this.set('requiredFields', ['name', 'document_type_id.id', 'approval_matrix_id.id']);
      }

      console.log('is_approval_matrix_required', is_approval_matrix_required);
      var fm = this.get('flashMessages');

      if (this.get('isValid')) {
        if (Ember.isBlank(this.get('model.owners'))) {
          fm.danger(this.get('intl').t('At least one document owner is required'));
          return;
        }

        var model = this.get('model');
        this.set('isLoading', true);
        model.save().then(function (doc) {
          _this.send('saveAttachments', doc);
        }, function (error) {
          _this.set('isLoading', false);

          fm.danger(error.message);
        });
      } else {
        this.send('showErrors');
      }
    },
    afterAttachmentsSaved: function afterAttachmentsSaved(doc) {
      var _this2 = this;

      var fm = this.get('flashMessages'),
          intl = this.get('intl');
      this.set('isLoading', false);
      doc.reload().then(function () {
        fm.success(intl.t('A new document has been created'));

        _this2.get('history').setRoute('documents');

        _this2.transitionToRoute('documents.edit', doc);
      });
    },
    addUserToDocument: function addUserToDocument(user) {
      var users = this.get('model.users');

      if (!users.includes(user)) {
        this.get('model.users').pushObject(user);
      }

      this.set('selectedUser', false);
    },
    addGroupToDocument: function addGroupToDocument(group) {
      var groups = this.get('model.groups');

      if (!groups.includes(group)) {
        this.get('model.groups').pushObject(group);
      }

      this.set('selectedGroup', false);
    }
  }), _Ember$Controller$ext));

  _exports.default = _default;
});