define("isolocity/pods/test/result/question/model", ["exports", "ember-data", "isolocity/mixins/test-mixin"], function (_exports, _emberData, _testMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_testMixin.default, {
    question_definitive: _emberData.default.attr('string'),
    answer: _emberData.default.attr('string'),
    test: _emberData.default.belongsTo('test'),
    result: _emberData.default.belongsTo('test.result'),
    question: _emberData.default.belongsTo('test.question'),
    score: _emberData.default.attr('number')
  });

  _exports.default = _default;
});