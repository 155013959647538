define("isolocity/pods/audit-report-templates/index/controller", ["exports", "isolocity/mixins/pagination-mixin"], function (_exports, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_paginationMixin.default);

  _exports.default = _default;
});