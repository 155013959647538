define("isolocity/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute(function (routeName) {
      return routeName.indexOf('.index') != -1;
    }), this.toRoute(function (routeName) {
      return routeName.indexOf('.new') != -1 || routeName.indexOf('.edit') != -1;
    }), this.use('toLeft'), this.reverse('toRight'));
  }
});