define("isolocity/mixins/non-conformance-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/action-item-mixin", "isolocity/mixins/icon-list-mixin", "isolocity/mixins/has-departments-mixin", "isolocity/mixins/batch-records-mixin"], function (_exports, _eSignatureMixin, _actionItemMixin, _iconListMixin, _hasDepartmentsMixin, _batchRecordsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _batchRecordsMixin.default, _iconListMixin.default, _actionItemMixin.default, _hasDepartmentsMixin.default, {
    batchEntityType: 'ncr',
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('ncrs');
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('ncrs', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('ncrs', 'is_esignature_required_on_save', false);
    },

    ncrSources: ['', 'Internal', 'Customer', 'Supplier'],
    ncrSeverities: ['', 'Critical', 'Major', 'Minor', 'No Risk'],
    ncrDispositions: ['', 'Scrap', 'Sorting', 'Use As Is', 'Repair', 'Return', 'Rework', 'Other'],
    actions: {
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.set('isFiveWhyAnalysis', true);
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.set('isFishboneAnalysis', true);
      },
      createActionItem: function createActionItem(item) {
        item.set('link', 'ncrs.edit');
        item.set('entity_type', 'ncr');
        item.set('use_queue', true);

        if (this.get('isEdit')) {
          item.set('entity_id', this.get('model.id'));
        }

        item.set('code', 'ncr_implementation_plan');
        this.get('model.implementation_action_items').pushObject(item);

        if (this.get('isEdit')) {
          item.save();
        }
      }
    }
  });

  _exports.default = _default;
});