define("isolocity/pods/equipment/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin, _ajaxRequestMixin, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, _ajaxRequestMixin.default, _modelEsignaturesMixin.default, {
    name: _emberData.default.attr('string'),
    serial_number: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    equipment_type_id: _emberData.default.belongsTo('equipment-type'),
    is_out_for_service: _emberData.default.attr('boolean'),
    is_archived: _emberData.default.attr('boolean'),
    reports: _emberData.default.hasMany('equipment-report'),
    is_critical: _emberData.default.attr('boolean'),
    latest_report: _emberData.default.attr(),
    files: _emberData.default.attr(),
    part_revision_id: _emberData.default.attr('number'),
    // the latest equipment revision
    is_finished_product: _emberData.default.attr('boolean'),
    displayName: Ember.computed('serial_number', 'name', function () {
      if (this.get('serial_number') != null && this.get('serial_number') != '') {
        return "#".concat(this.get('serial_number'), " - ").concat(this.get('name'));
      }

      return this.get('name');
    }),
    frequency_drawing_due_dates_array: _emberData.default.attr({
      async: false
    }),
    // Relations
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    frequency_entries: _emberData.default.hasMany('frequency-entry'),
    closest_frequency_entry: _emberData.default.attr(),
    drawings: _emberData.default.hasMany('drawing'),
    approved_drawings: _emberData.default.hasMany('drawing'),
    materials: _emberData.default.hasMany('part.material'),
    current_materials: _emberData.default.hasMany('part.material'),
    revisions: _emberData.default.hasMany('part.revision'),
    current_revision: _emberData.default.belongsTo('part.revision'),
    activities: _emberData.default.hasMany('activity'),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    all_approved_drawings: _emberData.default.hasMany('drawing'),
    is_owned_by_current_user: _emberData.default.attr('boolean'),
    // Computed
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    // Computed
    isOverdue: Ember.computed('latest_report.id', 'is_active', 'equipment_type_id.is_active', function () {
      var now = moment();

      if (this.get('is_active') && this.get('equipment_type_id.is_active') && !Ember.isEmpty(this.get('reports'))) {
        return now > moment(this.get('latest_report.next_due'));
      }

      return false;
    }),
    isInService: Ember.computed('is_out_for_service', function () {
      return !this.get('is_out_for_service');
    }),
    reloadRelations: function reloadRelations() {
      var equipmentRevisionId = this.get('part_revision_id');

      if (equipmentRevisionId) {
        this.set('billOfMaterials', this.store.query('part.material', {
          entity_id: this.get('id'),
          entity_type: 'equipment',
          part_revision_id: equipmentRevisionId
        }));
      } else {
        this.set('billOfMaterials', this.store.query('part.material', {
          entity_id: this.get('id'),
          entity_type: 'equipment'
        }));
      }
    },
    loadHistoricalInventoryBatchNumbers: function loadHistoricalInventoryBatchNumbers() {
      var _this = this;

      var authHeaders = this.get('authHeaders');
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/inventory/' + this.get('id') + '/historical-batch-numbers?type=equipment'),
        method: 'GET'
      }).then(function (data) {
        _this.set('historical_batch_numbers', data);
      });
    }
  });

  _exports.default = _default;
});