define("isolocity/pods/risk-reports/edit/controller", ["exports", "isolocity/mixins/risk-report-mixin", "isolocity/mixins/file-download-mixin", "isolocity/mixins/risk-report-editing-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/activity-log-mixin"], function (_exports, _riskReportMixin, _fileDownloadMixin, _riskReportEditingMixin, _requiredFieldsMixin, _historyHandlerMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _uploadFilesMixin, _pdfGenerationMixin, _ownersAndSubscribersMixin, _ajaxRequestMixin, _eSignatureMixin, _activityLogMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileDownloadMixin.default, _activityLogMixin.default, _requiredFieldsMixin.default, _historyHandlerMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _uploadFilesMixin.default, _eSignatureMixin.default, _pdfGenerationMixin.default, _riskReportMixin.default, _riskReportEditingMixin.default, _ownersAndSubscribersMixin.default, _ajaxRequestMixin.default, {
    sideMenu: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    persistence: Ember.inject.service(),
    id_prefix: 'edit_',
    requiredFields: ['report_number'],
    router: Ember.inject.service(),
    isOwnedByCurrentUser: Ember.computed('model.id', 'model.owners', function () {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));
      return this.get('model.owners').includes(user);
    }),
    isFormLocked: Ember.computed('isOwnedByCurrentUser', function () {
      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isApprovedForm: Ember.computed('isOwnedByCurrentUser', function () {
      var model = this.get('model');

      if (model.get('approvedAt')) {
        return true;
      } else {
        return false;
      }
    }),
    isFormDisabled: Ember.computed('isFormLocked', 'isApprovedForm', function () {
      return this.get('isFormLocked') || this.get('isApprovedForm');
    }),
    PdfTitle: Ember.computed('model.report_number', function () {
      return 'Risk Report #' + this.get('model.report_number');
    }),
    isEdit: true,
    isSaving: false,
    isCreatingReport: false,
    noteEntityType: 'risk-report',
    actionLink: 'risk-reports.edit',
    actionEntityType: 'risk-report',
    actionTitleOptions: [],
    isHaccp: false,
    canApproveReport: Ember.computed('model.id', 'isOwnedByCurrentUser', 'model.owners', function () {
      return this.get('permissions').hasRoleAtLeast('user') && this.get('isOwnedByCurrentUser');
    }),
    canDeleteReport: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('risk-report', 'is_reason_required_on_save', false);
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('risk-report', 'is_esignature_required_on_save', false);
    },

    actions: {
      save: function save() {
        var _this2 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          var model = this.get('model');
          var type = model.get('report_type');
          this.set('isSaving', true);
          model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (risk) {
            if (model.get(type + '_items.length') > 0) {
              _this2.send('saveItemsAndAttachments', risk);
            } else {
              _this2.send('saveAttachments', risk, false, _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));
            }
          }, function (error) {
            _this2.set('isSaving', false);

            fm.danger(intl.t('risk-reports.others.Save Report Error'));
          });
        } else {
          this.send('showErrors');
        }
      },
      saveItemsAndAttachments: function saveItemsAndAttachments(risk) {
        var _this3 = this;

        var model = this.get('model');
        var type = model.get('report_type');
        model.get(type + '_items').forEach(function (item) {
          item.set('risk_report_id', risk);

          if (item === model.get(type + '_items.lastObject')) {
            item.save().then(function () {
              _this3.send('saveAttachments', risk);
            });
          } else {
            item.save();
          }
        });
      },
      approve: function approve() {
        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();

        var _this = this;

        defer.promise.then(function () {
          _this.send('doApproval');

          _this.set('approvalPromise', null);
        }, function () {
          _this.set('approvalPromise', null);
        });
        this.set('approvalPromise', defer);
        this.get('isFormDisabled');
      },
      doApproval: function doApproval() {
        var _this = this,
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders'),
            intl = this.get('intl');

        this.set('isApprovingReport', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/risk-report/' + this.get('model.id') + '/approve'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).done(function (data) {
          _this.get('model').reload().then(function () {
            fm.success(intl.t('risk-reports.others.Approved Report'));
          });

          location.reload(true);
        }).fail(function (data) {
          fm.danger(intl.t('risk-reports.others.E-sign Provided'));
        }).always(function () {
          _this.set('isApprovingReport', false);
        });
      },
      undoApproval: function undoApproval() {
        var _this = this,
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders'),
            intl = this.get('intl');

        this.set('isApprovingReport', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/risk-report/' + this.get('model.id') + '/approve'),
          method: 'DELETE',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).done(function (data) {
          _this.get('model').reload().then(function () {
            fm.success(intl.t('risk-reports.others.Unapproved Report'));
          });

          location.reload(true);
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger(intl.t('risk-reports.others.Not Authorized'));
          }
        }).always(function () {
          _this.set('isApprovingReport', false);
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(risk) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        fm.success(intl.t('risk-reports.others.Update Report'));
      },
      createReport: function createReport() {
        var risk = this.get('model');
        var report = {};
        this.set('isCreatingReport', true);
        var reportContent = this.getRiskReportReportContent(risk);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createCsvReport: function createCsvReport() {
        var _this4 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var risk = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/risk-report/' + risk.get('id') + '/csv'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            var id = _this4.get('model.id');

            var model = 'risk-report';

            var reportNumber = _this4.get('model.report_number');

            var description = "Risk Report #".concat(reportNumber, " has been downloaded as CSV");

            _this4.send('newActivityLog', id, model, description);

            _this4.send('downloadCsvFile', data, 'RISK_' + _this4.get('model.report_number'));
          } else {// this.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(intl.t('risk-reports.others.Generate Report Error'));
        }).always(function (data) {
          _this4.set('isCreatingReport', false);
        });
      },
      createReportNew: function createReportNew() {
        var _this5 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var risk = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/risk-report/' + risk.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            var id = _this5.get('model.id');

            var model = 'risk-report';

            var reportNumber = _this5.get('model.report_number');

            var description = "Risk Report #".concat(reportNumber, " has been downloaded");

            _this5.send('newActivityLog', id, model, description);

            _this5.send('downloadPdfFile', data, 'RISK_' + _this5.get('model.report_number'));
          } else {
            _this5.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(intl.t('risk-reports.others.Generate Report Error'));
        }).always(function (data) {
          _this5.set('isCreatingReport', false);
        });
      },
      createReportNewWithAttachments: function createReportNewWithAttachments() {
        var _this6 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.send('createReportNew', isDownload);
        var car = this.get('model');
        var files = car.get('files');
        files.forEach(function (file, i) {
          // Delay download between files for browser optimization
          var timeout = 500 * i;
          setTimeout(function () {
            _this6.send('downloadFile', file);
          }, timeout);
        });
      },
      insertRow: function insertRow(item, type) {
        var _this7 = this;

        var model = this.get('model');
        var typeUpper = type.charAt(0).toUpperCase() + type.slice(1);
        item.set('order', model.get(type + '_items.length'));
        item.set('risk_report_id', this.get('model'));
        item.save().then(function (saved) {
          saved.set('alwaysEdittable', false);

          _this7.set('model.empty' + typeUpper + 'Row', _this7.store.createRecord('risk-report.' + type + '-item', {
            alwaysEdittable: true
          }));
        });
      },
      saveRow: function saveRow(item, type) {
        var _this8 = this;

        item.save().then(function (saved) {
          saved.setProperties({
            'edittable': false
          });

          _this8.notifyPropertyChange('model.' + type + '_items');
        }, function (error) {
          item.setProperties({
            'edittable': false
          });

          _this8.notifyPropertyChange('model.' + type + '_items');
        });
      },
      deleteRow: function deleteRow(item, type) {
        var typeUpper = type.charAt(0).toUpperCase() + type.slice(1);

        if (Ember.isNone(item.get('order'))) {
          this.set('model.empty' + typeUpper + 'Row', this.store.createRecord('risk-report.' + type + '-item', {
            alwaysEdittable: true
          }));
          return;
        }

        var _this = this;

        var model = this.get('model');
        item.destroyRecord().then(function () {
          _this.set('sortedPfmeaItems', model.get('pfmea_items'));

          _this.set('sortedHaccpItems', model.get('haccp_items'));

          _this.notifyPropertyChange('model.' + type + '_items');
        });
      },
      remove: function remove() {
        var _this9 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this9.get('model').destroyRecord().then(function () {
            _this9.transitionToRoute('risk-reports');
          });

          _this9.set('deletePromise', null);
        }, function () {
          _this9.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      }
    }
  });

  _exports.default = _default;
});