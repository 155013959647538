define("isolocity/services/search/parts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    type: '',
    keyword: '',
    supplierId: '',
    departmentId: '',
    typeId: '',
    getType: function getType() {
      return this.get('type');
    },
    setType: function setType(type) {
      this.set('type', type);
    },
    getKeyword: function getKeyword() {
      return this.get('keyword');
    },
    setKeyword: function setKeyword(keyword) {
      this.set('keyword', keyword);
    },
    getSupplierId: function getSupplierId() {
      return this.get('supplierId');
    },
    setSupplierId: function setSupplierId(supplierId) {
      this.set('supplierId', supplierId);
    },
    getDepartmentId: function getDepartmentId() {
      return this.get('departmentId');
    },
    setDepartmentId: function setDepartmentId(departmentId) {
      this.set('departmentId', departmentId);
    },
    getTypeId: function getTypeId() {
      return this.get('typeId');
    },
    setTypeId: function setTypeId(typeId) {
      this.set('typeId', typeId);
    },
    clear: function clear() {
      this.setType('');
      this.setKeyword('');
      this.setSupplierId('');
      this.setDepartmentId('');
      this.setTypeId('');
    }
  });

  _exports.default = _default;
});