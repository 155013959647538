define("isolocity/pods/audit-report-templates/edit/controller", ["exports", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _historyHandlerMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_historyHandlerMixin.default, _requiredFieldsMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    requiredFields: ['name', 'identification', 'audit_type_id.id'],
    actions: {
      save: function save() {
        var _this = this;

        this.set('model.form', $('#form').val());
        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          this.get('model').save().then(function (reportTemplate) {
            _this.transitionToRoute('audit-report-templates');

            fm.success(intl.t('audit-report.Template Details Save'));
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      }
    }
  });

  _exports.default = _default;
});