define("isolocity/helpers/calculate-defect-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var divide = function divide(params) {
    var result = params[0] / params[1] * 100;
    result = Math.round(result * 100) / 100;
    return result;
  };

  var _default = Ember.Helper.helper(divide);

  _exports.default = _default;
});