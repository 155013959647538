define("isolocity/mixins/document-users-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isEdit: false,
    actions: {
      addUserToDocument: function addUserToDocument(user) {
        var owners = this.get('model.owners');
        var users = this.get('model.users');

        if (owners.includes(user)) {
          this.get('model.owners').removeObject(user);
        }

        if (!users.includes(user)) {
          this.get('model.users').pushObject(user);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      addOwnerToDocument: function addOwnerToDocument(user) {
        var owners = this.get('model.owners');
        var users = this.get('model.users');

        if (users.includes(user)) {
          this.get('model.users').removeObject(user);
        }

        if (!owners.includes(user)) {
          this.get('model.owners').pushObject(user);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeUserFromDocument: function removeUserFromDocument(user) {
        this.get('model.users').removeObject(user);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeOwnerFromDocument: function removeOwnerFromDocument(user) {
        this.get('model.owners').removeObject(user);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      addGroupToDocument: function addGroupToDocument(group) {
        var groups = this.get('model.groups');

        if (!groups.includes(group)) {
          this.get('model.groups').pushObject(group);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeGroupFromDocument: function removeGroupFromDocument(group) {
        this.get('model.groups').removeObject(group);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      }
    }
  });

  _exports.default = _default;
});