define("isolocity/pods/location/index/route", ["exports", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    modelName: 'location',
    model: function model() {
      this.startLoading();
      return {
        locations: this.store.findAll('location', {
          reload: true
        })
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.locations.then(function (locations) {
        _this.doneLoading();

        _this.setPageMetadata(locations);
      });
    }
  });

  _exports.default = _default;
});