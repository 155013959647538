define("isolocity/helpers/create-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function () {
    return arguments.length <= 1 ? undefined : arguments[1];
  });

  _exports.default = _default;
});