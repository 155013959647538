define("isolocity/pods/support/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wCgmA6+V",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content\"],[14,\"role\",\"primary\"],[12],[2,\"\\n\\n  \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"support.headers.Contact Supports\"],null]],[13],[2,\"\\n\\n    \"],[1,[34,1]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\\n  \"],[10,\"div\"],[14,0,\"content-block\"],[12],[2,\"\\n\\n    \"],[1,[30,[36,4],null,[[\"selectedType\",\"description\"],[[35,3],[35,2]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\\n  \"],[10,\"footer\"],[14,0,\"bottom\"],[12],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"user-options\",\"contactDescription\",\"contactType\",\"contact-form\"]}",
    "meta": {
      "moduleName": "isolocity/pods/support/template.hbs"
    }
  });

  _exports.default = _default;
});