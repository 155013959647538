define("isolocity/pods/components/audit/key-performance-indicators/inventory-level-history/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/audit/key-performance-indicators/inventory-level-history/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    singleInventory: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('chartData', [{
        type: 'line',
        name: 'Inventory Level',
        data: [],
        color: '#efcb2c'
      }]);

      if (this.get('singleInventory')) {
        this.set('chartOptions.legend.enabled', false);
        this.send('updateGraph');
      }
    },
    unitOfMeasure: Ember.computed('inventory.part_id.unit_of_measure', function () {
      if (!Ember.isEmpty(this.get('inventory.part_id.unit_of_measure'))) {
        return this.get('inventory.part_id.unit_of_measure');
      }

      return '';
    }),
    onInventoryChange: Ember.observer('inventory', function () {
      this.send('updateGraph');
    }),
    actions: {
      updateGraph: function updateGraph() {
        var inventory = this.get('inventory');

        var _this = this;

        this.set('chartData', null);
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/inventory/' + inventory.get('id') + '/history',
          method: 'GET',
          data: {
            company_ids: this.get('reportingService').get('companies')
          },
          success: function success(data) {
            if (_this.get('isDestroyed')) return;
            var dataset = [],
                minQuantity = 0,
                maxQuantity = 0;

            for (var point in data) {
              var quantity = parseFloat(data[point]);

              if (quantity < minQuantity) {
                minQuantity = quantity;
              }

              if (quantity > maxQuantity) {
                maxQuantity = quantity;
              }

              var date = new Date(point);
              date.setDate(date.getDate() + 1);

              if (!isNaN(date.getTime()) && !isNaN(quantity)) {
                dataset.push([date.getTime(), quantity]);
              }
            }

            _this.set('chartOptions.tooltip.valueSuffix', ' ' + _this.get('unitOfMeasure'));

            _this.set('chartOptions.yAxis.title.text', 'Quantity (' + _this.get('unitOfMeasure') + ')');

            _this.set('chartOptions.yAxis.labels.format', '{value}');

            _this.set('chartOptions.yAxis.min', minQuantity);

            _this.set('chartOptions.yAxis.max', maxQuantity);

            if (inventory.get('reorder_point') && parseInt(inventory.get('reorder_point')) > 0) {
              _this.set('chartOptions.yAxis.plotLines', [{
                value: inventory.get('reorder_point'),
                color: 'red',
                dashStyle: 'shortdash',
                width: 2,
                label: {
                  text: 'Re-order Point'
                }
              }]);
            }

            if (dataset.length === 0) {
              _this.set('chartOptions.tooltip.enabled', false);
            }

            _this.set('chartData', [{
              type: 'line',
              name: 'Inventory Level',
              data: dataset,
              color: '#efcb2c'
            }]);
          }
        });
      }
    }
  });

  _exports.default = _default;
});