define("isolocity/pods/index/controller", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/audit-mixin", "isolocity/mixins/company-switch-mixin", "isolocity/mixins/enrollment-edit-mixin", "isolocity/mixins/test-result-edit-mixin", "isolocity/mixins/file-download-mixin"], function (_exports, _actionItemMixin, _eSignatureMixin, _auditMixin, _companySwitchMixin, _enrollmentEditMixin, _testResultEditMixin, _fileDownloadMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_actionItemMixin.default, _auditMixin.default, _companySwitchMixin.default, _enrollmentEditMixin.default, _testResultEditMixin.default, _fileDownloadMixin.default, _eSignatureMixin.default, {
    isEdit: true,
    authManager: Ember.inject.service('session'),
    search: Ember.inject.service('search'),
    router: Ember.inject.service('-routing'),
    userFullname: Ember.computed(function () {
      var auth = this.get('authManager');
      return auth.get('session.currentUser.name');
    }),
    userCurrentLevel: Ember.computed(function () {
      var auth = this.get('authManager');
      return auth.get('session.currentUser.primary_role_name');
    }),
    limitForActionItemForUserLevel: Ember.computed(function () {
      if (this.get('userCurrentLevel') == 'Production') {
        return 100;
      } else {
        return 5;
      }
    }),
    displayedFaqs: Ember.computed('model.faqs', function () {
      return this.get('model.faqs');
    }),
    actions: {}
  });

  _exports.default = _default;
});