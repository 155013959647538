define("isolocity/pods/order/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    order_number: _emberData.default.attr('string'),
    po_number: _emberData.default.attr('string'),
    serial_number: _emberData.default.attr('string'),
    is_closed: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr(),
    order_date: _emberData.default.attr('isodate'),
    start_date: _emberData.default.attr('isodate'),
    requested_ship_date: _emberData.default.attr('isodate'),
    actual_ship_date: _emberData.default.attr('isodate'),
    contact_id: _emberData.default.belongsTo('customer'),
    items: _emberData.default.hasMany('order.item'),
    parts: _emberData.default.hasMany('part'),
    // Relations
    activities: _emberData.default.hasMany('activity'),
    // belongsTo
    contact: _emberData.default.belongsTo('customer'),
    // computed
    displayName: Ember.computed('order_number', function () {
      return "Order #".concat(this.get('order_number'));
    })
  });

  _exports.default = _default;
});