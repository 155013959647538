define("isolocity/pods/components/bi-products-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSelectedPartChange: Ember.observer('selectedPart', function () {
      if (this.get('selectedPart') && this.get('selectedPart') !== false) {
        var part = this.get('selectedPart');
        this.set('selectedPart', null);
        this.sendAction('addPartToBiProductsList', part);
      }
    }),
    actions: {
      removeBiProduct: function removeBiProduct(biProduct) {
        this.sendAction('removePartFromBiProductsList', biProduct);
      }
    }
  });

  _exports.default = _default;
});