define("isolocity/pods/components/pay-wall/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "odEs2Hvq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"paywall\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"Cost tracking\"],null]],[2,\". \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"Re-imagined\"],null]],[2,\".\"],[13],[13],[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"Take control of your costs with our new fully integrated cost tracking solution\"],null]],[2,\".\"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"m-b-5\"],[12],[1,[30,[36,0],[\"Give us a call or use the link below to schedule a demo\"],null]],[2,\".\"],[13],[2,\"\\n      \"],[11,\"a\"],[24,0,\"btn btn-blue\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"scheduleDemo\"],null],[12],[1,[30,[36,0],[\"Schedule Demo\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"overlay\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/pay-wall/template.hbs"
    }
  });

  _exports.default = _default;
});