define("isolocity/services/search/training-schedules", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    type: '',
    keyword: '',
    employee: '',
    filterBy: 'Incomplete',
    isShowCompleted: false,
    getType: function getType() {
      return this.get('type');
    },
    setType: function setType(type) {
      this.set('type', type);
    },
    getKeyword: function getKeyword() {
      return this.get('keyword');
    },
    setKeyword: function setKeyword(keyword) {
      this.set('keyword', keyword);
    },
    getEmployee: function getEmployee() {
      return this.get('employee');
    },
    setEmployee: function setEmployee(employee) {
      this.set('employee', employee);
    },
    getFilterByActive: function getFilterByActive() {
      return this.get('filterByActive');
    },
    setFilterByActive: function setFilterByActive(filterByActive) {
      this.set('filterByActive', filterByActive);
    },
    getFilterBy: function getFilterBy() {
      return this.get('filterBy');
    },
    setFilterBy: function setFilterBy(filterBy) {
      this.set('filterBy', filterBy);
    },
    clear: function clear() {
      this.setEmployee('');
      this.setType('');
      this.setKeyword('');
      this.setFilterBy('Incomplete');
      this.setFilterByActive(null);
    }
  });

  _exports.default = _default;
});