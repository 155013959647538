define("isolocity/pods/components/graphs/ncrs/car-escalation-percentage/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/ncrs/car-escalation-percentage/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, (_Ember$Component$exte = {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting')
  }, _defineProperty(_Ember$Component$exte, "intl", Ember.inject.service()), _defineProperty(_Ember$Component$exte, "init", function init() {
    this.set('chartData', null);

    var _this = this;

    var startDate = null,
        endDate = null,
        source = null;

    if (!Ember.isEmpty(this.get('startDate'))) {
      startDate = moment(this.get('startDate')).format("YYYY-MM-DD");
    }

    if (!Ember.isEmpty(this.get('endDate'))) {
      endDate = moment(this.get('endDate')).format("YYYY-MM-DD");
    }

    if (!Ember.isEmpty(this.get('source'))) {
      source = this.get('source');
    }

    $.ajax({
      headers: this.get('authHeaders'),
      url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/ncrs/car-escalation-percentage/graph',
      method: 'GET',
      data: {
        company_ids: this.get('reportingService').get('companies'),
        start_date: startDate,
        end_date: endDate,
        source: source
      },
      success: function success(data) {
        if (_this.get('isDestroyed')) return;
        var series = [{
          name: 'Percentage',
          colorByPoint: true,
          data: [{
            name: _this.get('intl').t('Not Escalated') + ' (' + data.not_escalated.count + ')',
            y: data.not_escalated.percentage,
            selected: true,
            color: '#f8f9fb',
            borderColor: '#e8eaee'
          }, {
            name: _this.get('intl').t('Escalated') + ' (' + data.escalated.count + ')',
            y: data.escalated.percentage,
            color: '#ed6161',
            borderColor: '#e8eaee'
          }]
        }];

        if (!Ember.isBlank(series) && (data.escalated.count > 0 || data.not_escalated.count > 0)) {
          _this.set('chartData', series);
        } else {
          _this.set('chartData', [{
            name: _this.get('intl').t('No Data'),
            data: []
          }]);
        }
      }
    });

    this._super.apply(this, arguments);
  }), _defineProperty(_Ember$Component$exte, "onStartDateChange", Ember.observer('startDate', function () {
    this.init();
  })), _defineProperty(_Ember$Component$exte, "onEndDateChange", Ember.observer('endDate', function () {
    this.init();
  })), _defineProperty(_Ember$Component$exte, "onSourceChange", Ember.observer('source', function () {
    this.init();
  })), _Ember$Component$exte));

  _exports.default = _default;
});