define("isolocity/pods/risk-reports/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _paginatedRouteMixin.default, {
    routeName: 'risk-reports.index',
    search: Ember.inject.service('search.risk-reports'),
    modelName: ['risk-report'],
    model: function model(params) {
      var fromDate = !Ember.isEmpty(this.get('search').getFromDate()) ? this.get('search').getFromDate() : null;
      var toDate = !Ember.isEmpty(this.get('search').getToDate()) ? this.get('search').getToDate() : null;
      this.startLoading();
      return {
        risks: this.store.query('risk-report', {
          per_page: _environment.default.APP.pagination,
          page: this.get('currentPage'),
          type: 'pfmea',
          keyword: this.get('search').getKeyword(),
          dateRange: this.get('search').getDateRange(),
          fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
          toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : ''
        })
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.risks.then(function (risks) {
        _this.doneLoading();

        _this.setPageMetadata(risks);
      });
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.set('currentPage', 1);
        this.refresh();
      },
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('risk-reports');
        }
      }
    }
  });

  _exports.default = _default;
});