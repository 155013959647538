define("isolocity/pods/support/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    isVisibleAllUsers: true,
    model: function model(params) {
      if (params.feature === 'costs') {
        this.controllerFor('support').set('contactType', 'Billing');
        this.controllerFor('support').set('contactDescription', "I'm interested in scheduling a demo for the cost tracking tool.");
      } else {
        this.controllerFor('support').set('contactType', null);
        this.controllerFor('support').set('contactDescription', '');
      }
    }
  });

  _exports.default = _default;
});