define("isolocity/pods/components/test-questions-list/question-row/component", ["exports", "isolocity/mixins/test-mixin", "isolocity/mixins/table-editor-row-mixin"], function (_exports, _testMixin, _tableEditorRowMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorRowMixin.default, _testMixin.default, {
    intl: Ember.inject.service(),
    tagName: 'tr',
    classNameBindings: [':question-row', 'isBeingDragged:question-row--dragging'],
    mcOptions: Ember.computed('model.id', 'model.options.length', 'isMcOptionsChanged', function () {
      return this.get('model.options');
    }),
    isExpanded: Ember.computed('expand', function () {
      return this.get('model.id') !== null || this.get('expand') === true;
    }),
    onRowChange: Ember.observer('isEdittable', 'model.title', 'model.type', 'model.correct_answer', 'model.score', function () {
      var fields = ['title', 'type', 'correct_answer', 'score'],
          valid = true;
      var model = this.get('model');

      for (var i = 0, len = fields.length; i < len; i++) {
        if (Ember.isEmpty(model.get(fields[i]))) {
          valid = false;
        }
      }

      return this.set('isValidRow', valid);
    }),
    actions: {
      selectQuestionType: function selectQuestionType(type) {
        this.set('model.correct_answer', undefined);
        this.set('model.type', type);

        if (type === 'true_false') {
          this.set('model.options', ['True', 'False']);
        } else {
          this.set('model.options', null);

          if (type === 'text') {
            this.set('model.correct_answer', '*');
          }
        }
      },
      addTag: function addTag(option) {
        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (Ember.isBlank(option)) {
          fm.danger(intl.t('The option field is required'));
          return;
        }

        if (Ember.isEmpty(this.get('model.options'))) {
          this.set('model.options', []);
        }

        this.get('model.options').pushObject(option);
        this.notifyPropertyChange('isMcOptionsChanged');
      },
      removeTagAtIndex: function removeTagAtIndex(index) {
        this.get('model.options').removeAt(index);
      },
      toggleTextAnswer: function toggleTextAnswer() {
        if (this.get('model.correct_answer') === '*') {
          this.set('model.correct_answer', '');
        } else {
          this.set('model.correct_answer', '*');
        }
      }
    }
  });

  _exports.default = _default;
});