define("isolocity/pods/equipment/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UpqmmwoG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n    \"],[11,\"form\"],[4,[38,2],[[32,0],\"create\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n        \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"goBack\"],[[30,[36,2],[[32,0],\"goBack\"],null]]]]],[2,\"\\n            \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"equipments.headers.Create\"],null]],[2,\" \"],[1,[30,[36,1],[[30,[36,0],[\"modules.singular.Equipment\"],null]],null]],[13],[2,\"\\n            \\n\"],[6,[37,5],null,[[\"name\",\"model\",\"class\"],[\"activity-log\",[35,4],\"m-r-25\"]],[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"i\"],[14,0,\"fa fa-history\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n\\n            \"],[19,\"equipment/form\",[]],[2,\"\\n\\n        \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"input\"],[14,0,\"btn btn-go btn-disabled pull-right\"],[15,2,[30,[36,0],[\"Creating\"],null]],[14,\"disabled\",\"true\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[15,2,[31,[[30,[36,0],[\"equipments.headers.Create\"],null],\" \",[30,[36,1],[[30,[36,0],[\"modules.singular.equipment\"],null]],null]]]],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"t\",\"module-text\",\"action\",\"back-link\",\"model\",\"side-menu-toggle\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/equipment/new/template.hbs"
    }
  });

  _exports.default = _default;
});