define("isolocity/pods/deviation-type/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    intl: Ember.inject.service(),
    requiredFields: ['name'],
    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('isValid')) {
          this.send('showErrors');
          return;
        }

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.get('model').save().then(function () {
          fm.success(intl.t('You have successfully saved the type'));
          history.back();
        }, function (response) {
          _this.send('showServerErrors', response);
        });
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});