define("isolocity/mixins/inspections-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authManager: Ember.inject.service('session'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    persistence: Ember.inject.service(),
    percentageComplete: Ember.computed('model.completedSamples', 'model.samples.length', function () {
      var completed = this.get('model.completedSamples'),
          total = this.get('model.samples.length');

      if (total === 0) {
        return 0;
      }

      return Math.round(completed / total * 1000) / 10;
    }),
    getInspectionStatusName: function getInspectionStatusName(status) {
      var isOptional = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (status === true) return this.get('intl').t('Passed');else if (status === false) return this.get('intl').t('Failed');else if (isOptional) return this.get('intl').t('Optional');else return this.get('intl').t('Pending');
    },
    getInspectionsReportContent: function getInspectionsReportContent(inspection) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.RSVP.Promise.all([_this.getOrderData(inspection), _this.getPartData(inspection), _this.getDrawingData(inspection), _this.getSamplesData(inspection)]).then(function (values) {
          resolve(values);
        });
      });
    },
    getOrderData: function getOrderData(inspection) {
      return new Ember.RSVP.Promise(function (resolve) {
        inspection.get('order_id').then(function (order) {
          resolve({
            'order': order
          });
        });
      });
    },
    getOrderItemData: function getOrderItemData(inspection) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        inspection.get('order_item_id').then(function (order_item) {
          Ember.RSVP.Promise.all([_this2.getPartData(order_item), _this2.getDrawingData(order_item)]).then(function (values) {
            var response = {
              'order_item': order_item,
              'part': values[0],
              'drawing': values[1]
            };
            resolve(response);
          });
        });
      });
    },
    getPartData: function getPartData(inspection) {
      return new Ember.RSVP.Promise(function (resolve) {
        inspection.get('part_id').then(function (part) {
          resolve({
            'part': part
          });
        });
      });
    },
    getDrawingData: function getDrawingData(inspection) {
      return new Ember.RSVP.Promise(function (resolve) {
        inspection.get('drawing_id').then(function (drawing) {
          resolve({
            'drawing': drawing
          });
        });
      });
    },
    getSamplesData: function getSamplesData(inspection) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var samples = inspection.get('samples');
        var response = {
          'samples': []
        };
        if (Ember.isEmpty('samples')) resolve(response);
        var promises = [];
        samples.forEach(function (sample) {
          promises.push(_this3.getSampleData(sample));
        });
        Ember.RSVP.Promise.all(promises).then(function (values) {
          response.samples = values;
          resolve(response);
        });
      });
    },
    getSampleData: function getSampleData(sample) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var results = sample.get('results');
        var response = {
          'sample': sample,
          'results': []
        };
        if (Ember.isEmpty(results)) resolve(response);
        var promises = [];
        results.forEach(function (result) {
          promises.push(_this4.getResultData(result));
        });
        Ember.RSVP.Promise.all(promises).then(function (values) {
          response.results = values;
          resolve(response);
        });
      });
    },
    getResultData: function getResultData(result) {
      var _this5 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var response = {
          'result': result,
          'inspection_method': result.get('inspection_method_id'),
          'equipment': result.get('equipment_id'),
          'characteristic': null
        };

        _this5.getResultCharacteristic(result).then(function (values) {
          $.extend(response, values);
          resolve(response);
        });
      });
    },
    getResultCharacteristic: function getResultCharacteristic(result) {
      return new Ember.RSVP.Promise(function (resolve) {
        result.get('drawing_characteristic_id').then(function (characteristic) {
          var response = {
            'characteristic': characteristic
          };
          resolve(response);
        }, function (error) {
          resolve({
            characteristic: null
          });
        });
      });
    },
    actions: {
      createCAR: function createCAR() {
        var _this6 = this;

        var route = 'corrective-actions.new';
        this.get('history').setRoute('inspections');
        this.set('isCarCreating', true); // First fetch the CAR type, then use it to create our CAR

        this.store.queryRecord('car-type', {
          code: 'INS'
        }).then(function (type) {
          var car = _this6.store.createRecord('car', {
            is_closed: false,
            date_opened: new Date(),
            car_type_id: type,
            type_inspection_id: _this6.get('model')
          });

          _this6.get('persistence').add({
            route: route,
            model: car
          });

          _this6.transitionToRoute(route);

          _this6.get('flashMessages').clearMessages();

          _this6.set('isCarCreating', false);
        }, function (error) {
          _this6.set('isCarCreating', false);
        });
      },
      createNcr: function createNcr() {
        var route = 'ncrs.new';
        this.get('history').setRoute('inspections');
        var ncr = this.store.createRecord('ncr', {
          type_inspection_id: this.get('model')
        });
        this.get('persistence').add({
          route: route,
          model: ncr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      createOosform: function createOosform() {
        var route = 'oosforms.new';
        this.get('history').setRoute('inspections');
        var oosform = this.store.createRecord('oosform', {
          type_inspection_id: this.get('model')
        });
        this.get('persistence').add({
          route: route,
          model: oosform
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      }
    }
  });

  _exports.default = _default;
});