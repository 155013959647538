define("isolocity/pods/activities/index/controller", ["exports", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/activity-log-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _historyHandlerMixin, _dateSearchMixin, _activityLogMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_historyHandlerMixin.default, _dateSearchMixin.default, _activityLogMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.activities'),
    hasSideMenu: false,
    changedFields: [],
    jsonSnapshot: '',
    selectedModule: '',
    subjectId: '',
    searchIsLoading: false,
    refreshIsLoading: false,
    sortedModel: Ember.computed.sort('model.activities', 'sortProperties'),
    sortProperties: ['created_at:desc'],
    modules: Ember.computed(function () {
      return this.store.query('module', {
        is_parent: false,
        is_sidebar: true
      });
    }),
    isFilteredResults: Ember.computed(function () {
      return this.get('selectedModule') || this.get('searchText') || this.get('fromDate') || this.get('toDate') || this.get('dateRange');
    }),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchModule');
        }
      },
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setSelectedModule(this.get('selectedModule'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('selectedModule', null);
      }
    }
  });

  _exports.default = _default;
});