define("isolocity/services/search/activities", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    selectedModule: null,
    getSelectedModule: function getSelectedModule() {
      return this.get('selectedModule');
    },
    setSelectedModule: function setSelectedModule(module) {
      this.set('selectedModule', module);
    },
    clear: function clear() {
      this.setSelectedModule(null);
    }
  });

  _exports.default = _default;
});