define("isolocity/pods/part/material/model", ["exports", "ember-data", "isolocity/mixins/units-of-measure-mixin"], function (_exports, _emberData, _unitsOfMeasureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_unitsOfMeasureMixin.default, {
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    material_part_id: _emberData.default.belongsTo('part', {
      inverse: null
    }),
    part_revision_id: _emberData.default.belongsTo('part.revision', {
      inverse: null
    }),
    quantity: _emberData.default.attr('string'),
    unit_of_measure: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    // dynamically generated
    quantity_alternate: _emberData.default.attr('string'),
    isDraggable: true,
    unitsOfMeasure: Ember.computed('material_part_id.unit_type', function () {
      return this.getUnitsOfMeasure(this.get('material_part_id.unit_type'));
    })
  });

  _exports.default = _default;
});