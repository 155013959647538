define("isolocity/pods/ccrs/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('ccr', params.ccr_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('allDepartments', this.store.findAll('department'));
      model.set('emptyAction', this.store.createRecord('action-item'));
      model.set('allSources', this.store.findAll('ccr-source'));
      model.set('allClassifications', this.store.findAll('ccr-classification'));
      model.set('allChangeTypes', this.store.findAll('ccr-type'));
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('ccrs');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});