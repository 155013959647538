define("isolocity/mixins/has-notes-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    noteEntityType: '',
    hasUnreadNotes: Ember.computed('model.id', 'model.has_unread_notes', 'unread_notes_count', function () {
      return this.get('model.has_unread_notes') || this.get('model.unread_notes_count');
    }),
    actions: {
      onNotesReadAll: function onNotesReadAll() {
        var _this = this;

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/' + this.get('noteEntityType') + '/' + this.get('model.id') + '/notes',
          method: 'GET',
          success: function success(data) {
            _this.get('model').reload().then(function () {
              _this.notifyPropertyChange('hasUnreadNotes');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});