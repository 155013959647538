define("isolocity/mixins/user-inactivity-tracker-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/logout-handler-mixin"], function (_exports, _environment, _logoutHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_environment.default, _logoutHandlerMixin.default, {
    timeout: _environment.default.APP.sessionTimeoutMinutes * 60 * 1000,
    // Convert minutes into miliseconds
    minutes: _environment.default.APP.sessionTimeoutMinutes,
    // to use the minutes number in the logout popup message
    intervalLoopTime: 5 * 60 * 1000,
    // Loop through interval code evry 5 minutes
    eventLists: ['mousemove', 'wheel', 'scroll', 'click', 'keydown'],
    expiredTime: Ember.computed(function () {
      // Retrive the value of _tokenExpiredTime variable stored in session storage
      // If not present then return zero
      return parseInt(localStorage.getItem("_tokenExpiredTime") || 0, 10);
    }),
    eventHandler: Ember.computed(function () {
      // Update token expiry time on every event
      this.updateExpiredTime.bind(this);
    }),
    onInactive: function onInactive() {
      // Logout user if inactive and clear the tracker
      this.send('invalidateSession');
      this.cleanUpInactivityTracker();
    },
    startInactivityTracker: function startInactivityTracker() {
      var _this = this;

      // Return if user is not logged in
      var session = this.get('session');

      if (!session.get('isAuthenticated')) {
        return;
      } // Initialize user event trackers


      this.initializeEventTracker(); // Update _tokenExpiredTime variable if already exist
      // If not create new one

      this.updateExpiredTime(); // Loop through interval every {intervalLoopTime} minute to check if user is inactive or not

      this.interval = setInterval(function () {
        var expiredTime = parseInt(localStorage.getItem("_tokenExpiredTime") || 0, 10);

        if (expiredTime < Date.now()) {
          var controllers = _this.controllerFor("application");

          controllers.send('logoutWarn'); // this.onInactive();
        }
      }, this.intervalLoopTime);
    },
    updateExpiredTime: function updateExpiredTime() {
      var _this2 = this;

      // Check if tracker already exist then clear that tracker
      if (this.inactivityTracker) {
        clearTimeout(this.inactivityTracker);
      } // Set expiry time to _tokenExpiredTime varibale


      this.inactivityTracker = setTimeout(function () {
        localStorage.setItem("_tokenExpiredTime", Date.now() + _this2.timeout);
      }, 300);
    },
    initializeEventTracker: function initializeEventTracker() {
      var _this3 = this;

      // Bind all the listed user events to inactivity tracker
      this.eventLists.forEach(function (eventname) {
        window.addEventListener(eventname, _this3.eventHandler);
      });
    },
    cleanUpInactivityTracker: function cleanUpInactivityTracker() {
      var _this4 = this;

      // Remove the _tokenExpiredTime variable from session
      // Clear the interval loop and remove all the events
      localStorage.removeItem("_tokenExpiredTime");
      clearInterval(this.interval);
      this.eventLists.forEach(function (eventname) {
        window.removeEventListener(eventname, _this4.eventHandler);
      });
    }
  });

  _exports.default = _default;
});