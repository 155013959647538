define("isolocity/pods/components/department-esignatures/component", ["exports", "isolocity/mixins/e-signature-mixin"], function (_exports, _eSignatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_eSignatureMixin.default, {
    disabled: false,
    selectedResponsibility: null,
    // newResponsibility: null,
    actions: {
      addResponsibility: function addResponsibility(esignature, responsibility) {
        this.sendAction('addResponsibility', esignature, responsibility);
        this.set('selectedResponsibility', null);
        this.set('newResponsibility', null);
        document.querySelector('.esignature-sign-offs').value = '';
      },
      addEsignature: function addEsignature(esignature) {
        this.sendAction('addEsignature', esignature);
      },
      removeEsignature: function removeEsignature(esignature) {
        this.sendAction('removeEsignature', esignature);
      }
    }
  });

  _exports.default = _default;
});