define("isolocity/pods/components/characteristics-table/component", ["exports", "isolocity/mixins/table-editor-mixin"], function (_exports, _tableEditorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorMixin.default, {
    tagName: 'div',
    disabled: false,
    sortedCharacteristics: Ember.computed('model', 'model.characteristics', 'model.characteristics.length', function () {
      return this.get('model.characteristics').sortBy('order');
    }),
    hasOrderChange: Ember.observer('onOrderChange', function () {
      this.send('sortEndAction');
      this.set('sortedCharacteristics', this.get('model.characteristics').sortBy('order'));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var fullWidth = $('.characteristics-table').get(0).scrollWidth;
      var visibleWidth = $('.characteristics-table').get(0).clientWidth;
      this.set('hasScrollBar', fullWidth > visibleWidth);
    },
    actions: {
      resetEdittable: function resetEdittable(model) {
        var _this = this;

        var characteristics = this.get('model.characteristics');

        if (characteristics !== undefined) {
          characteristics.forEach(function (item) {
            if (item === model) item.set('edittable', true);else item.set('edittable', false);

            var controller = _this.get('controllerInstance');

            controller.addObserver('foo', _this, _this.onDataChange);
            controller.notifyPropertyChange('model.characteristics');
          });
        }

        this.set('newRowActive', !Ember.isBlank(model) && model.get('id') === null);
      },
      sortEndAction: function sortEndAction() {
        var index = 0;
        this.get('sortedCharacteristics').forEach(function (item) {
          if (!item.get('isDeleted')) {
            item.set('order', index++);
          }
        });
      }
    }
  });

  _exports.default = _default;
});