define("isolocity/pods/components/back-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgK/4LMw",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[24,0,\"go-back\"],[4,[38,0],[[32,0],\"goBack\"],null],[12],[2,\"\\n    \"],[10,\"svg\"],[14,\"width\",\"20\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"stroke\",\"currentColor\"],[12],[2,\"\\n        \"],[10,\"path\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M7 16l-4-4m0 0l4-4m-4 4h18\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,1],[\"Go Back\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/back-link/template.hbs"
    }
  });

  _exports.default = _default;
});