define("isolocity/pods/inspections/new-equipment/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    modules: Ember.inject.service('modules'),
    params: null,
    model: function model(params) {
      var inspection = this.store.createRecord('inspection', {
        entity_type: 'equipment',
        is_create_batch_records: true,
        is_sample_batch_records: true,
        sample_size: 1
      });
      this.set('params', params);
      return inspection;
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      model.set('equipments', this.store.findAll('equipment'));
      model.set('availableDrawings', this.store.findAll('drawing'));
      model.set('availableDrawingsFrequencyDue', []);
      model.set('drawing_id', null); // Await route to load, then retrieve relationships. Asana: https://app.asana.com/0/1198972706342981/1204688792953969

      var intervalId = setTimeout(function () {
        var params = _this.get('params');

        if (params.equipment_id && params.drawing_id) {
          _this.store.findRecord('equipment', params.equipment_id).then(function (equipment) {
            console.log('EQUIPMENY: ', equipment);
            model.set('equipment', equipment);
          });

          _this.store.findRecord('drawing', params.drawing_id).then(function (drawing) {
            console.log('DRAWING: ', drawing);
            model.set('drawing_id', drawing);
            model.notifyPropertyChange('drawing_id');
          });
        }
      }, 1000);
    }
  });

  _exports.default = _default;
});