define("isolocity/pods/groups/index/controller", ["exports", "isolocity/mixins/pagination-mixin"], function (_exports, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_paginationMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    sortedModel: Ember.computed.sort('model.groups', 'sortProperties'),
    sortProperties: ['name:asc'],
    canCreateGroups: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('manager');
    }),
    newPath: Ember.computed(function () {
      if (this.get('canCreateGroups') === true) {
        return "groups.new";
      }

      return false;
    })
  });

  _exports.default = _default;
});