define("isolocity/pods/supplier-upload/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/suppliers-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _environment, _suppliersMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_suppliersMixin.default, _requiredFieldsMixin.default, {
    uploadHash: '',
    publicApiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/supplier/files',
    publicSupplierCertApiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/supplier/details',
    saveSupplierCertificate: function saveSupplierCertificate(model, certs) {
      var _this = this;

      this.set('isSaving', true);
      $.ajax({
        url: this.get('publicSupplierCertApiUrl'),
        contentType: "application/json",
        data: JSON.stringify({
          upload_hash: this.get('uploadHash'),
          quality_department: model.get('quality_department'),
          quality_policy: model.get('quality_policy'),
          quality_procedures: model.get('quality_procedures'),
          certificates: certs
        }),
        method: 'POST'
      }).done(function () {
        _this.get('flashMessages').success("Your changes have been saved successfully");
      }).fail(function (response) {
        _this.send('showServerErrors', response.responseJSON);
      }).always(function () {
        _this.set('isSaving', false);
      });
    },
    actions: {
      addCertificate: function addCertificate() {
        var certificate = this.store.createRecord('supplier.certificate', {
          supplier_id: this.get('model')
        });
        this.set('selectedCertificate', certificate);
      },
      removeCertificate: function removeCertificate(certificate) {
        this.get('model.certificates').removeObject(certificate);
      },
      filesChanged: function filesChanged() {
        var _this2 = this;

        this.store.query('supplier', {
          upload_hash: this.get('uploadHash')
        }).then(function (suppliers) {
          var supplier = suppliers.get('firstObject');

          _this2.set('model', supplier);
        });
      },
      saveFiles: function saveFiles() {
        var _this3 = this;

        var model = this.get('model');
        var certs = [];
        model.get('certificates').forEach(function (cert) {
          certs.pushObject({
            'id': cert.get('id'),
            'expiry_date': cert.get('expiry_date'),
            'description': cert.get('description')
          });
        });

        if (certs.length == 0) {
          this.get('flashMessages').danger('Please add at least one certificate');
          return;
        }

        this.saveSupplierCertificate(model, certs);

        if (Dropzone.forElement(".dropzone").getQueuedFiles().length > 0) {
          Dropzone.forElement(".dropzone").options.params.entity_id = model.get('id');
          Dropzone.forElement(".dropzone").options.params.entity_type = 'supplier';
          Dropzone.forElement(".dropzone").options.params.upload_hash = this.get('uploadHash');
          Dropzone.forElement(".dropzone").processQueue();
          Dropzone.forElement(".dropzone").on("complete", function () {
            this.options.autoProcessQueue = true;
          });
          Dropzone.forElement(".dropzone").on("queuecomplete", function () {
            _this3.send("filesChanged");
          });
        }
      }
    }
  });

  _exports.default = _default;
});