define("isolocity/pods/inspection/sample/model", ["exports", "ember-data", "isolocity/mixins/batch-records-display-mixin"], function (_exports, _emberData, _batchRecordsDisplayMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_batchRecordsDisplayMixin.default, {
    sample_serial: _emberData.default.attr('string'),
    sample_number: _emberData.default.attr('number'),
    inspection_date: _emberData.default.attr('utc-datetime'),
    inspector_name: _emberData.default.attr('string'),
    inspection_id: _emberData.default.belongsTo('inspection'),
    results: _emberData.default.hasMany('inspection.sample.result'),
    batch_records: _emberData.default.hasMany('inspection.sample.batch-record', {
      async: false
    }),
    approver_user_id: _emberData.default.belongsTo('user'),
    approved_at: _emberData.default.attr('utc'),
    files: _emberData.default.attr(),
    cost: _emberData.default.attr('money'),
    // Read-only
    files_count: _emberData.default.attr('number'),
    // Computed
    approvedAt: Ember.computed('approved_at', function () {
      return Ember.isEmpty(this.get('approved_at')) ? '' : moment(this.get('approved_at')).format("MMM D, YYYY h:mm A");
    }),
    approvedUserName: Ember.computed('approver_user_id.name', function () {
      return Ember.isEmpty(this.get('approver_user_id.name')) ? '' : this.get('approver_user_id.name');
    }),
    is_passed: Ember.computed('results.[].is_passed', function () {
      var complete = true,
          passed = true;
      this.get('results').forEach(function (result) {
        if (!result.get('is_optional') && !result.get('is_text_input_optional')) {
          if (Ember.isBlank(result.get('is_passed'))) {
            complete = false;
          }

          if (result.get('is_passed') === false) {
            passed = false;
          }
        }
      });

      if (complete) {
        return passed;
      }

      return null;
    }),
    serial_number: Ember.computed('sample_serial', 'inspection_id.serial_number', function () {
      if (!Ember.isBlank(this.get('sample_serial'))) return this.get('sample_serial');
      var inspection_serial = this.get('inspection_id.serial_number');
      if (!Ember.isBlank(inspection_serial)) return inspection_serial;
      return '';
    }),
    //observer
    onStatusChanged: Ember.observer('is_passed', function () {
      if (this.get('isDeleted')) {
        return;
      }

      this.get('inspection_id').then(function (inspection) {
        if (!Ember.isEmpty(inspection)) {
          inspection.calculatePassFail();
        }
      });
    })
  });

  _exports.default = _default;
});