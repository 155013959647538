define("isolocity/pods/department/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/departments-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _departmentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _departmentsMixin.default, {
    intl: Ember.inject.service(),
    isLoading: false,
    requiredFields: ['name'],
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.hasBlankResponsibilities()) {
          fm.danger(intl.t('departments.other.Please fill in the empty responsibility fields'));
          return;
        }

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function (department) {
            _this.set('isLoading', false);

            _this.transitionToRoute('department.edit', department);

            fm.success(intl.t('departments.other.You have successfully added a department'));
          }, function (response) {
            _this.set('isLoading', false);
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});