define("isolocity/services/search/equipments-archived", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    isActive: null,
    isOutForService: null,
    equipmentTypeId: null,
    getIsActive: function getIsActive() {
      return this.get('isActive');
    },
    setIsActive: function setIsActive(isActive) {
      this.set('isActive', isActive);
    },
    getIsOutForService: function getIsOutForService() {
      return this.get('isOutForService');
    },
    setIsOutForService: function setIsOutForService(isOutForService) {
      this.set('isOutForService', isOutForService);
    },
    getEquipmentTypeId: function getEquipmentTypeId() {
      return this.get('equipmentTypeId');
    },
    setEquipmentTypeId: function setEquipmentTypeId(equipmentTypeId) {
      this.set('equipmentTypeId', equipmentTypeId);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
      this.setIsActive(null);
      this.setIsOutForService(null);
      this.setEquipmentTypeId(null);
    }
  });

  _exports.default = _default;
});