define("isolocity/pods/components/document-linked-drawings/component", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    schedules: null,
    actions: {
      removeLinkedTemplate: function removeLinkedTemplate(drawing) {
        this.get('drawings').removeObject(drawing);
        this.sendAction('handleRemovedDrawing');
      }
    }
  });

  _exports.default = _default;
});