define("isolocity/pods/users/archived/controller", ["exports", "isolocity/mixins/user-list-mixin", "isolocity/mixins/pagination-mixin", "isolocity/mixins/date-search-mixin"], function (_exports, _userListMixin, _paginationMixin, _dateSearchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_userListMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.users-archived')
  });

  _exports.default = _default;
});