define("isolocity/services/search/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    keyword: '',
    type: '',
    department: '',
    status: null,
    getKeyword: function getKeyword() {
      return this.get('keyword');
    },
    setKeyword: function setKeyword(keyword) {
      this.set('keyword', keyword);
    },
    getType: function getType() {
      return this.get('type');
    },
    setType: function setType(type) {
      this.set('type', type);
    },
    getDepartment: function getDepartment() {
      return this.get('department');
    },
    setDepartment: function setDepartment(department) {
      this.set('department', department);
    },
    getStatus: function getStatus() {
      return this.get('status');
    },
    setStatus: function setStatus(status) {
      this.set('status', status);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setType('');
      this.setDepartment('');
      this.setStatus(null);
    }
  });

  _exports.default = _default;
});