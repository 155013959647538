define("isolocity/pods/components/login-form/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/route-transition-mixin"], function (_exports, _environment, _ajaxRequestMixin, _routeTransitionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, _routeTransitionMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    isAuthenticating: false,
    is2FactorAuthenticationView: false,
    formAction: 'authenticate',
    btnLoginText: '',
    btnLoadingText: '',
    btnClass: 'btn-welcome',
    mfa_code: '',
    valuesChanged: Ember.observer('identification', 'password', function () {
      this.get('flashMessages').clearMessages();
    }),
    init: function init() {
      this.btnLoginText = this.get('intl').t('Sign in');
      this.btnLoadingText = this.get('intl').t('Signing in');

      this._super.apply(this, arguments);
    },
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('isAuthenticating', true);

        var _this$getProperties = this.getProperties('identification', 'password', 'mfa_code'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password,
            mfa_code = _this$getProperties.mfa_code;

        var fm = this.get('flashMessages');
        this.get('session').authenticate('authenticator:oauth2', identification, password, mfa_code).then(function (response) {
          if (_this.get('session.data.authenticated.error_class') === 'UserNoAppVersionAccessException') {
            localStorage.setItem('has_read_redirect-to-new-app-warning-message', 'no');
          } // We can do stuff here upon login


          var user = _this.get('session.data.authenticated.user');

          _this.set('isAuthenticating', false);

          _this.set('session.currentUser', user);

          _this.sendAction('onAuthenticate', user);
        }).catch(function (message) {
          _this.set('isAuthenticating', false);

          if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'Provide 2FA code, token has expired') {
            _this.set('is2FactorAuthenticationView', false);

            _this.get('flashMessages').danger(_this.get('intl').t('MFA Token has expired please enter again your credentials to receive a new code'), {
              sticky: true
            });
          } else if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'Provide 2FA code, token is incorrect') {
            _this.set('is2FactorAuthenticationView', true);

            _this.get('flashMessages').clearMessages();

            _this.get('flashMessages').danger(_this.get('intl').t('The code you entered is incorrect'), {
              sticky: true
            });
          } else if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'Provide 2FA code') {
            _this.set('is2FactorAuthenticationView', true);
          } else if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'The password has expired') {
            var tokenAtr = message.responseJSON.token;

            _this.sendAction('onPasswordReset', tokenAtr);
          } else if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'Use the new Isolocity sign in. The old Sign in is obsolete.') {
            _this.get('flashMessages').clearMessages();

            _this.get('flashMessages').danger(_this.get('intl').t('Use the new Isolocity sign in The old Sign in is obsolete'), {
              sticky: true
            }); // Redirect to Vue App


            var token = message.responseJSON.loginToken;
            var redirectUrl = _environment.default.APP.newAppUrl + "/login/token?t=".concat(token);
            window.location.href = redirectUrl;
          } else if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'Too many failed login attempts. Please try again in 15 minutes') {
            _this.get('flashMessages').clearMessages();

            _this.get('flashMessages').danger(_this.get('intl').t('Too many failed login attempts Please try again in 15 minutes'), {
              sticky: true
            });
          } else if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'The specified account is not active') {
            _this.get('flashMessages').danger(_this.get('intl').t('Your account is inactive Please contact the system administrator'), {
              sticky: true
            });
          } else if (message && !Ember.isBlank(message.responseJSON.error) && message.responseJSON.error === 'The email or password was not valid. You have five attempts.') {
            _this.get('flashMessages').clearMessages();

            _this.get('flashMessages').danger(_this.get('intl').t('Your email or password were incorrect You have a total of five attempts to access your account prior to being locked out'), {
              sticky: true
            });
          } else {
            fm.danger(_this.get('intl').t('Your email or password were incorrect'), {
              sticky: true
            });
          }
        });
      },
      changeToLoginView: function changeToLoginView() {
        this.set('is2FactorAuthenticationView', false);
      },
      redirectToMicrosoftLogin: function redirectToMicrosoftLogin() {
        var guestHeaders = this.get('guestHeaders'),
            fm = this.get('flashMessages'); // Generate Azure auth url and redirect

        $.ajax({
          headers: guestHeaders,
          url: this.getApiUrl('/authenticate/azure'),
          method: 'GET',
          data: {}
        }).then(function (response) {
          if (response.azureUathUrl) {
            window.location = response.azureUathUrl;
          }
        }).fail(function (response) {
          if (response.status === 401) {
            fm.danger(response.responseJSON.error);
          }

          fm.danger(this.get('intl').t('An error occurred while trying to map azure account.'));
        });
      }
    }
  });

  _exports.default = _default;
});