define("isolocity/pods/components/enrollments-table/component", ["exports", "isolocity/mixins/enrollment-filter-mixin"], function (_exports, _enrollmentFilterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_enrollmentFilterMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedEmployee: null,
    parentEntityName: 'list',
    isEmployeeView: false,
    filterPlaceholder: 'Filter employees',
    amountToShow: 20,
    hasMoreEnrollments: false,
    isTableLoading: false,
    shownRecordsCountText: Ember.computed('filteredEnrollments', 'totalCount', function () {
      return this.get('filteredEnrollments.length') + ' of ' + this.get('totalCount');
    }),
    onTotalCountChage: Ember.observer('totalCount', function () {
      return this.set('hasMoreEnrollments', this.get('totalCount') > this.get('amountToShow'));
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$(".data-table").tablesorter();
    },
    actions: {
      toggleAllCompleted: function toggleAllCompleted() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.get('model.enrollments').forEach(function (enrollment) {
          if (enrollment.completion_date == null) {
            enrollment.set('completion_date', new moment());
            enrollment.set('is_completed', true);

            if (enrollment === _this.get('model.enrollments.lastObject')) {
              enrollment.save().then(function () {
                fm.success(intl.t('All items have been marked as complete'));
              });
            } else {
              enrollment.save();
            }
          }
        });
      },
      toggleTrainingCompletion: function toggleTrainingCompletion(enrollment) {
        var _this = this;

        var fm = _this.get('flashMessages');

        if (enrollment.get('is_completed') === true) {
          enrollment.set('completion_date', null);
          enrollment.set('is_completed', false);
        } else {
          enrollment.set('completion_date', new moment());
          enrollment.set('is_completed', true);
          enrollment.set('completed_by_manager', true);
        }

        enrollment.save().then(function () {
          _this.get('model').reload();
        });
      },
      removeEnrollment: function removeEnrollment(enrollment) {
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.get('model.enrollments').removeObject(enrollment);
        enrollment.destroyRecord().then(function () {
          fm.success(intl.t('The employee has been removed from the training activity'));
        });
        this.sendAction('removeEnrollment', enrollment);
      },
      showMoreEnrollments: function showMoreEnrollments() {
        this.set('amountToShow', this.get('amountToShow') + 50);
        this.getFilteredEnrollments();
      }
    }
  });

  _exports.default = _default;
});