define("isolocity/pods/components/option-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UUebMUqW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,3],null,[[\"status\",\"active\",\"isActivePassed\"],[true,[35,7],true]]]],[2,\" / \\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[34,6]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[11,\"a\"],[24,6,\"#\"],[24,0,\"link\"],[4,[38,0],[[32,0],\"toggle\"],null],[12],[1,[34,6]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"span\"],[12],[1,[30,[36,3],null,[[\"status\",\"inactive\",\"isInactiveFailed\",\"isBothActive\"],[false,[35,2],false,true]]]],[2,\" / \"],[13],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[11,\"a\"],[24,6,\"#\"],[24,0,\"\"],[4,[38,0],[[32,0],\"toggle\"],null],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"approveAction\",\"unapprovedText\",\"current-status\",\"disabled\",\"if\",\"unapproveAction\",\"approvedText\",\"status\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/option-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});