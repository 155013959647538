define("isolocity/pods/components/inspections-table/result/yes-no/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zUnoOZfb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"fieldset\"],[15,0,[30,[36,2],[\"checkboxes\",[30,[36,1],[[35,0],\" checkboxes--readonly\"],null]],null]],[14,\"tabindex\",\"0\"],[12],[2,\"\\n    \"],[11,\"label\"],[24,0,\"active\"],[24,\"for\",\"is_active\"],[4,[38,3],[[32,0],\"toggle\",\"yes\"],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"tabindex\",\"value\",\"groupValue\"],[0,\"yes\",[35,4,[\"yes_no\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,6],[\"Yes\"],null]],[13],[2,\"\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"label\"],[14,0,\"sep\"],[12],[2,\"/\"],[13],[2,\"\\n    \"],[11,\"label\"],[24,0,\"inactive\"],[24,\"for\",\"inactive\"],[4,[38,3],[[32,0],\"toggle\",\"no\"],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"tabindex\",\"value\",\"groupValue\"],[0,\"no\",[35,4,[\"yes_no\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,6],[\"No\"],null]],[13],[2,\"\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"label\"],[14,0,\"sep\"],[12],[2,\"/\"],[13],[2,\"\\n    \"],[11,\"label\"],[24,0,\"neutral\"],[24,\"for\",\"neutral\"],[4,[38,3],[[32,0],\"toggle\",\"na\"],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"tabindex\",\"value\",\"groupValue\"],[0,\"na\",[35,4,[\"yes_no\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"N/A\"],[13],[2,\"\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"readOnly\",\"iff\",\"concat\",\"action\",\"result\",\"radio-button\",\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/inspections-table/result/yes-no/template.hbs"
    }
  });

  _exports.default = _default;
});