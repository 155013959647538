define("isolocity/mixins/complaint-mixin", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin", "isolocity/mixins/required-fields-validation-mixin"], function (_exports, _actionItemMixin, _eSignatureMixin, _iconListMixin, _requiredFieldsValidationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _iconListMixin.default, _actionItemMixin.default, _requiredFieldsValidationMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    modules: Ember.inject.service('modules'),
    moduleSlug: 'complaints',
    isValid: true,
    defaultRequiredFields: ['complaint_number', 'complaint_date'],
    defaultRequiredFieldsTranslation: {
      'complaint_date': 'Date of Complaint'
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('complaints', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('complaints', 'is_esignature_required_on_save', false);
    },

    cannotCreateDepartments: Ember.computed(function () {
      return !this.get('permissions').hasRoleAtLeast('manager');
    }),
    distinctNamedFields: [{
      id: 'adult_use_purchased',
      name: 'is_adult_use'
    }, {
      id: 'comments_from_complainant',
      name: 'comments'
    }, {
      id: 'comments_notes',
      name: 'additional_comments'
    }, {
      id: 'corrections_made',
      name: 'corrective_actions'
    }, {
      id: 'error_code',
      name: 'error_code_id'
    }, {
      id: 'capa_needed',
      name: 'is_capa_needed'
    }, {
      id: 'part_replaced',
      name: 'is_product_replaced'
    }, {
      id: 'recall_required',
      name: 'is_recall_required'
    }, {
      id: 'product_returned',
      name: 'is_product_returned'
    }, {
      id: 'reply_sent_to_complainant',
      name: 'is_reply_sent'
    }, {
      id: 'investigation_needed',
      name: 'is_investigation_needed'
    }, {
      id: 'retention_sample_examined',
      name: 'is_sample_examined'
    }, {
      id: 'source',
      name: 'complaint_source_id'
    }],
    complaintImpacts: ['', 'Critical', 'Major', 'Minor', 'No Risk'],
    complaintSources: ['', 'Phone', 'Email', 'In Person', 'Fax', 'Social Media'],
    complaintCategories: ['', 'Technical', 'Medical', 'Other'],
    hasSource: Ember.computed('model.complaint_source_id', function () {
      if (this.get('isNew') || this.get('isEdit')) {
        var source = this.get('model.complaint_source_id.name');
        return source === 'Customer' || source === 'Supplier';
      }
    }),
    onSourceChange: Ember.observer('model.complaint_source_id', function () {
      if (this.get('isNew') || this.get('isEdit')) {
        var model = this.get('model');

        if (model.get('complaint_source_id.name') !== 'Customer') {
          model.set('source_customer_id', null);
        }

        if (model.get('complaint_source_id.name') !== 'Supplier') {
          model.set('source_supplier_id', null);
        }
      }
    }),
    actions: {
      createActionItem: function createActionItem(item) {
        item.set('link', 'complaints.edit');
        item.set('entity_type', 'complaint');
        item.set('use_queue', true);

        if (this.get('isEdit')) {
          item.set('entity_id', this.get('model.id'));
        }

        item.set('code', 'complaint_implementation_plan');
        this.get('model.implementation_action_items').pushObject(item);

        if (this.get('isEdit')) {
          item.save();
        }
      }
    }
  });

  _exports.default = _default;
});