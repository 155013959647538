define("isolocity/pods/ncrs/new/route", ["exports", "isolocity/mixins/non-conformance-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _nonConformanceMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_nonConformanceMixin.default, _userOrganizationMixin.default, {
    persistence: Ember.inject.service(),
    model: function model() {
      var existingModel = this.get('persistence').find('ncrs.new');

      if (!Ember.isBlank(existingModel)) {
        if (!Ember.isEmpty(existingModel.model)) {
          return existingModel.model;
        }
      }

      return this.store.createRecord('ncr', {
        is_closed: false,
        error_code_name: '',
        date_opened: new moment()
      });
    },
    afterModel: function afterModel(model, transition) {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));

      if (!Ember.isBlank(user)) {
        model.get('owners').pushObject(user);
      }

      model.set('allDepartments', this.store.findAll('department'));
      model.set('error_codes', this.store.findAll('error-code'));
      model.set('parts', this.store.findAll('part'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('suppliers', this.store.findAll('supplier'));
      model.set('customers', this.store.findAll('customer'));
      model.set('inspections', this.store.findAll('inspection'));
      model.set('sources', this.ncrSources);
      model.set('severities', this.ncrSeverities);
      model.set('dispositions', this.ncrDispositions);
    }
  });

  _exports.default = _default;
});