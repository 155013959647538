define("isolocity/pods/components/label-and-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ETzNaXD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],null,[[\"labelFor\",\"translationKey\"],[[30,[36,8],[[35,7],[35,6]],null],[35,0]]],[[\"default\"],[{\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[15,1,[34,3]],[14,0,\"fa fa-info-circle m-l-5\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"targetId\",\"event\",\"side\"],[[35,3],\"hover\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h2\"],[14,0,\"tooltip-header\"],[12],[1,[30,[36,1],[[35,0]],null]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left\"],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"t\",\"helperSetting\",\"helperName\",\"ember-tooltip\",\"if\",\"keyName\",\"id_prefix\",\"concat\",\"t-label\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/label-and-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});