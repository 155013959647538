define("isolocity/pods/components/side-menu/component", ["exports", "ember-side-menu/utils/gestures", "ember-side-menu/components/side-menu"], function (_exports, _gestures, _sideMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sideMenu.default.extend({
    progress: Ember.computed.alias("menu.progress"),
    canOpen: Ember.computed.alias("menu.canOpen"),
    isOpen: Ember.computed.alias("menu.isOpen"),
    isClosed: Ember.computed.alias("menu.isClosed"),
    isSlightlyOpen: Ember.computed.alias("menu.isSlightlyOpen"),
    classNameBindings: ["isOpen:side-menu-open"],
    init: function init() {
      this._super.apply(this, arguments);

      var menu = Ember.get(this, "sideMenu").initMenu(Ember.get(this, "name"), Ember.get(this, "defaultTab"));
      Ember.set(this, "menu", menu);
    },
    _completeMenuTransition: function _completeMenuTransition(event) {// Evan: commented the lines below in order to issue #I-5029 on 6 March 2024
      // Evan: Do We Really need the following
      // Evan: i will keep them commented till we see if that affects anything else
      // const progress = get(this, "progress")
      // const touchStartEvent = get(this, "touchStartEvent")
      // const side = get(this, "side")
      // const gesture = createGesture(touchStartEvent, event)
      // const minClosingVelocity = 0.3
      // const autoCompleteThreshold = 50
      // const isSwipingLeft = gesture.velocityX > minClosingVelocity
      // const isSwipingRight = gesture.velocityX < -minClosingVelocity
      // const isClosingMovement = (side === "left" && isSwipingLeft) ||
      //           (side === "right" && isSwipingRight)
      // const isOpeningMovement = (side === "left" && isSwipingRight) ||
      //           (side === "right" && isSwipingLeft)
      // let menuName = get(this, "name")
      // if (isClosingMovement || progress < autoCompleteThreshold) {
      // 	alert(autoCompleteThreshold + ' open ' + menuName);
      // 	get(this, "sideMenu").set('canOpen', false)
      // 	get(this, "sideMenu").close(menuName)
      // } else if (isOpeningMovement || progress >= autoCompleteThreshold) {
      // 	alert(autoCompleteThreshold + ' close  ' + menuName);
      //   get(this, "sideMenu").open(menuName)
      // }
    },
    _sendTabChanged: function _sendTabChanged() {
      this.sendAction("tabChanged", Ember.get(this, "menu.tab"));
    },
    _setupObservers: function _setupObservers() {
      this._super.apply(this, arguments);

      this.addObserver("menu.tab", this, "_sendTabChanged");
    },
    _removeObservers: function _removeObservers() {
      this._super.apply(this, arguments);

      this.removeObserver("menu.tab", this, "_sendTabChanged");
    }
  });

  _exports.default = _default;
});