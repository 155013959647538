define("isolocity/pods/report-templates/new/controller", ["exports", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _historyHandlerMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    intl: Ember.inject.service(),
    isNew: true,
    isLoading: false,
    id_prefix: 'new_',
    cloneReportTemplate: null,
    requiredFields: ['name', 'identification', 'equipment_type_id.id'],
    onCloneReportTemplateChanged: Ember.observer('cloneReportTemplate', function () {
      if (!Ember.isBlank(this.get('cloneReportTemplate'))) {
        this.set('model.form', this.get('cloneReportTemplate.form'));
      }
    }),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          var formVal = $('#form').val();
          formVal = formVal.replace("&", "and");
          this.set('model.form', formVal);
          this.set('isLoading', true);
          this.get('model').save().then(function (template) {
            _this.set('isLoading', false);

            _this.transitionToRoute('report-templates.edit', template);

            fm.success(intl.t('report-templates.others.Create New Report'));
          }, function (response) {
            _this.set('isLoading', false);
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});