define("isolocity/services/search/oosforms", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    status: '',
    owner: null,
    batchNumber: null,
    getStatus: function getStatus() {
      return this.get('status');
    },
    setStatus: function setStatus(status) {
      this.set('status', status);
    },
    getOwner: function getOwner() {
      return this.get('owner');
    },
    setOwner: function setOwner(owner) {
      this.set('owner', owner);
    },
    getBatchNumber: function getBatchNumber() {
      return this.get('batchNumber');
    },
    setBatchNumber: function setBatchNumber(batchNumber) {
      this.set('batchNumber', batchNumber);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setStatus('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
      this.setOwner(null);
      this.setBatchNumber(null);
    }
  });

  _exports.default = _default;
});