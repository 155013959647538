define("isolocity/pods/action-items/edit/route", ["exports", "isolocity/mixins/action-item-priorities-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/ajax-request-mixin"], function (_exports, _actionItemPrioritiesMixin, _authenticationRoutingMixin, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_actionItemPrioritiesMixin.default, _authenticationRoutingMixin.default, _ajaxRequestMixin.default, {
    // Defer page access check to the API policy logic
    isVisibleAllUsers: true,
    beforeModel: function beforeModel() {
      var controller = this.controllerFor('action-items.edit');
      controller.set('canViewReport', false);
    },
    model: function model(params) {
      return this.store.findRecord('action-item', params.action_item_id, {
        reload: true
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('action-items');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('allEmployees', this.store.findAll('employee'));
      model.set('priorities', this.get('allPriorities'));
      this.checkCanAccessReport(model);
    },
    checkCanAccessReport: function checkCanAccessReport(model) {
      var controller = this.controllerFor('action-items.edit');
      $.ajax({
        headers: this.get('authHeaders'),
        url: this.getApiUrl('/action-items/' + model.get('id') + '/access'),
        method: 'GET'
      }).then(function (response) {
        controller.set('canViewReport', response.access);
      });
    }
  });

  _exports.default = _default;
});