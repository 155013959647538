define("isolocity/pods/components/display-header/component", ["exports", "isolocity/mixins/company-switch-mixin"], function (_exports, _companySwitchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_companySwitchMixin.default, {
    search: Ember.inject.service("search"),
    router: Ember.inject.service("-routing"),
    searchKeyword: '',
    actions: {
      searchAll: function searchAll() {
        var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (event && event.key === "Enter") {
          event.preventDefault();
          this.get("search").setKeyword(this.get("searchKeyword"));

          if (this.get("router.currentRouteName") === "search.index") {
            this.get("router").transitionTo("loading");
          }

          this.get("router").transitionTo('search');
        }
      }
    }
  });

  _exports.default = _default;
});