define("isolocity/pods/oosforms/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'oosforms.index',
    search: Ember.inject.service('search.oosforms'),
    modelName: ['oosform'],
    model: function model(params) {
      this.startLoading();
      var fromDate = !Ember.isEmpty(this.get('search').getFromDate()) ? this.get('search').getFromDate() : null;
      var toDate = !Ember.isEmpty(this.get('search').getToDate()) ? this.get('search').getToDate() : null;
      var filters = this.getQueryFilters();
      filters['per_page'] = _environment.default.APP.pagination;
      filters['page'] = this.get('currentPage');
      filters['keyword'] = this.get('search').getKeyword();
      filters['status'] = this.get('search').getStatus();
      filters['owner'] = this.get('search').getOwner();
      filters['batch_number'] = this.get('search').getBatchNumber();
      return {
        oosforms: this.store.query('oosform', filters)
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.oosforms.then(function (oosforms) {
        _this.doneLoading();

        _this.setPageMetadata(oosforms);
      });
      model.errorCodes = this.store.findAll('error-code');
      model.allUsers = this.store.findAll('user');
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.set('currentPage', 1);
        this.refresh();
      },
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('oosforms');
        }
      }
    }
  });

  _exports.default = _default;
});