define("isolocity/pods/planning-reports/edit/controller", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/planning-reports-mixin"], function (_exports, _actionItemMixin, _eSignatureMixin, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _planningReportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _planningReportsMixin.default, _actionItemMixin.default, _eSignatureMixin.default, {
    id_prefix: 'edit_',
    isEdit: true,
    isSaving: false,
    isReopening: false,
    permissions: Ember.inject.service('user-permissions'),
    noteEntityType: 'planning',
    actionLink: 'planning-reports.edit',
    actionEntityType: 'planning',
    intl: Ember.inject.service(),
    actionTitleOptions: Ember.computed(function () {
      return [];
    }),
    canReopenPlanning: Ember.computed(function () {
      if (this.get('permissions').hasRoleAtLeast('manager') && this.get('authManager.session.currentUser.can_reopen_reports')) {
        return true;
      }

      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    canDeletePlanning: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    hasFilesUploader: Ember.computed('model.is_closed', 'model.isSaving', function () {
      var _isClosed = this.get('model.is_closed'),
          _isSaving = this.get('model.isSaving');

      return !_isClosed || _isSaving;
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('planning-reports', 'is_reason_required_on_save', false);
    },

    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          this.set('isSaving', true);
          var model = this.get('model');
          var actionItems = model.get('result_action_items');
          model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (planning_report) {
            if (actionItems.get('length') === 0) {
              _this.send('saveAttachments', planning_report, false, _this.get('eSignaturePassword'), _this.get('eSignatureReason'));
            } else {
              actionItems.forEach(function (action) {
                if (action === model.get('result_action_items.lastObject')) {
                  action.save().then(function () {
                    _this.send('saveAttachments', planning_report);
                  }).catch(function () {
                    _this.set('isSaving', false);
                  });
                } else {
                  action.save();
                }
              });
            }
          }, function (error) {
            _this.set('isSaving', false);

            fm.danger(intl.t('planning-reports.others.Save Error'));
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(planning) {
        var intl = this.get('intl');
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        this.get('history').setRoute('planning-reports');
        this.transitionToRoute('planning-reports.edit', planning);
        fm.success(intl.t('planning-reports.others.Save Change Success'));
      },
      reopen: function reopen() {
        var _this2 = this;

        var model = this.get('model');
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var authHeaders = this.get('authHeaders');
        this.set('isReopening', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/planning/' + model.get('id') + '/reopen'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          model.set('date_closed', null);
          model.reload().then(function () {
            _this2.send('clearEsignature');

            fm.success(intl.t('planning-reports.others.Report reopen'));
          });
        }).fail(function () {
          fm.danger(intl.t('planning-reports.others.Report reopen Error'));
        }).always(function () {
          _this2.set('isReopening', false);
        });
      },
      createResultActionItem: function createResultActionItem(item) {
        item.set('link', 'planning-reports.edit');
        item.set('entity_type', 'planning');
        item.set('entity_id', this.get('model.id'));
        item.set('code', 'planning_result');
        this.get('model.result_action_items').pushObject(item);
        item.save();
      },
      remove: function remove() {
        var _this3 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this3.get('model').destroyRecord().then(function () {
            _this3.transitionToRoute('planning-reports');
          });

          _this3.set('deletePromise', null);
        }, function () {
          _this3.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      }
    }
  });

  _exports.default = _default;
});