define("isolocity/pods/components/auto-complete/component", ["exports", "isolocity/config/environment", "isolocity/mixins/click-outside-mixin", "ember-jquery-legacy", "isolocity/mixins/localization-mixin"], function (_exports, _environment, _clickOutsideMixin, _emberJqueryLegacy, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clickOutsideMixin.default, _localizationMixin.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNameBindings: [':selectivize', 'disabled:auto-complete-list--disabled', 'expanded:auto-complete-list--expanded'],
    attributeBindings: ['actionOnChange'],
    alwaysUseApi: false,
    isLoading: false,
    clearOnExpand: true,
    emptyRow: null,
    expanded: false,
    bubbles: false,
    tabindex: 0,
    filter: '',
    ignoreId: null,
    isIcon: false,
    isMultipleField: false,
    showStatus: false,
    isActivePassed: false,
    isInactiveFailed: false,
    searchParams: {
      list_type: 'for_autocompletion'
    },
    filteredItems: [],
    excludeItemIds: [],
    // notDisabled: !this.get('disabled'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('isServerSearch')) {
        this.set('filteredItems', this.get('items'));
      }
    },
    onItemsChanged: Ember.observer('items', 'items.length', function () {
      if (!this.get('isServerSearch')) {
        this.set('filteredItems', this.get('items'));
        this.notifyPropertyChange('finalItems');
      }
    }),
    notDisabled: Ember.computed('disabled', function () {
      return !this.get('disabled');
    }),
    filterPlaceholder: Ember.computed('isServerSearch', function () {
      if (this.get('isServerSearch')) {
        return this.get('intl').t('Start typing to search') + '...';
      }

      return this.get('intl').t('Enter a value to filter by') + '...';
    }),
    isServerSearch: Ember.computed('entity', 'items.length', 'alwaysUseApi', function () {
      return this.get('entity') && (this.get('items.length') >= _environment.default.APP.pagination || this.get('alwaysUseApi') === true);
    }),
    hasNoResults: Ember.computed('finalItems.length', function () {
      return this.get('finalItems.length') === 0;
    }),
    filtersRegex: Ember.computed('filter', 'filterValue', function () {
      if (!Ember.isBlank(this.get('filter'))) {
        return this.get('filter').split(' ').join('|');
      } else if (!Ember.isBlank(this.get('filterValue'))) {
        return this.get('filterValue').split(' ').join('|');
      }

      return '';
    }),
    onFilterValueChange: Ember.observer('filterValue', function () {
      if (this.get('isServerSearch')) {
        this.set('filteredItems', []);

        if (!Ember.isEmpty(this.get('filterValue'))) {
          this.set('isLoading', true);
        }

        Ember.run.debounce(this, this.applyFilter, 500);
      } else {
        this.notifyPropertyChange('finalItems');
      }
    }),
    applyFilter: function applyFilter() {
      this.set('filter', this.get('filterValue'));
    },
    onFilterChange: Ember.observer('filter', function () {
      var _this = this,
          filter = this.get('filter'),
          ignoreId = this.get('ignoreId');

      if (Ember.isEmpty(filter)) {
        if (this.get('isServerSearch')) {
          _this.set('filteredItems', []);

          _this.set('isLoading', false);
        }

        _this.notifyPropertyChange('finalItems');

        return;
      }

      var defaultParams = {
        keyword: filter,
        ignoreId: ignoreId
      };
      var params = Object.assign(defaultParams, this.get('searchParams'));
      this.get('store').query(this.get('entity'), params).then(function (items) {
        _this.set('isLoading', false);

        _this.set('filteredItems', items);

        if (_this.attrs.onItemsUpdated) {
          _this.sendAction('onItemsUpdated');
        }

        _this.notifyPropertyChange('finalItems');
      });
    }),
    finalItems: Ember.computed('filteredItems', 'filterValue', 'excludeItemIds', function () {
      var _this2 = this;

      var filter = '',
          items = this.get('filteredItems');

      if (!Ember.isEmpty(this.get('filterValue'))) {
        filter = this.get('filterValue');
      }

      if (Ember.isEmpty(items)) return items;
      return items.filter(function (item) {
        var value = item;

        if (Ember.typeOf(item) === 'instance') {
          value = _this2.getDisplayNameOfEmberObject(item);
        } else if (Ember.typeOf(item) === 'object') {
          value = item.name;
        }

        var valueMatchesFilter = !Ember.isEmpty(value) && (value.indexOf(filter) !== -1 || value.toLowerCase().indexOf(filter.toLowerCase()) !== -1) && (Ember.isBlank(_this2.get('ignoreId')) || item.get('id') !== _this2.get('ignoreId')) && (Ember.isBlank(_this2.get('excludeItemIds')) || !_this2.get('excludeItemIds').includes(parseInt(item.get('id'))));

        if (Ember.typeOf(item) === 'instance' && !Ember.isEmpty(item.get('hideInactiveFromDropdowns'))) {
          if (valueMatchesFilter && (item.get('is_active') || item.get('is_closed') === false)) {
            return item;
          }
        } else if (valueMatchesFilter) {
          return item;
        } else if (_this2.get("isMultipleField") && _this2.get("isServerSearch")) {
          return item;
        }
      });
    }),
    onExpand: Ember.observer('expanded', function () {
      if (this.get('clearOnExpand') === true && this.get('expanded') === true) {
        // this.set('value', null);
        this.set('filterValue', '');
      } //adjust selected value


      this.$('.current').removeClass('current');
      var options = this.$('.options'),
          active = options.children('.selected');

      if (active.length > 0) {
        active.addClass('current');
        options.scrollTop(options.scrollTop() + active.position().top - 2 * active.outerHeight());
      }
    }),
    onClickOutside: function onClickOutside() {
      this.send('closeDropdown');
    },
    click: function click(event) {
      var ev = (0, _emberJqueryLegacy.normalizeEvent)(event);
      event.preventDefault();
      this.$('.auto-complete-list').slideToggle('fast');
      this.$('.auto-complete__filter input').focus();

      if (this.get('disabled') !== true) {
        if (this.get('expanded')) {
          var target = $(event.target);

          if (!(target.is('.not-auto-collapsing') || target.parents('.not-auto-collapsing').length > 0)) {
            this.set('expanded', false);
          }
        } else {
          this.set('expanded', true);
        }
      }
    },
    keyUp: function keyUp(ev) {
      var keys = {
        tab: 9,
        enter: 13,
        escape: 27,
        up: 38,
        down: 40
      },
          classes = {
        list: '.options',
        item: '.options__item'
      };

      if (ev.keyCode === keys['escape']) {
        this.set('expanded', false);
        return;
      }

      if (ev.keyCode === keys['enter']) {
        if (this.get('expanded')) this.send('selectFilteredItem');else this.set('expanded', true);
        return;
      }

      var target = $(ev.target),
          options = this.$(classes['list']); //return no action if options has no children

      if (options.children(classes['item']).length === 0) return; //get current active, or get first

      var active,
          current = options.children(classes['item'] + '.current'); //   if(ev.keyCode === keys['tab']) {
      //     let focused = options.find(classes['item'] + ':focus').first();
      //     current.removeClass('current');
      //     focused.addClass('current');
      //     return;
      //   }

      if (current.length === 0) {
        switch (ev.keyCode) {
          case keys['up']:
            active = options.children(classes['item']).last();
            break;

          case keys['down']:
            active = options.children(classes['item']).first();
            break;

          default:
            return;
        }
      } else {
        current = current.first();

        switch (ev.keyCode) {
          case keys['up']:
            //up;
            active = current.prev();
            break;

          case keys['down']:
            //down;
            active = current.next();
            break;

          case keys['enter']:
            current.click();

          /* falls through */

          default:
            return;
        }

        if (active.length === 0) return;
        options.children('.current').removeClass('current');
      } //set new active and animate to new selection


      active.addClass('current');
      options.scrollTop(options.scrollTop() + active.position().top - 2 * active.outerHeight());
    },
    keyDown: function keyDown(ev) {
      if ($(ev.target).is('.auto-complete__filter input')) {
        if (ev.keyCode === 38 || ev.keyCode === 40 || ev.keyCode === 13) {
          return false;
        }
      }
    },
    getDisplayNameOfEmberObject: function getDisplayNameOfEmberObject(item) {
      if (Ember.typeOf(item) === 'instance') {
        if (item.get('displayName')) {
          return item.get('displayName');
        }

        if (item.get('name')) {
          return item.get('name');
        }
      }

      return '';
    },
    actions: {
      changeValue: function changeValue(item) {
        this.set('value', item);

        if (typeof this.actionOnChange != "undefined") {
          this.actionOnChange(item);
        }

        ;
      },
      closeDropdown: function closeDropdown() {
        this.set('expanded', false);
      },
      clearFilter: function clearFilter() {
        this.set('filterValue', '');
        this.$('.auto-complete__filter input').focus();
      },
      selectFilteredItem: function selectFilteredItem() {
        this.$('.options__item.current').click();
      },
      confirmAddNew: function confirmAddNew() {
        var _this3 = this;

        this.set('confirmPromise', null);
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this3.get('router').transitionTo(_this3.get('createRoute'));

          _this3.set('confirmPromise', null);
        }, function () {
          _this3.set('confirmPromise', null);
        });
        this.set('confirmPromise', defer);
      }
    }
  });

  _exports.default = _default;
});