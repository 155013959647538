define("isolocity/mixins/logout-handler-mixin", ["exports", "isolocity/mixins/freshchat-mixin"], function (_exports, _freshchatMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_freshchatMixin.default, {
    authManager: Ember.inject.service('session'),
    isAuthenticated: Ember.computed(function () {
      var auth = this.get('authManager');
      return auth.get('isAuthenticated');
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        var authManager = this.get('authManager');
        authManager.invalidate();
        authManager.set('session.currentUser', undefined);
        this.clearFreshchat();
        localStorage.removeItem('user_language');
        localStorage.removeItem("_tokenExpiredTime");
      }
    }
  });

  _exports.default = _default;
});