define("isolocity/pods/action-items/completed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E58GFC/l",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,5],null,[[\"isModelLoading\",\"model\"],[[35,4],[35,3,[\"actionItems\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-three-quarter\"],[12],[2,\"\\n            \"],[19,\"action-items/search\",[1]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-quarter col-create-btn\"],[12],[2,\"\\n            \"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-go pull-right\",\"action-items.new\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"action-items.Create Action Item\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[1,[30,[36,2],null,[[\"actionItems\",\"onActionItemToggled\",\"allowCreate\",\"isLinks\"],[[32,1],\"toggleCompleted\",false,true]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"secondary-link\"],[12],[2,\"\\n    \"],[6,[37,1],null,[[\"route\"],[\"action-items.index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"action-items.others.View Open Action Items\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"t\",\"link-to\",\"action-items-cards\",\"model\",\"isModelLoading\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/action-items/completed/template.hbs"
    }
  });

  _exports.default = _default;
});