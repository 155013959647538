define("isolocity/pods/risk-report/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    // Form fields
    initiator_user_id: _emberData.default.belongsTo('user'),
    initiated_at: _emberData.default.attr('utc'),
    approver_user_id: _emberData.default.belongsTo('user'),
    approved_at: _emberData.default.attr('utc'),
    report_number: _emberData.default.attr('string'),
    report_type: _emberData.default.attr('string'),
    pfmea_items: _emberData.default.hasMany('risk-report.pfmea-item'),
    haccp_items: _emberData.default.hasMany('risk-report.haccp-item'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    departments: _emberData.default.hasMany('department'),
    esignatures: _emberData.default.hasMany('esignature'),
    part_id: _emberData.default.belongsTo('part'),
    pfmea_details: _emberData.default.attr(),
    haccp_details: _emberData.default.attr(),
    files: _emberData.default.attr(),
    updated_at: _emberData.default.attr('utc'),
    form_name: _emberData.default.attr('string'),
    form_rev_level: _emberData.default.attr('string'),
    last_review_date: _emberData.default.attr('utc'),
    next_review_date: _emberData.default.attr('utc'),
    review_repeat_duration: _emberData.default.attr('string'),
    review_repeat_frequency: _emberData.default.attr('string'),
    // Action items & notes
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    // Other Fields
    activities: _emberData.default.hasMany('activity'),
    // Computed
    reportTypeName: Ember.computed('report_type', function () {
      return this.get('report_type').toUpperCase();
    }),
    initiatedAt: Ember.computed('initiated_at', function () {
      return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return Ember.isEmpty(this.get('updated_at')) ? '' : moment(this.get('updated_at')).format("MMM D, YYYY h:mm A");
    }),
    approvedAt: Ember.computed('approved_at', function () {
      return Ember.isEmpty(this.get('approved_at')) ? '' : moment(this.get('approved_at')).format("MMM D, YYYY h:mm A");
    }),
    processOwnerAuthorizedAt: Ember.computed('process_owner_authorized_at', function () {
      return Ember.isEmpty(this.get('process_owner_authorized_at')) ? '' : moment(this.get('process_owner_authorized_at')).format("MMM D, YYYY h:mm A");
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});