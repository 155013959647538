define("isolocity/pods/document-public/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b0xmPM56",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content supplier-upload\"],[14,\"role\",\"primary\"],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[10,\"center\"],[12],[1,[35,0,[\"name\"]]],[2,\" - \"],[1,[35,0,[\"rev_number\"]]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"content-block\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"col col-quarter\"],[12],[2,\" \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"col col-half\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"form-row uploaded-file-container\"],[12],[2,\"\\n                        \"],[1,[30,[36,3],null,[[\"files\",\"tokenName\",\"tokenValue\",\"entity\"],[[35,0,[\"current_revision\",\"files\"]],\"document_hash\",[35,2],\"document\"]]]],[2,\"\\n                    \"],[13],[2,\" \\n                    \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0,[\"current_revision\",\"links\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                            \"],[1,[30,[36,1],null,[[\"links\"],[[35,0,[\"current_revision\",\"links\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col col-quarter\"],[12],[2,\" \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"footer\"],[14,0,\"bottom\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"powered-by\"],[12],[1,[30,[36,5],[\"Powered by\"],null]],[2,\" ISOlocity\"],[13],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/img/logo-sm.png\"],[14,\"title\",\"ISOlocity\"],[14,0,\"logo--small\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"document-links\",\"documentHash\",\"uploaded-files/public\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "isolocity/pods/document-public/template.hbs"
    }
  });

  _exports.default = _default;
});