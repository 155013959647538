define("isolocity/pods/action-items/new/route", ["exports", "isolocity/mixins/action-item-priorities-mixin", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _actionItemPrioritiesMixin, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_actionItemPrioritiesMixin.default, _authenticationRoutingMixin.default, {
    isVisibleAllUsers: false,
    model: function model() {
      return this.store.createRecord('action-item');
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('allEmployees', this.store.findAll('employee'));
      model.set('priorities', this.get('allPriorities'));
    }
  });

  _exports.default = _default;
});